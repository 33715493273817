import React, { useContext, useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';

import LoadingContext from '../../context/loadingContext/loadingContext';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';
import InfoContext from '../../context/infoContext/infoContext';
import MessageContext from '../../context/messageContext/messageContext';

import { AvalResponderQuestionario } from '../../components/AutoAvaliacao/AvalResponderQuestionario';

import autoavaliacaoService from '../../services/autoavaliacaoService';
import { formatDate } from '../../helpers/Date';

export function AutoAvaliacaoResponder(props) {

    //Contexto
    const loadingContext = useContext(LoadingContext);
    const idCt = useContext(IdiomaContext);
    const infoContext = useContext(InfoContext);
    const messageContext = useContext(MessageContext);

    //Constructor
    const [dadosOferta, setDadosOferta] = useState(null);
    const [AcessoNegado, setAcessoNegado] = useState(true);
    const [error, setError] = useState(null);
    const [iniciarAvalDiscente, setIniciarAvalDiscente] = useState(false); 

    useEffect(() => {
        //componentDidMount
        if ((infoContext.user != null) && ((infoContext.user.profile.professor) || (infoContext.user.profile.aluno) || (infoContext.user.profile.coordenadorCurso))) {
            setAcessoNegado(false);
            loadingContext.showLoading();
            lerDados();
            loadingContext.hideLoading();
        }
        else {
            messageContext.openMessage("warning", "Somente docentes e discentes tem acesso a este conteúdo.");
        }
    }, []); 

    function lerDados() {
        autoavaliacaoService.UltimaOferta()
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(DadosResposta => {
                setDadosOferta(DadosResposta);
            })
            .catch(error => {
                messageContext.openMessage("error", "Erro.Por favor, tente novamente.");
                setError(error);
            });
    }

    function renderAll() {
        let contents = <p>{idCt.t("Sistema de Autoavaliação do ProfEPT", "ProfEPT Self-Assessment System")}</p>;
        let contents2 = <span></span>;
        if (AcessoNegado) {
            contents = <p>{idCt.t("Somente docentes e discentes tem acesso a este conteúdo.", "Only professors and students have access to this content.")}</p>;
        }
        else if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (dadosOferta == null) {
            contents = <p><i className="fa fa-refresh fa-spin"></i> <em>{idCt.t("Lendo dados...", "Loading...")}</em></p>;
        }
        else if ((infoContext.user.profile.professor) || (infoContext.user.profile.coordenadorCurso)) {
            let textoDocente = "Docente";
            if ((infoContext.user) && (infoContext.user.profile.coordenadorCurso))
                textoDocente = "Coordenador Local";
            if (parseInt(dadosOferta.situacaoAvaliacaoDocente) === 1) { //Aberta
                if (infoContext.user.profile.coordenadorCurso) {
                    contents = <AvalResponderQuestionario TipoAvaliador="coordenadorCurso" />;
                }
                else {
                    contents = <AvalResponderQuestionario TipoAvaliador="docente" />;
                }
            }
            else if (parseInt(dadosOferta.situacaoAvaliacaoDocente) === 2) { //Nao comecou
                contents =
                    <div>
                        <p> Caro <b>{textoDocente}</b>, o questionário da autoavaliação ({dadosOferta.anoSemestre}) <b>ainda não está disponível</b>. </p>
                        <p> O prazo para responder o questionário é de <b>{formatDate(dadosOferta.inicioAvaliacaoDocente)}</b> a <b>{formatDate(dadosOferta.fimAvaliacaoDocente)}</b>. </p>
                    </div>;
            }
            else if (parseInt(dadosOferta.situacaoAvaliacaoDocente) === 3) { //Encerrou
                contents =
                    <div>
                        <p> Caro <b>{textoDocente}</b>, o prazo para responder o questionário da autoavaliação ({dadosOferta.anoSemestre}) <b>já encerrou</b>. </p>
                        <p> O prazo foi de <b>{formatDate(dadosOferta.inicioAvaliacaoDocente)}</b> a <b>{formatDate(dadosOferta.fimAvaliacaoDocente)}</b>. </p>
                    </div>;
            }
        }
        else if (infoContext.user.profile.aluno) {
            if (parseInt(dadosOferta.situacaoDiscenteEgresso) === 4) { //Egresso que já participou em outro momento
                contents =
                    <div>
                        <p> Caro <b>Egresso</b>, você já participou da autoavaliação em outro momento. </p>
                    </div>;
            }
            else if (parseInt(dadosOferta.situacaoDiscenteEgresso) === 2) { //Egresso
                if (parseInt(dadosOferta.situacaoAvaliacaoEgresso) === 1) { //Aberta
                    contents = <AvalResponderQuestionario TipoAvaliador="egresso" />;
                }
                else if (parseInt(dadosOferta.situacaoAvaliacaoEgresso) === 2) { //Nao comecou
                    contents =
                        <div>
                            <p> Caro <b>Egresso</b>, o questionário da autoavaliação ({dadosOferta.anoSemestre}) <b>ainda não está disponível</b>. </p>
                            <p> O prazo para responder o questionário é de <b>{formatDate(dadosOferta.inicioAvaliacaoEgresso)}</b> a <b>{formatDate(dadosOferta.fimAvaliacaoEgresso)}</b>. </p>
                        </div>;
                }
                else if (parseInt(dadosOferta.situacaoAvaliacaoEgresso) === 3) { //Encerrou
                    contents =
                        <div>
                            <p> Caro <b>Egresso</b>, o prazo para responder o questionário da autoavaliação ({dadosOferta.anoSemestre}) <b>já encerrou</b>. </p>
                            <p> O prazo foi de <b>{formatDate(dadosOferta.inicioAvaliacaoEgresso)}</b> a <b>{formatDate(dadosOferta.fimAvaliacaoEgresso)}</b>. </p>
                        </div>;
                }
            }
            else if (parseInt(dadosOferta.situacaoDiscenteEgresso) === 3) { //Discente e não pode ser Egresso
                if (parseInt(dadosOferta.situacaoAvaliacaoDiscente) === 1) { //Aberta
                    contents = <AvalResponderQuestionario TipoAvaliador="discente" />;
                }
                else if (parseInt(dadosOferta.situacaoAvaliacaoDiscente) === 2) { //Nao comecou
                    contents =
                        <div>
                            <p> Caro <b>Discente</b>, o questionário da autoavaliação ({dadosOferta.anoSemestre}) <b>ainda não está disponível</b>. </p>
                            <p> O prazo para responder o questionário é de <b>{formatDate(dadosOferta.inicioAvaliacaoDiscente)}</b> a <b>{formatDate(dadosOferta.fimAvaliacaoDiscente)}</b>. </p>
                        </div>;
                }
                else if (parseInt(dadosOferta.situacaoAvaliacaoDiscente) === 3) { //Encerrou
                    contents =
                        <div>
                            <p> Caro <b>Discente</b>, o prazo para responder o questionário da autoavaliação ({dadosOferta.anoSemestre}) <b>já encerrou</b>. </p>
                            <p> O prazo foi de <b>{formatDate(dadosOferta.inicioAvaliacaoDiscente)}</b> a <b>{formatDate(dadosOferta.fimAvaliacaoDiscente)}</b>. </p>
                        </div>;
                }
            }
            else if (iniciarAvalDiscente) {
                contents = <AvalResponderQuestionario TipoAvaliador="discente" />;
            }
            else if (parseInt(dadosOferta.situacaoDiscenteEgresso) === 1) { //Discente, mas pode ser egresso
                //Egresso
                contents =
                    <div>
                        <p> Olá, se você <b>já concluiu</b> o Mestrado ProfEPT, você precisa atualizar suas informações sobre a defesa de dissertação.<br/>
                            Após realizar a atualização dos dados, volte aqui novamente para responder a autoavaliação com o <b>perfil de egresso</b>.</p>
                        <div style={{ textAlign: "left" }}>
                            <Link to='/perfilEgresso'><Button label="Defesa de Dissertação" className="p-button-success" /></Link>
                        </div> 
                        <br />
                        <p> <b>Egresso</b>, o prazo para responder o questionário é de <b>{formatDate(dadosOferta.inicioAvaliacaoEgresso)}</b> a <b>{formatDate(dadosOferta.fimAvaliacaoEgresso)}</b>. </p>
                        <br />
                    </div>;
                if (parseInt(dadosOferta.situacaoAvaliacaoDiscente) === 1) { //Aberta
                    contents2 =
                        <div>
                            <p> Caso você esteja <b>cursando o Mestrado ProfEPT</b>, clique no botão abaixo para iniciar a autoavaliação ({dadosOferta.anoSemestre}) com o <b>perfil de discente</b>.</p>
                            <div style={{ textAlign: "left" }}>
                                <Button label="Responder como Discente" onClick = {() => setIniciarAvalDiscente(true)} className="p-button-success" />
                            </div>
                            <br />
                        </div>;
                }
                //parei aqui
                else if (parseInt(dadosOferta.situacaoAvaliacaoDiscente) === 2) { //Nao comecou
                    contents2 =
                        <div>
                            <p> Caso você esteja <b>cursando o Mestrado ProfEPT</b>, você participará da autoavaliação com o <b>perfil de discente</b>.</p>
                            <p> O questionário da autoavaliação ({dadosOferta.anoSemestre}) <b>ainda não está disponível</b>. </p>
                            <p> <b>Discente</b>, o prazo para responder o questionário é de <b>{formatDate(dadosOferta.inicioAvaliacaoDiscente)}</b> a <b>{formatDate(dadosOferta.fimAvaliacaoDiscente)}</b>. </p>
                        </div>;
                }
                else if (parseInt(dadosOferta.situacaoAvaliacaoDiscente) === 3) { //Encerrou
                    contents2 =
                        <div>
                            <p> Caso você esteja <b>cursando o Mestrado ProfEPT</b>, você deve participar da autoavaliação com o <b>perfil de discente</b>.</p>
                            <p> O prazo para responder o questionário da autoavaliação ({dadosOferta.anoSemestre}) <b>já encerrou</b>. </p>
                            <p> <b>Discente</b>, o prazo foi de <b>{formatDate(dadosOferta.inicioAvaliacaoDiscente)}</b> a <b>{formatDate(dadosOferta.fimAvaliacaoDiscente)}</b>. </p>
                        </div>;
                }
            }
        }

        return (
            <Container >
                <Row>
                    <Col sm={12}>
                        <h2>{idCt.t("Responder Autoavaliação", "Respond to self-assessment")}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>                    
                        <PainelConteudo legenda={idCt.t("Autoavaliação", "Self-assessment")}
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        {contents}
                                        {contents2}
                                    </Col>
                                </Row>                            
                            } />
                    </Col>
                </Row>
            </Container >
        );
    }

    return (
        renderAll()
        );
}
