import React, { useState, useContext, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';

import LoadingContext from '../../context/loadingContext/loadingContext';
import MessageContext from '../../context/messageContext/messageContext';
import InfoContext from '../../context/infoContext/infoContext';

import { formatDate } from '../../helpers/Date';
import { stableSort, getSorting, toolbarStyles, styles } from '../../helpers/TableMaterialUI';

import disciplinaService from '../../services/disciplinaService';

let EnhancedTableToolbar = props => {
    const { numSelected, classes } = props;

    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        <span style={{ fontSize: "16px" }}>{numSelected} selecionadas</span>
                    </Typography>
                ) : (
                    <Typography variant="h6" id="tableTitle">
                        <span style={{ fontSize: "16px" }}>Caro <b>discente</b>, selecione as disciplinas eletivas:</span>
                    </Typography>
                    )}
            </div>
            <div className={classes.spacer} />
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

function AlunoDiscEletiva(props) {

    //Contexto
    const loadingContext = useContext(LoadingContext);
    const messageContext = useContext(MessageContext);
    const infoContext = useContext(InfoContext);

    //Construtor
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('nome');
    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [dadosOferta, setDadosOferta] = useState(null);
    const [error, setError] = useState(null);
    const [maxSelecionadas, setMaxSelecionadas] = useState(3);

    useEffect(() => {
        //componentDidMount
        loadingContext.showLoading();
        disciplinaService.UltimaOfertaAluno()
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados do perfil...');
                }
            })
            .then(DadosResposta => {
                setDadosOferta(DadosResposta);
                setData(DadosResposta.disciplinas);
                setRowsPerPage(DadosResposta.disciplinas.length);
                AtualizarSelecionadas(DadosResposta);
            })
            .catch(error => {
                messageContext.openMessage("error", "Erro.Por favor, tente novamente.");
                setError(error);
            });
        loadingContext.hideLoading();
    }, []); 

    function AtualizarSelecionadas(DadosResposta) {
        let newSelected = [];        
        for (let i = 0; i < DadosResposta.disciplinas.length; i++) {
            if (DadosResposta.disciplinas[i].selecionada) {
                newSelected = newSelected.concat(selected, DadosResposta.disciplinas[i].id);
            }
        }     
        setSelected(newSelected);
        if ( (DadosResposta.semestre === 1) && (infoContext.userInfo) && (DadosResposta.anoTurmaInicial === infoContext.userInfo.anoIngresso)) {
            setMaxSelecionadas(0);
        }
        else if ((DadosResposta.semestre === 2) && (infoContext.userInfo) && (DadosResposta.anoTurmaInicial === infoContext.userInfo.anoIngresso)) {
            setMaxSelecionadas(1);
        }
    }

    const handleRequestSort = (event, property) => {
        const porderBy = property;
        let porder = 'desc';

        if (orderBy === property && order === 'desc') {
            porder = 'asc';
        }
        setOrder(porder);
        setOrderBy(porderBy);        
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            setSelected(data.map(n => n.id));
            //this.setState(state => ({ selected: state.data.map(n => n.id) }));
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id, inscProf) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if ((selected.length === maxSelecionadas) && (selectedIndex === -1)) {
            messageContext.openMessage("warning", "Selecione no máximo " + maxSelecionadas + " disciplinas.");            
            return;
        }

        if (selectedIndex === -1) {
            InserirRemoverDisciplina(id, inscProf, 'INSERIR');
        }
        else {            
            InserirRemoverDisciplina(id, inscProf, 'REMOVER');
        }        

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);            
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);        
    };

    function InserirRemoverDisciplina(id, inscProf, pOpcao) {
        loadingContext.showLoading();

        let NinscProf = (inscProf) ? inscProf : 0;        

        let pBody = JSON.stringify({
            idOferta: dadosOferta.id, idAluno: 0, opcao: pOpcao, disciplinaID: id, ofertaInscProf: NinscProf
        });

        if (infoContext.user.profile.aluno) {
            disciplinaService.PutAtualizaDiscAluno(pBody)
                .then(r => {
                    r.json().then(response => {
                        if ((response.statusCode) && (response.statusCode > 1)) {
                            messageContext.openMessage("warning", response);
                        }
                        else {
                            //sucesso
                        }
                    });
                })
                .catch(error => {
                    messageContext.openMessage("error", "Erro.Por favor, tente novamente.");
                    setError(error);
                });
        }
        loadingContext.hideLoading();
    } 

    const handleChangePage = (event, page) => {
        setPage(page);        
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value);
    };

    const funcIsSelected = id => selected.indexOf(id) !== -1;

    function renderAll() {
        const { classes } = props;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

        let contents = <p><i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em></p>;
        if (loadingContext.loading) {
            return (
                <div>
                    {contents}
                </div>
            );
        }
        else if ((!dadosOferta) || (!dadosOferta.inicioInscAluno)) {
            contents =
                <div>
                    <p> Caro <b>Discente</b>, a oferta de disciplinas eletivas não começou. </p>
                </div>
        }
        else if (dadosOferta.situacaoAluno === 2) {
            contents =
                <div>
                    <p> Caro <b>Discente</b>, a oferta de disciplinas eletivas <b>não começou</b>. </p>
                    <p> O prazo para os discentes informarem o seu interesse nas disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscAluno)}</b> a <b>{formatDate(dadosOferta.fimInscAluno)}</b>. </p>
                    <p> O prazo para os docentes informarem a oferta de disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscProf)}</b> a <b>{formatDate(dadosOferta.fimInscProf)}</b>. </p>
                    <p> A enturmação ocorrerá entre os dias <b>{formatDate(dadosOferta.inicioEnturmacao)}</b> e <b>{formatDate(dadosOferta.fimEnturmacao)}</b>. </p>
                </div>;
        }
        else if (dadosOferta.situacaoAluno === 3) {
            contents =
                <div>
                    <p> Caro <b>Discente</b>, a oferta de disciplinas eletivas <b>já encerrou</b>. </p>
                    <p> O prazo para os discentes informarem o seu interesse nas disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscAluno)}</b> a <b>{formatDate(dadosOferta.fimInscAluno)}</b>. </p>
                    <p> O prazo para os docentes informarem a oferta de disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscProf)}</b> a <b>{formatDate(dadosOferta.fimInscProf)}</b>. </p>
                    <p> A enturmação ocorreu / ocorrerá entre os dias <b>{formatDate(dadosOferta.inicioEnturmacao)}</b> e <b>{formatDate(dadosOferta.fimEnturmacao)}</b>. </p>
                </div>;
        }           
        else {
            contents =
            <div>
                <p> Caro <b>Discente</b>, aqui você vai escolher as disciplinas eletivas que deseja cursar em <b>{dadosOferta.anoSemestre}</b>. </p>
                <p> O prazo para os discentes informarem o seu interesse nas disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscAluno)}</b> a <b>{formatDate(dadosOferta.fimInscAluno)}</b>. </p>
                <p> O prazo para os docentes informarem a oferta de disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscProf)}</b> a <b>{formatDate(dadosOferta.fimInscProf)}</b>. </p>
                <p> A enturmação ocorrerá entre os dias <b>{formatDate(dadosOferta.inicioEnturmacao)}</b> e <b>{formatDate(dadosOferta.fimEnturmacao)}</b>. </p>
                <p> Você pode selecionar até <b>{maxSelecionadas} disciplina(s) eletiva(s)</b>. Ao marcar ou desmarcar a disciplina, os dados são salvos automaticamente.</p>
                <Paper className={classes.root}>
                    <EnhancedTableToolbar numSelected={selected.length} />
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table} aria-labelledby="tableTitle">
                            <TableBody>
                                {stableSort(data, getSorting(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(n => {
                                        const isSelected = funcIsSelected(n.id);
                                        return (
                                            <TableRow
                                                hover
                                                onClick={event => handleClick(event, n.id, n.inscProf)}
                                                role="checkbox"
                                                aria-checked={isSelected}
                                                tabIndex={-1}
                                                key={n.id}
                                                selected={isSelected}
                                            >
                                                <TableCell style={{ width: '10px' }} padding="checkbox">
                                                    <Checkbox checked={isSelected} />
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    <span style={{ fontSize: "14px" }}>{n.nome}</span><br/>
                                                    <i><span style={{ fontSize: "10px" }}>{n.conteudo}</span></i>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 49 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>
            </div>
        }
        return (
            <div>
                {contents}
            </div>
        );
    }

    return (
        renderAll()
        )
};

AlunoDiscEletiva.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AlunoDiscEletiva);
