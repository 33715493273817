import React, { Component } from 'react';
import { TagCloud } from "react-tagcloud";

export default class NuvemAssuntos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            Assuntos: [],
            stackedData: {},
            stackedOptions: {},
            topics: []
        };
    }

    componentDidMount() {
        this.CarregarDados();
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.QualInstituicao !== this.props.QualInstituicao) ||
            (prevProps.QualAno !== this.props.QualAno)) {
            this.CarregarDados();
        }
    }

    CarregarDados() {
        this.setState({ loading: true });
        if ((this.props.QualInstituicao === -1) || (this.props.QualAno == null))
            return;
        if (this.props.QualAno.length === 0)
            return;
        let StringFetch = 'api/Indicadores/Assuntos?QualInstituicao=' + this.props.QualInstituicao + '&QuaisAnos=' + this.props.QualAno;
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ Assuntos: data, loading: false });
                this.ConfiguraDados();
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    ConfiguraDados() {
        let QuaisItens = [];
        let a = this.state.Assuntos;
        for (let i = 0; i < a.length; i++) {
            let Item = { value: 'palavra', count: 0 };
            Item.value = a[i].text;
            Item.count = a[i].qtde;
            QuaisItens.push(Item);
        }

        this.setState({
            topics: QuaisItens
        });

    }

    renderAssuntos() {        
        var PalavraMaxWidth = 50;
        if (window.innerWidth < 770) {
            PalavraMaxWidth = 30;
        }
        /*
        let fontSizeMapper = word => Math.log2(word.value * 50) * 5;
        let rotate = word => word.value % 360;
        */
        return (
            <TagCloud minSize={12}
                maxSize={PalavraMaxWidth}
                tags={this.state.topics}
                /*onClick={tag => alert(`${tag.value} was selected!`)}*/ />
        );
    }

    render() {
        const { loading, error } = this.state;

        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents = <div className="overlay">
                <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
            </div>;
        }
        else {
            contents = this.renderAssuntos();// <span></span>; //this.renderAssuntos();
        }

        return (
            <div>
                {contents}
            </div>
        );
    }
}