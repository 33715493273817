import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { ApplicationPaths } from '../../components/api-authorization/ApiAuthorizationConstants';

// Components
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';

export default function PrimeiroAcesso(props) {

    const idCt = useContext(IdiomaContext);
    const registerPath = `${ApplicationPaths.Register}`;
    const loginPath = `${ApplicationPaths.Login}`;

    return (
          <Container >
              <Row>
                  <Col sm={12}>
                    <h2>{idCt.t("Primeiro acesso", "First login")}</h2>
                  </Col>
              </Row>
              <Row>
                  <Col sm={12}>
                    {idCt.t("Somente docentes, alunos e secretários do programa ProfEPT podem possuir conta de usuário neste site.", "Only professors, students and secretaries of the ProfEPT may have a user account on this site.")}
                  </Col>
              </Row>
              <Row>
                  <Col sm={4}>
                    <PainelConteudo legenda={idCt.t("Discente", "Student")}
                          texto={
                              <Row>
                                  <Col sm={12}>
                                      <p>
                                          Caro <strong>aluno</strong>, no seu primeiro acesso, você terá que cadastrar o seu email no Observatório ProfEPT. <br />
                                          <b><Link to={registerPath}>Clique aqui</Link></b> para realizar o primeiro acesso.<br />
                                          O acesso ao sistema pode ser feito por uma conta local ou por um provedor externo (Microsoft, Facebook ou Gmail). <br />
                                          Se você já acessou o sistema, mas esqueceu sua senha, confira o seu email em <Link to={'/Discentes'}>Pessoas » Discentes</Link>, em seguida, na tela de <b><Link to={loginPath}>Login</Link></b> clique em esqueci a senha.
                                      </p>                                      
                                  </Col>
                              </Row>
                          } />
                  </Col>
                    <Col sm={4}>
                        <PainelConteudo legenda="Professor"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        <p>
                                            Caro <strong>docente</strong>, o seu email de acesso ao sistema é cadastrado pelo coordenador local de sua Instituição Associada.<br />
                                            Para conferir o seu email de acesso, basta acessar <Link to={'/ListaPesquisadores'}>Pessoas » Docentes</Link>. <br />
                                            O acesso ao sistema pode ser feito por uma conta local ou por um provedor externo (Microsoft, Facebook ou Gmail). <br />
                                            Se você esqueceu a senha de acesso, clique em esqueci a senha na tela de <b><Link to={loginPath}>Login</Link></b>.
                                        </p>    
                                    </Col>
                                </Row>
                            } />
                </Col>
                <Col sm={4}>
                    <PainelConteudo legenda={idCt.t("Secretário", "Secretary")}
                        texto={
                            <Row>
                                <Col sm={12}>
                                    <p>
                                        Caro <strong>secretário</strong>, o seu email de acesso ao sistema é cadastrado pelo coordenador local de sua Instituição Associada.<br />
                                        Para conferir o seu email de acesso, basta acessar <Link to={'/Secretarios'}>Pessoas » Secretários</Link>. <br />
                                        O acesso ao sistema pode ser feito por uma conta local ou por um provedor externo (Microsoft, Facebook ou Gmail). <br />
                                        Se você esqueceu a senha de acesso, clique em esqueci a senha na tela de <b><Link to={loginPath}>Login</Link></b>.
                                    </p> 
                                </Col>
                            </Row>
                        } />
                </Col>
              </Row>
          </Container>
    );
}
