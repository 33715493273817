import React, { useContext, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import SelecInstituicoes from '../../components/SelecInstituicoes/SelecInstituicoes';
import Descredenciados from '../../components/Pesquisadores/Descredenciados';

import IdiomaContext from '../../context/idiomaContext/idiomaContext';

export function ListaDescredenciados(props) {

    const idCt = useContext(IdiomaContext);

    let QualInstituicaoInicial = {
        id: 0,
        nome: "IFTM",
        sigla: "IFTM",
        quantidadeProfessores: 0,
        quantidadeProfessoresLattes: 0
    }
    const [QualInstituicao, setQualInstituicao] = useState(QualInstituicaoInicial);

    return (
        <Container >
            <Row>
                <Col sm={12}>
                    <h2>{idCt.t("Consulta de Docentes Descredenciados", "Consultation of professors leaving")}</h2>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>                    
                    <PainelConteudo legenda={idCt.t("Pesquisar", "Search")}
                        texto={
                            <Row>
                                <Col sm={12}>
                                    {idCt.t("Escolha a Instituição:", "Choose the Institution:")}
                                    <SelecInstituicoes AtualizaQualInstituicao={setQualInstituicao} />
                                </Col>
                            </Row>                            
                        } />
                </Col>
            </Row>      
            <Row>
                <Col sm={12}>
                    <PainelConteudo legenda={idCt.t("Descredenciados", "Output Professors")}
                        texto={
                            <Row>
                                <Col sm={12}>
                                    <Descredenciados                                        
                                        QualInstituicao={QualInstituicao.id} />
                                </Col>
                            </Row>
                        } />                    
                </Col>
            </Row>
        </Container>
    );
}
