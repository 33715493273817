import React, { Component } from 'react';
import { Form } from 'react-bootstrap'; ///lib/FormControl';

export default class SelectOfertaDisciplina extends Component {    

    constructor(props) {
        super(props);
        this.state = {
            Ofertas: [],
            loading: true,
            error: null
        };
    }

    componentDidMount() {
        let StringFetch = 'api/Disciplinas/QuaisOfertas';
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ Ofertas: data, loading: false });
                if (!this.props.OfertaSelecionada) {
                    this.props.AtualizaQualOferta(data[0]);
                }
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    AlteraOferta(pValor) {
        let a = this.state.Ofertas;
        for (let i = 0; i < a.length; i++) {
            if (a[i].id == pValor) {     
                this.props.AtualizaQualOferta(a[i]);
            }
        }        
    }

    renderSelectOfertas() {
        return (
            <div>
                <span className="p-float-label">
                    <Form.Control as="select"
                        placeholder="Selecione a disciplina"
                        onChange={(e) => this.AlteraOferta(e.target.value)} >
                        {
                            this.state.Ofertas.map(oferta =>
                                (this.props.OfertaSelecionada && this.props.OfertaSelecionada === oferta.id) ?
                                    <option selected value={oferta.id} key={oferta.id}> {oferta.anoSemestre} </option> :
                                    <option value={oferta.id} key={oferta.id}> {oferta.anoSemestre} </option>)
                        }
                    </Form.Control>
                </span>
            </div>       
        );
    }
    render() {
        const { loading, error } = this.state;
        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents =  <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                        </div>;
        }
        else {
            contents = this.renderSelectOfertas();
        }

        return (
            <div>
                {contents}
            </div>
        );

    }
}