import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

// Components
import IdiomaContext from '../../context/idiomaContext/idiomaContext';

export default function ExcluirConta(props) {

    const idCt = useContext(IdiomaContext);

    return (
          <Container >
              <Row>
                  <Col sm={12}>
                    <h2>{idCt.t("Solicitar Exclusão de Dados da Conta", "Request Account Data Deletion")}</h2>
                  </Col>
              </Row>
              <Row>
                <Col sm={12}>
                    <p>
                    {idCt.t("Você pode solicitar a exclusão da dados de sua conta vinculada ao Facebook ou Google. Preencha o formulário abaixo e em seguida enviaremos um email com as instruções para prosseguir a exclusão.", "You can request the deletion of data from your account linked to Facebook or Google. Fill out the form below and then we will send you an email with instructions to proceed with the deletion.")}
                    </p>
                    <p>
                        <a href={"https://forms.gle/YLZxowCKZ49nqjN28"} target="blank">{idCt.t("Clique aqui para preencher o formulário", "Click here to fill out the form")}</a>. 
                    </p>
                </Col>
              </Row>
          </Container>
    );
}
