import React, { useState, useContext, useEffect } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import { formatDate } from '../../helpers/Date';

import SelectOfertaDisciplina from '../../components/SelectOfertaDisciplina/SelectOfertaDisciplina';
import SelectDisciplina from '../../components/SelectDisciplina/SelecDisciplina';
import SelecInstituicoes from '../../components/SelecInstituicoes/SelecInstituicoes';
import SelectProfessor from '../../components/SelectProfessor/SelecProfessor';
import SelectAluno from '../../components/SelectAluno/SelecAluno';
import Turmas from '../../components/DisciplinasEletivas/Turmas';
import '../../components/Pesquisadores/Pesquisadores.css';

import LoadingContext from '../../context/loadingContext/loadingContext';
import InfoContext from '../../context/infoContext/infoContext';
import MessageContext from '../../context/messageContext/messageContext';

export function ConsultaTurmas(props) {

    //Contexto
    const loadingContext = useContext(LoadingContext);
    const infoContext = useContext(InfoContext);
    const messageContext = useContext(MessageContext);

    //Constructor
    const [AcessoNegado, setAcessoNegado] = useState(true);
    const [dadosOferta, setDadosOferta] = useState({ id: 0, anoSemestre: "2019/2", situacaoAluno: 2, situacaoProf: 2 });
    const [error, setError] = useState(null);
    const [StatusEnturmacao, setStatusEnturmacao] = useState(null);
    const [errorEnturmacao, setErrorEnturmacao] = useState(null);
    const [QualDisciplina, setQualDisciplina] = useState({ id: 0, nome: "Todas as Disciplinas", sigla: "Todas as Disciplinas" });
    const [ProfPrincipal, setProfPrincipal] = useState(null);

    let QualAlunoInicial = null;
    let QualInstituicaoInicial = null;
    if ((infoContext.user != null) && (infoContext.user.profile.aluno)) {
        QualAlunoInicial = { id: infoContext.userInfo.idaluno, nome: infoContext.user.name };
        QualInstituicaoInicial = { id: 0, nome: "Todas as Instituições Associadas", sigla: "Todas as Instituições" };
    }
    else if (infoContext.userInfo != null) {
        QualInstituicaoInicial = infoContext.userInfo.instituicao;
    }
    const [QualAluno, setQualAluno] = useState(QualAlunoInicial);
    const [QualInstituicao, setQualInstituicao] = useState(QualInstituicaoInicial);

    useEffect(() => {
        //componentDidMount
        if ((infoContext.user != null) && (infoContext.userInfo != null)) {
            setAcessoNegado(false);
        }
        else {
            messageContext.openMessage("warning", "Você deve estar logado para acessar este conteúdo.");
        }
    }, [dadosOferta]); 

    function handleEnturmacao() {
        loadingContext.showLoading();

        fetch('api/Disciplinas/Enturmacao?OfertaID=' + dadosOferta.id)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                if (data === -1) {
                    setStatusEnturmacao(data);
                    messageContext.openMessage("error", "Erro. Por favor, tente novamente.");                    
                }
                else {
                    setStatusEnturmacao(data);                    
                }
            })
            .catch(errorEnturmacao => {
                messageContext.openMessage("error", "Erro.Por favor, tente novamente.");
                setErrorEnturmacao(errorEnturmacao);
            });

        loadingContext.hideLoading();
    }

    function renderEnturmacao() {
        if (loadingContext.loading) {
            return (
                <div className="overlay">
                    <i className="fa fa-refresh fa-spin"></i> <em>Gerando turmas...</em>
                </div>
            );
        }
        if (StatusEnturmacao > 0) {
            return (
                <div>
                    Cadastro das {StatusEnturmacao} turmas finalizado.
                </div>
            );
        }
        else {
            return (
                <div>
                    <p> Caso queira iniciar o processo de enturmação (dura em torno de 2 minutos), clique no abaixo: </p>
                    <Button
                        bsStyle="success"
                        bsSize='small'
                        onClick={!loadingContext.loading ? handleEnturmacao : null}
                    >
                        {loadingContext.loading ? 'Gerando turmas...' : 'Enturmação'}
                    </Button>
                </div>
            );
        }
    }

    function renderPesqAluno(IdOferta) {
        if ((infoContext.user != null) && (infoContext.user.profile.aluno)) {
            let picture = (infoContext.user.picture) ? infoContext.user.picture : 'user.svg';            
            return (
                <Col sm={5}>
                    <p><b>Aluno:</b></p>
                    <p><img src={picture} className="prof-imagem-redondo" />{infoContext.user.name}</p>
                    <br />
                </Col>
            );
        }
        return (
            <Col sm={5}>
                <p><b>Aluno:</b></p>
                <SelectAluno QualOferta={IdOferta} AtualizaQualAluno={setQualAluno} />
                <br />
            </Col>
            );
    }

    function renderPesqInstituicao() {
        if ((infoContext.user != null) && (!infoContext.user.profile.coordenadorNacional) && (infoContext.user.profile.secretario)) {
            return (
                <Col sm={5}>
                    <p><b>Instituição:</b></p>
                    <p>{QualInstituicao.nome}</p>
                    <br />
                </Col>
            );
        }
        else if ((infoContext.user != null) && (infoContext.user.profile.aluno))  {
            return (
                <Col sm={5}>
                    <p><b>Instituição:</b></p>
                    <SelecInstituicoes AtualizaQualInstituicao={setQualInstituicao} />
                    <br />
                </Col>
            );
        }
        return (
            <Col sm={5}>
                <p><b>Instituição:</b></p>
                <SelecInstituicoes InstituicaoSelecionada={QualInstituicao.id} AtualizaQualInstituicao={setQualInstituicao} />
                <br />
            </Col>
        );
    }

    function renderPesquisaTurma(SomenteTexto, Texto) {
        let IdOferta = 0;
        if (dadosOferta) {
            IdOferta = dadosOferta.id;
        }
        if (SomenteTexto == true) {
            return (
                <Container >
                    <Row>
                        <Col sm={5}>
                            <p><b>Oferta de Disciplinas Eletivas:</b></p>
                            <SelectOfertaDisciplina AtualizaQualOferta={setDadosOferta} />
                            <br />
                        </Col>
                    </Row>
                    <Row>                        
                        <Col sm={10}>
                            <p>{Texto}</p>                            
                        </Col>
                    </Row>
                </Container>
            );
        }
        else {
            return (
                <Container >
                    <Row>
                        <Col sm={5}>
                            <p><b>Oferta de Disciplinas Eletivas:</b></p>
                            <SelectOfertaDisciplina AtualizaQualOferta={setDadosOferta} />
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        {renderPesqInstituicao()}
                        <Col sm={5}>
                            <p><b>Disciplina:</b></p>
                            <SelectDisciplina AtualizaQualDisciplina={setQualDisciplina} />
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={5}>
                            <p><b>Professor principal:</b></p>
                            <SelectProfessor AtualizaQualProfessor={setProfPrincipal} />
                            <br />
                        </Col>
                        {renderPesqAluno(IdOferta)}
                    </Row>
                </Container>
            );
        }        
    }

    function renderQuaisTurmas() {
        if (!dadosOferta){
            return (
                <div>
                    Nenhuma oferta encontrada.
                </div>
            );
        }
        if ((!(QualInstituicao) || (QualInstituicao.id == 0)) &&
            (!(QualDisciplina) || (QualDisciplina.id == 0)) &&
            (!(ProfPrincipal) || (ProfPrincipal.professorID == 0)) &&
            (!(QualAluno) || (QualAluno.id == 0))) {
            return (
                <div>
                    Selecione uma <b>Instituição</b> ou <b>Disciplina</b> ou <b>Professor</b> ou <b>Aluno</b>.
                </div>
            );
        }
        else {
            let podeVerArquivosInstituicao = -1;
            if ((infoContext.user.profile.coordenadorCurso) || (infoContext.user.profile.secretario)) {
                podeVerArquivosInstituicao = infoContext.userInfo.instituicao.id;
            }
            return (
                <div>
                    <Turmas QualOferta={dadosOferta.id} QualInstituicao={QualInstituicao} QualDisciplina={QualDisciplina}
                        ProfPrincipal={ProfPrincipal} QualAluno={QualAluno}
                        PodeVerTodosArquivos={((infoContext.user.profile.admin)||(infoContext.user.profile.coordenadorNacional))}
                        PodeVerArquivosInstituicao={podeVerArquivosInstituicao}
                    />
                </div>
            );
        }
    }

    function renderAll() {
        console.log(dadosOferta);
        let contents = <p></p>;
        let texto = <p></p>;
        if (AcessoNegado) {
            return (
                <Container >
                    <Row>
                        <Col sm={12}>
                            <h2>Turmas das disciplinas eletivas</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <PainelConteudo legenda="Acesso Negado"
                                texto={
                                    <Row>
                                        <Col sm={12}>
                                            Você deve estar logado para visualizar as turmas.
                                        </Col>
                                    </Row>
                                } />
                        </Col>
                    </Row>
                </Container >
            );
        }
        if (loadingContext.loading) {
            contents =  <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i> <em>Lendo/salvando informações...</em>
                        </div>;
        }
        else if ((!dadosOferta) || (!dadosOferta.inicioInscProf)) {
            texto =
                <div>
                    <p> Caro <b>usuário</b>, o cadastro de oferta de disciplinas eletivas não começou. </p>
                </div>;
            contents = renderPesquisaTurma(true, texto);
        }
        else if (dadosOferta.situacaoProf === 1) {
            if ((infoContext.user != null) && (infoContext.user.profile.admin)) {
                texto =
                    <div>
                        <p> Caro <b>usuário</b>, o cadastro de oferta de disciplinas eletivas <b>está em aberto</b>. </p>
                        <p> O prazo para os discentes informarem o seu interesse nas disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscAluno)}</b> a <b>{formatDate(dadosOferta.fimInscAluno)}</b>. </p>
                        <p> O prazo para os docentes informarem a oferta de disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscProf)}</b> a <b>{formatDate(dadosOferta.fimInscProf)}</b>. </p>
                        <p> A enturmação ocorrerá entre os dias <b>{formatDate(dadosOferta.inicioEnturmacao)}</b> e <b>{formatDate(dadosOferta.fimEnturmacao)}</b>. </p>
                        <br />
                        <p> Caso queira realizar a enturmação, a oferta de disciplinas eletivas será encerrada. </p>
                        {renderEnturmacao()}
                    </div>;
                contents = renderPesquisaTurma(true, texto);
            }
            else {
                texto =
                    <div>
                        <p> Caro <b>usuário</b>, o cadastro de oferta de disciplinas eletivas <b>está em aberto</b>. </p>
                        <p> O prazo para os discentes informarem o seu interesse nas disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscAluno)}</b> a <b>{formatDate(dadosOferta.fimInscAluno)}</b>. </p>
                        <p> O prazo para os docentes informarem a oferta de disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscProf)}</b> a <b>{formatDate(dadosOferta.fimInscProf)}</b>. </p>
                        <p> A enturmação ocorrerá entre os dias <b>{formatDate(dadosOferta.inicioEnturmacao)}</b> e <b>{formatDate(dadosOferta.fimEnturmacao)}</b>. </p>
                    </div>;
                contents = renderPesquisaTurma(true, texto);
            }
        }
        else if (dadosOferta.situacaoProf === 2) {
            texto =
                <div>
                    <p> Caro <b>usuário</b>, o cadastro de oferta de disciplinas eletivas <b>não começou</b>. </p>
                    <p> O prazo para os discentes informarem o seu interesse nas disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscAluno)}</b> a <b>{formatDate(dadosOferta.fimInscAluno)}</b>. </p>
                    <p> O prazo para os docentes informarem a oferta de disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscProf)}</b> a <b>{formatDate(dadosOferta.fimInscProf)}</b>. </p>
                    <p> A enturmação ocorrerá entre os dias <b>{formatDate(dadosOferta.inicioEnturmacao)}</b> e <b>{formatDate(dadosOferta.fimEnturmacao)}</b>. </p>
                </div>;
            contents = renderPesquisaTurma(true, texto);
        }
        else if ((dadosOferta.situacaoProf === 3) && (!dadosOferta.dataEnturmacao)) {
            if ((infoContext.user != null) && (infoContext.user.profile.admin))  {
                texto =
                    <div>
                        <p> Caro <b>usuário</b>, a oferta de disciplinas eletivas <b>já encerrou</b>. </p>
                        <br />
                        {renderEnturmacao()}
                    </div>;
            }
            else {
                texto =
                    <div>
                        <p> Caro <b>usuário</b>, a oferta de disciplinas eletivas <b>já encerrou</b>. </p>
                        <p> <b>Aguarde a enturmação.</b> </p>
                    </div>;
            }
            contents = renderPesquisaTurma(true, texto);
        }
        else {
            return (
                <Container >
                    <Row>
                        <Col sm={12}>
                            <h2>Turmas das disciplinas eletivas</h2>
                            <PainelConteudo legenda="Pesquise sua turma"
                                texto={
                                    <Row>
                                        <Col sm={12}>
                                            {renderPesquisaTurma(false, texto)}
                                        </Col>
                                    </Row>
                                } />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <PainelConteudo legenda="Turmas"
                                texto={
                                    <Row>
                                        <Col sm={12}>
                                            {renderQuaisTurmas()}
                                        </Col>
                                    </Row>
                                } />
                        </Col>
                    </Row>
                </Container>
                );
        }
        return (
            <Container >
                <Row>
                    <Col sm={12}>
                        <h2>Turmas das disciplinas eletivas</h2>
                        <PainelConteudo legenda="Turmas"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        {contents}
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
            </Container>
        );

    }

    return (
        renderAll()
        );
}
