import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../Pesquisadores/Pesquisadores.css';
import { formatDate } from '../../helpers/Date';
import Alert from 'react-bootstrap/Alert';

export default class RelacaoAlunosProcura extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            dadosOferta: null
        };
        this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
    }

    componentDidMount() {
        this.CarregarDados();
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.QualInstituicao !== this.props.QualInstituicao) ||
            (prevProps.QualOferta !== this.props.QualOferta) ||
            (prevProps.QualAnoIngresso !== this.props.QualAnoIngresso)) {
            this.CarregarDados();
        }
    }

    CarregarDados() {
        this.setState({ loading: true });
        if (this.props.QualInstituicao <= 0) {
            return;
        }
        let StringFetch = 'api/Disciplinas/RelacaoAlunosProcura?QualInstituicao=' + this.props.QualInstituicao;
        if (this.props.QualOferta) {
            StringFetch += '&OfertaID=' + this.props.QualOferta;
        }
        if (this.props.QualAnoIngresso) {
            StringFetch += '&AnoIngresso=' + this.props.QualAnoIngresso;
        }
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ dadosOferta: data, loading: false });
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    ColunaNome(rowData) {
        if (rowData.alerta) {
            return  <Alert style={{ margin: "0px 0px 0px", padding: "6px" }} variant={rowData.alerta}>
                        <span style={{ fontSize: "14px" }}>{rowData.nome}</span>
                    </Alert>;
        }
        else {
            return  <div>
                        <span style={{ fontSize: "14px" }}>{rowData.nome}</span>
                    </div>;
        }
    }

    ColunaQtdeDisciplinas(rowData) {
        return  <div>
                    <span>{rowData.disciplinas.length}</span>
                </div>;
    }

    ColunaDisciplinas(rowData) {
        let strDisc = "";
        for (let i = 0; i < rowData.disciplinas.length; i++) {
            if (i > 0)
                strDisc += " ; ";
            strDisc += rowData.disciplinas[i];
        }
        return  <div>
                    <span>{strDisc}</span>
                </div>;
    }

    rowExpansionTemplate(data) {
        return (
            <div className="p-grid p-fluid" style={{ padding: '1em' }}>
                <div className="p-col-12 p-md-12">
                    <div className="p-grid">
                        <div className="p-md-2">Email: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.email}</div>

                        <div className="p-md-2">Início: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.inicioAnoMes}</div>

                        <div className="p-md-2">Quantidade de disciplinas: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{this.ColunaQtdeDisciplinas(data)}</div>

                        <div className="p-md-2">Disciplinas: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{this.ColunaDisciplinas(data)}</div>
                    </div>
                </div>
            </div>
        );
    }

    renderAlunosCelular() {
        var header = <div style={{ 'textAlign': 'left' }}>
            <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
            <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Pesquisar" size="20" />
        </div>;

        return (
            <div>
                <div className="content-section implementation">
                    <DataTable value={this.state.dadosOferta.alunos} paginator={true} rows={12} rowsPerPageOptions={[6, 12, 24]}
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        globalFilter={this.state.globalFilter} header={header}
                        expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                        rowExpansionTemplate={this.rowExpansionTemplate}
                    >
                        <Column expander={true} style={{ width: '3em' }} />
                        <Column field="nome" header="Nome" body={this.ColunaNome} sortable={true} />
                    </DataTable>
                </div>
            </div>
        );
    }

    renderAlunosTamanhoNormal() {
        var header = <div style={{ 'textAlign': 'left' }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Pesquisar" size="50" />
                    </div>;
        return (
            <div>
                <div className="content-section implementation">
                    <DataTable value={this.state.dadosOferta.alunos} paginator={true} rows={12} rowsPerPageOptions={[6, 12, 24]}
                        resizableColumns={true} columnResizeMode="fit"
                        globalFilter={this.state.globalFilter} header={header}
                    >
                        <Column field="nome" header="Nome" body={this.ColunaNome} sortable={true} />
                        <Column field="inicioAnoMes" header="Início" sortable={true} style={{ textAlign: 'center', width: '8em' }} />
                        <Column field="disciplinas" header="# Disciplinas" body={this.ColunaQtdeDisciplinas} sortable={false} style={{ textAlign: 'center', width: '9em' }} />
                        <Column field="disciplinas" header="Disciplinas" body={this.ColunaDisciplinas} sortable={false} />
                    </DataTable>
                </div>
            </div>
        );
    }
       
    renderAlunos() {
        var windowWidth = window.innerWidth;
        if (windowWidth < 770) {
            return this.renderAlunosCelular();
        }
        else {
            return this.renderAlunosTamanhoNormal();
        }
    }

    render() {
        const { dadosOferta, loading, error } = this.state;

        let contents = <p>Conteudo</p>;
        let contentsCabecalho = <p></p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            if (this.props.QualInstituicao <= 0) {
                contents =  <div className="overlay">
                                <em>Escolha uma instituição</em>
                            </div>;
            }
            else {
                contents =  <div className="overlay">
                                <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                            </div>;
            }
        }
        else if ((!dadosOferta) || (!dadosOferta.inicioInscAluno)) {
            contents =
                <div>
                    <p> A oferta de disciplinas eletivas não começou. </p>
                </div>
        }
        else {
            contentsCabecalho =
                <div>
                    <p> O prazo para os discentes informarem o seu interesse nas disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscAluno)}</b> a <b>{formatDate(dadosOferta.fimInscAluno)}</b>. </p>
                    <p> O prazo para os docentes informarem a oferta de disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscProf)}</b> a <b>{formatDate(dadosOferta.fimInscProf)}</b>. </p>
                </div>
            contents = this.renderAlunos();
        }

        return (
            <div>
                <div>
                    {contentsCabecalho}
                </div>
                <div>
                    {contents}
                </div>
            </div>
        );

    }

}