import React, { Component } from 'react';
import { Form } from 'react-bootstrap'; ///lib/FormControl';

export default class SelectAnosIngresso extends Component {    

    constructor(props) {
        super(props);
        this.state = {
            Anos: [],
            loading: true,
            error: null
        };
    }

    componentDidMount() {
        fetch('api/Discentes/ListaAnosIngresso')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ Anos: data, loading: false });
                //this.props.AtualizaQualAno(0);
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    renderSelectAnos() {
        return (
            <div>
                <Form.Control as="select"
                    placeholder="Selecionar o ano"
                    onChange={(e) => this.props.AtualizaQualAno(e.target.value)} >
                    <option selected value="0" key="0">Todos os Anos</option>
                    {
                        this.state.Anos.map(ano => <option value={ano} key={ano}> {ano} </option>)
                    }
                </Form.Control>
            </div>       
        );
    }
    render() {
        const { loading, error } = this.state;
        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents = <div className="overlay">
                         <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                       </div>;
        }
        else {
            contents = this.renderSelectAnos();
        }

        return (
            <div>
                {contents}
            </div>
        );

    }
}