import React, { useContext, useState, useRef, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import SelecInstituicoes from '../../components/SelecInstituicoes/SelecInstituicoes';
import SelectTipoProdEdu from '../../components/SelectTipoProdEdu/SelectTipoProdEdu';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import Egressos from '../../components/Egresso/Egressos';

import IdiomaContext from '../../context/idiomaContext/idiomaContext';

export function ListaEgressos(props) {

    const idCt = useContext(IdiomaContext);

    let QualInstituicaoInicial = {
        id: 0,
        nome: "IFTM",
        sigla: "IFTM",
        quantidadeProfessores: 0,
        quantidadeProfessoresLattes: 0
    }

    const [QualInstituicao, setQualInstituicao] = useState(QualInstituicaoInicial);
    const [TipoProdEdu, setTipoProdEdu] = useState(0);
    const [AnoDefesa, setAnoDefesa] = useState(null);
    const [Assunto, setAssunto] = useState(null);
    const [NomeEgresso, setNomeEgresso] = useState(null);
    const [MembroBanca, setMembroBanca] = useState(null);

    const [Pesquisou, setPesquisou] = useState(false);
    const prevPesquisou = usePrevious(Pesquisou)

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    function handlePesquisar(event) {
        event.preventDefault();
        setPesquisou(!Pesquisou);
    }

    function renderCabecalho() {
        return (
            <Row>
                <Col sm={12}>
                    <h2>{idCt.t("Consulta de Egressos / Dissertações / Produtos Educacionais", "Search for Alumni / Dissertations / Educational Products")}</h2>
                    <PainelConteudo legenda={idCt.t("Pesquisar", "Search")}
                        texto={
                            <Container >
                                <Row>
                                    <Col sm={5}>
                                        {idCt.t("Escolha a Instituição:", "Choose the Institution:")}
                                        <SelecInstituicoes InstituicaoSelecionada={QualInstituicaoInicial.id} AtualizaQualInstituicao={setQualInstituicao} />
                                    </Col>
                                    <Col sm={5}>
                                        {idCt.t("Escolha o Tipo do Produto:", "Choose Product Type:")}
                                        <SelectTipoProdEdu Descricao="Todos" TipoProdEduSelecionado={TipoProdEdu} AtualizaQualTipoProdEdu={setTipoProdEdu} />
                                    </Col>
                                    <Col sm={2}>
                                        {idCt.t("Ano da Defesa:", "Defense Year:")}<br />
                                        <InputMask style={{ width: "100%" }} mask="9999" value={AnoDefesa} onChange={(e) => setAnoDefesa(e.target.value)} />
                                        <br /><br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={4}>
                                        {idCt.t("Assunto:", "Subject:")}<br />
                                        <InputText style={{ width: "100%" }} value={Assunto} onChange={(e) => setAssunto(e.target.value)} />
                                    </Col>
                                    <Col sm={4}>
                                        {idCt.t("Egresso:", "Alumni:")}<br />
                                        <InputText style={{ width: "100%" }} value={NomeEgresso} onChange={(e) => setNomeEgresso(e.target.value)} />
                                    </Col>
                                    <Col sm={4}>
                                        {idCt.t("Membro da Banca Examinadora:", "Member of the Examining:")}<br />
                                        <InputText style={{ width: "100%" }} value={MembroBanca} onChange={(e) => setMembroBanca(e.target.value)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <br />
                                        <div style={{ textAlign: "left" }}>
                                            <Button label={idCt.t("Pesquisar", "Search")} onClick={handlePesquisar.bind(this)} className="p-button-success" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        } />
                </Col>
            </Row>
            );
    }

    function renderAll() {
        if ((QualInstituicao.id == 0) && (TipoProdEdu == 0) && (!AnoDefesa) && (!Assunto) && (!NomeEgresso) && (!MembroBanca)) {
            return (
                <Container >
                    {renderCabecalho()}
                    <Row>
                        <Col sm={12}>
                            <PainelConteudo legenda={idCt.t("Egressos / Dissertações / Produtos", "Alumni / Dissertations / Products")}
                                texto={
                                    <Container >
                                        <Row>
                                            <Col sm={12}>
                                                <p>{idCt.t("Informe a instituição ou o tipo do produto ou ano da defesa ou assunto ou egresso ou membro da banca examinadora para realizar a pesquisa.", "Inform the institution or type of product or year of the defense or subject or alumni or member of the examining board to conduct the research.")}</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                } />
                        </Col>
                    </Row>
                </Container>
            );
        }
        else if (prevPesquisou === Pesquisou) {
            return (
                <Container >
                    {renderCabecalho()}
                    <Row>
                        <Col sm={12}>
                            <PainelConteudo legenda={idCt.t("Egressos / Dissertações / Produtos", "Alumni / Dissertations / Products")}
                                texto={
                                    <Container >
                                        <Row>
                                            <Col sm={12}>
                                                <p>{idCt.t("Após informar os itens da busca, clique em Pesquisar.", "After informing the search items, click Search.")}</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                } />
                        </Col>
                    </Row>
                </Container>
            );
        }
        return (
            <Container >
                {renderCabecalho()}
                <Row>
                    <Col sm={12}>
                        <PainelConteudo legenda={idCt.t("Egressos / Dissertações / Produtos", "Alumni / Dissertations / Products")}
                            texto={
                                <Container >
                                    <Row>
                                        <Col sm={12}>
                                            <Egressos QualInstituicao={QualInstituicao.id}
                                                QualProdEdu={TipoProdEdu}
                                                QualAnoDefesa={AnoDefesa}
                                                QualAssunto={Assunto}
                                                QualEgresso={NomeEgresso}
                                                QualMembro={MembroBanca}
                                                Pesquisou={Pesquisou}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            } />
                    </Col>
                </Row>
            </Container>
        );
    }

    return (renderAll());
}
