import React, { useState, useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Button } from 'primereact/button';
import PainelConteudo from '../PainelConteudo/PainelConteudo';

import MessageContext from '../../context/messageContext/messageContext';
import InfoContext from '../../context/infoContext/infoContext';

import admService from '../../services/admService';

export default function AdmExecutaEtapa(props) {

    //Contexto
    const messageContext = useContext(MessageContext);
    const infoContext = useContext(InfoContext);

    //Construtor
    const [Resultado, setResultado] = useState("");
    const [AcessoNegado, setAcessoNegado] = useState(true);
    const [error, setError] = useState(null);  
    const [loading, setLoading] = useState(false); 

    useEffect(() => {
        //componentDidMount
        if ((infoContext.user != null) && (infoContext.user.profile.admin)) {
            setAcessoNegado(false);
        }
        else {
            messageContext.openMessage("warning", "Somente docentes tem acesso a este conteúdo.");
        }
    }, [Resultado, loading]); 

    function handleEtapa() {
        setLoading(true);
        admService.QualEtapa(props.QualEtapa)
            .then(r => {
                r.json().then(response => {
                    if (response.statusCode) {
                        messageContext.openMessage(response.statusVariant, response.statusDescription);
                        setResultado(response.statusDescription);
                    }
                    else {
                        setResultado("erro");
                    }
                    setLoading(false);
                });
            })
            .catch(error => {
                messageContext.openMessage("error", "Erro. Por favor, tente mais tarde.");
                setError(error);
                setLoading(false);
            });
        
    }

    /*
      const handleEtapa = useCallback(
        () => {
                setLoading(true);
                admService.QualEtapa(props.QualEtapa)
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            throw new Error('Erro. Por favor, tente mais tarde.');
                        }
                    })
                    .then(DadosResposta => {
                        setQtde(DadosResposta);
                        setLoading(false);
                    })
                    .catch(error => {
                        messageContext.openMessage("error", "Erro. Por favor, tente mais tarde.");
                        setError(error);
                        setLoading(false);
                    });
        },
        [loading], // Tells React to memoize regardless of arguments.
      );
     */

    function renderBotao() {
        if (loading) {
            return (
                <div>
                    <p><i className="fa fa-refresh fa-spin"></i> <em>Lendo/salvando dados...</em></p>
                </div>
            );
        }
        else if (error) {
            return (
                <div>
                    <p><em>Ocorreu um erro.</em></p>
                </div>
            );
        }
        else if (Resultado) {
            return (
                <div>
                    {Resultado}
                </div>
            );
        }
        else {
            return (
                <Container >
                    <Row>
                        <Col sm={12}>
                            <div>
                                {props.QualEtapa}:&nbsp;&nbsp;
                                <Button label="Executar" onClick={() => handleEtapa()} className="p-button-raised p-button-success" />
                            </div>
                        </Col>
                    </Row>
                </Container >
            );
        }
    }
  
    function renderAll() {
        if (AcessoNegado) {
            return (
                <Container >
                    <Row>
                        <Col sm={12}>
                            <h2>Administrador</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <PainelConteudo legenda="Acesso Negado"
                                texto={
                                    <Row>
                                        <Col sm={12}>
                                            Somente administradores tem acesso a este conteúdo.
                                        </Col>
                                    </Row>
                                } />
                        </Col>
                    </Row>
                </Container >
            );
        }

        return (
            <Container >
                <Row>
                    <Col sm={12}>
                        <h2>Administrador</h2>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <PainelConteudo legenda={props.QualEtapa}
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        {renderBotao()}
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        renderAll()
    );

}