import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { formatDate } from '../../helpers/Date';

// Components
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';

export class DisciplinasEletivasComeceAqui extends Component {
    displayName = DisciplinasEletivasComeceAqui.name
    constructor(props) {
        super(props);
        this.state = {
            dadosOferta: null,
            loading: true,
            error: null
        };
    }

    componentDidMount() {
        let StringFetch = "api/Disciplinas/UltimaOferta";
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados do perfil...');
                }
            })
            .then(DadosResposta => {
                this.setState({ dadosOferta: DadosResposta, loading: false });
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    render() {
        const { error, loading, dadosOferta } = this.state;
        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents =  <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                        </div>;
        }
        else {
            let activeStep = dadosOferta.qualEtapa;
            contents = 
                <Container >
                    <Row>
                        <Col sm={12}>
                            <h3>Entenda as etapas da matrícula em Disciplinas Eletivas</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <PainelConteudo legenda="Etapas da Matrícula em Disciplina Eletiva"
                                texto={
                                    <div>
                                        <div>
                                            <p>Caro <b>Docente</b> e <b>Discente</b>, a matrícula em disciplinas eletivas é um processo complexo e exige comprometimento de todos do ProfEPT.</p>
                                            <p>Fiquem atentos aos <b>prazos</b>!</p>
                                        </div>
                                        <div>
                                            <Stepper activeStep={activeStep} orientation="vertical">
                                                <Step key="1">
                                                    <StepLabel>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <span style={{ color: "darkblue" }}><b>{formatDate(dadosOferta.inicioEtapa1)} a {formatDate(dadosOferta.fimEtapa1)}</b></span>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={2}>
                                                                <i>Coord. Nacional: </i>
                                                            </Col>
                                                            <Col sm={10}>
                                                                <i className="fa fa-circle-o" aria-hidden="true"></i> Cadastrar a oferta de Disciplinas Eletivas de <b>Tópicos Especiais em EPT</b>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ margin: "3px" }}></Row>
                                                        <Row>
                                                            <Col sm={2}>
                                                                <i>Coordenação Local: </i>
                                                            </Col>
                                                            <Col sm={10}>
                                                                <i className="fa fa-circle-o" aria-hidden="true"></i> Identificar com seus docentes quem ofertará a disciplina de <b>Tópicos Especiais em EPT</b>
                                                            </Col>
                                                        </Row>
                                                    </StepLabel>
                                                </Step>
                                                <Step key="2">
                                                    <StepLabel>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <span style={{ color: "darkblue" }}><b>{formatDate(dadosOferta.inicioEtapa2)} a {formatDate(dadosOferta.fimEtapa2)}</b></span>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={2}>
                                                                <i>Discente: </i>
                                                            </Col>
                                                            <Col sm={10}>
                                                                <i className="fa fa-circle-o" aria-hidden="true"></i> Cadastrar perfil e anexar documentos (o aluno que tem perfil cadastrado não precisa fazer um novo cadastro).<br />
                                                                <i className="fa fa-circle-o" aria-hidden="true"></i> Consultar no Observatório ProfEPT as disciplinas eletivas ofertadas pelo programa e <b>entrar em contato com o orientador para definir as disciplinas que cursará</b>.<br />
                                                                <i className="fa fa-circle-o" aria-hidden="true"></i> Selecionar as disciplinas eletivas que cursará.<br />
                                                            </Col>
                                                        </Row>
                                                    </StepLabel>
                                                </Step>
                                                <Step key="3">
                                                    <StepLabel>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <span style={{ color: "darkblue" }}><b>{formatDate(dadosOferta.inicioEtapa3)} a {formatDate(dadosOferta.fimEtapa3)}</b></span>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={2}>
                                                                <i>Coord. Nacional: </i>
                                                            </Col>
                                                            <Col sm={10}>
                                                                <i className="fa fa-circle-o" aria-hidden="true"></i> Acompanhar no Observatório ProfEPT a relação oferta/procura das Disciplinas Eletivas. <br />
                                                                <i className="fa fa-circle-o" aria-hidden="true"></i> Informar a quantidade de turmas por disciplinas eletivas necessárias para o semestre.                                                                
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ margin: "3px" }}></Row>
                                                        <Row>
                                                            <Col sm={2}>
                                                                <i>Coord. Local: </i>
                                                            </Col>
                                                            <Col sm={10}>
                                                                <i className="fa fa-circle-o" aria-hidden="true"></i> Identificar com seus docentes quem ofertará as disciplinas eletivas para o semestre, de acordo com a demanda informada pela Coordenação Nacional.
                                                            </Col>
                                                        </Row>
                                                    </StepLabel>
                                                </Step>
                                                <Step key="4">
                                                    <StepLabel>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <span style={{ color: "darkblue" }}><b>{formatDate(dadosOferta.inicioEtapa4)} a {formatDate(dadosOferta.fimEtapa4)}</b></span>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={2}>
                                                                <i>Coord. Nacional: </i>
                                                            </Col>
                                                            <Col sm={10}>
                                                                <i className="fa fa-circle-o" aria-hidden="true"></i> Cadastrar a oferta de Disciplinas Eletivas para o semestre.<br />
                                                                <i className="fa fa-circle-o" aria-hidden="true"></i> Preparar o Ambiente Virtual das disciplinas eletivas no Moodle do IFES.
                                                            </Col>
                                                        </Row>
                                                    </StepLabel>
                                                </Step>
                                                <Step key="5">
                                                    <StepLabel>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <span style={{ color: "darkblue" }}><b>{formatDate(dadosOferta.inicioEtapa5)} a {formatDate(dadosOferta.fimEtapa5)}</b></span>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={2}>
                                                                <i>Coord. Nacional: </i>
                                                            </Col>
                                                            <Col sm={10}>
                                                                <i className="fa fa-circle-o" aria-hidden="true"></i> Realizar o "ensalamento" por sorteio.<br />
                                                                <i className="fa fa-circle-o" aria-hidden="true"></i> Caso o discente escolha uma Disciplina que será ofertada pela sua IA, o vínculo deste mestrando <b>será com sua IA de origem</b>.
                                                            </Col>
                                                        </Row>
                                                    </StepLabel>
                                                </Step>
                                                <Step key="6">
                                                    <StepLabel>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <span style={{ color: "darkblue" }}><b>{formatDate(dadosOferta.inicioEtapa6)} a {formatDate(dadosOferta.fimEtapa6)}</b></span>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={2}>
                                                                <i>Secretaria IA: </i>
                                                            </Col>
                                                            <Col sm={10}>
                                                                <i className="fa fa-circle-o" aria-hidden="true"></i> Verificar quais alunos não anexaram os documentos para a realização da matrícula. Entrar em contato (por email) com discentes que não anexaram os documentos.
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ margin: "3px" }}></Row>
                                                        <Row>
                                                            <Col sm={2}>
                                                                <i>Discente: </i>
                                                            </Col>
                                                            <Col sm={10}>
                                                                <i className="fa fa-circle-o" aria-hidden="true"></i> Anexar a documentação necessária para a realização da matrícula (caso ainda não tenha feito).
                                                            </Col>
                                                        </Row>
                                                    </StepLabel>
                                                </Step>
                                                <Step key="7">
                                                    <StepLabel>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <span style={{ color: "darkblue" }}><b>{formatDate(dadosOferta.inicioEtapa7)} a {formatDate(dadosOferta.fimEtapa7)}</b></span>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={2}>
                                                                <i>Secretaria (IA): </i>
                                                            </Col>
                                                            <Col sm={10}>
                                                                <i className="fa fa-circle-o" aria-hidden="true"></i> Informar a Coordenação Nacional os alunos que não anexaram os documentos para matrícula<br />
                                                                <i className="fa fa-circle-o" aria-hidden="true"></i> Observação: o aluno que não tiver anexado/apresentado os documentos a coordenação que ofertará a eletiva de sua escolha, não será inserido no Ambiente Virtual (Moodle) do IFES
                                                            </Col>
                                                        </Row>   
                                                    </StepLabel>
                                                </Step>
                                                <Step key="8">
                                                    <StepLabel>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <span style={{ color: "darkblue" }}><b>{formatDate(dadosOferta.inicioEtapa8)} a {formatDate(dadosOferta.fimEtapa8)}</b></span>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={2}>
                                                                <i>Coord. Nacional: </i>
                                                            </Col>
                                                            <Col sm={10}>
                                                                <i className="fa fa-circle-o" aria-hidden="true"></i> Cadastrar os discentes no Ambiente Virtual (Moodle) do IFES.<br />
                                                                <i className="fa fa-circle-o" aria-hidden="true"></i> Informar os discentes (por email) sobre os procedimentos para o primeiro acesso ao Moodle.
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ margin: "3px" }}></Row>
                                                        <Row>
                                                            <Col sm={2}>
                                                                <i>Secretaria (IA): </i>
                                                            </Col>
                                                            <Col sm={10}>
                                                                <i className="fa fa-circle-o" aria-hidden="true"></i> Realizar a matrícula dos alunos como <b>Aluno Especial</b> (caso não seja daquela IA).
                                                            </Col>
                                                        </Row>
                                                    </StepLabel>
                                                </Step>
                                                <Step key="9">
                                                    <StepLabel>
                                                        <span style={{ color: "darkblue" }}><b>{formatDate(dadosOferta.inicioEtapa9)}</b></span><br />
                                                        <b>Previsão de início das aulas das Disciplinas Eletivas</b>.<br />
                                                    </StepLabel>
                                                </Step>
                                                <Step key="10">
                                                    <StepLabel>
                                                        <span style={{ color: "darkblue" }}><b>{formatDate(dadosOferta.fimEtapa9)}</b></span><br />
                                                        <b>Prazo máximo para encerramento as Disciplinas Eletivas</b>.<br />
                                                    </StepLabel>
                                                </Step>
                                            </Stepper>
                                        </div>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Container>
        }
        return (
            <div>
                {contents}
            </div>
        );
    }
}
