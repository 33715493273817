import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import PerfilProfessor from '../../components/Autenticacao/PerfilProfessor';
import PerfilAluno from '../../components/Autenticacao/PerfilAluno';

import IdiomaContext from '../../context/idiomaContext/idiomaContext';
import InfoContext from '../../context/infoContext/infoContext';

export function Perfil(props) {

    const idCt = useContext(IdiomaContext);
    const infoContext = useContext(InfoContext);

    function returnRender() {
        let conteudo = <p>Você deve estar logado para visualizar este conteúdo.</p>;
        if (infoContext.user != null) {
            if (infoContext.user.profile.aluno) {
                conteudo = <PerfilAluno />;
            }
            else {
                conteudo = <PerfilProfessor />;
            }
        }
        return conteudo;
    }

    return (
        <Container >
            <Row>
                <Col sm={12}>
                    <h2>{idCt.t("Perfil do usuário", "User Profile")}</h2>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>                    
                    <PainelConteudo legenda={idCt.t("Perfil do usuário", "User Profile")}
                        texto={
                            <Row>
                                <Col sm={12}>
                                    {returnRender()}
                                </Col>
                            </Row>                            
                        } />
                </Col>
            </Row>
        </Container>
    );

}
