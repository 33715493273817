import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap'; ///lib/FormControl';
import Mensagens from '../Mensagens/Mensagens';
import ModalLoading from '../../components/ModalLoading/ModalLoading';
import ModalMensagem from '../ModalMensagem/ModalMensagem';
import DocumentoDownload from '../Documento/DocumentoDownload';
import { Card } from 'primereact/card';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SplitButton } from 'primereact/splitbutton';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import axios from 'axios';
import './Turmas.css';
import SelecInstituicaoAluno from '../SelectInstituicaoAluno/SelecInstituicaoAluno';

export default class Turmas extends Component {    

    itemsFacilite= [
                {
                    label: 'Professores', 
                    icon: 'pi pi-envelope', 
                    command: (e) => {
                        this.FaciliteEmailsProfessores();
                    }
                }
    ];

    itemsFaciliteCoord = [
        {
            label: 'Incluir Aluno',
            icon: 'pi pi-plus',
            command: (e) => {
                this.FaciliteIncluirAluno();
            }
        },
        {
            label: 'Excluir Aluno',
            icon: 'pi pi-minus',
            command: (e) => {
                this.FaciliteExcluirAluno();
            }
        },
    ];

    PodeVerTodosArquivos= false;
    PodeVerArquivosInstituicao= -1;
    
    constructor(props) {
        super(props);
        if ((this.props.PodeVerTodosArquivos) && (this.props.PodeVerTodosArquivos == true)) {
            this.PodeVerTodosArquivos = true;
            this.itemsFacilite = this.itemsFacilite.concat(this.itemsFaciliteCoord);
        }
        else if (this.props.PodeVerArquivosInstituicao){
            this.PodeVerArquivosInstituicao = this.props.PodeVerArquivosInstituicao;
        }
        this.state = {
            Turmas: null,
            PesquisaNaTabela: null,
            loading: true,
            mensagemAviso: null,
            ExibeMensagem: false,
            QualPagina: 0,
            MensagemBotaoEmailAlunos: false,
            MensagemBotaoEmailProf: false,
            MensagemBotaoExcluirAluno: false,
            AlunoSelecionadoID: null,
            MensagemBotaoIncluirAluno: false,
            QualAlunoEditar: null,
            arquivosZipados: false,
            loadingArquivos: false
        };
        this.updateExibeMensagem = this.updateExibeMensagem.bind(this);
        this.updateMensagemBotaoEmailAlunos = this.updateMensagemBotaoEmailAlunos.bind(this);
        this.updateMensagemBotaoEmailProf = this.updateMensagemBotaoEmailProf.bind(this);
        this.updateMensagemBotaoExcluirAluno = this.updateMensagemBotaoExcluirAluno.bind(this);
        this.updateMensagemBotaoIncluirAluno = this.updateMensagemBotaoIncluirAluno.bind(this);
        this.updateAlunoEditar = this.updateAlunoEditar.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.ColunaDocumentos = this.ColunaDocumentos.bind(this);
        
    }
    updateExibeMensagem = (ExibeMensagem) => { this.setState({ ExibeMensagem }); }
    updateMensagemBotaoEmailAlunos = (MensagemBotaoEmailAlunos) => { this.setState({ MensagemBotaoEmailAlunos }); }
    updateMensagemBotaoEmailProf = (MensagemBotaoEmailProf) => { this.setState({ MensagemBotaoEmailProf }); }
    updateMensagemBotaoExcluirAluno = (MensagemBotaoExcluirAluno) => { this.setState({ MensagemBotaoExcluirAluno }); }
    updateMensagemBotaoIncluirAluno = (MensagemBotaoIncluirAluno) => { this.setState({ MensagemBotaoIncluirAluno }); }
    updateAlunoEditar = (QualAlunoEditar) => { this.setState({ QualAlunoEditar, AlunoSelecionadoID: QualAlunoEditar.id }); };

    onPageChange(event, newValue) {
        this.setState({
            QualPagina: newValue
        });
    }

    CarregaTurmas() {
        if (!this.props.QualOferta) {
            this.setState({
                ExibeMensagem: true, loading: false,
                mensagemAviso: { statusCode: 2, statusDescription: "Nenhuma oferta selecionada.", statusVariant: "error" }
            });
            return;
        }
        else {
            this.setState({
                QualPagina: 0, loading: true, PesquisaNaTabela: null
            });
        }
        let StringFetch = 'api/Disciplinas/ListaTurmas?QualOferta=' + this.props.QualOferta;
        if (this.props.QualInstituicao) {
            StringFetch += '&QualInstituicao=' + this.props.QualInstituicao.id;
        }
        if (this.props.QualDisciplina) {
            StringFetch += '&QualDisciplina=' + this.props.QualDisciplina.id;
        }
        if (this.props.ProfPrincipal) {
            StringFetch += '&QualProfessor=' + this.props.ProfPrincipal.professorID;
        }
        if (this.props.QualAluno) {
            StringFetch += '&QualAluno=' + this.props.QualAluno.id;
        }
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                let PesquisaNaTabela = [];
                for (let i = 0; i < data.length; i++) 
                    PesquisaNaTabela.push('');
                this.setState({ Turmas: data, ExibeMensagem: false, loading: false, PesquisaNaTabela });
            })
            .catch(error => this.setState({
                ExibeMensagem: true, loading: false,
                mensagemAviso: { statusCode: 2, statusDescription: "Ocorreu um erro ao ler as turmas.", statusVariant: "error" }
            }));
    }

    componentDidMount() {
        this.CarregaTurmas();
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.QualOferta != this.props.QualOferta) ||
            (prevProps.QualAluno != this.props.QualAluno) ||
            (prevProps.ProfPrincipal != this.props.ProfPrincipal) ||
            (prevProps.QualDisciplina != this.props.QualDisciplina) ||
            (prevProps.QualInstituicao != this.props.QualInstituicao) ) {
            this.CarregaTurmas();
        }
    }

    ColunaDocumentos(rowData, column) {
        let TemDocumentos = false;
        for (let i = 1; i < rowData.possuiDocumentos.length; i++) {
            if (rowData.possuiDocumentos[i]) TemDocumentos = true;
        }
        if ((this.state.arquivosZipados) && (this.state.arquivosZipados == true)) {
            if (TemDocumentos == false) {
                return (<div><span></span></div>);
            }
            return (<div>
                        <DocumentoDownload Id={rowData.id} TipoDoc="0" />
                    </div>
            );
        }
        return (<div>
                    {rowData.possuiDocumentos.map((itemDoc, i) => {
                        return (<span>{(itemDoc) ? <DocumentoDownload Id={rowData.id} TipoDoc={i} MostraIcone="true" /> : <i></i>} </span>)
                    })}
                </div>
        );
    }

    FaciliteEmailsProfessores(){
        this.setState({ MensagemBotaoEmailProf: true });
    }

    FaciliteExcluirAluno() {
        this.setState({ MensagemBotaoExcluirAluno: true });
    }

    FaciliteIncluirAluno() {
        this.setState({ MensagemBotaoIncluirAluno: true, QualAlunoEditar: null });
    }

    ExcluirAlunoDaTurma() {
        const { Turmas, QualPagina, AlunoSelecionadoID } = this.state;
        this.setState({ MensagemBotaoExcluirAluno: false, ExibeMensagem: false, mensagemAviso: null, loading: true }); //loadingArquivos

        const options = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                opcao: 'REMOVER', idOferta: Turmas[QualPagina].ofertaDisciplinaID, ofertaInscProf: Turmas[QualPagina].id, idAluno: parseInt(AlunoSelecionadoID)
            }),
            cache: 'default'
        };

        fetch('api/Disciplinas/PutInserirRemoverAlunoTurma', options)
            .then(r => {
                r.json().then(response => {                    
                    if (response.statusCode) {
                        this.setState({ loading: false, ExibeMensagem: true, mensagemAviso: response }); //loadingArquivos
                    }
                    else {
                        this.setState({ loading: false });
                    }
                    this.CarregaTurmas();
                });
            })
            .catch(error => this.setState({
                loading: false, ExibeMensagem: true, //loadingArquivos
                mensagemAviso: { statusCode: 2, statusDescription: "Ocorreu um erro ao excluir aluno na turma.", statusVariant: "error" }
            }));
    }

    IncluirAlunoNaTurma() {
        const { Turmas, QualPagina, AlunoSelecionadoID } = this.state;
        this.setState({ MensagemBotaoIncluirAluno: false, ExibeMensagem: false, mensagemAviso: null, loading: true }); //loadingArquivos

        const options = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                opcao: 'INSERIR', idOferta: Turmas[QualPagina].ofertaDisciplinaID, ofertaInscProf: Turmas[QualPagina].id, idAluno: parseInt(AlunoSelecionadoID)
            }),
            cache: 'default'
        };

        fetch('api/Disciplinas/PutInserirRemoverAlunoTurma', options)
            .then(r => {
                r.json().then(response => {
                    if (response.statusCode) {
                        this.setState({ loading: false, ExibeMensagem: true, mensagemAviso: response }); //loadingArquivos
                    }
                    else {
                        this.setState({ loading: false });
                    }
                    this.CarregaTurmas();
                });
            })
            .catch(error => this.setState({
                loading: false, ExibeMensagem: true, //loadingArquivos
                mensagemAviso: { statusCode: 2, statusDescription: "Ocorreu um erro ao incluir aluno na turma.", statusVariant: "error" }
            }));
    }

    PesquisouNaTabela(QualPagina, pValor) {
        let PesquisaNaTabela = this.state.PesquisaNaTabela;
        PesquisaNaTabela[QualPagina] = pValor;
        this.setState({ PesquisaNaTabela });   
    }

    renderFaciliteBotoes() {
        const { Turmas, QualPagina, QualAlunoEditar } = this.state;
        let contentBotaoIncluir = <p></p>;
        if (QualAlunoEditar) {
            contentBotaoIncluir =   <div style={{ textAlign: "left" }}>
                                        <Button label="Incluir" onClick={() => this.IncluirAlunoNaTurma()} className="p-button-raised p-button-success" />
                                    </div>
        }
        return (<div>
                <ModalMensagem  updateShow={this.updateMensagemBotaoEmailAlunos}
                                show={this.state.MensagemBotaoEmailAlunos} titulo="Lista de emails dos alunos da turma"
                                mensagem=
                                {<div>
                                    {Turmas[QualPagina].listaTurmaAlunos.map((itemTurma, i) => {
                                        return (<span>{(i == 0) ? <i></i> : <i>;</i>} {itemTurma.email}</span>)
                                    })}
                                 </div>
                                }
                />
                <ModalMensagem updateShow={this.updateMensagemBotaoEmailProf}
                    show={this.state.MensagemBotaoEmailProf} titulo="Lista de emails dos professores da disciplina"
                    mensagem=
                    {<div>
                        <span>{Turmas[QualPagina].emailsProfessoresMesmaDisciplina}</span>
                    </div>
                    }
                />
                <ModalMensagem updateShow={this.updateMensagemBotaoExcluirAluno}
                    show={this.state.MensagemBotaoExcluirAluno} titulo="Retirar o aluno desta turma"
                    mensagem=
                    {<div>
                        Selecione o aluno:
                        <Form.Control as="select"
                            onChange={(e) => this.setState({ AlunoSelecionadoID: e.target.value })} >
                            <option value={0} key={0}> Selecione o aluno </option>
                            {
                                Turmas[QualPagina].listaTurmaAlunos.map(itemTurma => <option value={itemTurma.id} key={itemTurma.id}> {itemTurma.nome} </option>)
                            }
                        </Form.Control>
                        <br />
                        <div style={{ textAlign: "left" }}>
                            <Button label="Excluir" onClick={() => this.ExcluirAlunoDaTurma()} className="p-button-raised p-button-danger" />
                        </div>
                    </div>
                    }
                />
                <ModalMensagem updateShow={this.updateMensagemBotaoIncluirAluno}
                    show={this.state.MensagemBotaoIncluirAluno} titulo="Inserir aluno nesta turma"
                    mensagem=
                    {<div style={{height: "220px"}}>
                        <SelecInstituicaoAluno AtualizaQualAluno={this.updateAlunoEditar} />
                        <br />                        
                        {contentBotaoIncluir}
                    </div>
                    }
                />
                </div>
            );
    }

    downloadDocsTurma() {
        const { Turmas, QualPagina } = this.state;
        if ((!Turmas) || (Turmas.length == 0)) {
            return;
        }
        this.setState({ ExibeMensagem: false, loadingArquivos: true });
        axios('api/Documentos/GetDocumentosTurma?idOfertaInscProf=' + Turmas[QualPagina].id, {
            method: 'GET',
            responseType: 'blob' //Force to receive data in a Blob Format
        })
            .then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [response.data],
                    { type: 'application/zip' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                //window.open(fileURL);
                let a = document.createElement('a');
                a.href = fileURL;
                a.download = Turmas[QualPagina].descricaoDisciplina;
                a.click();
                this.setState({ loadingArquivos: false });
            })
            .catch(Msgerror => this.setState({
                ExibeMensagem: true, loadingArquivos: false,
                mensagemAviso: { statusCode: 2, statusDescription: "Ocorreu um erro no download de documentos.", statusVariant: "error" }
            }));
    }

    ExibeInfoTurma(QualPagina) {
        const { Turmas } = this.state;
        
        let ConteudoDownload = <p></p>;
        let ConteudoColunaIA = <Column field="instituicao.sigla" header="IA" sortable={true} style={{ textAlign: 'center', width: '6em'}} />;
        let ConteudoColunaEmail = <Column field="email" header="Email" sortable={false} style={{ width: '0em' }} />;
        let ConteudoColunaDownload = <Column field="documentos" header="" sortable={false} style={{ width: '0em' }} />;

        var windowWidth = window.innerWidth;
        if ((this.PodeVerTodosArquivos) || (this.PodeVerArquivosInstituicao == Turmas[QualPagina].instituicao.id)) {
            ConteudoDownload =
                            <Col sm={3}>
                                <b>Download dos documentos:</b> <br />
                                <Button label="Todos Alunos" onClick={() => this.downloadDocsTurma()} className="p-button-raised p-button-info" />&nbsp;&nbsp;&nbsp;&nbsp;
                                <Checkbox onChange={e => this.setState({ arquivosZipados: e.checked })} checked={this.state.arquivosZipados}></Checkbox> .Zip
                                <br /><br />
                            </Col>;
            if (windowWidth >= 770) {
                ConteudoColunaDownload = <Column field="documentos" header="Documentos" body={this.ColunaDocumentos} sortable={false} style={{ textAlign: 'center', width: '15em' }} />;
            }            
        }

        var TamanhoInputPesq = 25;
        if (windowWidth >= 770) { //Nao e celular
            ConteudoColunaIA = <Column field="instituicao.sigla" header="IA" sortable={true} style={{ textAlign: 'center', width: '9em' }} />;
            ConteudoColunaEmail = <Column field="email" header="Email" sortable={false} />;
            TamanhoInputPesq = 32;
        }

        var header = <div style={{ 'textAlign': 'left' }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" value={this.state.PesquisaNaTabela[QualPagina]} 
                                   onInput={(e) => this.PesquisouNaTabela(QualPagina,e.target.value)} placeholder="Pesquisar" size={TamanhoInputPesq} />
                     </div>;

        return (
            <Container >
                <Row>
                    <Col sm={4}>
                        <b>Instituição:</b><br />
                        {Turmas[QualPagina].instituicao.nome}
                        <br /><br />
                    </Col>
                    <Col sm={5}>
                        <b>Disciplina:</b><br />
                        {Turmas[QualPagina].disciplinaEletiva.nome}
                        <br /><br />
                    </Col>
                    <Col sm={3}>
                        <b>Facilite sua vida:</b><br />
                        <SplitButton label="Emails Alunos" icon="pi pi-envelope" onClick={() => this.setState({ MensagemBotaoEmailAlunos: true })} model={this.itemsFacilite}></SplitButton>
                        <br /><br />
                        {this.renderFaciliteBotoes()}
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <b>Professor principal:</b><br />
                        {Turmas[QualPagina].professor.nome}<br /><i>{Turmas[QualPagina].professor.email}</i>
                        <br /><br />
                    </Col>
                    <Col sm={5}>
                        {(Turmas[QualPagina].conteudoDisciplina) ? <b>Conteúdo:</b> : <b>Professor auxiliar:</b>}<br />
                        {(Turmas[QualPagina].conteudoDisciplina) ? Turmas[QualPagina].conteudoDisciplina : (Turmas[QualPagina].professorAux) ? Turmas[QualPagina].professorAux.nome : null }
                        <br /><br />
                    </Col>
                    {ConteudoDownload}                                      
                </Row>
                <Row>
                    <Col sm={12}>
                        <p><b>({Turmas[QualPagina].listaTurmaAlunos.length}) Alunos:</b></p>
                        <div className="content-section implementation">
                            <DataTable value={Turmas[QualPagina].listaTurmaAlunos} paginator={true} rows={10} rowsPerPageOptions={[10, 30, 50]}
                                resizableColumns={true} columnResizeMode="fit"
                                globalFilter={this.state.PesquisaNaTabela[QualPagina]} header={header}
                            >
                                {ConteudoColunaIA}
                                <Column field="nome" header="Nome" sortable={true} />
                                {ConteudoColunaEmail}
                                {ConteudoColunaDownload}
                            </DataTable>
                        </div>
                    </Col>
                </Row>
            </Container>
            );
    }

    renderTurmas() {
        const { QualPagina, Turmas } = this.state;
        if ((!Turmas) || (Turmas.length == 0)) {
            return (
                    <div>
                        <br /><b>Nenhuma turma encontrada.</b><br />
                    </div>
            );
        }
        return (
            <Card style={{width: '100%'}}>
                <AppBar position="static" color="inherit">
                    <Tabs
                        value={QualPagina}
                        onChange={this.onPageChange}
                        indicatorColor="primary"
                        textColor="secondary"
                        variant="scrollable"
                        scrollButtons="on" /*"auto"*/                        
                    >
                        {Turmas.map((itemTurma, i) => {  
                            return (<Tab label={itemTurma.descricaoDisciplina} style={{ textTransform: 'none', maxWidth: '50px' }} />)
                        })}
                    </Tabs>
                </AppBar>
                <div style={{ padding: 8 * 3 }}>
                    {this.ExibeInfoTurma(QualPagina)}
                </div>
            </Card>
        );
    }
    render() {
        const { loading, ExibeMensagem, mensagemAviso } = this.state;
        let contents = <p></p>;
        let contentsAviso = <p></p>;
        if (loading) {
            contents =  <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                        </div>;
        }
        else {
            contents = this.renderTurmas();
        }
        if (mensagemAviso) {
            contentsAviso =  <Mensagens Exibir={ExibeMensagem} AtualizaExibir={this.updateExibeMensagem}
                                        Opcao={mensagemAviso.statusVariant} Mensagem={mensagemAviso.statusDescription} />;
        }
        return (
            <div>
                {contents}
                {contentsAviso}
                <div>
                    Caro <b>discente</b>, caso você queira entrar ou sair de alguma turma, entre em contato com o Iuri da Coordenação Nacional ( <b><i>iurimalverdi@gmail.com</i></b> ).<br />
                    Caro <b>secretário</b>, se algum documento estiver incorreto, entre em contato (por email) diretamente com o aluno.
                </div>
                <div>
                    <ModalLoading show={this.state.loadingArquivos} />
                </div>
            </div>
        );

    }
}