import React, { Component } from 'react';
import { Checkbox } from 'primereact/checkbox';
import BrazilMap from '../BrazilMap/BrazilMap';

import SelectArea from '../PeriodicoPorArea/selectArea';

export default class BrazilMapPeriodicoPorArea extends Component {
    areas = [];
    qtdAreas = 0;    
    TituloGraficoParte1 = "Quantidade";
    qtdLattes = [];

    insertValidData(label, dataType, index) {
        if (dataType[index] == null || dataType[index][label] == null) {
            return;
        }
        return dataType[index][label];
    }

    reflectsLabelArea(valueLabel) {
        this.setState({ labelChart: valueLabel }, () => {
            this.mapDataEstados();
        });
    }

    mapAreas() {
        //Popula as areas
        this.areas = [];
        this.qtdAreas = 0;
        let a = this.state.EstatisticaQualisGrupo.areas;
        for (let i = 0; i < a.length; i++) {
            this.areas.push({
                label: a[i].descricao,
                value: a[i].valor
            });
            this.qtdAreas++;
        }
    }

    mapDataEstados() {
        let qSP = 0, qMA = 0, qPA = 0, qSC = 0, qBA = 0, qAP = 0, qMS = 0, qMG = 0, qGO = 0,
            qRS = 0, qTO = 0, qPI = 0, qAL = 0, qPB = 0, qCE = 0, qSE = 0, qRR = 0, qPE = 0,
            qPR = 0, qES = 0, qRJ = 0, qRN = 0, qAM = 0, qMT = 0, qDF = 0, qAC = 0, qRO = 0;

        let dSP = 0, dMA = 0, dPA = 0, dSC = 0, dBA = 0, dAP = 0, dMS = 0, dMG = 0, dGO = 0,
            dRS = 0, dTO = 0, dPI = 0, dAL = 0, dPB = 0, dCE = 0, dSE = 0, dRR = 0, dPE = 0,
            dPR = 0, dES = 0, dRJ = 0, dRN = 0, dAM = 0, dMT = 0, dDF = 0, dAC = 0, dRO = 0;

        let a = this.state.EstatisticaQualisGrupo;
        for (let i = 0; i < a.listaQualisTodos.length; i++) {
            if (a.listaQualisTodos[i] === null) {
                continue;
            }
            let QualEstado = a.listaQualisTodos[i].instituicao.estado;
            let DocentesLattes = a.listaQualisTodos[i].instituicao.quantidadeProfessoresLattes;
            //A1
            let qualisA1 = 0;
            let propertiesName = Object.keys(a.listaQualisA1[i]);
            propertiesName.forEach(actualProperty => {
                if (actualProperty === this.state.labelChart) {                    
                    qualisA1 += a.listaQualisA1[i][actualProperty];
                }
            });
            //A2
            let qualisA2 = 0;
            propertiesName = Object.keys(a.listaQualisA2[i]);
            propertiesName.forEach(actualProperty => {
                if (actualProperty === this.state.labelChart) {
                    qualisA2 += a.listaQualisA2[i][actualProperty];
                }
            });
            //A3
            let qualisA3 = 0;
            propertiesName = Object.keys(a.listaQualisA3[i]);
            propertiesName.forEach(actualProperty => {
                if (actualProperty === this.state.labelChart) {
                    qualisA3 += a.listaQualisA3[i][actualProperty];
                }
            });
            //A4
            let qualisA4 = 0;
            propertiesName = Object.keys(a.listaQualisA4[i]);
            propertiesName.forEach(actualProperty => {
                if (actualProperty === this.state.labelChart) {
                    qualisA4 += a.listaQualisA4[i][actualProperty];
                }
            });
            //B1
            let qualisB1 = 0;
            propertiesName = Object.keys(a.listaQualisB1[i]);
            propertiesName.forEach(actualProperty => {
                if (actualProperty === this.state.labelChart) {
                    qualisB1 += a.listaQualisB1[i][actualProperty];
                }
            });
            //B2
            let qualisB2 = 0;
            propertiesName = Object.keys(a.listaQualisB2[i]);
            propertiesName.forEach(actualProperty => {
                if (actualProperty === this.state.labelChart) {
                    qualisB2 += a.listaQualisB2[i][actualProperty];
                }
            });
            //B3
            let qualisB3 = 0;
            propertiesName = Object.keys(a.listaQualisB3[i]);
            propertiesName.forEach(actualProperty => {
                if (actualProperty === this.state.labelChart) {
                    qualisB3 += a.listaQualisB3[i][actualProperty];
                }
            });
            //B4
            let qualisB4 = 0;
            propertiesName = Object.keys(a.listaQualisB4[i]);
            propertiesName.forEach(actualProperty => {
                if (actualProperty === this.state.labelChart) {
                    qualisB4 += a.listaQualisB4[i][actualProperty];
                }
            });
            //C
            let qualisC = 0;
            propertiesName = Object.keys(a.listaQualisC[i]);
            propertiesName.forEach(actualProperty => {
                if (actualProperty === this.state.labelChart) {
                    qualisC += a.listaQualisC[i][actualProperty];
                }
            });

            let TotalElementos = 0;            

            if (this.state.A1Checked) TotalElementos += qualisA1;
            if (this.state.A2Checked) TotalElementos += qualisA2;
            if (this.state.A3Checked) TotalElementos += qualisA3;
            if (this.state.A4Checked) TotalElementos += qualisA4;
            if (this.state.B1Checked) TotalElementos += qualisB1;
            if (this.state.B2Checked) TotalElementos += qualisB2;
            if (this.state.B3Checked) TotalElementos += qualisB3;
            if (this.state.B4Checked) TotalElementos += qualisB4;
            if (this.state.CChecked) TotalElementos += qualisC;

            if (QualEstado === "SP") { qSP += TotalElementos; dSP += DocentesLattes; }
            if (QualEstado === "MA") { qMA += TotalElementos; dMA += DocentesLattes; }
            if (QualEstado === "PA") { qPA += TotalElementos; dPA += DocentesLattes; }
            if (QualEstado === "SC") { qSC += TotalElementos; dSC += DocentesLattes; }
            if (QualEstado === "BA") { qBA += TotalElementos; dBA += DocentesLattes; }
            if (QualEstado === "AP") { qAP += TotalElementos; dAP += DocentesLattes; }
            if (QualEstado === "MS") { qMS += TotalElementos; dMS += DocentesLattes; }
            if (QualEstado === "MG") { qMG += TotalElementos; dMG += DocentesLattes; }
            if (QualEstado === "GO") { qGO += TotalElementos; dGO += DocentesLattes; }
            if (QualEstado === "RS") { qRS += TotalElementos; dRS += DocentesLattes; }
            if (QualEstado === "TO") { qTO += TotalElementos; dTO += DocentesLattes; }
            if (QualEstado === "PI") { qPI += TotalElementos; dPI += DocentesLattes; }
            if (QualEstado === "PB") { qPB += TotalElementos; dPB += DocentesLattes; }
            if (QualEstado === "CE") { qCE += TotalElementos; dCE += DocentesLattes; }
            if (QualEstado === "SE") { qSE += TotalElementos; dSE += DocentesLattes; }
            if (QualEstado === "RR") { qRR += TotalElementos; dRR += DocentesLattes; }
            if (QualEstado === "PE") { qPE += TotalElementos; dPE += DocentesLattes; }
            if (QualEstado === "PR") { qPR += TotalElementos; dPR += DocentesLattes; }
            if (QualEstado === "ES") { qES += TotalElementos; dES += DocentesLattes; }
            if (QualEstado === "RJ") { qRJ += TotalElementos; dRJ += DocentesLattes; }
            if (QualEstado === "RN") { qRN += TotalElementos; dRN += DocentesLattes; }
            if (QualEstado === "AM") { qAM += TotalElementos; dAM += DocentesLattes; }
            if (QualEstado === "MT") { qMT += TotalElementos; dMT += DocentesLattes; }
            if (QualEstado === "DF") { qDF += TotalElementos; dDF += DocentesLattes; }
            if (QualEstado === "AC") { qAC += TotalElementos; dAC += DocentesLattes; }
            if (QualEstado === "RO") { qRO += TotalElementos; dRO += DocentesLattes; }
        }

        if (this.props.TipoGrafico === "PorProfessor") {
            this.setState({
                selectedData: [
                    ['br-sp', (dSP > 0) ? Math.round((qSP * 10.00) / dSP) : 0],
                    ['br-ma', (dMA > 0) ? Math.round((qMA * 10.00) / dMA) : 0],
                    ['br-pa', (dPA > 0) ? Math.round((qPA * 10.00) / dPA) : 0],
                    ['br-sc', (dSC > 0) ? Math.round((qSC * 10.00) / dSC) : 0],
                    ['br-ba', (dBA > 0) ? Math.round((qBA * 10.00) / dBA) : 0],
                    ['br-ap', (dAP > 0) ? Math.round((qAP * 10.00) / dAP) : 0],
                    ['br-ms', (dMS > 0) ? Math.round((qMS * 10.00) / dMS) : 0],
                    ['br-mg', (dMG > 0) ? Math.round((qMG * 10.00) / dMG) : 0],
                    ['br-go', (dGO > 0) ? Math.round((qGO * 10.00) / dGO) : 0],
                    ['br-rs', (dRS > 0) ? Math.round((qRS * 10.00) / dRS) : 0],
                    ['br-to', (dTO > 0) ? Math.round((qTO * 10.00) / dTO) : 0],
                    ['br-pi', (dPI > 0) ? Math.round((qPI * 10.00) / dPI) : 0],
                    ['br-al', (dAL > 0) ? Math.round((qAL * 10.00) / dAL) : 0],
                    ['br-pb', (dPB > 0) ? Math.round((qPB * 10.00) / dPB) : 0],
                    ['br-ce', (dCE > 0) ? Math.round((qCE * 10.00) / dCE) : 0],
                    ['br-se', (dSE > 0) ? Math.round((qSE * 10.00) / dSE) : 0],
                    ['br-rr', (dRR > 0) ? Math.round((qRR * 10.00) / dRR) : 0],
                    ['br-pe', (dPE > 0) ? Math.round((qPE * 10.00) / dPE) : 0],
                    ['br-pr', (dPR > 0) ? Math.round((qPR * 10.00) / dPR) : 0],
                    ['br-es', (dES > 0) ? Math.round((qES * 10.00) / dES) : 0],
                    ['br-rj', (dRJ > 0) ? Math.round((qRJ * 10.00) / dRJ) : 0],
                    ['br-rn', (dRN > 0) ? Math.round((qRN * 10.00) / dRN) : 0],
                    ['br-am', (dAM > 0) ? Math.round((qAM * 10.00) / dAM) : 0],
                    ['br-mt', (dMT > 0) ? Math.round((qMT * 10.00) / dMT) : 0],
                    ['br-df', (dDF > 0) ? Math.round((qDF * 10.00) / dDF) : 0],
                    ['br-ac', (dAC > 0) ? Math.round((qAC * 10.00) / dAC) : 0],
                    ['br-ro', (dRO > 0) ? Math.round((qRO * 10.00) / dRO) : 0]
                ]
            });
        }
        else {
            this.setState({
                selectedData: [
                    ['br-sp', qSP],
                    ['br-ma', qMA],
                    ['br-pa', qPA],
                    ['br-sc', qSC],
                    ['br-ba', qBA],
                    ['br-ap', qAP],
                    ['br-ms', qMS],
                    ['br-mg', qMG],
                    ['br-go', qGO],
                    ['br-rs', qRS],
                    ['br-to', qTO],
                    ['br-pi', qPI],
                    ['br-al', qAL],
                    ['br-pb', qPB],
                    ['br-ce', qCE],
                    ['br-se', qSE],
                    ['br-rr', qRR],
                    ['br-pe', qPE],
                    ['br-pr', qPR],
                    ['br-es', qES],
                    ['br-rj', qRJ],
                    ['br-rn', qRN],
                    ['br-am', qAM],
                    ['br-mt', qMT],
                    ['br-df', qDF],
                    ['br-ac', qAC],
                    ['br-ro', qRO]
                ]
            });
        }

    }   
         
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            EstatisticaQualisGrupo: {},
            labelChart: "",
            A1Checked: true,
            A2Checked: true,
            A3Checked: true,
            A4Checked: true,
            B1Checked: true,
            B2Checked: true,
            B3Checked: true,
            B4Checked: true,
            CChecked: true
        };
      
    }

    componentDidMount() {
        this.CarregarDados();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.QualAno !== this.props.QualAno) {
            this.CarregarDados();
        }
    }

    CarregarDados() {
        this.setState({ loading: true });
        let StringFetch = "";
        if (this.props.QualConsulta === "PorAno") {
            if (this.props.QualAno == null)
                return;
            if (this.props.QualAno.length === 0)
                return;
            StringFetch = 'api/Indicadores/PeriodicoPorArea?QuaisAnos=' + this.props.QualAno;//QualAno=' + this.props.QualAno;
        }
        else {
            if (this.props.QualInstituicao == null)
                return;
            if (this.props.QualInstituicao === -1)
                return;
            StringFetch = 'api/Indicadores/PeriodicoPorArea?QualInstituicao=' + this.props.QualInstituicao;
        }
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ EstatisticaQualisGrupo: data, loading: false });
                this.setState({ labelChart: 'ensino' });
                this.ConfiguraDados();
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    ConfiguraDados() {

        this.reflectsLabelArea = this.reflectsLabelArea.bind(this);

        if (this.props.TipoGrafico === "PorProfessor") {
            this.TituloGraficoParte1 = "Média por professor * 10";
        }

        this.mapDataEstados = this.mapDataEstados.bind(this);

        this.mapAreas();

        this.mapDataEstados();

    }

    MudoueventoA1(valor) {
        this.setState({ A1Checked: valor }, () => {
            this.mapDataEstados();
        });
    }
    MudoueventoA2(valor) {
        this.setState({ A2Checked: valor }, () => {
            this.mapDataEstados();
        });
    }
    MudoueventoA3(valor) {
        this.setState({ A3Checked: valor }, () => {
            this.mapDataEstados();
        });
    }
    MudoueventoA4(valor) {
        this.setState({ A4Checked: valor }, () => {
            this.mapDataEstados();
        });
    }
    MudoueventoB1(valor) {
        this.setState({ B1Checked: valor }, () => {
            this.mapDataEstados();
        });
    }
    MudoueventoB2(valor) {
        this.setState({ B2Checked: valor }, () => {
            this.mapDataEstados();
        });
    }
    MudoueventoB3(valor) {
        this.setState({ B3Checked: valor }, () => {
            this.mapDataEstados();
        });
    }
    MudoueventoB4(valor) {
        this.setState({ B4Checked: valor }, () => {
            this.mapDataEstados();
        });
    }
    MudoueventoC(valor) {
        this.setState({ CChecked: valor }, () => {
            this.mapDataEstados();
        });
    }

    renderPeriodicoPorArea() {
        /*
                <p align="left">Escolha a <strong>Área</strong>:</p>
                <SelectArea reflectsLabelArea={this.reflectsLabelArea}
                    optionLabel="Selecione uma área"
                    optionsList={this.areas}></SelectArea>
         */ 
        return (
            <div align="center">
                <p align="center"><b><i>{this.TituloGraficoParte1}</i></b></p>
                <Checkbox onChange={e => this.MudoueventoA1(e.checked)} checked={this.state.A1Checked}></Checkbox>
                <label style={{ fontSize: '11px' }} htmlFor="cb1" className="p-checkbox-label">A1</label>&nbsp;&nbsp;&nbsp;
                <Checkbox onChange={e => this.MudoueventoA2(e.checked)} checked={this.state.A2Checked}></Checkbox>
                <label style={{ fontSize: '11px' }} htmlFor="cb2" className="p-checkbox-label">A2</label>&nbsp;&nbsp;&nbsp;
                <Checkbox onChange={e => this.MudoueventoA3(e.checked)} checked={this.state.A3Checked}></Checkbox>
                <label style={{ fontSize: '11px' }} htmlFor="cb3" className="p-checkbox-label">A3</label>&nbsp;&nbsp;&nbsp;
                <Checkbox onChange={e => this.MudoueventoA4(e.checked)} checked={this.state.A4Checked}></Checkbox>
                <label style={{ fontSize: '11px' }} htmlFor="cb4" className="p-checkbox-label">A4</label>&nbsp;&nbsp;&nbsp;
                <Checkbox onChange={e => this.MudoueventoB1(e.checked)} checked={this.state.B1Checked}></Checkbox>
                <label style={{ fontSize: '11px' }} htmlFor="cb5" className="p-checkbox-label">B1</label>&nbsp;&nbsp;&nbsp;
                <Checkbox onChange={e => this.MudoueventoB2(e.checked)} checked={this.state.B2Checked}></Checkbox>
                <label style={{ fontSize: '11px' }} htmlFor="cb6" className="p-checkbox-label">B2</label>&nbsp;&nbsp;&nbsp;
                <Checkbox onChange={e => this.MudoueventoB3(e.checked)} checked={this.state.B3Checked}></Checkbox>
                <label style={{ fontSize: '11px' }} htmlFor="cb7" className="p-checkbox-label">B3</label>&nbsp;&nbsp;&nbsp;
                <Checkbox onChange={e => this.MudoueventoB4(e.checked)} checked={this.state.B4Checked}></Checkbox>
                <label style={{ fontSize: '11px' }} htmlFor="cb8" className="p-checkbox-label">B4</label>&nbsp;&nbsp;&nbsp;
                <Checkbox onChange={e => this.MudoueventoC(e.checked)} checked={this.state.CChecked}></Checkbox>
                <label style={{ fontSize: '11px' }} htmlFor="c9" className="p-checkbox-label">C</label><br />
                <BrazilMap key={this.state.selectedData} rotulo={this.TituloGraficoParte1} selectedData={this.state.selectedData}></BrazilMap>
            </div>
        );
    }

    render() {
        const { loading, error } = this.state;

        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents = <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                       </div>;
        }
        else {
            contents = this.renderPeriodicoPorArea();
        }

        return (
            <div>
                {contents}
            </div>
        );
    }

}