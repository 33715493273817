import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import SelecInstituicoes from '../../components/SelecInstituicoes/SelecInstituicoes';
import Formacoes from '../../components/Formacoes/Formacoes';

export class FormacaoAcademica extends Component {
    displayName = FormacaoAcademica.name

  constructor(props) {
      super(props);
      let IFTM = {
          id: -1,
          nome: "IFTM",
          sigla: "IFTM",
          quantidadeProfessores: 0,
          quantidadeProfessoresLattes: 0
      }
      this.state = { QualInstituicao: IFTM };
      this.updateInst = this.updateInst.bind(this);
    }

  //Método para atualizar a instituição:
  updateInst = (QualInstituicao) => { this.setState({ QualInstituicao }); }

  render() {
    return (
        <Container>
            <Row>
                <Col sm={12}>
                    <h2>Formação Acadêmica por Instituição</h2>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>                    
                    <PainelConteudo legenda="Pesquisar"
                        texto={
                            <Row>
                                <Col sm={12}>
                                    Escolha a Instituição:
                                    <SelecInstituicoes AtualizaQualInstituicao={this.updateInst} />
                                </Col>
                            </Row>                            
                        } />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <br />
                    <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{this.state.QualInstituicao.nome}</span><br />
                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Número de docentes: {this.state.QualInstituicao.quantidadeProfessores}</span><br />
                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Número de docentes com Lattes: {this.state.QualInstituicao.quantidadeProfessoresLattes}</span><br />                    
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <PainelConteudo legenda="Graduações"
                        texto={
                            <Row>
                                <Col sm={12}>
                                    <Formacoes                                        
                                        QualFormacao="1"
                                        QualInstituicao={this.state.QualInstituicao.id} />
                                </Col>
                            </Row>
                        } />
                </Col>
            </Row>
        </Container>
    );
  }
}
