import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../Pesquisadores/Pesquisadores';
import { formatDate } from '../../helpers/Date';
import Alert from 'react-bootstrap/Alert';
import { TabView, TabPanel } from 'primereact/tabview';
import disciplinaService from '../../services/disciplinaService';

export default class RelacaoOfertaProcura extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            dadosOferta: null,
            descTurma1: "# Turma ",
            descTurma2: "# Turma ",
            descTurma1Total: "# Turma ",
            descTurma2Total: "# Turma "
        };
        this.ColunaDisciplina = this.ColunaDisciplina.bind(this);
        this.ColunaInstituicao = this.ColunaInstituicao.bind(this);
        this.ColunaMedia = this.ColunaMedia.bind(this);
        this.rowExpansionTemplateCelular = this.rowExpansionTemplateCelular.bind(this);
        this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
        this.rowExpansionInstituicaoCelular = this.rowExpansionInstituicaoCelular.bind(this);
        this.rowExpansionInstituicao = this.rowExpansionInstituicao.bind(this);
    }

    componentDidMount() {
        this.CarregarDados();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.QualOferta !== this.props.QualOferta) {
            this.CarregarDados();
        }
    }

    ColunaDisciplina(rowData) {
        let conteudodisc = <span></span>;
        if (rowData.conteudo) {
            conteudodisc = <i><span style={{ fontSize: "10px" }}><br />{rowData.conteudo}</span></i>;
        }
        if (rowData.alertaDisciplina) {
            return  <Alert style={{ margin: "0px 0px 0px", padding: "6px" }} variant={rowData.alertaDisciplina}>
                        <span style={{ fontSize: "14px" }}>{rowData.nome}</span>{conteudodisc}
                    </Alert>;
        }
        else {
            return  <div>
                        <span style={{ fontSize: "14px" }}>{rowData.nome}</span>{conteudodisc}
                    </div>;
        }
    }

    ColunaMedia(rowData) {
        if ((rowData.qtdeTurmaTotal == 0) || (rowData.qtdeProfessores == 0)) {
            return <i><span>-</span></i>;
        }
        else {
            return <span>{rowData.mediaPorTurma}</span>;
        }
    }

    ColunaInstituicao(rowData) {
        if (rowData.alertaInstituicao) {
            return <Alert style={{ margin: "0px 0px 0px", padding: "6px" }} variant={rowData.alertaInstituicao}>
                        <span style={{ fontSize: "14px" }}>{rowData.nome}</span>
                    </Alert>;
        }
        else {
            return  <div>
                         <span style={{ fontSize: "14px" }}>{rowData.nome}</span>
                    </div>;
        }
    }

    CarregarDados() {
        this.setState({ loading: true });    
        let StringFetch = "api/Disciplinas/RelacaoOfertaProcura";
        if (this.props.QualOferta) {
            StringFetch += '?OfertaID=' + this.props.QualOferta;
        }
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({
                    dadosOferta: data,
                    descTurma1: "# Turma " + data.anoTurmaInicial + " Inscritos",
                    descTurma2: "# Turma " + (parseInt(data.anoTurmaInicial) - 1).toString() + " Inscritos",
                    descTurma1Total: "# Turma " + data.anoTurmaInicial,
                    descTurma2Total: "# Turma " + (parseInt(data.anoTurmaInicial) - 1).toString(),
                    loading: false
                });
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    rowConteudoProfessores(rowData) {
        let strProfs = "";
        for (let i = 0; i < rowData.professores.length; i++) {
            if (i > 0)
                strProfs += " ; ";
            strProfs += rowData.professores[i];            
        }
        return <div><div className="p-md-2">Professores: </div>
                    <div className="p-md-10" style={{ fontWeight: 'bold' }}>{strProfs}</div></div>;
    }

    rowConteudoDisciplinas(rowData) {
        let strProfs = "";
        for (let i = 0; i < rowData.disciplinas.length; i++) {
            if (i > 0)
                strProfs += " ; ";
            strProfs += rowData.disciplinas[i];
        }
        return <div><div className="p-md-2">Disciplinas: </div>
            <div className="p-md-10" style={{ fontWeight: 'bold' }}>{strProfs}</div></div>;
    }

    rowExpansionTemplateCelular(data) {
        return (
            <div className="p-grid p-fluid" style={{ padding: '1em' }}>
                <div className="p-col-12 p-md-12">
                    <div className="p-grid">
                        <div className="p-md-2">{this.state.descTurma1}: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.qtdeTurmaAtual}</div>
                        <div className="p-md-2">{this.state.descTurma2}: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.qtdeTurmaAnterior}</div>
                        <div className="p-md-2"># Alunos Inscritos: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.qtdeTurmaTotal}</div>
                        <div className="p-md-2"># Professores (Turmas): </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.qtdeProfessores}</div>
                        <div className="p-md-2">Média por Professor: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{this.ColunaMedia(data)}</div>
                        {this.rowConteudoProfessores(data)}
                    </div>
                </div>
            </div>
        );
    }

    rowExpansionTemplate(data) {
        return (
            <div className="p-grid p-fluid" style={{ padding: '1em' }}>
                <div className="p-col-12 p-md-12">
                    <div className="p-grid">
                        {this.rowConteudoProfessores(data)}
                    </div>
                </div>
            </div>
        );
    }

    renderDisciplinasCelular() {
        var header= <div style={{ 'textAlign': 'left' }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Pesquisar" size="20" />
                    </div>;

        return (
            <div>
                <div className="content-section implementation">
                    <DataTable value={this.state.dadosOferta.disciplinas} paginator={true} rows={15} rowsPerPageOptions={[5, 15, 30]}
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        globalFilter={this.state.globalFilter} header={header}
                        expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                        rowExpansionTemplate={this.rowExpansionTemplateCelular}
                    >
                        <Column expander={true} style={{ width: '3em' }} />
                        <Column field="nome" header="Disciplina" body={this.ColunaDisciplina} sortable={true} />
                    </DataTable>
                </div>
            </div>
        );
    }

    renderDisciplinasTamanhoNormal() {
        var header= <div style={{ 'textAlign': 'left' }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Pesquisar" size="50" />
                    </div>;
        return (
            <div>
                <div className="content-section implementation">
                    <DataTable value={this.state.dadosOferta.disciplinas} paginator={true} rows={15} rowsPerPageOptions={[5, 15, 30]}
                        resizableColumns={true} columnResizeMode="fit"
                        globalFilter={this.state.globalFilter} header={header}
                        expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                        rowExpansionTemplate={this.rowExpansionTemplate}
                    >
                        <Column expander={true} style={{ width: '3em' }} />
                        <Column field="nome" header="Disciplina" body={this.ColunaDisciplina} sortable={true} />
                        <Column field="qtdeTurmaAtual" header={this.state.descTurma1} sortable={false} style={{ textAlign: 'right', width: '9em' }} />
                        <Column field="qtdeTurmaAnterior" header={this.state.descTurma2} sortable={false} style={{ textAlign: 'right', width: '9em' }} />
                        <Column field="qtdeTurmaTotal" header="# Alunos Inscritos" sortable={false} style={{ textAlign: 'right', width: '9em' }} />
                        <Column field="qtdeProfessores" header="# Professores (Turmas)" sortable={false} style={{ textAlign: 'right', width: '9em' }} />
                        <Column field="mediaPorTurma" header="# Média por Professor" body={this.ColunaMedia} sortable={false} style={{ textAlign: 'right', width: '9em' }} />
                    </DataTable>
                </div>
            </div>
        );
    }

    renderDisciplinas() {
        var windowWidth = window.innerWidth;
        if (windowWidth < 770) {
            return this.renderDisciplinasCelular();
        }
        else {
            return this.renderDisciplinasTamanhoNormal();
        }
    }

    rowExpansionInstituicaoCelular(data) {
        return (
            <div className="p-grid p-fluid" style={{ padding: '1em' }}>
                <div className="p-col-12 p-md-12">
                    <div className="p-grid">
                        <div className="p-md-2">{this.state.descTurma1}: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.qtdeTurmaAtual}</div>
                        <div className="p-md-2">{this.state.descTurma1Total}: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.qtdeAlunosTurmaAtual}</div>
                        <div className="p-md-2">{this.state.descTurma2}: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.qtdeTurmaAnterior}</div>
                        <div className="p-md-2">{this.state.descTurma2Total}: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.qtdeAlunosTurmaAnterior}</div>
                        <div className="p-md-2"># Professores (Turmas): </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.qtdeTurmaProfessores}</div>
                        {this.rowConteudoProfessores(data)}
                        {this.rowConteudoDisciplinas(data)}
                    </div>
                </div>
            </div>
        );
    }

    rowExpansionInstituicao(data) {
        return (
            <div className="p-grid p-fluid" style={{ padding: '1em' }}>
                <div className="p-col-12 p-md-12">
                    <div className="p-grid">
                        {this.rowConteudoProfessores(data)}
                        {this.rowConteudoDisciplinas(data)}
                    </div>
                </div>
            </div>
        );
    }

    renderInstituicoesCelular() {
        var header = <div style={{ 'textAlign': 'left' }}>
            <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
            <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Pesquisar" size="20" />
        </div>;

        return (
            <div>
                <div className="content-section implementation">
                    <DataTable value={this.state.dadosOferta.instituicoes} paginator={true} rows={15} rowsPerPageOptions={[5, 15, 30]}
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        globalFilter={this.state.globalFilter} header={header}
                        expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                        rowExpansionTemplate={this.rowExpansionInstituicaoCelular}
                    >
                        <Column expander={true} style={{ width: '3em' }} />
                        <Column field="nome" header="Instituição" body={this.ColunaInstituicao} sortable={true} />
                    </DataTable>
                </div>
            </div>
        );
    }

    renderInstituicoesTamanhoNormal() {
        var header= <div style={{ 'textAlign': 'left' }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Pesquisar" size="50" />
                    </div>;
        return (
            <div>
                <div className="content-section implementation">
                    <DataTable value={this.state.dadosOferta.instituicoes} paginator={true} rows={15} rowsPerPageOptions={[5, 15, 30]}
                        resizableColumns={true} columnResizeMode="fit"
                        globalFilter={this.state.globalFilter} header={header}
                        expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                        rowExpansionTemplate={this.rowExpansionInstituicao}
                    >
                        <Column expander={true} style={{ width: '3em' }} />
                        <Column field="nome" header="Instituição" body={this.ColunaInstituicao} sortable={true} />
                        <Column field="qtdeTurmaAtual" header={this.state.descTurma1} sortable={false} style={{ textAlign: 'right', width: '9em' }} />
                        <Column field="qtdeAlunosTurmaAtual" header={this.state.descTurma1Total} sortable={false} style={{ textAlign: 'right', width: '9em' }} />
                        <Column field="qtdeTurmaAnterior" header={this.state.descTurma2} sortable={false} style={{ textAlign: 'right', width: '9em' }} />
                        <Column field="qtdeAlunosTurmaAnterior" header={this.state.descTurma2Total} sortable={false} style={{ textAlign: 'right', width: '9em' }} />
                        <Column field="qtdeTurmaProfessores" header="# Professores (Turmas)" sortable={false} style={{ textAlign: 'right', width: '9em' }} />
                    </DataTable>
                </div>
            </div>
        );
    }

    renderInstituicoes() {
        var windowWidth = window.innerWidth;
        if (windowWidth < 770) {
            return this.renderInstituicoesCelular();
        }
        else {
            return this.renderInstituicoesTamanhoNormal();
        }
    }

    render() {
        const { dadosOferta, loading, error } = this.state;

        let contents = <p>Conteudo</p>;
        let contentsCabecalho = <p></p>;
        let contentsInst = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents =  <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                        </div>;
        }
        else if ((!dadosOferta) || (!dadosOferta.inicioInscAluno)) {
            contents =
                <div>
                    <p> A oferta de disciplinas eletivas não começou. </p>
                </div>
        }
        else {
            contentsCabecalho =
                <div>
                    <p> O prazo para os discentes informarem o seu interesse nas disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscAluno)}</b> a <b>{formatDate(dadosOferta.fimInscAluno)}</b>. </p>
                    <p> O prazo para os docentes informarem a oferta de disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscProf)}</b> a <b>{formatDate(dadosOferta.fimInscProf)}</b>. </p>
                </div>
            contents = this.renderDisciplinas();
            contentsInst = this.renderInstituicoes();
        }

        return (
            <div>
                <div>
                    {contentsCabecalho}
                </div>
                <div>
                    <TabView renderActiveOnly={false}>
                        <TabPanel header="Por disciplina" leftIcon="fa fa-book">
                            {contents}
                        </TabPanel>
                        <TabPanel header="Por Instituição" leftIcon="fa fa-university">
                            {contentsInst}
                        </TabPanel>                        
                    </TabView>
                </div>                    
            </div>
        );

    }

}