import React, { useState, useContext, useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import '../Pesquisadores/Pesquisadores.css';
import { Button } from 'primereact/button';
import { Form } from 'react-bootstrap';
import SelectProfessor from '../SelectProfessor/SelecProfessor';
import SelectDisciplina from '../SelectDisciplina/SelecDisciplina';
import '../Pesquisadores/Pesquisadores.css';
import { formatDate } from '../../helpers/Date';

import LoadingContext from '../../context/loadingContext/loadingContext';
import MessageContext from '../../context/messageContext/messageContext';
import InfoContext from '../../context/infoContext/infoContext';

import disciplinaService from '../../services/disciplinaService';

export default function ProfessorDiscEletiva(props) {

    //Contexto
    const loadingContext = useContext(LoadingContext);
    const messageContext = useContext(MessageContext);
    const infoContext = useContext(InfoContext);

    //Construtor
    const [dadosOferta, setDadosOferta] = useState(null);
    const [ProfPrincipal, setProfPrincipal] = useState(null); 
    const [ProfAuxiliar, setProfAuxiliar] = useState(null); 
    const [Disciplina, setDisciplina] = useState(null); 
    const [ConteudoDisciplina, setConteudoDisciplina] = useState(null); 
    const [error, setError] = useState(null); 
       
    useEffect(() => {
        //componentDidMount
        loadingContext.showLoading();
        if ((infoContext.user == null) || (!(infoContext.user.profile.professor || infoContext.user.profile.coordenadorNacional)))
            return;
        let userPrincipal = { professorID: 0, nome: "" };
        if (infoContext.user.profile.professor) {
            userPrincipal = { professorID: infoContext.userInfo.idprof, nome: infoContext.user.name };
        }
        let profAuxiliar = { professorID: 0, nome: "" };

        disciplinaService.UltimaOferta()
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados do perfil...');
                }
            })
            .then(DadosResposta => {
                setDadosOferta(DadosResposta);
                setProfPrincipal(userPrincipal);
                setProfAuxiliar(profAuxiliar);
            })
            .catch(error => {
                messageContext.openMessage("error", "Erro.Por favor, tente novamente.");
                setError(error);
            });
        loadingContext.hideLoading();
    }, []); 

    const updateProfPrincipal = (pProfPrincipal) => { setProfPrincipal(pProfPrincipal); };
    const updateProfAuxiliar = (pProfAuxiliar) => { setProfAuxiliar(pProfAuxiliar); };
    const updateDisciplina = (pDisciplina) => { setDisciplina(pDisciplina); };

    const handleSubmit = (event) => {
        event.preventDefault();
        loadingContext.showLoading();

        let pBody = JSON.stringify({
            idOferta: dadosOferta.id, IdProfPrincipal: ProfPrincipal.professorID, IdProfAuxiliar: ProfAuxiliar.professorID,
            IdDisciplina: Disciplina.id, conteudoDisciplina: ConteudoDisciplina
        });

        disciplinaService.PostAtualizaOferta(pBody)
            .then(r => {
                r.json().then(response => {
                    if (response.statusCode) {
                        messageContext.openMessage(response.statusVariant, response.statusDescription);                        
                    }
                    if (props.AtualizaPagina) {
                        props.AtualizaPagina();
                    }
                });
            })
            .catch(error => {
                messageContext.openMessage("error", "Erro.Por favor, tente novamente.");
                setError(error);
            });
        loadingContext.hideLoading();
    }          

    function renderAll() {
        let contents = <p>Você deve logar como <b>Discente</b> para acessar este conteúdo.</p>;
        let contentsProfPrincipal = <p></p>;
        let contentsConteudoDisciplina = <p></p>;
        if (Disciplina && Disciplina.id === 14) {            
            contentsConteudoDisciplina =
                <div>
                    <br />
                    <p><b>Conteúdo (aprovado pela Comissão Acadêmica Local):</b></p>
                    <Form.Control as="textarea" rows="3" cols="50"  
                                  value={ConteudoDisciplina} onChange={(e) => setConteudoDisciplina(e.target.value)} autoResize={true}/>
                </div>;
        }
        let textoDocente = "Docente";
        if ((infoContext.user) && (infoContext.user.profile.coordenadorNacional))
            textoDocente = "Coordenador Nacional";
        else if ((infoContext.user) && (infoContext.user.profile.coordenadorCurso))
                textoDocente = "Coordenador Local";
        if (loadingContext.loading) {
            contents =  <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i> <em>Lendo/salvando informações...</em>
                        </div>;
        }
        else if ((!dadosOferta) || (!dadosOferta.inicioInscProf)) {
            contents =
                <div>
                    <p> Caro <b>{textoDocente}</b>, o cadastro de oferta de disciplinas eletivas não começou. </p>
                </div>
        }
        else if (dadosOferta.situacaoProf === 2) {
            contents =
                <div>
                    <p> Caro <b>{textoDocente}</b>, o cadastro de oferta de disciplinas eletivas <b>não começou</b>. </p>
                    <p> O prazo para os discentes informarem o seu interesse nas disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscAluno)}</b> a <b>{formatDate(dadosOferta.fimInscAluno)}</b>. </p>
                    <p> O prazo para os docentes informarem a oferta de disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscProf)}</b> a <b>{formatDate(dadosOferta.fimInscProf)}</b>. </p>
                    <p> A enturmação ocorrerá entre os dias <b>{formatDate(dadosOferta.inicioEnturmacao)}</b> e <b>{formatDate(dadosOferta.fimEnturmacao)}</b>. </p>
                </div>;
        }
        else if (dadosOferta.situacaoProf === 3) {
            contents =
                <div>
                    <p> Caro <b>{textoDocente}</b>, a oferta de disciplinas eletivas <b>já encerrou</b>. </p>
                    <p> O prazo para os discentes informarem o seu interesse nas disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscAluno)}</b> a <b>{formatDate(dadosOferta.fimInscAluno)}</b>. </p>
                    <p> O prazo para os docentes informarem a oferta de disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscProf)}</b> a <b>{formatDate(dadosOferta.fimInscProf)}</b>. </p>
                    <p> A enturmação ocorreu / ocorrerá entre os dias <b>{formatDate(dadosOferta.inicioEnturmacao)}</b> e <b>{formatDate(dadosOferta.fimEnturmacao)}</b>. </p>
                </div>;
        }           
        else {
            if ((infoContext.user) && ((infoContext.user.profile.coordenadorNacional) || (infoContext.user.profile.admin))) {
                //Administrador ou Coordenador Nacional
                contentsProfPrincipal = <SelectProfessor AtualizaQualProfessor={updateProfPrincipal} />;
            }
            else if ((infoContext.user) && (infoContext.user.profile.coordenadorCurso)) {
                //Coordenador Local
                contentsProfPrincipal = <SelectProfessor QualInstituicao={infoContext.userInfo.instituicao.id} AtualizaQualProfessor={updateProfPrincipal} />;
            }
            else { //Docente
                contentsProfPrincipal = <p><img src={infoContext.user.picture} alt="Foto" className="prof-imagem-redondo" />{infoContext.user.name}</p>;
            }
            contents =
                <Container >
                    <Row>
                        <Col sm={12}>
                            <p> Caro <b>{textoDocente}</b>, aqui você vai cadastrar a oferta de disciplinas eletivas para <b>{dadosOferta.anoSemestre}</b>. </p>
                            <p> O prazo para os discentes informarem o seu interesse nas disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscAluno)}</b> a <b>{formatDate(dadosOferta.fimInscAluno)}</b>. </p>
                            <p> O prazo para os docentes informarem a oferta de disciplinas eletivas é de <b>{formatDate(dadosOferta.inicioInscProf)}</b> a <b>{formatDate(dadosOferta.fimInscProf)}</b>. </p>
                            <p> A enturmação ocorrerá entre os dias <b>{formatDate(dadosOferta.inicioEnturmacao)}</b> e <b>{formatDate(dadosOferta.fimEnturmacao)}</b>. </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <p><b>Professor principal:</b></p>
                            {contentsProfPrincipal}
                        </Col>
                        <Col sm={6}>
                            <p><b>Professor auxiliar (se houver):</b></p>
                            <SelectProfessor AtualizaQualProfessor={updateProfAuxiliar} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <br />
                            <p><b>Disciplina a ser ofertada:</b></p>
                            <SelectDisciplina ComTodas="1" AtualizaQualDisciplina={updateDisciplina} />
                        </Col>
                        <Col sm={6}>
                            {contentsConteudoDisciplina}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <div style={{ textAlign: "left" }}>
                                <br />
                                <Button type="submit" label="Adicionar" className="p-button-success" />
                            </div>
                        </Col>
                    </Row>
                </Container>;
        }

        return (
            <form name="OfertaProfessorform" onSubmit={handleSubmit.bind(this)}>
                <div >
                    {contents}
                </div>
            </form >
        );
    }

    return (
        renderAll()
        );
}