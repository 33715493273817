import React, { useState, useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap'; 
import { DataView, DataViewLayoutOptions } from "primereact/dataview";

import autoavaliacaoService from '../../services/autoavaliacaoService';

import MessageContext from '../../context/messageContext/messageContext';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';

import { AvalRelatorioItem } from './AvalRelatorioItem';

export default function AvalRelatorioDisciplinas(props) {

    //Contexto
    const messageContext = useContext(MessageContext);
    const idCt = useContext(IdiomaContext);

    //Construtor
    const [Relatorio, setRelatorio] = useState(null);
    const [Disciplina, setDisciplina] = useState(0);
    const [layout, setLayout] = useState('list');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        //componentDidMount e componentDidUpdate      
        CarregarDados();
    }, [Disciplina]);

    useEffect(() => {
        //componentDidMount e componentDidUpdate
        setDisciplina(0);
        CarregarDados();
    }, [props]);

    function CarregarDados() {
        setLoading(true);
        setRelatorio(null);
        if (props.QualAutoAval.id === 0) return;
        autoavaliacaoService.GetRelatorioDisciplinas(props.QualAutoAval.id, props.QualModelo, props.QualInstituicao.id, Disciplina)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                setRelatorio(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                messageContext.openMessage("error", "Erro.Por favor, tente novamente.");
                setError(error);
            });
    }

    function itemTemplate(indicador, playout) {
        if (!indicador) {
            return renderNullItem();
        }
        if (playout === 'list')
            return renderListItem(indicador);
        //else if (layout === 'grid')
        return renderGridItem(indicador);
    }

    function renderNullItem() {
        return (<div></div>);
    }

    function renderItem(item, tipoRender) {
        return (
            item.relatorio.indicadores.map(indicador =>
                <Container >
                    <Row>
                        <Col sm={12}>
                            <div style={{ fontSize: '13px' }}><b>{(props.QualModelo === 1) ? <span>Disciplina Eletiva</span> : <span>Disciplina Obrigatória</span>}: </b>{item.disciplina}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <div style={{ fontSize: '13px' }}><b>Indicador: </b><span style={{ color: 'black' }}>{indicador.pergunta}</span></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <div style={{ fontSize: '12px' }}>
                                <AvalRelatorioItem Indicador={indicador} Tipo={tipoRender} QualInstituicao={props.QualInstituicao} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <hr size="1" />
                        </Col>
                    </Row>
                </Container>
            )
        );
    }

    function renderListItem(indicador) {
        return (renderItem(indicador, "COMPLETO"));
    }

    function renderGridItem(indicador) {
        return (renderItem(indicador, "RESUMO"));
    }

    function renderHeader() {
        return (
            <Container >
                <Row>                    
                    <Col sm={10} style={{ textAlign: 'left' }}>
                        <div style={{ display: 'inline-block', padding: '0.20em 0 0 0' }}>Disciplina:&nbsp;&nbsp;</div>
                        <div style={{ display: 'inline-block' }}>
                            <Form.Control as="select"
                                placeholder="Escolha a Disciplina"
                                onChange={(e) => setDisciplina(e.target.value)} >
                                <option value={0} key={0}> Todas as disciplinas </option>
                                {
                                    Relatorio.disciplinas.map(tipo =>
                                        (tipo.id == Disciplina) ?
                                            <option selected value={tipo.id} key={tipo.id}> {tipo.nome} </option> :
                                            <option value={tipo.id} key={tipo.id}> {tipo.nome} </option>)
                                }
                            </Form.Control>
                        </div>
                    </Col>
                    <Col sm={2} style={{ textAlign: 'right' }}>
                        <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
                    </Col>
                </Row>
            </Container>
        );
    }

    function renderRelatorio() {
        const header = renderHeader();
        console.log(Relatorio);
        if ((loading) || (Relatorio == null) || (Relatorio.disciplinasRelatorio == null) || (Relatorio.disciplinasRelatorio.length == 0)) {
            return (
                <Container>
                    <Row>
                        <Col sm={12}>
                            <p><i className="fa fa-refresh fa-spin"></i> <em>Aguarde. Lendo dados...</em></p>
                        </Col>
                    </Row>
                </Container>
            );
        }
        return (
            <Container>
                <Row>
                    <Col sm={12} style={{ padding: '0' }}>
                        <DataView value={Relatorio.disciplinasRelatorio} layout={layout} header={header}
                                  itemTemplate={itemTemplate} paginatorPosition={'both'} paginator={true} rows={1} />
                    </Col>
                </Row>
            </Container>
            );
    }
         
    function renderAll() {

        let contents = <p></p>;
        if ((loading)) {
            return (
                <div>
                    <p><i className="fa fa-refresh fa-spin"></i> <em>Aguarde. Lendo dados...</em></p>
                </div>
            );
        }
        else if (error) {
            return (
                <div>
                    <p><em>Ocorreu um erro.</em></p>
                </div>
            );
        }
        else {
            contents = renderRelatorio();
        }

        return (
            <div>
                {contents}
            </div>
        );

    }

    return (
        renderAll()
    );

}