import authService from '../components/api-authorization/AuthorizeService';

const autoavaliacaoService = {
    UltimaOferta,
    GetFichaAval,
    putResposta,
    GetDisciplinasObrigatorias,
    GetDisciplinasEletivas,
    GetRelacaoAvalInstituicoes,
    GetRelatorio,
    GetRelatorioObs,
    GetRelatorioDisciplinas
}

async function UltimaOferta() {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: "GET",
        headers: headers
    };
    return await fetch('api/AutoAvaliacao/UltimaOferta', options);
}
 
async function GetFichaAval(TipoAvaliador) {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: "GET",
        headers: headers
    };
    if (TipoAvaliador === "coordenadorCurso")
        return await fetch('api/AutoAvaliacao/GetFichaCoordenador', options);
    if (TipoAvaliador === "docente")
        return await fetch('api/AutoAvaliacao/GetFichaDocente', options);
    if (TipoAvaliador === "egresso")
        return await fetch('api/AutoAvaliacao/GetFichaAluno?Tipo=egresso', options);
    if (TipoAvaliador === "discente")
        return await fetch('api/AutoAvaliacao/GetFichaAluno?Tipo=discente', options);
}

async function putResposta(pBody) {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: 'PUT',
        headers: headers,
        body: pBody,
        cache: 'default'
    };
    return await fetch('api/AutoAvaliacao/PutResposta', options);
}

async function GetDisciplinasObrigatorias(id) {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: "GET",
        headers: headers
    };
    return await fetch('api/AutoAvaliacao/GetDisciplinasObrigatorias?AutoAvaliacaoID='+id, options);
}

async function GetDisciplinasEletivas(id) {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: "GET",
        headers: headers
    };
    return await fetch('api/AutoAvaliacao/GetDisciplinasEletivas?AutoAvaliacaoID=' + id, options);
}

async function GetRelacaoAvalInstituicoes(id) {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: "GET",
        headers: headers
    };
    return await fetch('api/AutoAvaliacao/RelacaoAvalInstituicoes?AutoAvaliacaoID=' + id, options);
}

async function GetRelatorio(AutoAvaliacaoID, QualModelo, QualInstituicao, QualDimensao) {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: "GET",
        headers: headers
    };
    return await fetch('api/AutoAvaliacao/GetRelatorio?' +
        'AutoAvaliacaoID=' + AutoAvaliacaoID +
        '&QualModelo=' + QualModelo +
        '&QualInstituicao=' + QualInstituicao+
        '&QualDimensao=' + QualDimensao, options);
}

async function GetRelatorioObs(AutoAvaliacaoID, QualInstituicao) {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: "GET",
        headers: headers
    };
    return await fetch('api/AutoAvaliacao/GetRelatorioObs?' +
        'AutoAvaliacaoID=' + AutoAvaliacaoID +
        '&QualInstituicao=' + QualInstituicao, options);
}

async function GetRelatorioDisciplinas(AutoAvaliacaoID, QualModelo, QualInstituicao, QualDisciplina) {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: "GET",
        headers: headers
    };
    return await fetch('api/AutoAvaliacao/GetRelatorioDisciplinas?' +
        'AutoAvaliacaoID=' + AutoAvaliacaoID +
        '&QualModelo=' + QualModelo +
        '&QualInstituicao=' + QualInstituicao +
        '&QualDisciplina=' + QualDisciplina, options);
}

export default autoavaliacaoService;

