import React, { useState, useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap'; ///lib/FormControl';
import { Panel } from 'primereact/panel';
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Rating from '@material-ui/lab/Rating';
import { Button } from 'primereact/button';
import ModalMensagem from '../ModalMensagem/ModalMensagem';
import { formatDate } from '../../helpers/Date';
import discenteService from '../../services/discenteService';

import LoadingContext from '../../context/loadingContext/loadingContext';
import MessageContext from '../../context/messageContext/messageContext';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';

export default function Egressos(props) {

    //Contexto
    const loadingContext = useContext(LoadingContext);
    const messageContext = useContext(MessageContext);
    const idCt = useContext(IdiomaContext);

    //Construtor
    const [Egressos, setEgressos] = useState([]);
    const [EgressoSelecionado, setEgressoSelecionado] = useState(null);
    const [rate, setRate] = useState(null);
    const [hoverRateInt, setHoverRateInt] = React.useState(-1);
    const [comentario, setComentario] = useState(null);
    const [ExibeMsg, setExibeMsg] = useState(false);
    const [ExibeMsgEstat, setExibeMsgEstat] = useState(false);
    const [layout, setLayout] = useState('list');
    const [sortKey, setSortKey] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [error, setError] = useState(null);

    const labelsRating = {
        0: '',
        0.5: idCt.t('Sem utilidade','Useless'),
        1: idCt.t('Sem utilidade+', 'Useless+'),
        1.5: idCt.t('Ruim', 'Poor'),
        2: idCt.t('Ruim+', 'Poor+'),
        2.5: 'Ok',
        3: 'Ok+',
        3.5: idCt.t('Bom', 'Good'),
        4: idCt.t('Bom+', 'Good+'),
        4.5: idCt.t('Ótimo', 'Excellent'),
        5: idCt.t('Ótimo+', 'Excellent+')
    };

    const labelsRatingInt = {
        1: idCt.t('Sem utilidade', 'Useless'),
        2: idCt.t('Ruim', 'Poor'),
        3: 'Ok',
        4: idCt.t('Bom', 'Good'),
        5: idCt.t('Ótimo', 'Excellent')
    };

    useEffect(() => {
        //componentDidMount e componentDidUpdate
        loadingContext.showLoading();
        CarregarDados();
        loadingContext.hideLoading();
    }, [props.Pesquisou]);


    function CarregarDados() {
        setEgressoSelecionado(null);
        let QualInstituicao = (props.QualInstituicao != null) ? props.QualInstituicao : 0;
        let QualProdEdu = (props.QualProdEdu != null) ? props.QualProdEdu : 0;
        let QualAnoDefesa = ((props.QualAnoDefesa != null)&&(props.QualAnoDefesa.trim())) ? parseInt(props.QualAnoDefesa) : 0;
        let QualAssunto = (props.QualAssunto != null) ? props.QualAssunto : "";
        let QualEgresso = (props.QualEgresso != null) ? props.QualEgresso : "";
        let QualMembro = (props.QualMembro != null) ? props.QualMembro : "";
        if ((QualInstituicao == 0) && (QualProdEdu == 0) && (QualAnoDefesa == 0) && (!QualAssunto) && (!QualEgresso) && (!QualMembro)) {
            return;
        }
        let StringFetch = 'api/Discentes/GetEgressos?' +
            'QualInstituicao=' + QualInstituicao +
            '&QualProdEdu=' + QualProdEdu +
            '&QualAnoDefesa=' + QualAnoDefesa +
            '&QualAssunto=' + QualAssunto +
            '&QualNomeEgresso=' + QualEgresso +
            '&QualMembroBanca=' + QualMembro;
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                setEgressos(data);
            })
            .catch(error => {
                messageContext.openMessage("error", "Erro.Por favor, tente novamente.");
                setError(error);
            });
    }

    function abrirJanela(aluno, opcao) {
        discenteService.putAtualizaAcessoAluno(aluno.id, parseInt(opcao), 0, "")
            .then(r => {
                r.json().then(response => {
                    if (response.statusCode) {
                        //messageContext.openMessage(response.statusVariant, response.statusDescription);
                    }
                });
            })
            .catch(error => {
                //messageContext.openMessage("error", "Erro. Por favor, tente novamente.");
                //setError(error);
            });
        let endereco = 'https://obsprofept.midi.upt.iftm.edu.br';
        if (parseInt(opcao) === 1) {
            endereco = aluno.linkDissertacao;
        }
        if (parseInt(opcao) === 2) {
            endereco = "https://www.facebook.com/sharer/sharer.php?u=" + aluno.linkDissertacao;
        }
        if (parseInt(opcao) === 3) {
            endereco = aluno.linkProdEdu;
        }
        if (parseInt(opcao) === 4) {
            endereco = "https://www.facebook.com/sharer/sharer.php?u=" + aluno.linkProdEdu;
        }
        if (parseInt(opcao) === 5) {
            endereco = "mailto:" + aluno.email;
        }
        if (parseInt(opcao) === 6) {
            endereco = aluno.lattesEndereco;
        }
        if (parseInt(opcao) === 7) {
            endereco = aluno.facebookEndereco;
        }
        if ((endereco.substring(0, 7) != 'http://') && (endereco.substring(0, 8) != 'https://')) {
            endereco = 'https://' + endereco;
        }
        window.open(endereco);
    }

    function onSortChange(value) {
        //const value = event.value;
        if (value.indexOf('!') === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        }
        else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
    }

    function itemTemplate(aluno, playout) {
        if (!aluno) {
            return renderNullItem();
        }
        if (playout === 'list')
            return renderListItem(aluno);
        //else if (layout === 'grid')
        return renderGridItem(aluno);
    }

    function renderNullItem() {
        return (<div></div>);
    }

    function BancaExaminadora(aluno) {
        let Banca = '';
        if (aluno.membroBancaProfEPT1 != null) {
            Banca = aluno.membroBancaProfEPT1.nome + " (" + aluno.membroBancaProfEPT1.instituicao.sigla + ")";
        }
        if (aluno.membroBancaProfEPT2 != null) {
            Banca += "; " + aluno.membroBancaProfEPT2.nome + " (" + aluno.membroBancaProfEPT2.instituicao.sigla + ")";
        }
        if (aluno.membroBancaProfEPT3 != null) {
            Banca += "; " + aluno.membroBancaProfEPT3.nome + " (" + aluno.membroBancaProfEPT3.instituicao.sigla + ")";
        }
        if (aluno.membroBancaExt1Nome) {
            Banca += "; " + aluno.membroBancaExt1Nome + " (" + aluno.membroBancaExt1Inst + ")";
        }
        if (aluno.membroBancaExt2Nome) {
            Banca += "; " + aluno.membroBancaExt2Nome + " (" + aluno.membroBancaExt2Inst + ")";
        }
        if (aluno.membroBancaExt3Nome) {
            Banca += "; " + aluno.membroBancaExt3Nome + " (" + aluno.membroBancaExt3Inst + ")";
        }
        return Banca;
    }

    function renderListItem(aluno) {
        let tipoProd = aluno.tipoProdEduDesc;
        if (aluno.tipoProdEduDesc2) {
            tipoProd = tipoProd + " / " + aluno.tipoProdEduDesc2;
        }
        let Banca = BancaExaminadora(aluno);
        let conteudoLinkDiss = <div></div>;
        if (aluno.linkDissertacao) {
            conteudoLinkDiss =
                <div>
                    <div style={{ display: 'inline-block', margin: '0px', padding: '0px' }}>
                        <IconButton color="primary" aria-label="Download" style={{ verticalAlign: "top", padding: '5px' }}
                            onClick={() => abrirJanela(aluno, 1)}>
                            <Icon>attach_file</Icon>
                        </IconButton>&nbsp;
                                </div>
                    <div style={{ display: 'inline-block', margin: '0px', padding: '0px' }}>
                        <IconButton color="primary" aria-label="Share" style={{ verticalAlign: "top", padding: '5px' }}
                            onClick={() => abrirJanela(aluno, 2)}>
                            <Icon>share</Icon>
                        </IconButton>&nbsp;
                                </div>
                    <div style={{ display: 'inline-block', margin: '0px', padding: '0px' }}>
                        <IconButton color="primary" aria-label="Rate" style={{ verticalAlign: "top", padding: '5px' }}
                            onClick={() => { setExibeMsgEstat(true); setEgressoSelecionado(aluno); setComentario(null); setRate(null); }}>
                            <Icon>equalizer</Icon>
                        </IconButton>
                    </div>
                </div>;
        }
        let conteudoLinkProd = <div></div>;
        if (aluno.linkProdEdu) {
            conteudoLinkProd =
                <div>
                    <div style={{ display: 'inline-block', margin: '0px', padding: '0px' }}>
                        <IconButton color="secondary" aria-label="Download" style={{ verticalAlign: "top", padding: '5px' }}
                            onClick={() => abrirJanela(aluno, 3)}>
                            <Icon>attach_file</Icon>
                        </IconButton>&nbsp;
                                </div>
                    <div style={{ display: 'inline-block', margin: '0px', padding: '0px' }}>
                        <IconButton color="secondary" aria-label="Share" style={{ verticalAlign: "top", padding: '5px' }}
                            onClick={() => abrirJanela(aluno, 4)}>
                            <Icon>share</Icon>
                        </IconButton>&nbsp;
                                </div>
                    <div style={{ display: 'inline-block', margin: '0px', padding: '0px' }}>
                        <IconButton color="secondary" aria-label="Rate" style={{ verticalAlign: "top", padding: '5px' }}
                            onClick={() => { setExibeMsg(true); setEgressoSelecionado(aluno); setComentario(null); setRate(null); }}>
                            <Icon>star</Icon>
                        </IconButton>
                    </div>
                </div>;
        }
        let conteudoRegistro = <span>&nbsp;</span>;
        if (aluno.registroProdEdu) {
            conteudoRegistro = 
                <span style={{ color: 'black' }}>
                    <b>&nbsp;&nbsp;&nbsp;&nbsp;{idCt.t("Registro", "Registration")}: </b>{aluno.registroProdEdu}
                </span>
        }

        return (
                <Container >
                    <Row>
                        <Col sm={6}>
                            <div style={{ fontSize: '12px' }}><b>{idCt.t("Egresso", "Alumni")}: </b>{aluno.nome} ({aluno.instituicao.sigla})</div>
                        </Col>
                        <Col sm={6}>
                            <div style={{ fontSize: '12px' }}><b>{idCt.t("Data da Defesa", "Defense Date")}: </b>{formatDate(aluno.dataDefesa)}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={10}>
                            <div style={{ fontSize: '12px', color: '#3F51B5' }}><b>{idCt.t("Dissertação", "Dissertation")}: </b><span style={{ color: 'black' }}>{idCt.t(aluno.tituloDissertacao, aluno.tituloDissertacaoIng)}</span></div>
                        </Col>
                        <Col sm={2} style={{ textAlign: 'center' }}>
                            {conteudoLinkDiss}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={10}>
                            <div style={{ fontSize: '12px', color: '#F50057' }}><b>{idCt.t("Produto Educacional", "Educational Product")}: </b><span style={{ color: 'black' }}>{idCt.t(aluno.tituloProdEdu, aluno.tituloProdEduIng)}</span></div>
                            <div style={{ fontSize: '12px' }}><i>({tipoProd})</i>
                            {conteudoRegistro}
                            </div>
                        </Col>
                        <Col rowspan={2} sm={2} style={{ textAlign: 'center' }}>
                            {conteudoLinkProd}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <div style={{ fontSize: '12px' }}><b>{idCt.t("Banca Examinadora", "Examiners")}: </b>{Banca}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <hr size="1" />
                        </Col>
                    </Row>
                </Container>
        );
    }

    function renderGridItem(aluno) {
        let tipoProd = aluno.tipoProdEduDesc;
        if (aluno.tipoProdEduDesc2) {
            tipoProd = tipoProd + " / " + aluno.tipoProdEduDesc2;
        }
        let conteudoLinkDiss = <span></span>;
        if (aluno.linkDissertacao) {
            conteudoLinkDiss =
                <span>
                    <div style={{ display: 'inline-block', margin: '0px', padding: '0px' }}>
                        <IconButton color="primary" aria-label="Download" style={{ verticalAlign: "top", padding: '5px' }}
                            onClick={() => abrirJanela(aluno, 1)}>
                            <Icon>attach_file</Icon>
                        </IconButton>&nbsp;&nbsp;
                                    </div>
                    <div style={{ display: 'inline-block', margin: '0px', padding: '0px' }}>
                        <IconButton color="primary" aria-label="Share" style={{ verticalAlign: "top", padding: '5px' }}
                            onClick={() => abrirJanela(aluno, 2)}>
                            <Icon>share</Icon>
                        </IconButton>&nbsp;&nbsp;
                                    </div>
                    <div style={{ display: 'inline-block', margin: '0px', padding: '0px' }}>
                        <IconButton color="primary" aria-label="Rate" style={{ verticalAlign: "top", padding: '5px' }}
                            onClick={() => { setExibeMsgEstat(true); setEgressoSelecionado(aluno); setComentario(null); setRate(null); }}>
                            <Icon>equalizer</Icon>
                        </IconButton>&nbsp;&nbsp;
                    </div>
                </span>;
        }
        let conteudoLinkProd = <span></span>;
        if (aluno.linkProdEdu) {
            conteudoLinkProd =
                <span>
                    <div style={{ display: 'inline-block', margin: '0px', padding: '0px' }}>
                        <IconButton color="secondary" aria-label="Download" style={{ verticalAlign: "top", padding: '5px' }}
                            onClick={() => abrirJanela(aluno, 3)}>
                            <Icon>attach_file</Icon>
                        </IconButton>&nbsp;&nbsp;
                                    </div>
                    <div style={{ display: 'inline-block', margin: '0px', padding: '0px' }}>
                        <IconButton color="secondary" aria-label="Share" style={{ verticalAlign: "top", padding: '5px' }}
                            onClick={() => abrirJanela(aluno, 4)}>
                            <Icon>share</Icon>
                        </IconButton>&nbsp;&nbsp;
                                    </div>
                    <div style={{ display: 'inline-block', margin: '0px', padding: '0px' }}>
                        <IconButton color="secondary" aria-label="Rate" style={{ verticalAlign: "top", padding: '5px' }}
                            onClick={() => { setExibeMsg(true); setEgressoSelecionado(aluno); setComentario(null); setRate(null); }}>
                            <Icon>star</Icon>
                        </IconButton>&nbsp;&nbsp;
                    </div>
                </span>;
        }
        let conteudoLinkLattes = <span></span>;
        if (aluno.lattesEndereco) {
            conteudoLinkLattes =
                <span>
                    <div style={{ display: 'inline-block', margin: '0px', padding: '0px' }}>
                        <IconButton color="primary" aria-label="Lattes" style={{ verticalAlign: "top", padding: "5px" }}
                            onClick={() => abrirJanela(aluno, 6)}>
                            <Icon>
                                <img src="images/lattes2.png" alt="Lattes" style={{ verticalAlign: "top", width: "100%", height: "100%", margin: "0px", padding: "0px" }} />
                            </Icon>
                        </IconButton>&nbsp;&nbsp;
                    </div>
                </span>;
        }
        let conteudoLinkFace = <span></span>;
        if (aluno.facebookEndereco) {
            conteudoLinkFace =
                <span>
                    <div style={{ display: 'inline-block', margin: '0px', padding: '0px' }}>
                        <IconButton color="primary" aria-label="facebook" style={{ verticalAlign: "top", padding: '5px' }}
                            onClick={() => abrirJanela(aluno, 7)}>
                            <Icon>
                                <img src="images/facebook-logo.svg" alt="Lattes" style={{ verticalAlign: "top", width: "100%", height: "100%", margin: "0px", padding: "0px" }} />
                            </Icon>
                        </IconButton>
                    </div>
                </span>;
        }

        return (
            <div style={{ display: 'inline-block', padding: '.4em', width: '50%', minWidth: '230px', flex: '0 0 auto', verticalAlign: 'top'}}>
                <Panel header={aluno.nome + " (" + aluno.instituicao.sigla + ")"} style={{ textAlign: 'center' }}>
                    <Container style={{ textAlign: 'left' }}>
                        <Row>
                            <Col sm={12}>
                                <div style={{ fontSize: '12px' }}><b>{idCt.t("Data da Defesa", "Defense Date")}: </b>{formatDate(aluno.dataDefesa)}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <div style={{ fontSize: '12px', color: '#3F51B5' }}><b>{idCt.t("Dissertação", "Dissertation")}: </b><span style={{ color: 'black' }}>{idCt.t(aluno.tituloDissertacao, aluno.tituloDissertacaoIng)}</span></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <div style={{ fontSize: '12px', color: '#F50057' }}><b>{idCt.t("Produto Educacional", "Educational Product")}: </b><span style={{ color: 'black' }}>{idCt.t(aluno.tituloProdEdu, aluno.tituloProdEduIng)}</span></div>
                                <div style={{ fontSize: '12px' }}><i>({tipoProd})</i></div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <hr size="1" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} style={{ textAlign: 'center' }}>
                                {conteudoLinkDiss}
                                {conteudoLinkProd}
                                {conteudoLinkLattes}
                                {conteudoLinkFace}
                            </Col>
                        </Row>
                    </Container>
                </Panel>
            </div>
        );
    }

    function renderHeader() {
        const sortOptions = [
            { label: idCt.t('Nome do Egresso', 'Name'), value: 'nome' },
            { label: idCt.t('Data da Defesa', 'Defense Date'), value: '!dataDefesa' },
            { label: idCt.t('Título da Dissertação', 'Dissertation Title'), value: 'tituloDissertacao' }
        ];

        return (
            <Container >
                <Row>
                    <Col sm={6} style={{ textAlign: 'left' }}>
                        <div style={{ display: 'inline-block', padding: '0.28em 0 0 0' }}>{idCt.t("Ordenar por", 'Sort by')}:&nbsp;&nbsp;</div>
                        <div style={{ display: 'inline-block' }}>
                            <Form.Control as="select"
                                placeholder="Ordenar por"
                                onChange={(e) => onSortChange(e.target.value)} >
                                {
                                    sortOptions.map(tipo =>
                                        <option value={tipo.value} key={tipo.value}> {tipo.label} </option>)
                                }
                            </Form.Control>
                        </div>
                    </Col>
                    <Col sm={6} style={{ textAlign: 'right' }}>
                        <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
                    </Col>
                </Row>
            </Container>
        );
    }

    function renderEgressos() {
        const header = renderHeader();
        if (Egressos == null) {
            return (
                <Container>
                    <Row>
                        <Col sm={12}>
                            {idCt.t("Nenhum egresso encontrado.", "No Educational Product found.")}
                        </Col>
                    </Row>
                </Container>
            );
        }
        return (
            <Container>
                <Row>
                    <Col sm={12} style={{ padding: '0'}}>
                        <div style={{ fontSize: '12px' }}><i>{idCt.t("Total de egressos encontrados ", "Total graduates found ")} {Egressos.length}</i>.</div>
                        <div style={{ fontSize: '12px' }}><i>{idCt.t("É muito importante para o programa conhecer onde/como os Produtos Educacionais/Dissertações estão sendo utilizados. Caso você utilizou algum, por favor, avalie clicando na estrela.", "It is very important for the program to know where / how Educational Products / Dissertations are being used. If you used any, please rate by clicking on the star.")}</i></div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} style={{ padding: '0' }}>
                        <DataView value={Egressos} layout={layout} header={header}
                                  itemTemplate={itemTemplate} paginatorPosition={'both'} paginator={true} rows={10}
                                  sortOrder={sortOrder} sortField={sortField} />
                    </Col>
                </Row>
            </Container>
            );
    }

    function handleSubmitAvaliacao(event) {
        event.preventDefault();
        loadingContext.showLoading();
        let qualAvaliacao = (rate != null) ? rate : 0;
        let qualComentario = (comentario != null) ? comentario : "";
        discenteService.putAtualizaAcessoAluno(EgressoSelecionado.id, 5, qualAvaliacao, qualComentario)
            .then(r => {
                r.json().then(response => {
                    if (response.statusCode) {
                        messageContext.openMessage(response.statusVariant, response.statusDescription);
                    }
                });
            })
            .catch(error => {
                messageContext.openMessage("error", "Erro. Por favor, tente novamente.");
                setError(error);
            });        
        loadingContext.hideLoading();
    } 

    function renderDetalhesEstat() {
        if (!EgressoSelecionado) {
            return;
        }
        return (<div>
            <ModalMensagem updateShow={setExibeMsgEstat}
                show={ExibeMsgEstat} titulo="Informações do Trabalho"
                mensagem=
                {<div>
                    <p>{idCt.t("Estatísticas da dissertação/produto educacional de", "Statistics of the dissertation / educational product of")}:</p>
                    <p><b>{EgressoSelecionado.nome}</b><br /><i>{EgressoSelecionado.email}</i></p>
                    <p>{idCt.t("Total de visualizações da dissertação", "Dissertation views")}: {EgressoSelecionado.visualizacoesDissertacao}</p>
                    <p>{idCt.t("Total de compartilhamentos no facebook da dissertação", "Dissertation shares")}: {EgressoSelecionado.compartilhamentosDissertacao}</p>
                    <p>{idCt.t("Total de visualizações do produto educacional", "Education product views")}: {EgressoSelecionado.visualizacoesProdEdu}</p>
                    <p>{idCt.t("Total de compartilhamentos no facebook do produto educacional", "Education product shares")}: {EgressoSelecionado.compartilhamentosProdEdu}</p>
                    <p>{idCt.t("Total de Avaliações", "Ratings")}: {EgressoSelecionado.visualizacoesAvalicacoes}</p>
                    <p>{idCt.t("Média das Avaliações", "Average Rating")}: </p>
                    <div style={{ fontSize: '0.875rem' }}>
                        <div style={{ display: 'inline-block' }}>
                            <Rating name="mediaAval" readOnly value={EgressoSelecionado.mediaAvaliacoes} precision={0.5} />
                        </div>                        
                        <div style={{ display: 'inline-block', position: 'absolute', paddingLeft: '10px', paddingTop: '4px' }}>
                            {EgressoSelecionado.mediaAvaliacoes !== null && labelsRating[EgressoSelecionado.mediaAvaliacoes]}
                        </div>
                    </div>                    
                </div>
                }
            />
        </div>
        );
    }

    function renderDetalhes() {
        if (!EgressoSelecionado) {
            return;
        }
        return (<div>
            <ModalMensagem updateShow={setExibeMsg}
                show={ExibeMsg} titulo="Informações do Trabalho"
                mensagem=
                {<div>
                    <p>{idCt.t("Avaliação da dissertação/produto educacional de", "Rating of the dissertation / educational product of")}:</p>
                    <p><b>{EgressoSelecionado.nome}</b></p>
                    <p>{idCt.t("É muito importante para o programa conhecer onde/como você utilizou este Produto Educacional/Dissertação.", "It is very important for the program to know where / how you used this Educational Product / Dissertation.")}</p>
                    <p><i>{idCt.t("Caso você utilizou este Produto Educacional/Dissertação para o desenvolvimento do seu trabalho (dissertação, TCC), ou aplicou em sala de aula, avalie e comente sua experiência.", "If you used this Educational Product / Dissertation for the development of your work, or applied it in the classroom, rate and comment on your experience.")}</i></p>
                    <hr size="1" />
                    <p><b>{idCt.t("Avalie", "Rate")}:</b></p>
                    <div style={{ fontSize: '0.875rem' }}>
                        <div style={{ display: 'inline-block' }}>
                            <Rating name="AvalStars" value={rate}
                                onChange={(event, newValue) => { setRate(newValue); }}
                                onChangeActive={(event, newHover) => {
                                    setHoverRateInt(newHover);
                                }}
                            />
                        </div>
                        <div style={{ display: 'inline-block', position: 'absolute', paddingLeft: '10px', paddingTop: '4px' }}>
                            {rate !== null && labelsRatingInt[hoverRateInt !== -1 ? hoverRateInt : rate]}
                        </div>
                    </div>
                    <p><b>{idCt.t("Comente", "Comment")}:</b></p>
                    <Form.Control as="textarea" rows={2} cols={50} size={400} style={{ width: "100%" }}
                        value={comentario} onChange={(e) => setComentario(e.target.value)} autoResize={true} />
                    <br />
                    <div style={{ textAlign: "left" }}>
                        <Button label={idCt.t("Salvar Avaliacao e Comentario", "Save Rate and Comment")} onClick={handleSubmitAvaliacao.bind(this)} className="p-button-success" />
                    </div>
                </div>
                }
            />
        </div>
        );
    }
         
    function renderAll() {

        let contents = <p></p>;
        if (loadingContext.loading) {
            return (
                <div>
                    <p><i className="fa fa-refresh fa-spin"></i> <em>Lendo/salvando dados...</em></p>
                </div>
            );
        }
        else if ((Egressos == null) || (Egressos.length == 0)) {
            return (
                <div>
                    <p><em>Nenhum egresso encontrado.</em></p>
                </div>
            );
        }
        else {
            contents = renderEgressos();
        }

        return (
            <div>
                {contents}
                {renderDetalhesEstat()}
                {renderDetalhes()}
            </div>
        );

    }

    return (
        renderAll()
    );

}