import React, { useContext, useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import SelectOfertaDisciplina from '../../components/SelectOfertaDisciplina/SelectOfertaDisciplina';

import LoadingContext from '../../context/loadingContext/loadingContext';
import MessageContext from '../../context/messageContext/messageContext';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';
import InfoContext from '../../context/infoContext/infoContext';
import { formatDate } from '../../helpers/Date';

import { Button } from 'primereact/button';
import Workbook from 'react-excel-workbook';

export function ExportarTurmasExcel(props) {

    //Contexto
    const loadingContext = useContext(LoadingContext);
    const messageContext = useContext(MessageContext);
    const idCt = useContext(IdiomaContext);
    const infoContext = useContext(InfoContext);

    //Construtor
    const [AcessoNegado, setAcessoNegado] = useState(true);
    const [QualOferta, setQualOferta] = useState({ id: 0, anoSemestre: "2019/2", situacaoAluno: 2, situacaoProf: 2 });
    const [Turmas, setTurmas] = useState(null);

    useEffect(() => {
        //componentDidMount
        if ((infoContext.user != null) && (infoContext.userInfo != null) &&
            ((infoContext.user.profile.admin) || (infoContext.user.profile.coordenadorNacional))) {
            setAcessoNegado(false);            
            CarregarDados();
        }
        else {
            messageContext.openMessage("warning", "Somente a coordenação nacional tem acesso a este conteúdo.");
        }
    }, [QualOferta]); 

    function CarregarDados() {
        loadingContext.showLoading();
        let StringFetch = 'api/Disciplinas/ListaTurmas?QualOferta=' + QualOferta.id;
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                setTurmas(data);                
            })
            .catch(error => {
                messageContext.openMessage("error", "Erro. Por favor, tente novamente.");
            });
        loadingContext.hideLoading();
    }

    function renderExportacao() {
        let contents = <p></p>;
        if (loadingContext.loading) {
            contents = <div className="overlay">
                <i className="fa fa-refresh fa-spin"></i> <em>Lendo informações...</em>
            </div>;
        }
        else if ((!QualOferta) || (!QualOferta.inicioInscProf)) {
            contents =
                <div>
                    <p> Caro <b>usuário</b>, o cadastro de oferta de disciplinas eletivas não começou. </p>
                </div>;
        }
        else if (QualOferta.situacaoProf === 1) {
            contents =
                <div>
                    <p> Caro <b>usuário</b>, o cadastro de oferta de disciplinas eletivas <b>está em aberto</b>. </p>
                    <p> O prazo para os discentes informarem o seu interesse nas disciplinas eletivas é de <b>{formatDate(QualOferta.inicioInscAluno)}</b> a <b>{formatDate(QualOferta.fimInscAluno)}</b>. </p>
                    <p> O prazo para os docentes informarem a oferta de disciplinas eletivas é de <b>{formatDate(QualOferta.inicioInscProf)}</b> a <b>{formatDate(QualOferta.fimInscProf)}</b>. </p>
                    <p> A enturmação ocorrerá entre os dias <b>{formatDate(QualOferta.inicioEnturmacao)}</b> e <b>{formatDate(QualOferta.fimEnturmacao)}</b>. </p>
                </div>;
        }
        else if (QualOferta.situacaoProf === 2) {
            contents =
                <div>
                    <p> Caro <b>usuário</b>, o cadastro de oferta de disciplinas eletivas <b>não começou</b>. </p>
                    <p> O prazo para os discentes informarem o seu interesse nas disciplinas eletivas é de <b>{formatDate(QualOferta.inicioInscAluno)}</b> a <b>{formatDate(QualOferta.fimInscAluno)}</b>. </p>
                    <p> O prazo para os docentes informarem a oferta de disciplinas eletivas é de <b>{formatDate(QualOferta.inicioInscProf)}</b> a <b>{formatDate(QualOferta.fimInscProf)}</b>. </p>
                    <p> A enturmação ocorrerá entre os dias <b>{formatDate(QualOferta.inicioEnturmacao)}</b> e <b>{formatDate(QualOferta.fimEnturmacao)}</b>. </p>
                </div>;
        }
        else if ((QualOferta.situacaoProf === 3) && (!QualOferta.dataEnturmacao)) {
            contents =
                <div>
                    <p> Caro <b>usuário</b>, a oferta de disciplinas eletivas <b>já encerrou</b>. </p>
                    <p> <b>Aguarde a enturmação.</b> </p>
                </div>;
        }
        else {
            contents =
                <div>
                    <p> Exportar turmas em arquivo Excel: </p>
                    <p> {ExportarExcel()}</p>                    
                </div>
        }
        return (contents);
    }

    function ExportarExcel() {
        let DadosProf = [];
        let DadosAlunos = [];
        if (Turmas == null) {
            return;
        }
        for (let i = 0; i < Turmas.length; i++) {
            DadosProf.push({
                ia_prof: Turmas[i].instituicao.sigla,
                ia_disc: Turmas[i].disciplinaEletiva.nome,
                turma: Turmas[i].descricaoDisciplina,
                prof_nome: Turmas[i].professor.nome,
                prof_email: Turmas[i].professor.email
            });
            for (let j = 0; j < Turmas[i].listaTurmaAlunos.length; j++) {
                DadosAlunos.push({
                    ia_prof: Turmas[i].instituicao.sigla,
                    ia_disc: Turmas[i].disciplinaEletiva.nome,
                    turma: Turmas[i].descricaoDisciplina,
                    aluno_ia: Turmas[i].listaTurmaAlunos[j].instituicao.sigla,
                    aluno_nome: Turmas[i].listaTurmaAlunos[j].nome,
                    aluno_email: Turmas[i].listaTurmaAlunos[j].email
                });
            }
        }
        return (
            <div>
                <div>
                    <Workbook id="PlanilhaProf" filename="turmasProfessores.xlsx" element={<Button id="BtPlanilhaProf" label="Professores" className="p-button-raised p-button-info" />}>
                        <Workbook.Sheet data={DadosProf} name="Professores">
                            <Workbook.Column label="Instituicao Professor" value="ia_prof" />
                            <Workbook.Column label="Disciplina" value="ia_disc" />
                            <Workbook.Column label="Turma" value="turma" />
                            <Workbook.Column label="Professor" value="prof_nome" />
                            <Workbook.Column label="Email do Professor" value="prof_email" />
                        </Workbook.Sheet>
                    </Workbook>
                </div>
                <div><br/></div>
                <div>
                    <Workbook id="PlanilhaAlunos" filename="turmasAlunos.xlsx" element={<Button id="BtPlanilhaAlunos" label="Alunos" className="p-button-raised p-button-info" />}>
                        <Workbook.Sheet data={DadosAlunos} name="Alunos">
                            <Workbook.Column label="Instituicao Professor" value="ia_prof" />
                            <Workbook.Column label="Disciplina" value="ia_disc" />
                            <Workbook.Column label="Turma" value="turma" />
                            <Workbook.Column label="Instituicao Aluno" value="aluno_ia" />
                            <Workbook.Column label="Aluno" value="aluno_nome" />
                            <Workbook.Column label="Email do Aluno" value="aluno_email" />
                        </Workbook.Sheet>
                    </Workbook>
                </div>
            </div>
        );
    }

    function renderAll() {
        if (AcessoNegado) {
            return (
                <Container >
                    <Row>
                        <Col sm={12}>
                            <h2>{idCt.t("Exportar Turmas de Disciplinas Eletivas para Excel", "Export Elective Courses to Excel")}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <PainelConteudo legenda={idCt.t("Acesso Negado", "Access Denied")} 
                                texto={
                                    <Row>
                                        <Col sm={12}>
                                            {idCt.t("Somente a coordenação nacional tem acesso a este conteúdo.", "Only national coordination has access to this content.")}                                            
                                        </Col>
                                    </Row>
                                } />
                        </Col>
                    </Row>
                </Container >
            );
        }
        return (
            <Container >
                <Row>
                    <Col sm={12}>
                        <h2>{idCt.t("Exportar Turmas de Disciplinas Eletivas para Excel", "Export Elective Courses to Excel")}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <PainelConteudo legenda="Selecione a Oferta"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        Escolha a Oferta:
                                        <SelectOfertaDisciplina AtualizaQualOferta={setQualOferta} />
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <PainelConteudo legenda={idCt.t("Exportar", "Export")}
                            texto={renderExportacao()} />
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        renderAll()
    );
}
