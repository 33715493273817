import React, { useState, useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import EgressoDefesa from '../../components/Egresso/EgressoDefesa';
import SelectAluno from '../../components/SelectAluno/SelecAluno';
import SelecInstituicaoAluno from '../../components/SelectInstituicaoAluno/SelecInstituicaoAluno';

import IdiomaContext from '../../context/idiomaContext/idiomaContext';
import InfoContext from '../../context/infoContext/infoContext';

export function PerfilEgresso(props) {

    const idCt = useContext(IdiomaContext);
    const infoContext = useContext(InfoContext);

    //Construtor
    let QualAlunoInicial = { id: 0, nome: "Nenhum" };
    let QualInstituicaoInicial = null;
    if ((infoContext.user != null) && (infoContext.user.profile.aluno)) {
        QualAlunoInicial = { id: infoContext.userInfo.idaluno, nome: infoContext.user.name };
        QualInstituicaoInicial = { id: 0, nome: "Todas as Instituições Associadas", sigla: "Todas as Instituições" };
    }
    else if (infoContext.userInfo != null) {
        QualInstituicaoInicial = infoContext.userInfo.instituicao;
    }
    const [QualAluno, setQualAluno] = useState(QualAlunoInicial);
    const [QualInstituicao, setQualInstituicao] = useState(QualInstituicaoInicial);

    useEffect(() => {
    }, [QualAluno]); 

    function renderEscolhaAluno() {
        let conteudo = null;
        if (infoContext.user != null) {
            if ((infoContext.user.profile.coordenadorNacional) || (infoContext.user.profile.admin)) {
                conteudo = <SelecInstituicaoAluno AtualizaQualAluno={setQualAluno} />;
            }
            else if ((infoContext.user.profile.secretario) || (infoContext.user.profile.coordenadorCurso)) {
                conteudo = <SelectAluno QualInstituicao={QualInstituicao.id} AtualizaQualAluno={setQualAluno} />;
            }
        }
        if (conteudo == null)
            return null;
        return (
            <Row>
                <Col sm={12}>
                    <PainelConteudo legenda={idCt.t("Selecione o Egresso", "Select Alumni")}
                        texto={
                            <Row>
                                <Col sm={12}>
                                    <br />
                                    {conteudo}
                                </Col>
                            </Row>
                        } />
                </Col>
            </Row>
            );
    }

    function renderEgresso() {
        let conteudo = <p>{idCt.t("Você deve estar logado para visualizar este conteúdo", "You must be logged in to view this content")}.</p>;
        if (infoContext.user != null) {
            if ((infoContext.user.profile.aluno) || (infoContext.user.profile.secretario) || (infoContext.user.profile.coordenadorCurso) ||
                (infoContext.user.profile.coordenadorNacional) || (infoContext.user.profile.admin)) {
                if (QualAluno.id > 0) {
                    conteudo = <EgressoDefesa QualAluno={QualAluno.id} />;
                }
                else {
                    conteudo = <p>{idCt.t("Você deve selecionar o egresso", "You must select the graduate")}.</p>;
                }
            }
        }
        return (
            <Row>
                <Col sm={12}>
                    <PainelConteudo legenda={idCt.t("Egresso (Defesa)", "Alumni (Defense)")}
                        texto={
                            <Row>
                                <Col sm={12}>
                                    {conteudo}
                                </Col>
                            </Row>
                        } />
                </Col>
            </Row>
            );
    }

    return (
        <Container >
            <Row>
                <Col sm={12}>
                    <h2>{idCt.t("Defesa de Dissertação / Produto Educacional", "Dissertation / Educational Product Defense")}</h2>                    
                </Col>
            </Row>
            {renderEscolhaAluno()}
            {renderEgresso()}
        </Container>
    );

}
