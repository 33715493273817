import React, { useContext, useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import IdiomaContext from '../../context/idiomaContext/idiomaContext';
import SelectAutoAval from '../../components/SelectAutoAval/SelectAutoAval';
import RelacaoAutoAval from '../../components/AutoAvaliacao/RelacaoAutoAval';

// Components
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';

export function AutoAvaliacaoAcompanhamento(props) {
    //Contexto
    const idCt = useContext(IdiomaContext);

    //Constructor
    let OFERTA = {
        id: 0,
        anoSemestre: "2020/1",
        inicioAvaliacaoEgresso: null,
        fimAvaliacaoEgresso: null,
        inicioAvaliacaoDocente: null,
        fimAvaliacaoDocente: null,
        inicioAvaliacaoDiscente: null,
        fimAvaliacaoDiscente: null,
        duracaoAvaliacaoEgresso: 0,
        duracaoAvaliacaoDiscente: 0,
        duracaoAvaliacaoDocente: 0,
        duracaoAvaliacaoCoordLocal: 0
    }

    const [dadosOferta, setDadosOferta] = useState(OFERTA);

    useEffect(() => {

    }, []); 

    function renderAll() {
        let contents =
            <Container >
                <Row>
                    <Col sm={12}>
                        <h2>{idCt.t("Acompanhamento da Autoavaliação do ProfEPT", "Monitoring the ProfEPT Self-Assessment")}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <PainelConteudo legenda={idCt.t("Selecione a Autoavaliação", "Select the Self-Assessment")}
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        {idCt.t("Escolha o Ano:", "Choose year:")}
                                        <SelectAutoAval AtualizaQualAutoAval={setDadosOferta} />
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <PainelConteudo legenda={idCt.t("Acompanhamento", "Monitoring")}
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        <RelacaoAutoAval QualAutoAval={dadosOferta} />
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
            </Container>
        return (contents);
    }

    return (
            <div>
                {renderAll()}
            </div>
        );    
}
