import React, { useState } from 'react';
import LoadingContext from './loadingContext';

export default function LoadingContextProvider(props) {

    const [loading, setLoading] = useState(false);   


    function showLoading() {
        setLoading(true);
    }

    function hideLoading() {
        setLoading(false);
    }


    return (
        <LoadingContext.Provider
            value={{
                loading: loading,
                showLoading: showLoading,
                hideLoading: hideLoading
            }}
        >
            {props.children}
        </LoadingContext.Provider>
    )
    
}

