import authService from '../components/api-authorization/AuthorizeService';

const cronJobService = {
    getUltimaAtualizacaoLattes
}

async function getUltimaAtualizacaoLattes() {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: "GET",
        headers: headers
    };    
    return await fetch('api/Home/UltimaAtualizacaoLattes', options);
}

export default cronJobService;

