import React, { useEffect, useState, useContext } from 'react'
import './Rodape.css'
import { Container } from 'reactstrap';
import usuarioService from '../../services/usuarioService';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';

export default function Rodape() {

    const [nroAcessos, setNroAcessos] = useState(0);
    const idCt = useContext(IdiomaContext);

    useEffect(() => {
        RodapeGetQtdeAcesso();
    }, [])

    async function RodapeGetQtdeAcesso() {
        usuarioService.getQtdeAcesso()
            .then(async res => {
                setNroAcessos(await res.json());
            })
            .catch(err => {
                setNroAcessos(0);
            });
    }

    return (
        <div>
            <div id="footer-brasil">
                <div id="wrapper-footer-brasil">
                    <a href="https://www.gov.br/acessoainformacao/pt-br">
                        <span className="logo-acesso-footer"></span>
                    </a>
                    <a className="logo-governo-federal" href="https://www.gov.br/pt-br" alt="Governo Federal" title="Governo Federal"></a>
                </div>
            </div>
            <div className="footer-ferramenta" style={{ backgroundColor: '#f1f1f1' }} >
                <Container style={{ maxWidth: "980px" }}>
                    <span>{idCt.t("Número de acessos neste site","Number of hits on this site")}: <b>{nroAcessos}</b></span><br />
                    <span>{idCt.t("Desenvolvido pelo Grupo de Pesquisa em Mineração da Dados e Imagens", "Developed by the Data Mining and Image Research Group")} (MiDI) - IFTM - <b>{idCt.t("Versão","Version")} 2.4.2 (24/07/2023)</b></span>
                </Container>
            </div>
        </div>

    )
}