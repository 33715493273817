import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../Pesquisadores/Pesquisadores.css';

export default class PesquisadoresTopMais extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            Professores: [],
            stackedData: {},
            stackedOptions: {}
        };
        this.ColunaFoto = this.ColunaFoto.bind(this);
        this.ColunaFotoNome = this.ColunaFotoNome.bind(this);
        this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
    }

    componentDidMount() {
        this.CarregarDados();
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.QualInstituicao !== this.props.QualInstituicao) ||
            (prevProps.QualArea !== this.props.QualArea) ||
            (prevProps.QualAno !== this.props.QualAno) ){
            this.CarregarDados();
        }
    }

    ColunaFoto(rowData, column) {
        return <div>
                    <img src={rowData.urlFoto} className="prof-imagem-redondo" />
               </div>;
    }

    ColunaFotoNome(rowData, column) {
        return <div>
                    <img src={rowData.urlFoto} className="prof-imagem-redondo" />&nbsp;{rowData.nome}
               </div>;
    }

    CarregarDados() {
        this.setState({ loading: true });
        if ((this.props.QualInstituicao === -1) || (this.props.QualAno == null) || (this.props.QualAno.length === 0))
            return;
        let StringFetch = 'api/Pesquisadores/PesquisadoresMaisProdutivos?QualArea=' + this.props.QualArea +
            '&QualInstituicao=' + this.props.QualInstituicao + '&QuaisAnos=' + this.props.QualAno;
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ Professores: data, loading: false });
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    rowExpansionTemplate(data) {
        return (
            <div className="p-grid p-fluid" style={{ padding: '1em' }}>
                <div className="p-col-12 p-md-12">
                    <div className="p-grid">
                        <div className="p-md-2">IA: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.instituicao}</div>

                        <div className="p-md-2">Produção Científica: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.descricaoProducao}</div>

                        <div className="p-md-2">Data Lattes: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.dataAtualizacaoLattes}</div>
                    </div>
                </div>
            </div>
        );
    }

    renderProfessoresCelular() {
        var header = <div style={{ 'textAlign': 'left' }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Pesquisar" size="20" />
                    </div>;

        //<Column field="pontos" header="Pontos" sortable={true} style={{ textAlign: 'center', width: '6em', padding: '0 0 0 0' }} />

        return (
            <div>
                <span style={{ fontSize: '14px', fontWeight: 'bold' }}>Docentes com produção científica: {this.state.Professores.length}</span><br />
                <div className="content-section implementation">
                    <DataTable value={this.state.Professores} paginator={true} rows={15} rowsPerPageOptions={[5, 15, 30]}
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        globalFilter={this.state.globalFilter} header={header}
                        expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                        rowExpansionTemplate={this.rowExpansionTemplate}
                    >
                        <Column expander={true} style={{ width: '3em' }} />
                        <Column field="nome" header="Nome" body={this.ColunaFotoNome} sortable={true} />                        
                    </DataTable>
                </div>
            </div>
        );
    }

    renderProfessoresTamanhoNormal() {
        var header = <div style={{ 'textAlign': 'left' }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Pesquisar" size="50" />
                     </div>;

        //<Column field="pontos" header="Pontos" sortable={true} style={{ textAlign: 'center', width: '7em' }} />

        if (this.props.QualInstituicao === 0) {
            return (
                <div>
                    <span style={{ fontSize: '14px', fontWeight: 'bold' }}>Docentes com produção científica: {this.state.Professores.length}</span><br /><br />
                    <div className="content-section implementation">
                        <DataTable value={this.state.Professores} paginator={true} rows={15} rowsPerPageOptions={[5, 15, 30]}
                            resizableColumns={true} columnResizeMode="fit"
                            globalFilter={this.state.globalFilter} header={header} >
                            <Column header="Foto" body={this.ColunaFoto} style={{ textAlign: 'center', width: '4em' }} />
                            <Column field="nome" header="Nome" sortable={true} />
                            <Column field="instituicao" header="IA" sortable={true} style={{ textAlign: 'center', width: '9em' }} />                            
                            <Column field="descricaoProducao" header="Produção Científica" sortable={false} />
                            <Column field="dataAtualizacaoLattes" header="Data Lattes" sortable={true} style={{ textAlign: 'center', width: '10em' }} />
                        </DataTable>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <span style={{ fontSize: '14px', fontWeight: 'bold' }}>Docentes com produção científica: {this.state.Professores.length}</span><br /><br />
                <div className="content-section implementation">
                    <DataTable value={this.state.Professores} paginator={true} rows={15} rowsPerPageOptions={[5, 15, 30]}
                        resizableColumns={true} columnResizeMode="fit"
                        globalFilter={this.state.globalFilter} header={header} >
                        <Column header="Foto" body={this.ColunaFoto} style={{ textAlign: 'center', width: '4em' }} />
                        <Column field="nome" header="Nome" sortable={true} />
                        <Column field="descricaoProducao" header="Produção Científica" sortable={false} />
                        <Column field="dataAtualizacaoLattes" header="Data Lattes" sortable={true} style={{ textAlign: 'center', width: '10em' }} />
                    </DataTable>
                </div>
            </div>
        );
    }
       
    renderProfessores() {
        //let paginatorLeft = <Button icon="pi pi-refresh" />;
        //let paginatorRight = <Button icon="pi pi-cloud-upload" />;
        /*paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}*/
        /* <Column field="nome" header="Nome" sortable={true} filter={true} / > */
        var windowWidth = window.innerWidth;
        if (windowWidth < 770) {
            return this.renderProfessoresCelular();
        }
        else {
            return this.renderProfessoresTamanhoNormal();
        }
    }

    render() {
        const { loading, error } = this.state;

        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents = <div className="overlay">
                           <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                       </div>;
        }
        else {
            contents = this.renderProfessores();
        }

        return (
            <div>
                {contents}
            </div>
        );

    }

}