import React, { useState, useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import ModalMensagem from '../ModalMensagem/ModalMensagem';
import IconButton from '@material-ui/core/IconButton';
import MapaCidades from '../MapaCidades/MapaCidades';

import LoadingContext from '../../context/loadingContext/loadingContext';
import MessageContext from '../../context/messageContext/messageContext';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';

export default function DiscentesCidades(props) {

    //Contexto
    const loadingContext = useContext(LoadingContext);
    const messageContext = useContext(MessageContext);
    const idCt = useContext(IdiomaContext);

    //Construtor
    const [infoCidades, setInfoCidades] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [exibeMsgAlunos, setExibeMsgAlunos] = useState(false);
    const [cidadeSelecionada, setCidadeSelecionada] = useState(null);
    const [error, setError] = useState(null);    

    useEffect(() => {
        //componentDidMount e componentDidUpdate
        loadingContext.showLoading();        
        CarregarDados();        
        loadingContext.hideLoading();
    }, [props.QualInstituicao, props.QualAno]); 

    function CarregarDados() {
        setGlobalFilter(null);    
        let StringFetch = 'api/IndicadoresPrograma/DiscentesCidades?QualInstituicao=' + props.QualInstituicao.id + '&QualAno=' + props.QualAno;
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                setInfoCidades(data);                
            })
            .catch(error => {
                messageContext.openMessage("error", "Erro.Por favor, tente novamente.");
                setError(error);
            });
    }

    function MarcouCidade(cidade, estado) {
        let dados = infoCidades;
        if ((dados === null) || (dados.cidades === null))
            return;
        for (let i = 0; i < dados.cidades.length; i++) {
            if ((dados.cidades[i].nome == cidade) && (dados.cidades[i].estado == estado)) {
                dados.cidades[i].selecionada = !dados.cidades[i].selecionada;
                //setInfoCidades(dados);
                infoCidades.cidades[i].selecionada = !infoCidades.cidades[i].selecionada;
                setInfoCidades(infoCidades);
                return;
            }
        }
    }

    function ColunaEditar(rowData, column) {
        //<Checkbox onChange={e => {rowData.selecionada = !rowData.selecionada; setInfoCidades(infoCidades);} } checked={rowData.selecionada}></Checkbox>
        
        let contentsEditar =
            <div style={{ display: 'inline-block' }}>
                <Checkbox onChange={e => { MarcouCidade(rowData.nome, rowData.estado);} } checked={rowData.selecionada}></Checkbox>
            </div>;        
        return (
            <div>
                {contentsEditar}
            </div>
        );
    }

    function MarcouAluno(qCid, qUF, qAlunos) {
        setCidadeSelecionada({
            cidade: qCid + "-" + qUF,
            alunos: qAlunos
        });
        setExibeMsgAlunos(true);
    }

    function ColunaQtd(rowData, column) {
        return (
            <div style={{ display: 'inline-block', margin: '0px', padding: '0px' }}>
                <IconButton color="primary" aria-label="Download" style={{ verticalAlign: "top", padding: '6px', margin: '0px' }}
                    onClick={() => MarcouAluno(rowData.nome, rowData.estado, rowData.alunos)}>
                    <span style={{fontSize: '14px'}}>{rowData.quantidade}</span>
                </IconButton>
            </div>
        );
    }
        
    function renderCidades() {        
        var header = <div style={{ 'textAlign': 'left' }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar" size="20" />
                     </div>;
        //<Column field="editar" header="" body={ColunaEditar} sortable={false} style={{ margin: '0px', padding: '0px', textAlign: 'center', width: '3em' }} />
        return (
            <div>
                <div className="content-section implementation">
                    <DataTable value={infoCidades.cidades} paginator={true} rows={15} rowsPerPageOptions={[5, 15, 30]}
                        resizableColumns={true} columnResizeMode="fit"
                        globalFilter={globalFilter} header={header}
                    >                        
                        <Column field="nome" header={idCt.t("Cidade", "City")} sortable={true} /> 
                        <Column field="estado" header={idCt.t("UF", "St")} sortable={true} style={{ textAlign: 'center', width: '5em' }} />
                        <Column field="quantidade" header={idCt.t("Qt", "Qt")} body={ColunaQtd}  sortable={true} style={{ textAlign: 'center', width: '5em' }} /> 
                    </DataTable>
                </div>
            </div>
        );
    }

    function renderAlunos() {
        if ((cidadeSelecionada === null) || (setExibeMsgAlunos === false)) {
            return <div></div>;
        }
        return (
            <div>
                <ModalMensagem updateShow={setExibeMsgAlunos}
                    show={exibeMsgAlunos} titulo={cidadeSelecionada.cidade}
                    mensagem=
                    {<div>
                        <p>{cidadeSelecionada.alunos.length} {idCt.t("discentes/egressos", "students/alumni")}:</p>
                        {cidadeSelecionada.alunos.map((item, i) => {
                            return (<p>{item}</p>)
                        })}
                    </div>
                    }
                />
            </div>
        );
    }
    
    function renderAll() {

        let contents = <p></p>;
        if (loadingContext.loading) {
            return (
                <div>
                    <p><i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em></p>
                </div>
            );
        }
        else if (infoCidades == null) {
            return (
                <div>
                    <p><em>{idCt.t("Nenhuma cidade encontrada.", "No cities found.")}</em></p>
                </div>
            );
        }
        else {
            contents = renderCidades();
        }

        return (
            <Container >
                <Row>
                    <Col sm={12}>
                        <h4>{props.QualInstituicao.nome}</h4>
                        <p>{idCt.t("Total de Discentes/Egressos", "Total Students/Alumni")}: {infoCidades.qtdeAlunos}</p>
                        <p>{idCt.t("Total de Discentes/Egressos que informaram o local de residência", "Total Students/Alumni who informed the address")}: {infoCidades.qtdeAlunosComCidade}</p>
                        <p>{idCt.t("Total de Cidades/Municípios dos Discentes/Egressos", "Total Students/Alumni Cities")}: {infoCidades.cidades.length}</p>
                        {renderAlunos()}
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        {contents}
                    </Col>
                    <Col sm={8} style={{ minHeight: '500px', minWidth: '300px' }}>
                        <MapaCidades data={infoCidades.cidades} onClick={MarcouAluno} />
                    </Col>
                </Row>
            </Container>
        );

    }

    return (
        renderAll()
    );

}