import React, { useState, useContext, useEffect } from 'react';
//Contexto
import IdiomaContext from '../../context/idiomaContext/idiomaContext';
// Panel
import { Panel } from 'primereact/panel';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ModalMensagem from '../ModalMensagem/ModalMensagem';
import CronJobLattes from '../AtualizacaoLattes/CronJobLattes';
// CSS
import "./PainelConteudo.css";

export default function PainelConteudo(props) {

    const idCt = useContext(IdiomaContext);

    const [ExibeInformacao, setExibeInformacao] = useState(false);
    const [MsgInformacao, setMsgInformacao] = useState(null);
    const [Botao, setBotao] = useState(null);

    //Datas
    //const DataLattes = "dados extraidos em 12/03/2020";
    //const QualisCapes = "O Qualis Periodicos 2017-2020 sofrera uma grande mudanca em relacao ao Quadrienio 2013-2016. Os dados usados no Observatorio NAO SAO OFICIAIS. o Qualis Periodicos utilizado e uma estratificacao previa discutida no Seminario de Meio Termo de 2019 e sofrera alteracoes.";    

    useEffect(() => {        
        Configurar();
        ConfigurarMsg();
    }, [props.legenda, props.texto]);

    function Configurar() {
        let contentBotao = <span></span>;
        if (props.mostraAjuda) {
            contentBotao =
                <div style={{ display: 'inline-block' }}>
                    <IconButton size="small" color="primary" aria-label="Ajuda" style={{ verticalAlign: "top", paddingLeft: "5px", paddingBottom: "0px", paddingTop: "0px" }}
                                onClick={() => { setExibeInformacao(true); }}>
                        <Icon fontSize="small">help</Icon>
                    </IconButton>
                </div>
        }
        setBotao(contentBotao);
    }    

    function renderAll() {
        let legenda = <span>{props.legenda}{Botao}</span>;
        let Sobre = <span>{idCt.t("Sobre ", "About ")} {props.legenda}</span>;

        return (
            <div>
                <div className="divConteudo">
                    <Panel header={legenda} toggleable={true} >
                        {props.texto}
                    </Panel>
                </div>
                <div>
                    <ModalMensagem updateShow={setExibeInformacao}
                        show={ExibeInformacao} titulo={Sobre}
                        mensagem=
                        {<div>
                            {MsgInformacao}
                        </div>
                        }
                    />
                </div>
            </div>
        );
    }

    return (
        renderAll()
    );

    function ConfigurarMsg() {
        let Msg = <span></span>;
        if (props.qualIndicador === "BOAS VINDAS") {
            Msg = BoasVindas();
        }
        else if (props.qualIndicador === "PROD BIB DOCENTE") {
            Msg = IndProducaoBibliograficaDocente();
        }
        else if (props.qualIndicador === "PROD PERIODICOS DOCENTE") {
            Msg = IndProducaoPeriodicosDocente();
        }
        else if (props.qualIndicador === "ORIENTACOES DOCENTE") {
            Msg = IndOrientacoesDocente();
        }
        else if (props.qualIndicador === "PERFIL DOCENTE") {
            Msg = IndPerfilDocente();
        }
        else if (props.qualIndicador === "LOCALIDADE") {
            Msg = IndLocalidade();
        }
        setMsgInformacao(Msg);
    }

    function BoasVindas() {
        return (
            <div>
                {idCt.t("Os indicadores sao ferramentas de gestao que quantificam o desempenho das instituicoes associadas ao ProfEPT, sendo essenciais para o seu aprimoramento. Os indicadores podem ser mensurados em varios niveis de dimensao.",
                    "Indicators are management tools that quantify the performance of ProfEPT member institutions and are essential for their improvement. Indicators can be measured at various levels of dimension.")}
                <br />
                {idCt.t("Os indicadores visam o monitoramento do desempenho das instituicoes associadas, docentes e discentes.",
                    "The indicators aim to monitor the performance of associated institutions, professors and master's students.")}
                <br />
                {idCt.t("Os dados utilizados para a elaboracao dos indicadores sao extraidos dos seguintes ambientes: Plataforma Sucupira, Plataforma Lattes, Periodicos Qualis CAPES e do proprio Observatorio ProfEPT.",
                    "The data used for the preparation of the indicators are extracted from the following environments: Sucupira Platform, Lattes Platform, Qualis CAPES Periodicals and the ProfEPT Observatory itself.")}
                <br />
                {idCt.t("Para saber mais sobre cada indicador, basta clicar no icone ",
                        "To learn more about each indicator, just click on the icon ")}
                <div style={{ display: 'inline-block' }}>
                    <IconButton size="small" color="primary" aria-label="Ajuda"
                        style={{ verticalAlign: "top", paddingLeft: "5px", paddingBottom: "0px", paddingTop: "0px" }}>
                        <Icon fontSize="small">help</Icon>
                    </IconButton>
                </div>
                {idCt.t("(ao lado do titulo do indicador)", "(next to the indicator title)")}
            </div>
        );
    }

    function IndProducaoBibliograficaDocente() {
        return (
            <div>
                <b>{idCt.t("Objetivo: ", "Object: ")}</b>
                {idCt.t("Este indicador visa diagnosticar a producao cientifica do docente. Os tipos de producao a serem consideradas sao: artigo completo em evento; Resumo em evento; artigo em periodico; capitulo de livro e livro.",
                        "This indicator aims to diagnose the scientific production of the professor. The types of production to be considered are: complete article at an event; Event summary; journal article; book and book chapter.")}
                <br />
                <b>{idCt.t("Fonte dos dados: ", "Data source: ")}</b>
                <br />
                <i>{idCt.t("Docentes: ","Professors: ")}</i>
                {idCt.t("Plataforma Sucupira e atualizado constantemente pela coordenacoes locais e nacional.",
                        "Sucupira platform and constantly updated by local and national coordinations.")}
                <br />
                <i>{idCt.t("Producao: ", "Production: ")}</i>
                {idCt.t("Plataforma Lattes ","Lattes Plataform ")}
                <CronJobLattes />
            </div>
        );
    }

    function IndProducaoPeriodicosDocente() {
        return (
            <div>
                <b>{idCt.t("Objetivo: ", "Object: ")}</b>
                {idCt.t("Este indicador visa diagnosticar a producao cientifica do docente apenas em periodicos. As quantidades sao separadas pelos estratos dos periodicos.",
                        "This indicator aims to diagnose the professor's scientific production only in journals. The quantities are separated by the categories of the journals.")}
                <br />
                <b>{idCt.t("Fonte dos dados: ", "Data source: ")}</b>
                <br />
                <i>{idCt.t("Docentes: ", "Professors: ")}</i>
                {idCt.t("Plataforma Sucupira e atualizado constantemente pela coordenacoes locais e nacional.",
                    "Sucupira platform and constantly updated by local and national coordinations.")}
                <br />
                <i>{idCt.t("Producao: ", "Production: ")}</i>
                {idCt.t("Plataforma Lattes ", "Lattes Plataform ")}
                {idCt.t(" e Periodicos Qualis CAPES: ", " and Qualis CAPES Periodicals: ")}
                <br />
                <CronJobLattes />
            </div>
        );
    }

    function IndOrientacoesDocente() {
        return (
            <div>
                <b>{idCt.t("Objetivo: ", "Object: ")}</b>
                {idCt.t("Este indicador visa diagnosticar o quantitativo de orientacoes dos professores  (nao so do programa ProfEPT).",
                        "This indicator aims to diagnose the amount of orientation from professors (not only from the ProfEPT program).")}
                <br />
                <b>{idCt.t("Fonte dos dados: ", "Data source: ")}</b>
                <br />
                <i>{idCt.t("Docentes: ", "Professors: ")}</i>
                {idCt.t("Plataforma Sucupira e atualizado constantemente pela coordenacoes locais e nacional.",
                    "Sucupira platform and constantly updated by local and national coordinations.")}
                <br />
                <i>{idCt.t("Orientacoes: ", "Orientations: ")}</i>
                {idCt.t("Plataforma Lattes ", "Lattes Plataform ")}
                <CronJobLattes />
            </div>
        );
    }

    function IndPerfilDocente() {
        return (
            <div>
                <b>{idCt.t("Objetivo: ", "Object: ")}</b>
                {idCt.t("Este indicador visa diagnosticar o perfil docente atraves dos assuntos mais pesquisados e divulgados em producoes cientificas.",
                        "This indicator aims to diagnose the teaching profile through the most researched and disseminated subjects in scientific productions.")}
                <br />
                <b>{idCt.t("Fonte dos dados: ", "Data source: ")}</b>
                <br />
                <i>{idCt.t("Docentes: ", "Professors: ")}</i>
                {idCt.t("Plataforma Sucupira e atualizado constantemente pela coordenacoes locais e nacional.",
                        "Sucupira platform and constantly updated by local and national coordinations.")}
                <br />
                <i>{idCt.t("Producao: ", "Production: ")}</i>
                {idCt.t("Plataforma Lattes ", "Lattes Plataform ")}
                <CronJobLattes />
            </div>
        );
    }

    function IndLocalidade() {
        return (
            <div>
                <b>{idCt.t("Objetivo: ", "Object: ")}</b>
                {idCt.t("Este indicador visa diagnosticar as localidades das residencias dos discentes/egressos do ProfEPT em um mapa do Brasil. O intuito e identificar as regioes atendidas pelo ProfEPT e as regioes que ainda nao estao sendo atendidas.",
                    "This indicator aims to diagnose the locations of the residences of ProfEPT students / alumni on a map of Brazil. The aim is to identify the regions served by ProfEPT and the regions that are not yet being served.")}
                <br />
                <b>{idCt.t("Fonte dos dados: ", "Data source: ")}</b>
                <br />
                {idCt.t("Os dados sao atualizados constantemente pelos proprios estudantes.", "The data is constantly updated by the students/alumni themselves.")}
            </div>
        );
    }

}