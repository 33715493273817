import React, { Component } from 'react';
import { Form } from 'react-bootstrap'; ///lib/FormControl';

export default class SelectArea extends Component {

    constructor(props) {
        super(props);
        this.state = {
            area: ''
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        let valueTarget = event.target.value;
        this.setState({ area: valueTarget });
        this.props.reflectsLabelArea(valueTarget);
    }

    render() {
        return (
            <div>
                <Form.Control as="select" value={this.state.area} onChange={this.handleChange}>
                    {
                        this.props.optionsList.map(op => <option value={op.value} key={op.value}> {op.label} </option>)
                    }
                </Form.Control>
            </div>
        )
    }   
}