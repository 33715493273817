import React, { useContext, useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import RelacaoAlunosProcura from '../../components/DisciplinasEletivas/RelacaoAlunosProcura';
import SelectOfertaDisciplina from '../../components/SelectOfertaDisciplina/SelectOfertaDisciplina';
import SelectAnosIngresso from '../../components/SelectAnosIngresso/SelectAnosIngresso';

import IdiomaContext from '../../context/idiomaContext/idiomaContext';
import InfoContext from '../../context/infoContext/infoContext';
import MessageContext from '../../context/messageContext/messageContext';

export function ConsultaRelacaoAlunos(props) {

    //Contexto
    const idCt = useContext(IdiomaContext);
    const infoContext = useContext(InfoContext);
    const messageContext = useContext(MessageContext);

    //Constructor
    const [AcessoNegado, setAcessoNegado] = useState(true);
    const [QualOferta, setQualOferta] = useState({ id: 0, anoSemestre: "2019/2" });
    const [QualAno, setQualAno] = useState(0);

    useEffect(() => {
        //componentDidMount
        if ((infoContext.user != null) && (infoContext.userInfo != null) &&
            ((infoContext.user.profile.professor) || (infoContext.user.profile.secretario))) {
            setAcessoNegado(false);
        }
        else {
            messageContext.openMessage("warning", "Somente docentes e secretários tem acesso a este conteúdo.");
        }
    }, []); 

    function renderAll() {
        if (AcessoNegado) {
            return (
                <Container >
                    <Row>
                        <Col sm={12}>
                            <h2>{idCt.t("Relação dos alunos por interesse nas disciplinas eletivas", "List of students by interest in electives courses")}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <PainelConteudo legenda="Acesso Negado"
                                texto={
                                    <Row>
                                        <Col sm={12}>
                                            Somente docentes e secretários tem acesso a este conteúdo.
                                        </Col>
                                    </Row>
                                } />
                        </Col>
                    </Row>
                </Container >
            );
        }
        return (
            <Container >  
                <Row>
                    <Col sm={12}>
                        <h2>{idCt.t("Relação dos alunos por interesse nas disciplinas eletivas", "List of students by interest in electives courses")}</h2>
                        <h4>{infoContext.userInfo.instituicao.nome}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <PainelConteudo legenda="Selecione a Oferta"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        Escolha a Oferta:
                                        <SelectOfertaDisciplina AtualizaQualOferta={setQualOferta} />
                                    </Col>
                                </Row>
                            } />
                    </Col>
                    <Col sm={6}>
                        <PainelConteudo legenda="Selecione o Ano de Ingresso"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        Escolha o Ano de Ingresso:
                                        <SelectAnosIngresso AtualizaQualAno={setQualAno} />
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>                
                <Row>
                    <Col sm={12}>
                        <PainelConteudo legenda={idCt.t("Alunos inscritos nas disciplinas", "Enrolled students")}
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        <RelacaoAlunosProcura QualOferta={QualOferta.id} QualInstituicao={infoContext.userInfo.instituicao.id} QualAnoIngresso={QualAno} />
                                    </Col>
                                </Row>
                            } />                    
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        renderAll()
        );
}
