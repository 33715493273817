import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import SelecInstituicoes from '../../components/SelecInstituicoes/SelecInstituicoes';
import PeriodicoPorArea from '../../components/PeriodicoPorArea/PeriodicoPorArea';
import ProducaoBibliografica from '../../components/ProducaoBibliografica/ProducaoBibliografica';
import Orientacao from '../../components/Orientacao/Orientacao';
import CronJobLattes from '../../components/AtualizacaoLattes/CronJobLattes';

export class IndicadoresPorInstituicao extends Component {
  displayName = IndicadoresPorInstituicao.name

  constructor(props) {
      super(props);
      let IFTM = {
          id: -1,
          nome: "IFTM",
          sigla: "IFTM",
          quantidadeProfessores: 0,
          quantidadeProfessoresLattes: 0
      }
      this.state = { QualInstituicao: IFTM };
      this.updateInst = this.updateInst.bind(this);
    }

  //Método para atualizar a instituição:
  updateInst = (QualInstituicao) => { this.setState({ QualInstituicao }); }

  render() {
    return (
        <Container >
            <Row>
                <Col sm={12}>
                    <h2>Indicadores por Instituição</h2>
                    <CronJobLattes />
                 </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <PainelConteudo legenda="Pesquisar"
                        texto={
                            <Row>
                                <Col sm={12}>
                                    Escolha a Instituição:
                                    <SelecInstituicoes AtualizaQualInstituicao={this.updateInst} />
                                </Col>
                            </Row>                            
                        } />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <br />
                    <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Indicadores - {this.state.QualInstituicao.nome}</span><br />
                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Número de docentes: {this.state.QualInstituicao.quantidadeProfessores}</span><br />
                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Número de docentes com Lattes: {this.state.QualInstituicao.quantidadeProfessoresLattes}</span><br />
                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Observação: </span>
                    <span style={{ fontSize: '13px' }}>Os dados da estatística são relacionados ao docentes permanentes da Instituição. Com isso, alguns dados podem estar vinculados ao docente e não à Instituição.</span><br />
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <PainelConteudo mostraAjuda={true} qualIndicador="PROD BIB DOCENTE" legenda="Produção Bibliográfica"
                        texto={
                            <Row>
                                <Col sm={12}>
                                    <ProducaoBibliografica
                                        QualConsulta="PorInstituicao"
                                        TipoGrafico="Quantidade"
                                        QualInstituicao={this.state.QualInstituicao.id} />
                                </Col>
                            </Row>
                        } />                    
                </Col>
                <Col sm={6}>
                    <PainelConteudo mostraAjuda={true} qualIndicador="PROD BIB DOCENTE" legenda="Produção Bibliográfica"
                        texto={
                            <Row>
                                <Col sm={12}>
                                    <ProducaoBibliografica
                                        QualConsulta="PorInstituicao"
                                        TipoGrafico="PorProfessor"
                                        QualInstituicao={this.state.QualInstituicao.id} />
                                </Col>
                            </Row>
                        } />
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <PainelConteudo mostraAjuda={true} qualIndicador="PROD PERIODICOS DOCENTE" legenda="Produção em Periódicos"
                        texto={
                            <Row>
                                <Col sm={12}>
                                    <PeriodicoPorArea
                                        QualConsulta="PorInstituicao"
                                        TipoGrafico="Quantidade"
                                        QualInstituicao={this.state.QualInstituicao.id} />
                                </Col>
                            </Row>
                        } />
                </Col>
                <Col sm={6}>
                    <PainelConteudo mostraAjuda={true} qualIndicador="PROD PERIODICOS DOCENTE" legenda="Produção em Periódicos"
                        texto={
                            <Row>
                                <Col sm={12}>
                                    <PeriodicoPorArea
                                        QualConsulta="PorInstituicao"
                                        TipoGrafico="PorProfessor"
                                        QualInstituicao={this.state.QualInstituicao.id} />
                                </Col>
                            </Row>
                        } />
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <PainelConteudo mostraAjuda={true} qualIndicador="ORIENTACOES DOCENTE" legenda="Orientações Concluídas"
                        texto={
                            <Row>
                                <Col sm={12}>
                                    <Orientacao
                                        QualConsulta="PorInstituicao"
                                        TipoGrafico="Quantidade"
                                        QualInstituicao={this.state.QualInstituicao.id} />
                                </Col>
                            </Row>
                        } />
                </Col>
                <Col sm={6}>
                    <PainelConteudo mostraAjuda={true} qualIndicador="ORIENTACOES DOCENTE" legenda="Orientações Concluídas"
                        texto={
                            <Row>
                                <Col sm={12}>
                                    <Orientacao
                                        QualConsulta="PorInstituicao"
                                        TipoGrafico="PorProfessor"
                                        QualInstituicao={this.state.QualInstituicao.id} />
                                </Col>
                            </Row>
                        } />
                </Col>
            </Row>
        </Container>
    );
  }
}
