import React, { useContext, useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Radio from '@material-ui/core/Radio';

import IdiomaContext from '../../context/idiomaContext/idiomaContext';
import InfoContext from '../../context/infoContext/infoContext';
import SelectAutoAval from '../../components/SelectAutoAval/SelectAutoAval';
import SelecInstituicoes from '../../components/SelecInstituicoes/SelecInstituicoes';
import AvalRelatorio from '../../components/AutoAvaliacao/AvalRelatorio';
import { formatDate } from '../../helpers/Date';

// Components
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';

export function AutoAvaliacaoPrograma(props) {
    //Contexto
    const idCt = useContext(IdiomaContext);
    const infoContext = useContext(InfoContext);

    //Constructor
    let OFERTA = {
        id: 0,
        anoSemestre: "2020/1",
        situacaoAvaliacao: 1,
        divulgacaoResultados: null
    }
    let QualInstituicaoInicial = {
        id: 0,
        nome: "Todas",
        sigla: "Todas"
    }
    if ((infoContext.userInfo != null) && (infoContext.userInfo.instituicao != null)) {
        QualInstituicaoInicial = infoContext.userInfo.instituicao;
    }

    //const [dadosOferta, setDadosOferta] = useState(OFERTA);
    //const [modeloExibicao, setModeloExibicao] = useState(2); //1: CAPES; 2: NAPE
    //const [instituicao, setInstituicao] = useState(IFTM);
    const [varState, setVarState] = useState({
        dadosOferta: OFERTA,
        instituicao: QualInstituicaoInicial,
        modeloExibicao: 1
    })

    useEffect(() => {

    }, []); 

    function setDadosOferta(newDadosOferta) {
        setVarState({
            dadosOferta: newDadosOferta,
            instituicao: varState.instituicao,
            modeloExibicao: varState.modeloExibicao
        });
    }

    function setInstituicao(newInstituicao) {
        setVarState({
            dadosOferta: varState.dadosOferta,
            instituicao: newInstituicao,
            modeloExibicao: varState.modeloExibicao
        });
    }

    function setModeloExibicao(newModeloExibicao) {
        setVarState({
            dadosOferta: varState.dadosOferta,
            instituicao: varState.instituicao,
            modeloExibicao: newModeloExibicao
        });
    }

    function renderConteudo() {
        let conteudo = <p>Relatorio não está disponível</p>;
        if (varState.dadosOferta.situacaoAvaliacao != 3) {
            if ((infoContext.user == null) || (!infoContext.NAPE())) { //(!infoContext.user.profile.admin)) {
                if (varState.dadosOferta.divulgacaoResultados) {
                    conteudo = <p>O resultado da Autoavaliação do ProfEPT ({varState.dadosOferta.anoSemestre}) estará disponível em {formatDate(varState.dadosOferta.divulgacaoResultados)}</p>
                }
                return conteudo;
            }            
        }
        conteudo=<p>exibe relatorio</p>
        conteudo = <AvalRelatorio QualAutoAval={varState.dadosOferta} QualModelo={varState.modeloExibicao} QualInstituicao={varState.instituicao} />
        return conteudo;
    }

    function renderAll() {
        let contents =
            <Container >
                <Row>
                    <Col sm={12}>
                        <h2>{idCt.t("Relatório da Autoavaliação do ProfEPT", "ProfEPT Self-Assessment Report")}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <PainelConteudo legenda={idCt.t("Pesquisar", "Search")}
                            texto={
                                <Row>
                                    <Col sm={3}>
                                        {idCt.t("Escolha a Autoavaliação:", "Choose year:")}
                                        <SelectAutoAval AtualizaQualAutoAval={setDadosOferta} />
                                    </Col>
                                    <Col sm={4}>
                                        {idCt.t("Forma de exibição:", "Choose display:")}<br />
                                        <div style={{ display: 'inline-block' }}>
                                            <Radio
                                                checked={varState.modeloExibicao === 1}
                                                value={1}
                                                onClick={(event, newValue) => { setModeloExibicao(1); }}
                                                name={"radio_2"}
                                                size="small"
                                                color="primary"
                                            />
                                            <span>CAPES</span>
                                            <Radio
                                                checked={varState.modeloExibicao === 2}
                                                value={2}
                                                onClick={(event, newValue) => { setModeloExibicao(2); }}
                                                name={"radio_1"}
                                                size="small"
                                                color="primary"
                                            />
                                            <span>NAPE</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </Col>
                                    <Col sm={5}>
                                        Escolha a Instituição:
                                        <SelecInstituicoes InstituicaoSelecionada={QualInstituicaoInicial.id} AtualizaQualInstituicao={setInstituicao} />
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <PainelConteudo legenda={idCt.t("Relatório", "Report")}
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        {renderConteudo()}
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
            </Container>
        return (contents);
    }

    return (
            <div>
                {renderAll()}
            </div>
        );    
}
