import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import SelecMultAnos from '../../components/SelecAnos/SelecMultAnos';
import SelecInstituicoes from '../../components/SelecInstituicoes/SelecInstituicoes';
import NuvemAssuntos from '../../components/NuvemAssuntos/NuvemAssuntos';

export class IndicadoresAssuntos extends Component {
    displayName = IndicadoresAssuntos.name

    constructor(props) {
        super(props);
        this.state = {
            QualAno: [],
            MostraAnos: ""
        };
        let IFTM = {
            id: -1,
            nome: "IFTM",
            sigla: "IFTM",
            quantidadeProfessores: 0,
            quantidadeProfessoresLattes: 0
        };
        this.state = { QualInstituicao: IFTM };
        this.updateAno = this.updateAno.bind(this);
        this.updateInst = this.updateInst.bind(this);
    }

  //Método para atualizar o ano:
    updateAno = (QualAno) => {
        let Mostra = "";
        for (let i = 0; i < QualAno.length; i++) {
            if (i === 0) {
                Mostra = " " + QualAno[i];
            }
            else {
                Mostra = Mostra + " ; " + QualAno[i];
            }
        }
        this.setState({ QualAno, MostraAnos: Mostra });        
    }
    //Método para mudar a instituição
    updateInst = (QualInstituicao) => { this.setState({ QualInstituicao }); }

    render() {
        return (
            <Container >
                <Row>
                    <Col sm={12}>
                        <h2>Assuntos mais pesquisados em {this.state.MostraAnos}</h2>
                        <PainelConteudo legenda="Pesquisar"
                            texto={
                                <Container >
                                    <Row>
                                        <Col sm={6}>
                                            Escolha o(s) ano(s):
                                            <SelecMultAnos AtualizaQualAno={this.updateAno} />
                                        </Col>
                                        <Col sm={6}>
                                            Escolha a Instituição:
                                            <SelecInstituicoes AtualizaQualInstituicao={this.updateInst} />
                                        </Col>
                                    </Row>
                                </Container>
                            } />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <PainelConteudo mostraAjuda={true} qualIndicador="PERFIL DOCENTE" legenda="Principais Assuntos"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        <NuvemAssuntos
                                            QualAno={this.state.QualAno}
                                            QualInstituicao={this.state.QualInstituicao.id} />
                                    </Col>
                                </Row>
                            } /> 
                    </Col>
                </Row>
            </Container>
        );
    }
}
