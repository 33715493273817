import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

// Components
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';

export function Home(props) {

    const idCt = useContext(IdiomaContext);
    
    return (
          <Container>
              <Row>
                  <Col sm={12}>
                    <h2>{idCt.t("Bem vindo ao Observatório ProfEPT", "Welcome to the Observatory of the ProfEPT")}</h2>                      
                  </Col>
              </Row>
              <Row>
                  <Col sm={6}>
                      <PainelConteudo legenda={idCt.t("Observatório", "Observatory")}
                          texto={
                              <div> 
                                  <a href="https://midi.upt.iftm.edu.br" target="_blank">
                                      <img style={{ height: '80px', width: 'auto', float: 'left', marginRight: '8px', marginLeft: '0px', marginTop: '0px' }} src="obs.png" border="0" />
                                  </a>                                  
                                  <p>
                                      {idCt.t(
                                          "O Observatório tem como principal objetivo o mapeamento das áreas de pesquisa, perfis de professores e elaboração de indicadores de pesquisa. O Observatório faz parte do Grupo de Pesquisa em Mineração da Dados e Imagens (MiDI) do IFTM Campus Avançado Uberaba Parque Tecnológico. As estatísticas são realizadas usando o currículo Lattes dos professores permanentes das instituições pesquisadas.",
                                          "The Observatory's main objective is the mapping of research areas, teacher profiles and the elaboration of research indicators. The Observatory is part of the the Data Mining and Image Research Group (MiDI) of the IFTM Campus Avançado Uberaba Parque Tecnológico. Statistics are performed using the Lattes curriculum of the permanent teachers of the surveyed institutions.")}
                                       <br />
                                      {idCt.t(
                                          "Para maiores informações, ",
                                          "For more information, ")}
                                      <Link to='/sobre'>{idCt.t("clique aqui", "click here ")}</Link>.                                  
                                  </p>
                              </div>
                          }
                      />
                  </Col>
                  <Col sm={6}>
                      <PainelConteudo legenda="ProfEPT"
                          texto={
                              <div>
                                  <a href="https://profept.ifes.edu.br/" target="_blank">
                                      <img style={{ height: '80px', width: 'auto', float: 'left', marginRight: '8px', marginLeft: '0px', marginTop: '0px' }} src="profept.png" border="0" />
                                  </a>
                                  <p>
                                      {idCt.t(
                                          "O Mestrado Profissional em Educação Profissional e Tecnológica em Rede Nacional (ProfEPT) tem como objetivo proporcionar formação em educação profissional e tecnológica aos profissionais da Rede Federal de Educação Profissional, Científica e Tecnológica(RFEPCT), visando tanto a produção de conhecimento como o desenvolvimento de produtos, por meio da realização de pesquisas que integrem os saberes inerentes ao mundo do trabalho e ao conhecimento sistematizado.",
                                          "The Professional Master in Professional and Technological Education in National Network (ProfEPT) aims to provide training in professional and technological education to professionals of the Federal Network of Professional, Scientific and Technological Education (RFEPCT), aiming at both the production of knowledge and the development of products by conducting research that integrates the knowledge inherent in the world of work and systematized knowledge.")}
                                      < br />
                                      {idCt.t(
                                          "Para maiores informações, acesse ",
                                          "For more information, visit ")} 
                                      <a href={"https://profept.ifes.edu.br"} target="blank">profept.ifes.edu.br</a>.
                                  </p>
                              </div>
                          }
                      />
                  </Col>
              </Row>
              <Row>
                  <Col sm={12}>
                    <PainelConteudo mostraAjuda={true} qualIndicador="BOAS VINDAS" legenda={idCt.t("Atualização dos Dados", "Data Update")}
                          texto={
                              <div>
                                  {idCt.t("Os indicadores são ferramentas de gestão que quantificam o desempenho das instituições associadas ao ProfEPT, sendo essenciais para o seu aprimoramento. Os indicadores podem ser mensurados em vários níveis de dimensão.",
                                          "Indicators are management tools that quantify the performance of ProfEPT member institutions and are essential for their improvement. Indicators can be measured at various levels of dimension.")} 
                                  <br /> 
                                  {idCt.t("Os indicadores visam o monitoramento do desempenho das instituições associadas, docentes e discentes.",
                                          "The indicators aim to monitor the performance of associated institutions, professors and master's students.")} 
                                  <br />
                                  {idCt.t("Os dados utilizados para a elaboração dos indicadores são extraídos dos seguintes ambientes: Plataforma Sucupira, Plataforma Lattes, Periódicos Qualis CAPES e do próprio Observatório ProfEPT.",
                                          "The data used for the preparation of the indicators are extracted from the following environments: Sucupira Platform, Lattes Platform, Qualis CAPES Periodicals and the ProfEPT Observatory itself.")} 
                                  <br /> 
                                  {idCt.t("Para saber mais sobre cada indicador, basta clicar no ícone ",
                                          "To learn more about each indicator, just click on the icon ")}
                                  <div style={{ display: 'inline-block' }}>
                                      <IconButton size="small" color="primary" aria-label="Ajuda"
                                                  style={{ verticalAlign: "top", paddingLeft: "5px", paddingBottom: "0px", paddingTop: "0px" }}>
                                          <Icon fontSize="small">help</Icon>
                                      </IconButton>
                                  </div>
                                  {idCt.t("(ao lado do título do indicador)","(next to the indicator title)")}                                                                   
                              </div>
                          }
                      />
                  </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <PainelConteudo legenda={idCt.t("Entre em Contato", "Contact")}
                        texto={
                            <div>
                                {idCt.t("Você pode entrar em contato com a equipe de desenvolvimento do Observatório ProfEPT para deixar sugestões ou comentários, ",
                                    "You can contact the ProfEPT Observatory development team to leave suggestions or comments, ")}
                                <a href={"https://docs.google.com/forms/d/e/1FAIpQLSdPBGPnZAP-VvR9cWuIXvFK78CXVOpmGUfwPmQbheZlt78pfA/viewform?usp=pp_url"} target="blank">{idCt.t("clique aqui", "click here")}</a>.
                                <br />
                                {idCt.t("Caso esteja encontrado problemas na utilização do Observatório ProfEPT, você pode relatar o erro, ",
                                    "If you encounter problems using the Observatory of the ProfEPT, you can report the error, ")}
                                <a href={"https://docs.google.com/forms/d/e/1FAIpQLSd6pzddQ7dGwGL1A2R9YKux98zXBy0Ish2IyMHcWll5QYORGA/viewform?usp=pp_url"} target="blank">{idCt.t("clique aqui", "click here")}</a>.
                                <br />
                                {idCt.t("Para ler as principais dúvidas que surgem sobre esta ferramenta e conferir as respostas de cada pergunta, ",
                                        "Read the main questions that arise about this tool and check the answers to each question, ")}
                                <Link to='/sobre'>{idCt.t("clique aqui", "click here ")}</Link>.
                            </div>
                        }
                    />
                </Col>
            </Row>
          </Container>
    );
}
