import React, { useContext, useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Radio from '@material-ui/core/Radio';

import IdiomaContext from '../../context/idiomaContext/idiomaContext';
import InfoContext from '../../context/infoContext/infoContext';
import SelectAutoAval from '../../components/SelectAutoAval/SelectAutoAval';
import SelecInstituicoes from '../../components/SelecInstituicoes/SelecInstituicoes';
import AvalRelatorioObs from '../../components/AutoAvaliacao/AvalRelatorioObs';
import { formatDate } from '../../helpers/Date';

// Components
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';

export function AutoAvaliacaoObservatorio(props) {
    //Contexto
    const idCt = useContext(IdiomaContext);
    const infoContext = useContext(InfoContext);

    //Constructor
    let OFERTA = {
        id: 0,
        anoSemestre: "2020/1",
        situacaoAvaliacao: 1,
        divulgacaoResultados: null
    }

    let QualInstituicaoInicial = {
        id: 0,
        nome: "Todas",
        sigla: "Todas"
    }
    if ((infoContext.userInfo != null) && (infoContext.userInfo.instituicao != null)) {
        QualInstituicaoInicial = infoContext.userInfo.instituicao;
    }

    //const [dadosOferta, setDadosOferta] = useState(OFERTA);
    //const [instituicao, setInstituicao] = useState(IFTM);
    const [varState, setVarState] = useState({
        dadosOferta: OFERTA,
        instituicao: QualInstituicaoInicial
    })

    useEffect(() => {

    }, []); 

    function setDadosOferta(newDadosOferta) {
        setVarState({
            dadosOferta: newDadosOferta,
            instituicao: varState.instituicao
        });
    }

    function setInstituicao(newInstituicao) {
        setVarState({
            dadosOferta: varState.dadosOferta,
            instituicao: newInstituicao
        });
    }

    function renderConteudo() {
        let conteudo = <p>Relatorio não está disponível</p>;
        if (varState.dadosOferta.situacaoAvaliacao != 3) {
            if ((infoContext.user == null) || (!infoContext.user.profile.admin)) { //(!infoContext.NAPE())) { 
                if (varState.dadosOferta.divulgacaoResultados) {
                    conteudo = <p>O resultado da Autoavaliação do ProfEPT ({varState.dadosOferta.anoSemestre}) estará disponível em {formatDate(varState.dadosOferta.divulgacaoResultados)}</p>
                }
                return conteudo;
            }            
        }
        conteudo=<p>exibe relatorio</p>
        conteudo = <AvalRelatorioObs QualAutoAval={varState.dadosOferta} QualInstituicao={varState.instituicao} />
        return conteudo;
    }

    function renderAll() {
        let contents =
            <Container >
                <Row>
                    <Col sm={12}>
                        <h2>{idCt.t("Relatório da Autoavaliação do Observatório ProfEPT", "Observatory of the ProfEPT Self-Assessment Report")}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <PainelConteudo legenda={idCt.t("Pesquisar", "Search")}
                            texto={
                                <Row>
                                    <Col sm={6}>
                                        {idCt.t("Escolha a Autoavaliação:", "Choose year:")}
                                        <SelectAutoAval AtualizaQualAutoAval={setDadosOferta} />
                                    </Col>
                                    <Col sm={6}>
                                        Escolha a Instituição:
                                        <SelecInstituicoes InstituicaoSelecionada={QualInstituicaoInicial.id} AtualizaQualInstituicao={setInstituicao} />
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <PainelConteudo legenda={idCt.t("Relatório (Observatório)", "Report")}
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        {renderConteudo()}
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
            </Container>
        return (contents);
    }

    return (
            <div>
                {renderAll()}
            </div>
        );    
}
