import authService from '../components/api-authorization/AuthorizeService';

const discenteService = {
    getAtualizaPerfil,
    getAtualizaEgresso,
    putAtualizaPerfil,
    putAtualizaEgresso,
    putAtualizaAcessoAluno
}

async function getAtualizaPerfil() {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: "GET",
        headers: headers
    };    
    return await fetch('api/Discentes/GetAtualizaPerfil', options);
}

async function getAtualizaEgresso(QualAluno) {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: "GET",
        headers: headers
    };
    return await fetch('api/Discentes/GetAtualizaEgresso?AlunoId=' + QualAluno, options);
}

async function putAtualizaPerfil(pBody) {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: 'PUT',
        headers: headers,
        body: pBody,
        cache: 'default'
    };
    return await fetch('api/Discentes/PutAtualizaPerfil', options);
}

async function putAtualizaEgresso(pBody) {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: 'PUT',
        headers: headers,
        body: pBody,
        cache: 'default'
    };
    return await fetch('api/Discentes/PutAtualizaEgresso', options);
}

async function putAtualizaAcessoAluno(AlunoID, TipoAcesso, Avaliacao, Comentario) {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: 'PUT',
        headers: headers,
        cache: 'default'
    };
    return await fetch('api/Discentes/PutAtualizaAcessoAluno?AlunoID=' + AlunoID +
                      '&TipoAcesso=' + TipoAcesso + '&Avaliacao=' + Avaliacao + '&Comentario=' + Comentario, options);
}

export default discenteService;

