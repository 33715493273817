import React, { useState } from 'react';
import IdiomaContext from './idiomaContext';
import pt_brJson from './pt_br.json';
import enJson from './en.json';

export default function IdiomaContextProvider(props) {

    const [idioma, setIdioma] = useState("pt-br");   

    function changeLanguage(language){
        setIdioma(language);
    }

    function t(textoId1, textoId2) {
        if (idioma === "en") {
            return textoId2;
        }
        return textoId1;
    }

    function l(label) {
        if (idioma === "en") {
            return enJson[label];
        }
        return pt_brJson[label]; //pt_brJson.label(label); 
    }

    return (
        <IdiomaContext.Provider
            value={{
                idioma: idioma,
                changeLanguage: changeLanguage,
                t: t,
                l: l
            }}
        >
            {props.children}
        </IdiomaContext.Provider>
    )
    
}

