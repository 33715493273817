import React, { useContext, useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import PerfilProfessor from '../../components/Autenticacao/PerfilProfessor';
import SelecMultAnos from '../../components/SelecAnos/SelecMultAnos';
import PeriodicoPorArea from '../../components/PeriodicoPorArea/PeriodicoPorArea';
import ProducaoBibliografica from '../../components/ProducaoBibliografica/ProducaoBibliografica';

import IdiomaContext from '../../context/idiomaContext/idiomaContext';
import InfoContext from '../../context/infoContext/infoContext';
import MessageContext from '../../context/messageContext/messageContext';
import CronJobLattes from '../../components/AtualizacaoLattes/CronJobLattes';

export function IndicadoresPorProfessor(props) {

    //Contexto
    const idCt = useContext(IdiomaContext);
    const infoContext = useContext(InfoContext);
    const messageContext = useContext(MessageContext);

    //Constructor
    const [QualAno, setQualAno] = useState([]);
    const [MostraAnos, setMostraAnos] = useState("");
    const [AcessoNegado, setAcessoNegado] = useState(true);

    //Método para atualizar o ano:
    const updateAno = (pQualAno) => {
        let Mostra = "";
        for (let i = 0; i < pQualAno.length; i++) {
            if (i === 0) {
                Mostra = " " + pQualAno[i];
            }
            else {
                Mostra = Mostra + " ; " + pQualAno[i];
            }
        }
        setQualAno(pQualAno);
        setMostraAnos(Mostra);
    }

    useEffect(() => {
        //componentDidMount
        if ((infoContext.user != null) && (infoContext.user.profile.professor)) {
            setAcessoNegado(false);
        }
        else {
            messageContext.openMessage("warning", "Somente docentes tem acesso a este conteúdo.");
        }
    }, []); 

    function renderAll() {       
        if (AcessoNegado) {            
            return (
                <Container >
                    <Row>
                        <Col sm={12}>
                            <h2>{idCt.t("Indicadores por Professor", "Indicators by Professor")}</h2>
                            <CronJobLattes />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <PainelConteudo legenda={idCt.t("Acesso Negado", "Access Denied")}
                                texto={
                                    <Row>
                                        <Col sm={12}>
                                            {idCt.t("Somente docentes tem acesso a este conteúdo.", "Only professors have access to this content.")}                                            
                                        </Col>
                                    </Row>
                                } />
                        </Col>
                    </Row>
                </Container >
            );
        }

        return (
            <Container >
                <Row>
                    <Col sm={12}>
                        <h2>{idCt.t("Indicadores por Professor em ", "Indicators by Professor in ")} {MostraAnos}</h2>
                        <CronJobLattes />
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>                    
                        <PainelConteudo legenda={idCt.t("Perfil do Professor", "Professor Profile")}
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        <PerfilProfessor />
                                    </Col>
                                </Row>                            
                            } />
                    </Col>
                    <Col sm={6}>
                        <PainelConteudo legenda={idCt.t("Selecione o(s) Ano(s)", "Select the Year(s)")} 
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        {idCt.t("Escolha o(s) ano(s):", "Choose the Year(s)")}
                                            <SelecMultAnos AtualizaQualAno={updateAno} /><br />
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <PainelConteudo mostraAjuda={true} qualIndicador="PROD BIB DOCENTE" legenda={idCt.t("Produção Bibliográfica", "Bibliographic Production")}
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        <ProducaoBibliografica
                                            QualConsulta="PorIndividuo"
                                            TipoGrafico="PorProfessor"
                                            QualAno={QualAno} />
                                    </Col>
                                </Row>
                            } />                    
                    </Col> 
                    <Col sm={6}>
                        <PainelConteudo mostraAjuda={true} qualIndicador="PROD PERIODICOS DOCENTE" legenda={idCt.t("Produção em Periódicos", "Production in Journals")}
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        <PeriodicoPorArea
                                            QualConsulta="PorIndividuo"
                                            TipoGrafico="PorProfessor"
                                            QualAno={QualAno} />
                                    </Col>
                                </Row>
                            } />
                    </Col>                
                </Row>
            </Container>
        );
    }

    return (
        renderAll()
        );
}
