import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import Mensagens from '../Mensagens/Mensagens';
import ModalLoading from '../../components/ModalLoading/ModalLoading';
import ModalMensagem from '../ModalMensagem/ModalMensagem';

export default class Secretarios extends Component {

    PodeIncluirNaInstituicao = -1;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingSave: false,
            ExibeMensagem: false,
            mensagemAviso: null,
            Secretarios: [],
            nome: "",
            email: "",
            ExibeInsereSecretario: false,
            MensagemBotaoEmailSecretarios: false
        };

        this.PodeIncluirNaInstituicao = -1;
        if ((this.props.TipoEdicao) && (this.props.TipoEdicao == "Sim") && (this.props.QualInstituicaoUsr)) {
            this.PodeIncluirNaInstituicao = this.props.QualInstituicaoUsr.id;
        }

        this.updateExibeMensagem = this.updateExibeMensagem.bind(this);
        this.updateExibeInsereSecretario = this.updateExibeInsereSecretario.bind(this);
        this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
        this.updateMensagemBotaoEmailSecretarios = this.updateMensagemBotaoEmailSecretarios.bind(this);
    }

    updateExibeMensagem = (ExibeMensagem) => { this.setState({ ExibeMensagem }); }
    updateExibeInsereSecretario = (ExibeInsereSecretario) => { this.setState({ ExibeInsereSecretario }); }
    updateMensagemBotaoEmailSecretarios = (MensagemBotaoEmailSecretarios) => { this.setState({ MensagemBotaoEmailSecretarios }); }

    componentDidMount() {
        this.CarregarDados();
    }
       
    CarregarDados() {
        this.setState({ loading: true });
        let StringFetch = 'api/Secretarios/ListaSecretarios';
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ Secretarios: data, ExibeMensagem: false, loading: false });
            })
            .catch(error => this.setState({
                ExibeMensagem: true, loading: false,
                mensagemAviso: { statusCode: 2, statusDescription: "Ocorreu um erro ao ler os secretários.", statusVariant: "error" }
            }));
    }

    insereSecretario() {
        this.setState({ loadingSave: true, ExibeInsereSecretario: false, ExibeMensagem: false, mensagemAviso: null });

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ID: 0, Nome: this.state.nome, Email: this.state.email,
                InstituicaoID: this.PodeIncluirNaInstituicao, UsuarioID: 0
            }),
            cache: 'default'
        };

        fetch('api/Secretarios/PostInsereSecretario', options)
             .then(r => {
                   r.json().then(response => {
                        if (response.statusCode) {
                            this.setState({ ExibeMensagem: true, mensagemAviso: response, loadingSave: false });
                        }
                        else {
                            this.setState({ loadingSave: false });                            
                       }                       
                       this.CarregarDados();
                   });
             })
            .catch(error => this.setState({
                ExibeMensagem: true, loadingSave: false, 
                mensagemAviso: { statusCode: 2, statusDescription: "Ocorreu ao incluir secretário.", statusVariant: "error" }
            }));
    }

    rowExpansionTemplate(data) {
        return (
            <div className="p-grid p-fluid" style={{ padding: '1em' }}>
                <div className="p-col-12 p-md-12">
                    <div className="p-grid">
                        <div className="p-md-2">IA: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.instituicao.nome}</div>

                        <div className="p-md-2">Email: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.email}</div>

                    </div>
                </div>
            </div>
        );
    }

    renderSecretariosCelular() {
        var header = <div style={{ 'textAlign': 'left' }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Pesquisar" size="20" />
                    </div>;

        return (
            <div>
                <div className="content-section implementation">
                    <DataTable value={this.state.Secretarios} paginator={true} rows={15} rowsPerPageOptions={[5, 15, 30]}
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        globalFilter={this.state.globalFilter} header={header}
                        expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                        rowExpansionTemplate={this.rowExpansionTemplate}
                    >
                        <Column expander={true} style={{ width: '3em' }} />
                        <Column field="nome" header="Nome" sortable={true} />
                    </DataTable>
                </div>
            </div>
        );
    }

    renderSecretariosTamanhoNormal() {
        var header = <div style={{ 'textAlign': 'left' }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Pesquisar" size="50" />
                     </div>;
        return (
                <div>
                    <div className="content-section implementation">
                        <DataTable value={this.state.Secretarios} paginator={true} rows={15} rowsPerPageOptions={[5, 15, 30]}
                            resizableColumns={true} columnResizeMode="fit"
                            globalFilter={this.state.globalFilter} header={header} 
                        >
                            <Column field="nome" header="Nome" sortable={true} />
                            <Column field="instituicao.sigla" header="IA" sortable={true} style={{ textAlign: 'center', width: '9em' }} />
                            <Column field="email" header="Email" sortable={false} />                            
                        </DataTable>
                    </div>
                </div>
            );             
    }
       
    renderSecretarios() {
        var windowWidth = window.innerWidth;
        if (windowWidth < 770) {
            return this.renderSecretariosCelular();
        }
        else {
            return this.renderSecretariosTamanhoNormal();
        }
    }

    renderIncluirSecretario() {
        return (<div>
                    <ModalMensagem updateShow={this.updateExibeInsereSecretario}
                        show={this.state.ExibeInsereSecretario} titulo="Incluir Secretário em sua Instituição"
                        mensagem=
                        {<div>  
                            <br />
                            <span className="p-float-label">
                                <InputText style={{ width: "100%" }} id="in" value={this.state.nome} onChange={(e) => this.setState({ nome: e.target.value })} />
                                <label htmlFor="in">Nome</label>
                            </span>                            
                            <br /><br />
                            <span className="p-float-label">
                                <InputText style={{ width: "100%" }} id="in" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                <label htmlFor="in">Email</label>
                            </span>
                            <span style={{ color: "red" }}><i>Cuidado: </i></span><i>somente emails do <b>Google</b> ou <b>Facebook</b> ou <b>Microsoft</b>.</i>
                            <br /><br />
                            <div style={{ textAlign: "left" }}>
                                <Button label="Salvar" onClick={() => this.insereSecretario()} className="p-button-raised p-button-success" />
                            </div>
                            <br />
                        </div>
                        }
                    />
                </div>
        );
    }

    renderEmailsSecretarios() {
        return (
            <div>
                <ModalMensagem updateShow={this.updateMensagemBotaoEmailSecretarios}
                    show={this.state.MensagemBotaoEmailSecretarios} titulo="Lista de emails dos secretários"
                    mensagem=
                    {<div>
                        {this.state.Secretarios.map((item, i) => {
                            return (<span>{(i == 0) ? <i></i> : <i>;</i>} {item.email}</span>)
                        })}
                    </div>
                    }
                />
            </div>
        );
    }

    render() {
        const { loading, loadingSave, ExibeMensagem, mensagemAviso } = this.state;
        let contents = <p></p>;
        let contentsAviso = <p></p>;
        let contentsIncSecretario = <p></p>;
        let contentsEmailsSecretarios = <p></p>;
        if (loading) {
            contents =  <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                        </div>;
        }
        else {
            contents = this.renderSecretarios();
            if (this.PodeIncluirNaInstituicao > 0) {
                contentsIncSecretario =
                    <Col sm={4}>
                        <b>Incluir Secretário:</b><br />
                        <Button label="Incluir" onClick={() => this.setState({ ExibeInsereSecretario: true })} className="p-button-raised p-button-info" />
                        <br /><br />
                    </Col>
            }
            contentsEmailsSecretarios = 
                <Col sm={4}>
                    <b>Facilite sua vida:</b><br />
                    <Button label="Emails Secretários" icon="pi pi-envelope" onClick={() => this.setState({ MensagemBotaoEmailSecretarios: true })}></Button>
                    <br /><br />
                    {this.renderEmailsSecretarios()}
                </Col>
        }
        if (mensagemAviso) {
            contentsAviso = <Mensagens Exibir={ExibeMensagem} AtualizaExibir={this.updateExibeMensagem}
                                       Opcao={mensagemAviso.statusVariant} Mensagem={mensagemAviso.statusDescription} />;
        }

        return (
            <Container >
                <Row>
                    {contentsIncSecretario}
                    {contentsEmailsSecretarios}
                </Row>
                <Row>
                    <Col sm={12}>
                        {contents}
                        {contentsAviso}
                        {this.renderIncluirSecretario()}
                        <ModalLoading show={loadingSave} />
                    </Col>
                </Row>
            </Container>
        );

    }

}