import React, { useContext, useState, useEffect } from 'react';
import { Alert } from '@material-ui/lab';

import InfoContext from '../../context/infoContext/infoContext';
import LoadingContext from '../../context/loadingContext/loadingContext';
import MessageContext from '../../context/messageContext/messageContext';

import { AvalPergunta } from './AvalPergunta';

import autoavaliacaoService from '../../services/autoavaliacaoService';

export function AvalDiscEletiva(props) {

    //Contexto
    const infoContext = useContext(InfoContext);
    const loadingContext = useContext(LoadingContext);
    const messageContext = useContext(MessageContext);

    //Constructor
    const [disciplinas, setDisciplinas] = useState(null);
    const [discSelecionadas, setDiscSelecionadas] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        //componentDidMount
        loadingContext.showLoading();
        lerDados();
        loadingContext.hideLoading();        
    }, []); 

    function lerDados() {
        autoavaliacaoService.GetDisciplinasEletivas(props.AutoAvaliacaoID)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(DadosResposta => {
                setDisciplinas(DadosResposta);
                inicializarDiscSelecionadas(DadosResposta);                
            })
            .catch(error => {
                messageContext.openMessage("error", "Erro. Por favor, tente novamente.");
                setError(error);                
            });
    }

    function inicializarDiscSelecionadas(DadosResposta) {
        if (DadosResposta == null) {
            return;
        }
        let TempDiscSelecionadas = [];
        for (let i = 0; i < DadosResposta.length; i++) {
            TempDiscSelecionadas.push({
                id: DadosResposta[i].id,
                nome: DadosResposta[i].nome,
                sigla: DadosResposta[i].sigla,
                ofertaInscProfID: DadosResposta[i].ofertaInscProfID,
                professorNome: DadosResposta[i].professorNome
            });
        }
        setDiscSelecionadas(TempDiscSelecionadas);
        setLoading(false);
    }

    function respondeuPergunta(perguntaID, tipoExibicao, etapa, listaRespostas, index) {
        if (!discSelecionadas[index]) return;
        let item = discSelecionadas[index];                
        props.responder(perguntaID, tipoExibicao, etapa, listaRespostas, item.ofertaInscProfID)
    }

    function getRespostas(perguntaID, disc) {
        for (let i = 0; i < props.respostas.length; i++) {
            if ((props.respostas[i].id === perguntaID) && (props.respostas[i].disciplinaEletiva === disc)) {
                return props.respostas[i].respostas;
            }
        }
        return null;
    }

    function exibePerguntas(index) {
        if (loading == true) return null;
        let contents =
            <div>
                <div style={{ fontSize: '13px' }}>
                    <p><Alert severity="info" icon={false}><b>{discSelecionadas[index].nome}</b></Alert></p>
                    <p><b>Professor</b>: {discSelecionadas[index].professorNome}</p>                     
                </div>
                <div style={{width: '100%'}}>
                    <hr size="1" />
                </div>
                <div style={{ fontSize: '12px' }}>
                    {props.perguntas.map((itemP, indexP) => (
                        <AvalPergunta
                            etapa={props.step + 1}
                            nro={indexP + 1}
                            disciplina={index}
                            pergunta={itemP}
                            responder={respondeuPergunta}
                            respostas={getRespostas(itemP.id, discSelecionadas[index].ofertaInscProfID)}
                            discObrigatoria={discSelecionadas[index].ofertaInscProfID}
                        />
                    ))}
                </div>
            </div>
        return contents;
    }

    function renderAll() {
        if (error != null) {
            return (<p>Erro. Por favor, tente novamente.</p>);
        }
        else if ((discSelecionadas === null) || (loading == true)) {
            return (<p><i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em></p>);
        }

        let contents =
            <div>
                <div style={{ width: '100%' }}>
                    <Alert severity="warning">Responda apenas as disciplinas que <b>já concluiu</b> no mestrado ProfEPT.</Alert>
                </div>
                <div>
                    {discSelecionadas.map((item, index) => (
                        <div style={{ display: 'inline-block', padding: '.8em', width: '50%', minWidth: '230px', flex: '0 0 auto', verticalAlign: 'top' }}>
                            {exibePerguntas(index)}
                        </div>
                    ))}
                </div>
            </div>;
        return (contents);
    }

    return (
        renderAll()
        );
}
