import React, { useState, useEffect } from 'react';
import MapGL, { Marker } from 'react-map-gl';
//import MapboxLanguage from 'mapbox-gl-language';

export default function MapaCidades(props) {

    const MAPBOX_TOKEN = 'pk.eyJ1IjoiZXJuYW5pbWVsbyIsImEiOiJjazc4ZDJwNGEwNzk0M21udWRldDd5ejk4In0.qhhvrTUVZEQOz7FefUC0Ig'; // Set your mapbox token here

    const SizeMarker = 20;
    const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9C20.1,15.8,20.2,15.8,20.2,15.7z`;

    const [viewport, setViewport] = useState({
        latitude: -14.7795, // -15.7795, //Brasilia
        longitude: -47.9297,
        zoom: 4,
        bearing: 0,
        pitch: 0
    });

    useEffect(() => {
        //componentDidMount e componentDidUpdate
        AlteraParametros();
    }, [props.data]);

    function AlteraParametros() {
        if ((props.Zoom !== null) && (parseInt(props.Zoom) > 1)) {
            setViewport(
                {
                    latitude: -14.7795, //Brasilia
                    longitude: -53.9297,
                    zoom: props.Zoom,
                    bearing: 0,
                    pitch: 0
                }
            )
        }
    }

/*
    const AlteraIdioma = (event) => {
        const map = event.target;
        map.addControl(new MapboxLanguage({
            defaultLanguage: 'pt',
        }));
    }
*/
    //<GeolocateControl />
    //<NavigationControl />
    //setRTLTextPlugin="https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.1.0/mapbox-gl-rtl-text.js"
    //onLoad={AlteraIdioma}
    return (
        <MapGL
            {...viewport}
            width="100%"
            height="100%"
            mapStyle="mapbox://styles/mapbox/light-v9"
            onViewportChange={setViewport}
            mapboxApiAccessToken={MAPBOX_TOKEN}              
        >
            {props.data.map((city, index) => (
                <Marker key={`marker-${index}`} longitude={city.longitude} latitude={city.latitude}>
                    <svg
                        height={SizeMarker}
                        viewBox="0 0 24 24"
                        style={{
                            cursor: 'pointer',
                            fill: '#d00',
                            stroke: 'none',
                            transform: `translate(${-SizeMarker / 2}px,${-SizeMarker}px)`
                        }}
                        onClick={() => props.onClick(city.nome,city.estado,city.alunos)}
                    >
                        <path d={ICON} />
                    </svg>
                </Marker>
            ))}
        
        </MapGL>
    );
}
