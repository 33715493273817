import React, { useContext, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import SelecInstituicoes from '../../components/SelecInstituicoes/SelecInstituicoes';
import DiscentesCompleto from '../../components/DiscentesCompleto/DiscentesCompleto';
import SelectAnosIngresso from '../../components/SelectAnosIngresso/SelectAnosIngresso';

import IdiomaContext from '../../context/idiomaContext/idiomaContext';
import InfoContext from '../../context/infoContext/infoContext';

export function Discentes(props) {

    const idCt = useContext(IdiomaContext);
    const infoContext = useContext(InfoContext);
   
    let TipoEdicao = "Nao";
    if ((infoContext.user != null) && ((infoContext.user.profile.admin) || (infoContext.user.profile.coordenadorNacional))) {
        TipoEdicao = "Tudo";
    }
    else if ((infoContext.user != null) && (infoContext.user.profile.coordenadorCurso)) {
        TipoEdicao = "Instituicao";
    }

    let QualInstituicaoInicial = {
        id: 0,
        nome: "IFTM",
        sigla: "IFTM",
        quantidadeProfessores: 0,
        quantidadeProfessoresLattes: 0
    }
    if (infoContext.userInfo != null) {
        QualInstituicaoInicial = infoContext.userInfo.instituicao;
    }
    const [QualInstituicao, setQualInstituicao] = useState(QualInstituicaoInicial);
    const [QualAno, setQualAno] = useState(0);

    return (
        <Container>
            <Row>
                <Col sm={12}>
                    <h2>{idCt.t("Consulta de Discentes", "Search for Master's students")}</h2>
                    <PainelConteudo legenda={idCt.t("Pesquisar", "Search")}
                        texto={
                            <Container >
                                <Row>
                                    <Col sm={6}>
                                        {idCt.t("Escolha a Instituição:", "Choose the Institution:")}
                                        <SelecInstituicoes InstituicaoSelecionada={QualInstituicao.id} AtualizaQualInstituicao={setQualInstituicao} />
                                    </Col>
                                    <Col sm={6}>
                                        {idCt.t("Escolha o Ano de Ingresso:", "Choose the Year:")}
                                        <SelectAnosIngresso AtualizaQualAno={setQualAno} />
                                    </Col>                                    
                                </Row>
                            </Container>
                        } />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <br />
                    <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{QualInstituicao.nome}</span><br />
                </Col>
            </Row>         
            <Row>
                <Col sm={12}>
                    <PainelConteudo legenda={idCt.t("Discentes", "Master's students")}
                        texto={
                            <Row>
                                <Col sm={12}>
                                    <DiscentesCompleto TipoEdicao={TipoEdicao} QualInstituicaoUsr={QualInstituicaoInicial} QualAnoIngresso={QualAno} QualInstituicao={QualInstituicao.id} />
                                </Col>
                            </Row>
                        } />                    
                </Col>
            </Row>
        </Container>
    );

}
