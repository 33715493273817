import React, { useContext, useEffect, useState } from 'react';
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import { QueryParameterNames, LogoutActions, ApplicationPaths } from './ApiAuthorizationConstants';
import LoadingContext from '../../context/loadingContext/loadingContext';

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.

export function Logout(props) {

    const loadingContext = useContext(LoadingContext);
    const [message, setMessage] = useState(undefined);
    const [isReady, setIsReady] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        loadingContext.showLoading();
        const action = props.action;
        switch (action) {
            case LogoutActions.Logout:
                if (!!window.history.state.state.local) {
                    fazerLogout(getReturnUrl());
                } else {
                    // This prevents regular links to <app>/authentication/logout from triggering a logout
                    setIsReady(true);
                    loadingContext.hideLoading();
                    setMessage("O logout não foi iniciado de dentro da página.");
                }
                break;
            case LogoutActions.LogoutCallback:
                processLogoutCallback();
                break;
            case LogoutActions.LoggedOut:
                setIsReady(true);
                loadingContext.hideLoading();
                setMessage("Você efetuou logout com sucesso!");
                break;
            default:
                throw new Error(`Ação inválida '${action}'`);
        }
        populateAuthenticationState();
    }, []);


    function returnRender() {
        if (!isReady) {
            return <div></div>
        }
        if (!!message) {
            return (<div>{message}</div>);
        } else {
            const action = props.action;
            switch (action) {
                case LogoutActions.Logout:
                    return (<div><i className="fa fa-refresh fa-spin"></i> <em>Processando logout...</em></div>);
                case LogoutActions.LogoutCallback:
                    return (<div><i className="fa fa-refresh fa-spin"></i> <em>Processando logout callback...</em></div>);
                case LogoutActions.LoggedOut:
                    return (<div>{message}</div>);
                default:
                    throw new Error(`Ação inválida '${action}'`);
            }
        }
    }

    async function fazerLogout(returnUrl) {
        const state = { returnUrl };        
        const isauthenticated = await authService.isAuthenticated();        
        if (isauthenticated) {
            const result = await authService.signOut(state);
            switch (result.status) {
                case AuthenticationResultStatus.Redirect:
                    break;
                case AuthenticationResultStatus.Success:
                    await navigateToReturnUrl(returnUrl);
                    break;
                case AuthenticationResultStatus.Fail:
                    setMessage(result.message);
                    break;
                default:
                    throw new Error("Status de autenticação inválido.");
            }
        } else {            
            setMessage("Você efetuou logout com sucesso!");
        }        
    }

    async function processLogoutCallback() {
        const url = window.location.href;
        const result = await authService.completeSignOut(url);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                // There should not be any redirects as the only time completeAuthentication finishes
                // is when we are doing a redirect sign in flow.
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success:
                await navigateToReturnUrl(getReturnUrl(result.state));
                break;
            case AuthenticationResultStatus.Fail:
                setMessage(result.message);
                break;
            default:
                throw new Error("Status de autenticação inválido.");
        }
    }

    async function populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        setIsReady(true);
        loadingContext.hideLoading();
        setAuthenticated(authenticated);
    }

    function getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("URL de retorno inválido. O URL de retorno precisa ter a mesma origem da página atual.")
        }
        return (state && state.returnUrl) ||
            fromQuery ||
            `${window.location.origin}${ApplicationPaths.LoggedOut}`;
    }

    function navigateToReturnUrl(returnUrl) {
        return window.location.replace(returnUrl);
    }

    return (
        <div>{returnRender()}</div>
    );
}
