import React, { Component } from 'react';
import { Form } from 'react-bootstrap'; ///lib/FormControl';

export default class SelectBasicoProfessor extends Component {    

    constructor(props) {
        super(props);
        this.state = {
            Professores: [],
            loading: true,
            error: null
        };
    }

    componentDidMount() {
        let StringFetch = 'api/Pesquisadores/ListaPesquisadores';
        if ((this.props.Selecao) && (this.props.Selecao == 'TODOS')) {
            StringFetch = 'api/Pesquisadores/ListaCredenciadosDescredenciados';
        }
        if (this.props.QualInstituicao) {
            StringFetch = StringFetch + '?QualInstituicao=' + this.props.QualInstituicao;
        } 
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ Professores: data, loading: false });
                if (!this.props.ProfSelecionado) {
                    let RetornoProf = { id: 0, nome: "Nenhum" };
                    if (this.props.Index != null) {
                        this.props.AtualizaQualProf(RetornoProf, this.props.Index);
                    }
                    else {
                        this.props.AtualizaQualProf(RetornoProf);
                    }
                }                
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    AlteraProfessor(pValor) {
        let a = this.state.Professores;
        if (parseInt(pValor) == 0) {
            let RetornoProf = { id: 0, nome: "Nenhum" };
            if (this.props.Index != null) {
                this.props.AtualizaQualProf(RetornoProf, this.props.Index);
            }
            else {
                this.props.AtualizaQualProf(RetornoProf);
            }
            return;
        }
        for (let i = 0; i < a.length; i++) {
            if (parseInt(a[i].professorID) == parseInt(pValor)) {
                let RetornoProfSel = { id: a[i].professorID, nome: a[i].nome };
                if (this.props.Index != null) {
                    this.props.AtualizaQualProf(RetornoProfSel, this.props.Index);
                }
                else {
                    this.props.AtualizaQualProf(RetornoProfSel);
                }                
                return;
            }
        }       

    }

    renderSelectProfessor() {
        return (
            <div>
                <Form.Control as="select"
                    placeholder="Selecione o Docente"
                    onChange={(e) => this.AlteraProfessor(e.target.value)} >
                    <option value={0} key={0}> Nenhum </option>
                    {
                        this.state.Professores.map(prof =>
                            (this.props.ProfSelecionado && parseInt(this.props.ProfSelecionado) == parseInt(prof.professorID)) ?
                                <option selected value={prof.professorID} key={prof.professorID}> {prof.nome} </option> :
                                <option value={prof.professorID} key={prof.professorID}> {prof.nome} </option>)
                    }
                </Form.Control>
            </div>       
        );
    }
    render() {
        const { loading, error } = this.state;
        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents =  <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                        </div>;
        }
        else {
            contents = this.renderSelectProfessor();
        }

        return (
            <div>
                {contents}
            </div>
        );

    }
}