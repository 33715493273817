import authService from '../components/api-authorization/AuthorizeService';

const admService = {
    QualEtapa,
    DownloadCurriculoLattes,
    ExcluirDadosEstatisticos
}

async function QualEtapa(pEtapa) {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: "GET",
        headers: headers
    }; 
    if (pEtapa === "Download Curriculos Lattes") {
        return DownloadCurriculoLattes(options);
    }
    else if (pEtapa === "Excluir Estatisticas") {
        return ExcluirDadosEstatisticos(options);
    }
    else if (pEtapa === "Download Curriculos Lattes e Gerar Estatísticas (Completo)") {
        return DownloadLattesGerarEstatistica(options);
    }
    return null;
}

async function DownloadCurriculoLattes(options) {
    return await fetch('api/Admin/DownloadCurriculoLattes', options);
}

async function ExcluirDadosEstatisticos(options) {
    return await fetch('api/Admin/ExcluirDadosEstatisticos', options);
}

async function DownloadLattesGerarEstatistica(options) {
    return await fetch('api/Admin/DownloadLattesGerarEstatistica', options);
}

export default admService;

