import React, { useContext, useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Steps } from 'primereact/steps';
import { formatDate } from '../../helpers/Date';

import LoadingContext from '../../context/loadingContext/loadingContext';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';
import MessageContext from '../../context/messageContext/messageContext';

import autoavaliacaoService from '../../services/autoavaliacaoService';

// Components
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';

export function AutoAvaliacaoComeceAqui(props) {
    //Contexto
    const loadingContext = useContext(LoadingContext);
    const idCt = useContext(IdiomaContext);
    const messageContext = useContext(MessageContext);

    //Constructor
    const [dadosOferta, setDadosOferta] = useState(null);
    const [error, setError] = useState(null);

    //DadosEtapas
    const itemsEtapas = [
        {
            label: idCt.t('Sensibilização', 'Awareness') 
        },
        {
            label: idCt.t('Aplicação de Instrumentos', 'Application of Instruments')  
        },
        {
            label: idCt.t('Análise de Dados', 'Data Analysis')  
        },
        {
            label: idCt.t('Divulgação em Seminários', 'Disclosure in Seminars')  
        },
        {
            label: idCt.t('Planejamento Estratégico', 'Strategic Planning')  
        }
    ];

    useEffect(() => {
        loadingContext.showLoading();
        lerDados();
        loadingContext.hideLoading();
    }, []); 

    function lerDados() {
        autoavaliacaoService.UltimaOferta()
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(DadosResposta => {
                setDadosOferta(DadosResposta);
            })
            .catch(error => {
                messageContext.openMessage("error", "Erro.Por favor, tente novamente.");
                setError(error);
            });
    }

    function renderAll() {
        let contents = <p>Sistema de Autoavaliação do ProfEPT</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loadingContext.loading) {
            contents = <div className="overlay">
                <i className="fa fa-refresh fa-spin"></i> <em>{idCt.t("Lendo dados...", "Loading...")}</em>
            </div>;
        }
        else if (dadosOferta != null) {
            contents =
                <Container >
                    <Row>
                        <Col sm={12}>
                            <h3>{idCt.t("Entenda o Sistema de Autoavaliação do ProfEPT", "ProfEPT Self-Assessment System")}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <PainelConteudo legenda={idCt.t("Sistema de Autoavaliação", "Self-Assessment")}
                                texto={
                                    <div>
                                        <div>
                                            <p>{idCt.t("A Autoavaliação é o processo de avaliar a si próprio, por vezes também chamada de avaliação interna ou avaliação institucional, quando referida às instituições. Seu principal objetivo é formativo, de aprendizagem. Uma vez que é planejada, conduzida, implementada e analisada, a autoavaliação possibilita uma reflexão sobre contexto e políticas adotadas, além da sistematização dos dados que levam à tomada de decisão (CAPES, 2019).",
                                                "Self - assessment is the process of assessing yourself, sometimes also called internal assessment or institutional assessment, when referring to institutions.Its main objective is training, learning.Once self - assessment is planned, conducted, implemented and analyzed, it allows a reflection on the context and policies adopted, in addition to the systematization of the data that lead to decision making (CAPES, 2019).")}
                                            </p>
                                            <p>{idCt.t("Toda a gestão do sistema de autoavaliação do ProfEPT é realizada pelo NAPE (Núcleo de Autoavaliação e Planejamento Estratégico do ProfEPT). O Sistema de Autoavaliação está organizado a partir de 5 etapas:",
                                                "The entire management of the ProfEPT self-assessment system at the NAPE (Center for Self-Evaluation and Strategic Planning ProfEPT). The Self-Assessment System is organized around 5 steps:")}
                                            </p>
                                        </div>
                                        <div>
                                            <Steps model={itemsEtapas} activeIndex={1} />
                                        </div>
                                        <div>
                                            <p><b>{idCt.t("A aplicação dos instrumentos ocorrerá nas seguintes datas:", "The instruments will be applied on the following dates:")}</b></p>
                                            <p><b>{idCt.t("Egressos", "Alumni")}</b>: {formatDate(dadosOferta.inicioAvaliacaoEgresso)} a {formatDate(dadosOferta.fimAvaliacaoEgresso)}</p>
                                            <p><b>{idCt.t("Discentes", "Students")}</b>: {formatDate(dadosOferta.inicioAvaliacaoDiscente)} a {formatDate(dadosOferta.fimAvaliacaoDiscente)}</p>
                                            <p><b>{idCt.t("Docentes e Coordenações", "Professors and Coordinators")}</b>: {formatDate(dadosOferta.inicioAvaliacaoDocente)} a {formatDate(dadosOferta.fimAvaliacaoDocente)}</p>
                                            <p>{idCt.t("A análise dos dados ocorrerá imediatamente após a aplicação dos instrumentos.", "Data analysis will take place immediately after application of the instruments.")}</p>
                                            <p>{idCt.t("O resultado final da Autoavaliação estará disponível aqui no Observatório ProfEPT (no menu indicadores).", "The final result of the Self-Assessment will be available here at the ProfEPT Observatory (in the indicators menu).")}</p>
                                        </div>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Container>
        }
        return (contents);
    }

    return (
            <div>
                {renderAll()}
            </div>
        );    
}
