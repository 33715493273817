import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import StarIcon from '@material-ui/icons/Star';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

export function AvalRelatorioItemRating(props) {

    const StyledRatingCoracao = withStyles({
        iconFilled: {
            color: '#ff6d75',
        },
        iconHover: {
            color: '#ff3d47',
        },
    })(Rating);

    const ConcordanciaRatingInt = {
        0: {
            icon: <SentimentVeryDissatisfiedIcon fontSize="small" />,
            label: 'Discordo totalmente',
        },
        0.5: {
            icon: <SentimentVeryDissatisfiedIcon fontSize="small" />,
            label: 'Discordo totalmente',
        },
        1: {
            icon: <SentimentVeryDissatisfiedIcon fontSize="small" />,
            label: 'Discordo totalmente+',
        },
        1.5: {
            icon: <SentimentDissatisfiedIcon fontSize="small" />,
            label: 'Discordo parcialmente',
        },
        2: {
            icon: <SentimentDissatisfiedIcon fontSize="small" />,
            label: 'Discordo parcialmente+',
        },
        2.5: {
            icon: <SentimentSatisfiedIcon fontSize="small" />,
            label: 'Não concordo nem discordo',
        },
        3: {
            icon: <SentimentSatisfiedIcon fontSize="small" />,
            label: 'Não concordo nem discordo+',
        },
        3.5: {
            icon: <SentimentSatisfiedAltIcon fontSize="small" />,
            label: 'Concordo parcialmente',
        },
        4: {
            icon: <SentimentSatisfiedAltIcon fontSize="small" />,
            label: 'Concordo parcialmente+',
        },
        4.5: {
            icon: <SentimentVerySatisfiedIcon fontSize="small" />,
            label: 'Concordo totalmente',
        },
        5: {
            icon: <SentimentVerySatisfiedIcon fontSize="small" />,
            label: 'Concordo totalmente+',
        }
    };

    function IconContainerConcordancia(props) {
        const { value, ...other } = props;
        return <span {...other}>{ConcordanciaRatingInt[value].icon}</span>;
    }

    IconContainerConcordancia.propTypes = {
        value: PropTypes.number.isRequired,
    };

    useEffect(() => {
        //componentDidMount e componentDidUpdate
    }, [props]);

    function renderCelular() {
        let contentsTotalIA = <p></p>;
        if (props.QualInstituicao.id > 0) {
            if (!props.Indicador.respostaInstituicao) {
                return (<p><i className="fa fa-refresh fa-spin"></i> <em>Aguarde...</em></p>);
            }
            if (props.Indicador.respostaInstituicao.exibirTotal) {
                contentsTotalIA = renderMedia(props.Indicador.respostaInstituicao.mediaRespostaArrendTotal);
            }
            else {
                contentsTotalIA = <VisibilityOffIcon color="primary" fontSize="small" />;
            }

            return(
                <div>
                    <div style={{ paddingTop: '0px' }}>
                        <b>Média <span style={{ color: '#3F51B5' }}>{props.QualInstituicao.sigla}</span> : </b>{contentsTotalIA}
                    </div>
                    <div style={{ paddingTop: '0px' }}>
                        <b>Média <span style={{ color: '#F50057' }}>Nacional</span> : </b>{renderMedia(props.Indicador.respostaGeral.mediaRespostaArrendTotal)}
                    </div>
                </div>);
        }
        else {
            return (
                <div style={{ paddingTop: '0px' }}>
                    <b>Média <span style={{ color: '#F50057' }}>Nacional</span> :</b>{renderMedia(props.Indicador.respostaGeral.mediaRespostaArrendTotal)}
                </div>
                );
        }
    }

    function renderAll() {

        var windowWidth = window.innerWidth;
        if (windowWidth < 770) {
            return renderCelular();
        }

        let contentsAmostra = <p></p>;
        let contentsCoord = <p></p>;
        let contentsDocente = <p></p>;
        let contentsDiscente = <p></p>;
        let contentsEgresso = <p></p>;
        let contentsTotal = <p></p>;
        if (props.QualInstituicao.id > 0) {
            if (!props.Indicador.respostaInstituicao) {
                return (<p><i className="fa fa-refresh fa-spin"></i> <em>Aguarde...</em></p>);
            }
            contentsAmostra =
                <div>
                    <div style={{ paddingTop: '0px' }}>
                        <b>Média <span style={{ color: '#3F51B5' }}>{props.QualInstituicao.sigla}</span> :</b>
                    </div>
                    <div style={{ paddingTop: '7px' }}>
                        <b>Média <span style={{ color: '#F50057' }}>Nacional</span> :</b>
                    </div>
                </div>;

            if (props.Indicador.respostaGeral.nroRespostaCoord > 0) {
                if (props.Indicador.respostaInstituicao.exibirCoord) {
                    contentsCoord =
                        <div>
                            {renderMedia(props.Indicador.respostaInstituicao.mediaRespostaArrendCoord)} <br />
                            {renderMedia(props.Indicador.respostaGeral.mediaRespostaArrendCoord)}
                        </div>;
                }
                else {
                    contentsCoord =
                        <div>
                            <VisibilityOffIcon color="primary" fontSize="small" /> <br />
                            {renderMedia(props.Indicador.respostaGeral.mediaRespostaArrendCoord)}
                        </div>;
                }
            }
            if (props.Indicador.respostaGeral.nroRespostaDocente > 0) {
                if (props.Indicador.respostaInstituicao.exibirDocente) {
                    contentsDocente =
                        <div>
                            {renderMedia(props.Indicador.respostaInstituicao.mediaRespostaArrendDocente)} <br />
                            {renderMedia(props.Indicador.respostaGeral.mediaRespostaArrendDocente)}
                        </div>
                }
                else {
                    contentsDocente =
                        <div>
                            <VisibilityOffIcon color="primary" fontSize="small" /> <br />
                            {renderMedia(props.Indicador.respostaGeral.mediaRespostaArrendDocente)}
                        </div>
                }
            }
            if (props.Indicador.respostaGeral.nroRespostaDiscente > 0) {
                if (props.Indicador.respostaInstituicao.exibirDiscente) {
                    contentsDiscente =
                        <div>
                            {renderMedia(props.Indicador.respostaInstituicao.mediaRespostaArrendDiscente)} <br />
                            {renderMedia(props.Indicador.respostaGeral.mediaRespostaArrendDiscente)}
                        </div>
                }
                else {
                    contentsDiscente =
                        <div>
                            <VisibilityOffIcon color="primary" fontSize="small" /> <br />
                            {renderMedia(props.Indicador.respostaGeral.mediaRespostaArrendDiscente)}
                        </div>
                }
            }
            if (props.Indicador.respostaGeral.nroRespostaEgresso > 0) {
                if (props.Indicador.respostaInstituicao.exibirEgresso) {
                    contentsEgresso =
                        <div>
                            {renderMedia(props.Indicador.respostaInstituicao.mediaRespostaArrendEgresso)} <br />
                            {renderMedia(props.Indicador.respostaGeral.mediaRespostaArrendEgresso)}
                        </div>
                }
                else {
                    contentsEgresso =
                        <div>
                            <VisibilityOffIcon color="primary" fontSize="small" /> <br />
                            {renderMedia(props.Indicador.respostaGeral.mediaRespostaArrendEgresso)}
                        </div>
                }
            }
            if (props.Indicador.respostaGeral.nroRespostaTotal > 0) {
                if (props.Indicador.respostaInstituicao.exibirTotal) {
                    contentsTotal =
                        <div>
                            {renderMedia(props.Indicador.respostaInstituicao.mediaRespostaArrendTotal)} <br />
                            {renderMedia(props.Indicador.respostaGeral.mediaRespostaArrendTotal)}
                        </div>;
                }
                else {
                    contentsTotal =
                        <div>
                            <VisibilityOffIcon color="primary" fontSize="small" /> <br />
                            {renderMedia(props.Indicador.respostaGeral.mediaRespostaArrendTotal)}
                        </div>;
                }
            }
        }
        else {
            contentsAmostra =
                <div>
                    <div style={{ paddingTop: '0px' }}>
                        <b>Média <span style={{ color: '#F50057' }}>Nacional</span> :</b>
                    </div>
                </div>;

            if (props.Indicador.respostaGeral.nroRespostaCoord > 0) {
                contentsCoord = renderMedia(props.Indicador.respostaGeral.mediaRespostaArrendCoord);
            }
            if (props.Indicador.respostaGeral.nroRespostaDocente > 0) {
                contentsDocente = renderMedia(props.Indicador.respostaGeral.mediaRespostaArrendDocente);
            }
            if (props.Indicador.respostaGeral.nroRespostaDiscente > 0) {
                contentsDiscente = renderMedia(props.Indicador.respostaGeral.mediaRespostaArrendDiscente);
            }
            if (props.Indicador.respostaGeral.nroRespostaEgresso > 0) {
                contentsEgresso = renderMedia(props.Indicador.respostaGeral.mediaRespostaArrendEgresso);
            }
            if (props.Indicador.respostaGeral.nroRespostaTotal > 0) {
                contentsTotal = renderMedia(props.Indicador.respostaGeral.mediaRespostaArrendTotal);
            }            
        }
        if (props.Indicador.respostaGeral.nroRespostaTotal === 0) {
            return (<span></span>);
        }  
        return (
            <Container style={{ margin: '0px' }} >
                <Row>
                    <Col sm={2} style={{ textAlign: 'left', padding: '0px' }}>
                        <br />
                        {contentsAmostra}
                    </Col>
                    <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                        <b>Total</b><br />
                        {contentsTotal}
                    </Col>
                    <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                        <b>Coord. Local</b><br />
                        {contentsCoord}
                    </Col>
                    <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                        <b>Docentes</b><br />
                        {contentsDocente}
                    </Col>
                    <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                        <b>Discentes</b><br />
                        {contentsDiscente}
                    </Col>
                    <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                        <b>Egressos</b><br />
                        {contentsEgresso}
                    </Col>
                </Row>
            </Container>
        );
    }
        
    function renderMedia(pValor) {
        let contents = <p></p>;
        if (parseInt(props.Indicador.tipoExibicao) === 1) { //IconeEstrela
            contents = <Rating name={"IndTotal" + props.Indicador.perguntaID + "_" + props.QualInstituicao} readOnly
                        value={pValor} precision={0.5} 
                        icon={<StarIcon fontSize="small" />} />
        }
        if (parseInt(props.Indicador.tipoExibicao) === 2) { //IconeSentimentos
            contents = <Rating name={"IndTotal" + props.Indicador.perguntaID + "_" + props.QualInstituicao} readOnly
                        value={pValor} precision={0.5}
                        IconContainerComponent={IconContainerConcordancia} />
        }
        if (parseInt(props.Indicador.tipoExibicao) === 6) { //GrauImportancia
            contents = <StyledRatingCoracao name={"IndTotal" + props.Indicador.perguntaID + "_" + props.QualInstituicao} readOnly
                        value={pValor} precision={0.5}
                        icon={<FavoriteIcon fontSize="small" />} />
        }
        return (contents);

    }

    return (
        renderAll()
    );

}