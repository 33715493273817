import React, { useContext, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import SelecInstituicoes from '../../components/SelecInstituicoes/SelecInstituicoes';
//import AtualizacaoLattes from '../../components/AtualizacaoLattes/AtualizacaoLattes';
//import Titularidade from '../../components/Titularidade/Titularidade';
import Pesquisadores from '../../components/Pesquisadores/Pesquisadores';

import IdiomaContext from '../../context/idiomaContext/idiomaContext';
import InfoContext from '../../context/infoContext/infoContext';

export function ListaPesquisadores(props) {

    const idCt = useContext(IdiomaContext);
    const infoContext = useContext(InfoContext);

    let QualInstituicaoInicial = {
        id: 0,
        nome: "IFTM",
        sigla: "IFTM",
        quantidadeProfessores: 0,
        quantidadeProfessoresLattes: 0
    }
    if ((infoContext.userInfo != null) && (infoContext.userInfo.instituicao != null)){
        QualInstituicaoInicial = infoContext.userInfo.instituicao;
    }
    const [QualInstituicao, setQualInstituicao] = useState(QualInstituicaoInicial);

    return (
        <Container >
            <Row>
                <Col sm={6}>
                    <h2>{idCt.t("Consulta de Docentes", "Search for professors")}</h2>
                    <PainelConteudo legenda={idCt.t("Pesquisar", "Search")}
                        texto={
                            <Row>
                                <Col sm={12}>
                                    {idCt.t("Escolha a Instituição:", "Choose the Institution:")}
                                    <SelecInstituicoes InstituicaoSelecionada={QualInstituicaoInicial.id} AtualizaQualInstituicao={setQualInstituicao} />
                                </Col>
                            </Row>                            
                        } />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <br />
                    <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{QualInstituicao.nome}</span><br />
                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{idCt.t("Número de docentes:", "Number of professors:")} {QualInstituicao.quantidadeProfessores}</span><br />
                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{idCt.t("Número de docentes com Lattes:", "Number of professors with Lattes:")} {QualInstituicao.quantidadeProfessoresLattes}</span><br />
                </Col>
            </Row>         
            <Row>
                <Col sm={12}>
                    <PainelConteudo legenda={idCt.t("Docentes", "Professors")}
                        texto={
                            <Row>
                                <Col sm={12}>
                                    <Pesquisadores                                        
                                        QualInstituicao={QualInstituicao.id} />
                                </Col>
                            </Row>
                        } />                    
                </Col>
            </Row>
        </Container>
    );
}
