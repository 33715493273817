import React, { Component } from 'react';
import { Form } from 'react-bootstrap'; ///lib/FormControl';

export default class SelecDisciplina extends Component {    

    constructor(props) {
        super(props);
        this.state = {
            Disciplinas: [],
            loading: true,
            error: null
        };
    }

    componentDidMount() {
        let StringFetch = 'api/Disciplinas/QuaisDisciplinas';
        if (this.props.ComTodas) {
            StringFetch += '?ComTodas=' + this.props.ComTodas;
        }
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ Disciplinas: data, loading: false });
                if (!this.props.DisciplinaSelecionada) {
                    this.props.AtualizaQualDisciplina(this.state.Disciplinas[0]);
                }
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    AlteraDisciplina(pValor) {
        let a = this.state.Disciplinas;
        for (let i = 0; i < a.length; i++) {
            if (a[i].id == pValor) {     
                /*
                if (this.props.DisciplinaSelecionada) { 
                    this.props.DisciplinaSelecionada = a[i].id;
                } 
                */
                this.props.AtualizaQualDisciplina(a[i]);
            }
        }        
    }

    renderSelectDisciplinas() {
        let contentPlaceHolder = null;
        if ((this.props.ComTodas) && (this.props.ComTodas > 0)) {
            //contentPlaceHolder = <label htmlFor="in">Selecione a disciplina</label>;            
        }
        return (
            <div>
                <span className="p-float-label">
                    <Form.Control as="select"
                        placeholder="Selecione a disciplina"
                        onChange={(e) => this.AlteraDisciplina(e.target.value)} >
                        {
                            this.state.Disciplinas.map(disciplina =>
                                (this.props.DisciplinaSelecionada && this.props.DisciplinaSelecionada === disciplina.id) ?
                                    <option selected value={disciplina.id} key={disciplina.id}> {disciplina.nome} </option> :
                                    <option value={disciplina.id} key={disciplina.id}> {disciplina.nome} </option>)
                        }
                    </Form.Control>
                    {contentPlaceHolder}
                </span>
            </div>       
        );
    }
    render() {
        const { loading, error } = this.state;
        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents =  <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                        </div>;
        }
        else {
            contents = this.renderSelectDisciplinas();
        }

        return (
            <div>
                {contents}
            </div>
        );

    }
}