import React, { useContext, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import Secretarios from '../../components/Secretarios/Secretarios';

import IdiomaContext from '../../context/idiomaContext/idiomaContext';
import InfoContext from '../../context/infoContext/infoContext';

export function ListaSecretarios(props) {

    const idCt = useContext(IdiomaContext);
    const infoContext = useContext(InfoContext);

    let TipoEdicao = "Nao";
    if ((infoContext.user != null) && ((infoContext.user.profile.admin) || (infoContext.user.profile.coordenadorNacional) || (infoContext.user.profile.coordenadorCurso))) {
        TipoEdicao = "Sim";
    }

    let QualInstituicaoInicial = {
        id: 0,
        nome: "IFTM",
        sigla: "IFTM",
        quantidadeProfessores: 0,
        quantidadeProfessoresLattes: 0
    }
    if (infoContext.userInfo != null) {
        QualInstituicaoInicial = infoContext.userInfo.instituicao;
    }

    return (
        <Container >       
            <Row>
                <Col sm={12}>
                    <h2>{idCt.t("Consulta de Secretários das Instituições Associadas", "Search for Secretaries of Associated Institutions")}</h2>
                    <PainelConteudo legenda={idCt.t("Secretários", "Secretaries")}
                        texto={
                            <Row>
                                <Col sm={12}>
                                    <Secretarios TipoEdicao={TipoEdicao} QualInstituicaoUsr={QualInstituicaoInicial}/>
                                </Col>
                            </Row>
                        } />                    
                </Col>
            </Row>
        </Container>
    );
}
