import React, { useState } from 'react';
import InfoContext from './infoContext';

export default function InfoContextProvider(props) {

    const [user, setUser] = useState(null);   
    const [userInfo, setUserInfo] = useState(null);

    function NAPE() {
        if (user == null) return false;        
        if (!user.profile.professor) return false;
        if (user.name.toUpperCase().trim() === 'ERNANI VIRIATO DE MELO') return true;
        if (user.name.toUpperCase().trim() === 'CLARICE MONTEIRO ESCOTT') return true;
        if (user.name.toUpperCase().trim() === 'DANIELLE PIONTKOVSKY') return true;
        if (user.name.toUpperCase().trim() === 'JOSINA MARIA PONTES RIBEIRO') return true;
        if (user.name.toUpperCase().trim() === 'MARIA CRISTINA CAMINHA DE CASTILHOS FRANCA') return true;
        if (user.name.toUpperCase().trim() === 'PAULO SERGIO CALEFI') return true;
        if (user.name.toUpperCase().trim() === 'INGE RENATE FROSE SUHR') return true;
        if (user.name.toUpperCase().trim() === 'WANDERLEY AZEVEDO DE BRITO') return true;
        return false;
    }

    return (
        <InfoContext.Provider
            value={{
                user: user,
                setUser: setUser,
                userInfo: userInfo,
                setUserInfo: setUserInfo,
                NAPE: NAPE
            }}
        >
            {props.children}
        </InfoContext.Provider>
    )
    
}

