import React, { useEffect, useState, useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'

import MessageContext from '../../context/messageContext/messageContext';

export default function AuthorizeRoute(props) {

    //Constructor
    const messageContext = useContext(MessageContext);
    const [ready, setReady] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        //componentDidMount
        const _subscription = authService.subscribe(() => authenticationChanged());
        populateAuthenticationState();
        //componentWillUnmount
        return () => { authService.unsubscribe(_subscription); }
    }, []);    

    function returnRender() {
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`
        if (!ready) {
            return <div></div>;
        } else {
            const { component: Component, ...rest } = props;
            return <Route {...rest}
                render={(AllProps) => {
                    if (authenticated) {
                        return <Component {...AllProps} />
                    } else {
                        messageContext.openMessage("warning", "Para acessar este conteúdo, você deve estar logado.");
                        //return <Redirect to={redirectUrl} />
                        return <Redirect to={'/primeiroAcesso'} /> //acima redireciona onde solicitou.
                    }
                }} />
        }
    }

    async function populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        setReady(true);
        setAuthenticated(authenticated);
    }

    async function authenticationChanged() {
        setReady(false);
        setAuthenticated(false);
        await populateAuthenticationState();
    }

    return (
        <div>{returnRender()}</div>
    );

}
