import React, { Component } from 'react';
import { AutoComplete } from 'primereact/autocomplete';

export default class SelecAssunto extends Component {    

    constructor(props) {
        super(props);
        this.state = {
            assuntoSuggestions: null,
            loading: false, //true
            error: null
        };
        this.Assuntos = null;
    }
        
    componentDidMount() {
        fetch('api/Indicadores/QuaisAssuntos')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.Assuntos = data;
                this.setState({ loading: false });
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    suggestAssuntos(event) {
        let results = this.Assuntos.filter((Assunto) => {
            return Assunto.toLowerCase().startsWith(event.query.toLowerCase());
        });

        this.setState({ assuntoSuggestions: results });
    }

    AlteraAssuntos(pValor) {
        this.setState({ Assuntos: pValor });
        this.props.AtualizaQualAssunto(pValor);
    }

    itemTemplate(brand) {
        //<span style={{width: '200px'}}>{brand}<br/></span>
        return (
           <div style={{width: '200px', fontSize: '14px', margin: '0 0 0 0', padding: '0px' }}><span>{brand}</span></div>     
        );
    }

    renderAssuntos() {
        return (
            <div>
                <AutoComplete multiple={true} value={this.state.Assuntos} onChange={(e) => this.AlteraAssuntos(e.value)}
                    suggestions={this.state.assuntoSuggestions} completeMethod={this.suggestAssuntos.bind(this)} 
                    itemTemplate={this.itemTemplate.bind(this)} minLength={2} />
            </div>       
        );
    }
    render() {
        const { loading, error } = this.state;
        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents = <div className="overlay">
                         <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                       </div>;
        }
        else {
            contents = this.renderAssuntos();
        }

        return (
            <div>
                {contents}
            </div>
        );

    }
}