import React, { useState, useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ModalMensagem from '../../components/ModalMensagem/ModalMensagem';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import MapaCidades from '../../components/MapaCidades/MapaCidades';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';

import LoadingContext from '../../context/loadingContext/loadingContext';
import MessageContext from '../../context/messageContext/messageContext';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';

export function Instituicoes(props) {

    //Contexto
    const loadingContext = useContext(LoadingContext);
    const messageContext = useContext(MessageContext);
    const idCt = useContext(IdiomaContext);

    //Construtor
    const [infoCidades, setInfoCidades] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [exibeMsg, setExibeMsg] = useState(false);
    const [cidadeSelecionada, setCidadeSelecionada] = useState(null);
    const [error, setError] = useState(null);    

    useEffect(() => {
        //componentDidMount e componentDidUpdate
        loadingContext.showLoading();        
        CarregarDados();        
        loadingContext.hideLoading();
    }, []); 

    function CarregarDados() {
        setGlobalFilter(null);    
        let StringFetch = 'api/Instituicoes/InstituicoesCidades';
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                setInfoCidades(data);                
            })
            .catch(error => {
                messageContext.openMessage("error", "Erro.Por favor, tente novamente.");
                setError(error);
            });
    }

    function MarcouInstituicao(qCid, qUF, qAlunos) {
        setCidadeSelecionada({
            cidade: qCid + "-" + qUF,
            alunos: qAlunos
        });
        setExibeMsg(true);
    }

    function AbrirSite(qLink) {
        window.open(qLink);
    }

    function ColunaSite(rowData, column) {
        if (!rowData.link) {
            return (<div></div>);
        }
        return (
            <div style={{ display: 'inline-block', margin: '0px', padding: '0px' }}>
                <IconButton color="secondary" aria-label="Website" style={{ verticalAlign: "top", padding: '5px' }}
                    onClick={() => AbrirSite(rowData.link)}>
                    <Icon>language</Icon>
                </IconButton>
            </div>
        );
    }
        
    function renderInstituicoes() {        
        var header = <div style={{ 'textAlign': 'left' }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar" size="20" />
                     </div>;
        return (
            <div>
                <div className="content-section implementation">
                    <DataTable value={infoCidades.instituicoes} paginator={true} rows={10} rowsPerPageOptions={[10, 30, 50]}
                        resizableColumns={true} columnResizeMode="fit"
                        globalFilter={globalFilter} header={header}
                    >                        
                        <Column field="nome" header={idCt.t("Nome", "Name")} sortable={true} /> 
                        <Column field="sigla" header={idCt.t("Sigla", "Abbr")} sortable={true} style={{ textAlign: 'center', width: '7em' }} /> 
                        <Column field="cidade" header={idCt.t("Cidade", "City")} sortable={true} /> 
                        <Column field="estado" header={idCt.t("UF", "St")} sortable={true} style={{ textAlign: 'center', width: '5em' }} />
                        <Column field="site" header={idCt.t("Site", "Site")} body={ColunaSite}  sortable={false} style={{ textAlign: 'center', width: '6em' }} /> 
                    </DataTable>
                </div>
            </div>
        );
    }

    function renderDetalhes() {
        if ((cidadeSelecionada === null) || (setExibeMsg === false)) {
            return <div></div>;
        }
        return (
            <div>
                <ModalMensagem updateShow={setExibeMsg}
                    show={exibeMsg} titulo={cidadeSelecionada.cidade}
                    mensagem=
                    {<div style={{minWidth: '300px'}}>
                        {cidadeSelecionada.alunos.map((item, i) => {
                            return (<p>{item}</p>)
                        })}
                    </div>
                    }
                />
            </div>
        );
    }
    
    function renderAll() {

        let contents = <p></p>;
        if (loadingContext.loading) {
            return (
                <div>
                    <p><i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em></p>
                </div>
            );
        }
        else if (infoCidades == null) {
            return (
                <div>
                    <p><em>{idCt.t("Nenhuma instituição encontrada.", "No institution found.")}</em></p>
                </div>
            );
        }
        else {
            contents = renderInstituicoes();
        }        
        return (
            <Container >
                <Row>
                    <Col sm={12}>
                        <h4>{idCt.t("Instituições Associadas", "Associated Institutions")}</h4>
                        {renderDetalhes()}
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <PainelConteudo legenda={idCt.t("Instituições / Mapa", "Institutions / Map")}
                            texto={
                                <Row>
                                    <Col sm={7}>
                                        {contents}
                                    </Col>
                                    <Col sm={5} style={{minHeight: '400px', minWidth: '300px'}}>
                                        <MapaCidades data={infoCidades.cidades} onClick={MarcouInstituicao} Zoom={2.7} />
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
            </Container>
        );

    }

    return (
        renderAll()
    );

}