import React, { useContext, useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { Alert, AlertTitle } from '@material-ui/lab';

import LoadingContext from '../../context/loadingContext/loadingContext';
import InfoContext from '../../context/infoContext/infoContext';
import MessageContext from '../../context/messageContext/messageContext';

import { AvalPergunta } from './AvalPergunta';
import { AvalDiscObrigatoria } from './AvalDiscObrigatoria';
import { AvalDiscEletiva } from './AvalDiscEletiva';

import autoavaliacaoService from '../../services/autoavaliacaoService';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export function AvalResponderQuestionario(props) {

    //Parametros
    const classes = useStyles();

    //Contexto
    const loadingContext = useContext(LoadingContext);
    const infoContext = useContext(InfoContext);
    const messageContext = useContext(MessageContext);

    //Constructor
    const [dadosFicha, setDadosFicha] = useState(null);
    const [dadosAutoAvalFicha, setDadosAutoAvalFicha] = useState(null);
    const [AcessoNegado, setAcessoNegado] = useState(true);
    const [error, setError] = useState(null);
    const [etapa, setEtapa] = useState(0); 
    const [novasRespostas, setNovasRespostas] = useState([]);

    useEffect(() => {
        //componentDidMount
        if (infoContext.user != null) {
            setAcessoNegado(false);
            loadingContext.showLoading();
            lerDados();
            loadingContext.hideLoading();
        }
        else {
            messageContext.openMessage("warning", "Somente docentes e discentes tem acesso a este conteúdo.");
        }
    }, []); 

    function lerDados() {
        autoavaliacaoService.GetFichaAval(props.TipoAvaliador)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(DadosResposta => { 
                setDadosAutoAvalFicha(DadosResposta);
                setDadosFicha(DadosResposta.avalFicha);
                atualizaNovasRespostas(DadosResposta.avalFicha);
                setEtapa(DadosResposta.aplicacaoIntrumentoEtapa);
            })
            .catch(error => {
                messageContext.openMessage("error", "Erro. Por favor, tente novamente.");
                setError(error);
            });
    }

    function atualizaNovasRespostas(dados){
        let tempResp = [];
        for (let s = 0; s < dados.secoes.length; s++) {
            for (let p = 0; p < dados.secoes[s].perguntas.length; p++) {
                let item = dados.secoes[s].perguntas[p];
                if ((item.respostas != null) && (item.respostas.length > 0)) {                    
                    for (let r = 0; r < item.respostas.length; r++) {
                        //Procurar item
                        let achou = -1;
                        for (let pd = 0; pd < tempResp.length; pd++) {
                            if ((item.respostas[r].disciplinaObrigatoriaID) && (item.respostas[r].disciplinaObrigatoriaID > 0)) {
                                if ((tempResp[pd].id == item.id) && (tempResp[pd].disciplinaOb == parseInt(item.respostas[r].disciplinaObrigatoriaID))) {
                                    achou = pd;
                                }
                            }
                            else if ((item.respostas[r].disciplinaEletivaID) && (item.respostas[r].disciplinaEletivaID > 0)) {
                                if ((tempResp[pd].id == item.id) && (tempResp[pd].disciplinaEletiva == parseInt(item.respostas[r].disciplinaEletivaID))) {
                                    achou = pd;
                                }
                            }
                            else {
                                if ((tempResp[pd].id == item.id) && (tempResp[pd].disciplinaOb == 0) && (tempResp[pd].disciplinaEletiva == 0)) {
                                    achou = pd;
                                }
                            }
                        }
                        //Inserir item ou atualizar item
                        if (achou == -1) {
                            let tempRespItens = [];
                            tempRespItens.push({
                                valor: parseInt(item.respostas[r].valor),
                                valorAberta: item.respostas[r].valorAberta
                            });
                            tempResp.push({
                                id: item.id,
                                tipo: parseInt(item.tipoExibicao),
                                step: s + 1,
                                alterou: false,
                                disciplinaOb: (item.respostas[r].disciplinaObrigatoriaID === null) ? 0 : parseInt(item.respostas[r].disciplinaObrigatoriaID),
                                disciplinaObProf: (item.respostas[r].professorID === null) ? 0 : parseInt(item.respostas[r].professorID),
                                disciplinaEletiva: (item.respostas[r].disciplinaEletivaID === null) ? 0 : parseInt(item.respostas[r].disciplinaEletivaID),
                                respostas: tempRespItens
                            })
                        }
                        else {
                            tempResp[achou].respostas.push({
                                valor: parseInt(item.respostas[r].valor),
                                valorAberta: item.respostas[r].valorAberta
                            });
                        }                        
                    }                    
                }
            }
        }
        setNovasRespostas(tempResp);
    }

    function getRespostas(id) {
        for (let i = 0; i < novasRespostas.length; i++) {
            if (novasRespostas[i].id === id) {
                return novasRespostas[i].respostas;
            }
        }
        return null;
    }

    function respondeuPergunta(perguntaID, tipoExibicao, etapa, listaRespostas, index) {
        let tempResp = novasRespostas;
        let achou = false;
        for (let i = 0; i < novasRespostas.length; i++) {
            if (novasRespostas[i].id === perguntaID) {
                novasRespostas[i].respostas = listaRespostas;
                novasRespostas[i].alterou = true;
                i = novasRespostas.length;
                achou = true;
            }
        }
        if (!achou) {
            tempResp.push({
                id: perguntaID,
                tipo: tipoExibicao,
                step: etapa,
                alterou: true,
                disciplinaOb: 0,
                disciplinaObProf: 0,
                disciplinaEletiva: 0,
                respostas: listaRespostas
            })
        }
        setNovasRespostas(tempResp);
    }

    function respondeuPerguntaDiscOb(perguntaID, tipoExibicao, etapa, listaRespostas, discOb, discObProf) {
        let tempResp = novasRespostas;
        let achou = false;
        for (let i = 0; i < novasRespostas.length; i++) {
            if ((novasRespostas[i].id === perguntaID) && (novasRespostas[i].disciplinaOb === discOb)) {
                novasRespostas[i].respostas = listaRespostas;
                novasRespostas[i].disciplinaObProf = discObProf;
                novasRespostas[i].alterou = true;
                i = novasRespostas.length;
                achou = true;
            }
        }
        if (!achou) {
            tempResp.push({
                id: perguntaID,
                tipo: tipoExibicao,
                step: etapa,
                alterou: true,
                disciplinaOb: discOb,
                disciplinaObProf: discObProf,
                disciplinaEletiva: 0,
                respostas: listaRespostas
            })
        }
        setNovasRespostas(tempResp);
    }

    function respondeuPerguntaDiscEletiva(perguntaID, tipoExibicao, etapa, listaRespostas, discEletiva) {
        let tempResp = novasRespostas;
        let achou = false;
        for (let i = 0; i < novasRespostas.length; i++) {
            if ((novasRespostas[i].id === perguntaID) && (novasRespostas[i].disciplinaEletiva === discEletiva)) {
                novasRespostas[i].respostas = listaRespostas;
                novasRespostas[i].alterou = true;
                i = novasRespostas.length;
                achou = true;
            }
        }
        if (!achou) {
            tempResp.push({
                id: perguntaID,
                tipo: tipoExibicao,
                step: etapa,
                alterou: true,
                disciplinaOb: 0,
                disciplinaObProf: 0,
                disciplinaEletiva: discEletiva,
                respostas: listaRespostas
            })
        }
        setNovasRespostas(tempResp);
    }

    function pendenciaPerguntas(step) {
        for (let i = 0; i < dadosFicha.secoes[step].perguntas.length; i++) {            
            if ((dadosFicha.secoes[step].perguntas[i].tipoExibicao !== 4) && //Aberta
                (dadosFicha.secoes[step].perguntas[i].tipoExibicao !== 5) // Checkbox
            ) {
                let achou = false;
                for (let j = 0; j < novasRespostas.length; j++) {
                    if ((novasRespostas[j].id === dadosFicha.secoes[step].perguntas[i].id) &&
                        (novasRespostas[j].respostas != null) && (novasRespostas[j].respostas.length > 0)) {
                        achou = true;
                    }
                }
                if (!achou) {
                    return true;
                }
            }
        }
        return false;
    }

    function handleNext(step) {
        if ((dadosFicha.secoes[step].nome !== "Disciplina Obrigatória") &&
            (dadosFicha.secoes[step].nome !== "Disciplina Eletiva")){
            if (pendenciaPerguntas(step)) {
                messageContext.openMessage("warning", "Você deve responder todas as perguntas.");
                return;
            }
        }
        //Atualizar so a etapa
        let pBodyRespostas = [];
        for (let i = 0; i < novasRespostas.length; i++) {
            if ((novasRespostas[i].step === step + 1) && (novasRespostas[i].alterou)) {
                pBodyRespostas.push({
                    id: novasRespostas[i].id,
                    tipoExibicao: parseInt(novasRespostas[i].tipo),
                    disciplinaOb: novasRespostas[i].disciplinaOb,
                    disciplinaObProf: novasRespostas[i].disciplinaObProf,
                    disciplinaEletiva: novasRespostas[i].disciplinaEletiva,
                    respostas: novasRespostas[i].respostas
                })
            }
        }
        if (pBodyRespostas.length > 0) {
            let pBody = JSON.stringify({
                autoAvaliacaoID: dadosAutoAvalFicha.autoAvaliacaoID,
                autoAvaliacaoFichaID: dadosAutoAvalFicha.id,
                aplicacaoInstrumentoInicio: dadosAutoAvalFicha.aplicacaoInstrumentoInicio,
                avalFichaSecaoID: dadosFicha.secoes[step].id,
                tipoAvaliador: dadosFicha.tipoAvaliador,
                finalizou: (step === dadosFicha.secoes.length - 1),
                etapa: parseInt(step + 1),
                perguntasRespostas: pBodyRespostas
            });
            autoavaliacaoService.putResposta(pBody)
                .then(r => {
                    r.json().then(response => {
                        if (response.statusCode) {
                            if ((response.statusVariant === "error") || (response.statusVariant === "warning")) {
                                messageContext.openMessage(response.statusVariant, response.statusDescription);
                            }
                            else {
                                //sucesso ir para proxima etapa
                                setEtapa((prevActiveStep) => prevActiveStep + 1);
                                let tempResp = novasRespostas;
                                for (let i = 0; i < novasRespostas.length; i++) {
                                    if (novasRespostas[i].step === step + 1) {
                                        novasRespostas[i].alterou = false;
                                    }
                                }
                                setNovasRespostas(tempResp);
                            }
                        }
                    });
                })
                .catch(error => {
                    messageContext.openMessage("error", "Erro. Por favor, tente novamente.");
                    setError(error);
                });
        }
        else {
            setEtapa((prevActiveStep) => prevActiveStep + 1);
        }
    }

    const handleBack = () => {
        setEtapa((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setEtapa(0);
    };

    function getStepContent(step) {
        if (dadosFicha.secoes[step].nome === "Disciplina Obrigatória") {
            return (
                <AvalDiscObrigatoria
                    AutoAvaliacaoID={dadosAutoAvalFicha.autoAvaliacaoID}
                    perguntas={dadosFicha.secoes[step].perguntas}
                    step={step}
                    responder={respondeuPerguntaDiscOb}
                    respostas={novasRespostas}
                />);
        }
        if (dadosFicha.secoes[step].nome === "Disciplina Eletiva") {
            return (
                <AvalDiscEletiva
                    AutoAvaliacaoID={dadosAutoAvalFicha.autoAvaliacaoID}
                    perguntas={dadosFicha.secoes[step].perguntas}
                    step={step}
                    responder={respondeuPerguntaDiscEletiva}
                    respostas={novasRespostas}
                />);
        }
        return (
            dadosFicha.secoes[step].perguntas.map((item, index) => (
                <AvalPergunta etapa={step + 1} nro={index + 1} pergunta={item} responder={respondeuPergunta} respostas={getRespostas(item.id)} />
            ))
        );
    }

    function renderAll() {
        if (AcessoNegado) {
            return (<p>Acesso Negado. Somente docentes e discentes tem acesso a este conteúdo.</p>);
        }
        else if (error != null) {
            return (<p>Erro. Por favor, tente novamente.</p>);
        }
        else if (dadosFicha == null) {
            return (<p><i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em></p>);
        }
        let contentsAviso =
            <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                <Alert severity="warning">
                    <AlertTitle>Aviso</AlertTitle>
                    Você não será identificado e suas respostas são privadas.<br/>
                    Em caso de perda de conexão com a internet, você pode interromper a autoavaliação e recomeçar em outro dia.
                </Alert>
            </div>
        let contents =
            <div className={classes.root}>
                <Stepper activeStep={etapa} orientation="vertical">
                    {dadosFicha.secoes.map((item, index) => (
                        <Step key={item.nome}>
                            <StepLabel>
                                <Chip label={item.nome} />
                            </StepLabel>
                            <StepContent>
                                <Typography>{getStepContent(index)}</Typography>
                                <div className={classes.actionsContainer}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            disabled={etapa === 0}
                                            onClick={handleBack}
                                            className={classes.button}
                                        >
                                            Voltar
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleNext(index)}
                                            className={classes.button}
                                        >
                                            {etapa === dadosFicha.secoes.length - 1 ? 'Salvar e Finalizar' : 'Salvar e Continuar'}
                                        </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                {etapa === dadosFicha.secoes.length && (
                    <Paper square elevation={0} className={classes.resetContainer}>
                        <Typography>
                            <Alert severity="success">
                                <AlertTitle>Autoavaliação realizada com sucesso</AlertTitle>
                                Muito obrigado. Você finalizou o questionário da autoavaliação.<br />
                                A sua contribuição é muito importante para o Programa.<br />
                                Os resultados da autoavaliação serão divulgados a partir de {dadosAutoAvalFicha.divulgacaoResultados} aqui no Observatório ProfEPT.
                            </Alert><br />
                        </Typography>
                        <Button onClick={handleReset} className={classes.button} variant="contained" color="secondary">
                            Cancelar e fazer novamente
                        </Button>
                    </Paper>
                )}
            </div>;

        return (
            <div>
                {contentsAviso}
                {contents}
            </div>
        );
    }

    return (
        renderAll()
        );
}
