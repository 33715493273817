import React, { useState, useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './Pesquisadores.css';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { Button } from 'primereact/button';
import ModalMensagem from '../ModalMensagem/ModalMensagem';
import SelecInstituicoes from '../SelecInstituicoes/SelecInstituicoes';

import LoadingContext from '../../context/loadingContext/loadingContext';
import MessageContext from '../../context/messageContext/messageContext';
import InfoContext from '../../context/infoContext/infoContext';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';

import Workbook from 'react-excel-workbook';

export default function Pesquisadores(props) {

    //Contexto
    const loadingContext = useContext(LoadingContext);
    const messageContext = useContext(MessageContext);
    const infoContext = useContext(InfoContext);
    const idCt = useContext(IdiomaContext);

    //Construtor
    const [Professores, setProfessores] = useState([]);
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [dataDescredenciamento, setDataDescredenciamento] = useState("");
    const [dataCredenciamento, setDataCredenciamento] = useState("");
    const [lattes, setLattes] = useState("");
    const [idLattes, setIdLattes] = useState("");
    const [ExibeEditarProf, setExibeEditarProf] = useState(false);
    const [ExibeInsereProfessor, setExibeInsereProfessor] = useState(false);
    const [ProfSelecionado, setProfSelecionado] = useState(null);
    const [QualInstituicaoId, setQualInstituicaoId] = useState(props.QualInstituicao);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);
    const [error, setError] = useState(null);    

    useEffect(() => {
        //componentDidMount e componentDidUpdate
        loadingContext.showLoading();        
        CarregarDados();        
        setQualInstituicaoId(props.QualInstituicao);
        loadingContext.hideLoading();
    }, [props.QualInstituicao]); 

    const updateQualInstituicao = (QualInstituicao) => { setQualInstituicaoId(QualInstituicao.id) };

    function ColunaFoto(rowData, column) {
        return <div>
                    <img src={rowData.urlFoto} className="prof-imagem-redondo" />
               </div>;
    }

    function ColunaFotoNome(rowData, column) {
        return <div>
                    <img src={rowData.urlFoto} className="prof-imagem-redondo" />&nbsp;{rowData.nome}
               </div>;
    }

    function ColunaEditar(rowData, column) {
        let contentsLattes = <div style={{ display: 'inline-block' }}></div>;
        let contentsEditar = <div style={{ display: 'inline-block' }}></div>;

        if (rowData.lattesEndereco) {
            contentsLattes= <div style={{ display: 'inline-block' }}>
                                <IconButton size="small" color="primary" aria-label="Lattes" style={{ verticalAlign: "top", padding: "6px" }}
                                    onClick={() => abrirJanela(rowData.lattesEndereco)}>
                                    <Icon fontSize="small">
                                        <img src="images/lattes2.png" alt="Lattes" style={{ verticalAlign: "top", width: "100%", height: "100%", margin: "0px", padding: "0px" }} />
                                    </Icon>
                                </IconButton>
                            </div>;
        }

        let PodeEditar = false;
        if (infoContext.user != null) {
            if ((infoContext.user.profile.admin) || (infoContext.user.profile.coordenadorNacional)) {
                PodeEditar = true;
            }
            else if ((infoContext.user != null) && (infoContext.userInfo.instituicao.id === rowData.instituicaoID)) {
                PodeEditar = true;
            }
        }
        if (PodeEditar) {        
            contentsEditar =
                <div style={{ display: 'inline-block' }}>
                    <IconButton size="small" color="secondary" aria-label="Download" style={{ verticalAlign: "top", padding: "6px" }}
                        onClick={() => {setExibeEditarProf(true); setProfSelecionado(rowData); setEmail(rowData.email)}}>
                        <Icon fontSize="small">edit</Icon>
                    </IconButton>
                </div>;
        }
        return (
            <div>
                {contentsLattes}
                {contentsEditar}
            </div>
        );
    }

    function CarregarDados() {
        setGlobalFilter(null);
        setProfSelecionado(null);        
        let StringFetch = 'api/Pesquisadores/ListaPesquisadores?QualInstituicao=' + props.QualInstituicao;
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                setProfessores(data);                
            })
            .catch(error => {
                messageContext.openMessage("error", "Erro.Por favor, tente novamente.");
                setError(error);
            });
    }

    function editarProfessor() {
        setExibeEditarProf(false);
        loadingContext.showLoading();
        
        const options = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ProfessorID: ProfSelecionado.professorID,
                Nome: ProfSelecionado.nome,
                Email: email,
                SaidaInstituicao: dataDescredenciamento
            }),
            cache: 'default'
        };

        fetch('api/Pesquisadores/PutMudancaEmail', options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                loadingContext.hideLoading();
                messageContext.openMessage(data.statusVariant, data.statusDescription);
                setProfSelecionado(null);
                setEmail("");
                CarregarDados();
            })
            .catch(error => {
                loadingContext.hideLoading();
                messageContext.openMessage("error", "Erro. Por favor, tente novamente.");
                setError(error);
            });
    }

    function insereProfessor() {
        setExibeInsereProfessor(false);
        loadingContext.showLoading();        

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ProfessorID: 0,
                Nome: nome,
                Email: email,
                InstituicaoID: QualInstituicaoId,
                LattesEndereco: lattes,
                IdFotoLattes: idLattes,
                EntradaInstituicao: dataCredenciamento
            }),
            cache: 'default'
        };

        fetch('api/Pesquisadores/PostInsereProfessor', options)
            .then(r => {
                r.json().then(response => {
                    if (response.statusCode) {
                        loadingContext.hideLoading();
                        messageContext.openMessage(response.statusVariant, response.statusDescription);
                    }
                    setProfSelecionado(null);
                    setNome("");
                    setLattes("");
                    setIdLattes("");
                    setDataCredenciamento("");
                    setEmail("");
                    CarregarDados();
                });
            })
            .catch(error => {
                loadingContext.hideLoading();
                messageContext.openMessage("error", "Erro. Por favor, tente novamente.");
                setError(error);
            });
    }

    function abrirJanela(endereco) {
        if ((endereco.substring(0, 7) != 'http://') && (endereco.substring(0, 8) != 'https://')) {
            endereco = 'https://' + endereco;
        }
        window.open(endereco);
    }

    function rowExpansionMaior(data) {
        return (
            <div className="p-grid p-fluid" style={{ padding: '1em' }}>
                <div className="p-col-12 p-md-12">
                    <div className="p-grid">
                        <div className="p-md-2">{idCt.t("Mestrado", "Master's degree")}: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.mestrado}</div>

                        <div className="p-md-2">{idCt.t("Doutorado", "Doctorate degree")}: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.doutorado}</div>
                    </div>
                </div>
            </div>
        );
    }

    function rowExpansionTemplate(data) {
        return (
            <div className="p-grid p-fluid" style={{ padding: '1em' }}>
                <div className="p-col-12 p-md-12">
                    <div className="p-grid">
                        <div className="p-md-2">{idCt.t("IA", "AI")}: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.instituicao}</div>

                        <div className="p-md-2">Email: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.email}</div>

                        <div className="p-md-2">{idCt.t("Graduação", "Graduation")}: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.graduacao}</div>

                        <div className="p-md-2">{idCt.t("Mestrado", "Master's degree")}: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.mestrado}</div>

                        <div className="p-md-2">{idCt.t("Doutorado", "Doctorate degree")}: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.doutorado}</div>

                        <div className="p-md-2">{idCt.t("Data", "Date")} Lattes: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.dataAtualizacaoLattes}</div>
                    </div>
                </div>
            </div>
        );
    }

    function renderProfessoresCelular() {
        var header = <div style={{ 'textAlign': 'left' }}>
            <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar" size="20" />
        </div>;

        return (
            <div>
                <div className="content-section implementation">
                    <DataTable value={Professores} paginator={true} rows={15} rowsPerPageOptions={[5, 15, 30]}
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        globalFilter={globalFilter} header={header}
                        expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                        rowExpansionTemplate={rowExpansionTemplate}
                    >
                        <Column expander={true} style={{ width: '3em' }} />
                        <Column field="nome" header={idCt.t("Nome", "Name")} body={ColunaFotoNome} sortable={true} />
                        <Column field="editar" header="+Info" body={ColunaEditar} sortable={false} style={{ textAlign: 'center', width: '7em' }} />
                    </DataTable>
                </div>
            </div>
        );
    }

    function renderProfessoresTamanhoNormal() {
        var header = <div style={{ 'textAlign': 'left' }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar" size="50" />
                    </div>;
        if (props.QualInstituicao === 0) {
            return (
                <div>
                    <div className="content-section implementation">
                        <DataTable value={Professores} paginator={true} rows={15} rowsPerPageOptions={[5, 15, 30]}
                            resizableColumns={true} columnResizeMode="fit"
                            globalFilter={globalFilter} header={header} 
                            expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                            rowExpansionTemplate={rowExpansionMaior}
                        >
                            <Column expander={true} style={{ width: '3em' }} />
                            <Column header={idCt.t("Foto", "Photo")} body={ColunaFoto} style={{ textAlign: 'center', width: '4em' }} />
                            <Column field="nome" header={idCt.t("Nome", "Name")} sortable={true} />
                            <Column field="instituicao" header={idCt.t("IA", "AI")} sortable={true} style={{ textAlign: 'center', width: '9em' }} />
                            <Column field="email" header="Email" sortable={false} />
                            <Column field="graduacao" header={idCt.t("Graduação", "Graduation")} sortable={true} />
                            <Column field="dataAtualizacaoLattes" header={idCt.t("Data Lattes", "Date Lattes")} sortable={true} style={{ textAlign: 'center', width: '10em' }} />
                            <Column field="editar" header="+Info" body={ColunaEditar} sortable={false} style={{ textAlign: 'center', width: '7em' }} />
                        </DataTable>
                    </div>
                </div>
            );      
        }
        return (
            <div>
                <div className="content-section implementation">
                    <DataTable value={Professores} paginator={true} rows={15} rowsPerPageOptions={[5, 15, 30]}
                        resizableColumns={true} columnResizeMode="fit"
                        globalFilter={globalFilter} header={header}
                        expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                        rowExpansionTemplate={rowExpansionMaior}
                    >
                        <Column expander={true} style={{ width: '3em' }} />
                        <Column header={idCt.t("Foto", "Photo")} body={ColunaFoto} style={{ textAlign: 'center', width: '4em' }} />
                        <Column field="nome" header={idCt.t("Nome", "Name")} sortable={true} /> 
                        <Column field="email" header="Email" sortable={false} />
                        <Column field="graduacao" header={idCt.t("Graduação", "Graduation")} sortable={true} />
                        <Column field="dataAtualizacaoLattes" header={idCt.t("Data Lattes", "Date Lattes")} sortable={true} style={{ textAlign: 'center', width: '10em' }} />
                        <Column field="editar" header="+Info" body={ColunaEditar} sortable={false} style={{ textAlign: 'center', width: '7em' }} />
                    </DataTable>
                </div>
            </div>
        );
    }

    function renderEditarProf() {
        if (!ProfSelecionado) {
            return;
        }
        return (<div>
                    <ModalMensagem updateShow={setExibeEditarProf}
                        show={ExibeEditarProf} titulo="Editar Professor"
                        mensagem=
                        {<div>
                            <br />
                            <span className="p-float-label">
                                <InputText style={{ width: "100%" }} disabled id="in" value={ProfSelecionado.nome} />
                                <label htmlFor="in">Nome</label>
                            </span>
                            <br /><br />
                            <span className="p-float-label">
                                <InputText style={{ width: "100%" }} id="in" value={email} onChange={(e) => setEmail(e.target.value)} />
                                <label htmlFor="in">Email</label>
                            </span>
                            <span style={{ color: "red" }}><i>Cuidado: </i></span><i>somente emails do <b>Google</b> ou <b>Facebook</b> ou <b>Microsoft</b>.</i>
                            <br /><br />
                            <span className="p-float-label">
                                <InputText style={{ width: "100%" }} id="in" value={dataDescredenciamento} onChange={(e) => setDataDescredenciamento(e.target.value)} />
                                <label htmlFor="in">Data Descredenciamento</label>
                            </span>
                            <span style={{ color: "red" }}><i>Descredenciamento: </i></span>Caso o professor foi descredenciado, informe a data no formato <b>dd/mm/aaaa</b>. Caso contrário, deixar este campo em branco.
                            <br /><br />
                            <div style={{ textAlign: "left" }}>
                                <Button label="Salvar" onClick={() => editarProfessor()} className="p-button-raised p-button-success" />
                            </div>
                            <br />
                        </div>
                        }
                    />
                </div>
        );
    }

    function renderIncluirProf() {

        let contentInst = <SelecInstituicoes ComTodas="1" AtualizaQualInstituicao={updateQualInstituicao} />;
        if (QualInstituicaoId > 0) {            
            contentInst = <SelecInstituicoes InstituicaoSelecionada={QualInstituicaoId} ComTodas="1" AtualizaQualInstituicao={updateQualInstituicao} />;
        }

        return (
            <div>
                <ModalMensagem updateShow={setExibeInsereProfessor}
                    show={ExibeInsereProfessor} titulo="Incluir Docente"
                    mensagem=
                    {<div>
                        <span style={{ color: "red" }}><i>Atenção: </i></span>Verifique se o docente não está cadastrado antes de incluí-lo.
                        <br />
                        <span style={{ color: "red" }}><i>Atenção: </i></span>Fique atento ao formato de data e da inserção do <b>link do Lattes e do Id do Lattes</b>. Estes dados interferem na importação dos dados do Lattes e pode afetar os indicadores.
                        <br />
                        Instituição:
                        {contentInst}
                        <br />
                        <span className="p-float-label">
                            <InputText style={{ width: "100%" }} id="in" value={nome} onChange={(e) => setNome(e.target.value)} />
                            <label htmlFor="in">Nome</label>
                        </span>
                        <br />
                        <span className="p-float-label">
                            <InputText style={{ width: "100%" }} id="in" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <label htmlFor="in">Email</label>
                        </span>
                        <span style={{ color: "red" }}><i>Cuidado: </i></span><i>somente emails do <b>Google</b> ou <b>Facebook</b> ou <b>Microsoft</b>.</i>
                        <br /><br />
                        <span className="p-float-label">
                            <InputText style={{ width: "100%" }} id="in" value={lattes} onChange={(e) => setLattes(e.target.value)} />
                            <label htmlFor="in">Lattes</label>
                        </span>
                        <span style={{ color: "red" }}><i>Exemplo: </i></span>http://lattes.cnpq.br/0989262262491615
                        <br /><br />
                        <span className="p-float-label">
                            <InputText style={{ width: "100%" }} id="in" value={idLattes} onChange={(e) => setIdLattes(e.target.value)} />
                            <label htmlFor="in">Identificador Lattes</label>
                        </span>
                        <span style={{ color: "red" }}><i>Exemplo:</i> K4759404E7.</span> Ao acessar este endereço: http://lattes.cnpq.br/0989262262491615 a plataforma Lattes vai te encaminhar para este endereço que contém o identificador: http://buscatextual.cnpq.br/buscatextual/visualizacv.do?id= <span style={{ color: "red" }}><b>K4759404E7</b></span>
                        <br /><br />
                        <span className="p-float-label">
                            <InputText style={{ width: "100%" }} id="in" value={dataCredenciamento} onChange={(e) => setDataCredenciamento(e.target.value)} />
                            <label htmlFor="in">Data Credenciamento</label>
                        </span>
                        <span style={{ color: "red" }}><i>Credenciamento: </i></span>Informe a data no formato <b>dd/mm/aaaa</b>.
                            <br /><br />
                        <div style={{ textAlign: "left" }}>
                            <Button label="Salvar" onClick={() => insereProfessor()} className="p-button-raised p-button-success" />
                        </div>
                        <br />
                    </div>
                    }
                />
            </div>
        );
    }

    function ExportarDocentesExcel() {
        return (
            <div>
                <Workbook filename="professores.xlsx" element={<Button label="Excel" className="p-button-raised p-button-info" />}>
                    <Workbook.Sheet data={Professores} name="Docentes">
                        <Workbook.Column label="Instituicao" value="instituicao" />
                        <Workbook.Column label="Nome" value="nome" />
                        <Workbook.Column label="Email" value="email" />
                        <Workbook.Column label="Graducao" value="graduacao" />
                        <Workbook.Column label="Mestrado" value="mestrado" />
                        <Workbook.Column label="Doutorado" value="doutorado" />
                        <Workbook.Column label="Lattes" value="dataAtualizacaoLattes" />
                    </Workbook.Sheet>
                </Workbook>
            </div>

            );
    }

    function renderProfessores() {
        //let paginatorLeft = <Button icon="pi pi-refresh" />;
        //let paginatorRight = <Button icon="pi pi-cloud-upload" />;
        /*paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}*/
        /* <Column field="nome" header="Nome" sortable={true} filter={true} / > */
        var windowWidth = window.innerWidth;
        if (windowWidth < 770) {
            return renderProfessoresCelular();
        }
        else {
            return renderProfessoresTamanhoNormal();
        }
    }

    function renderAll() {

        let contents = <p></p>;
        let contentsIncProfessor = <p></p>;
        let contentsExportaExcel = <p></p>;
        if (loadingContext.loading) {
            return (
                <div>
                    <p><i className="fa fa-refresh fa-spin"></i> <em>Lendo/salvando dados...</em></p>
                </div>
            );
        } 
        else {
            contents = renderProfessores();
            if ((infoContext.user != null) && ((infoContext.user.profile.admin) || (infoContext.user.profile.coordenadorNacional))) {
                contentsIncProfessor =
                    <Col sm={4}>
                        <b>Incluir Docente:</b><br />
                        <Button label="Incluir" onClick={() => setExibeInsereProfessor(true)} className="p-button-raised p-button-info" />
                        <br /><br />
                    </Col>;
                contentsExportaExcel =
                    <Col sm={4}>
                        <b>Exportar Docentes:</b><br />
                        {ExportarDocentesExcel()}
                        <br /><br />
                    </Col>;
            }
        }

        return (
            <Container >
                <Row>
                    {contentsIncProfessor}
                    {contentsExportaExcel}
                </Row>
                <Row>
                    <Col sm={12}>
                        {contents}
                        {renderEditarProf()}
                        {renderIncluirProf()}
                    </Col>
                </Row>
            </Container>
        );

    }

    return (
        renderAll()
    );

}