import React, { Component } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';

// Components
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import { Accordion, AccordionTab } from 'primereact/accordion';

export class DisciplinasEletivas extends Component {
    displayName = DisciplinasEletivas.name
    constructor(props) {
        super(props);
    }
  render() {
      return (
          <Container >
              <Row>
                  <Col sm={12}>
                      <h2>Disciplinas Eletivas</h2>
                  </Col>
              </Row>
              <Row>
                  <Col sm={12}>
                    <Accordion multiple={true}>
                        <AccordionTab header="Juventude, Trabalho e Escola">
                            Juventude e inserção social. Culturas juvenis. Juventude, educação e mundo do trabalho. Socialização juvenil. Juventude e contemporaneidade.
                        </AccordionTab>
                        <AccordionTab header="História da Ciência, da Técnica e da Tecnologia">
                            Da técnica à engenharia, da antiguidade à Idade Moderna. Técnicas indígenas. A Mineração. A Eletrotécnica. A Construção Civil. A Mecânica. A Informática. Energia e Tecnologia.
                        </AccordionTab>
                        <AccordionTab header="Formação de Professores para Educação Profissional e Tecnológica">
                            Formação de professores para a EPT no contexto atual. Políticas de formação de professores e as relações educação, trabalho e sociedade. Estado da arte da formação de professores. Saberes profissionais docentes. Temas recorrentes e temas silenciados: perspectivas para a prática.
                        </AccordionTab>
                        <AccordionTab header="Espaços Não-Formais na Educação Profissional e Tecnológica">
                            Definições de espaços não-formais, formais e informais de educação. Histórico dos espaços não-formais de educação no Brasil e comparativo metodológico com seus equivalentes em outros países. O espaço da educação não-formal e os processos de desenvolvimento e aprendizagem. Educação não-formal e a participação da sociedade civil nos processos educativos. O ambiente de trabalho como um espaço não-formal de educação.
                        </AccordionTab>
                        <AccordionTab header="Educação do Campo">
                            Educação do Campo dentro da conjuntura atual. Nova realidade do rural no Brasil, inserido no contexto internacional, reconhecendo os conflitos e as implicações dos conceitos de Educação rural x Educação no campo x Educação do campo, e o processo histórico da construção de um novo paradigma em educação emancipadora. Desafios e as necessidades da Pedagogia da alternância frente a escola tradicional institucionalizada.
                        </AccordionTab>
                        <AccordionTab header="Educação e Tecnologias">
                            O papel da educação na sociedade contemporânea em suas relações com as tecnologias digitais em rede. Tecnologia e mediação pedagógica. Análise de recursos tecnológicos como recursos pedagógico-didáticos e suas aplicações no ensino profissional e tecnológico. O trabalho pedagógico online. Elaboração de projetos de ensino com utilização de tecnologias digitais em rede. Desenvolvimento de projetos e protótipos aplicados ao ensino profissional e tecnológico.
                        </AccordionTab>
                        <AccordionTab header="Educação de Jovens e Adultos">
                            A configuração do campo da EJA: a diversidade dos sujeitos; a afirmação do direito à educação; história e memórias; a relação educação de jovens e adultos e trabalho. Paulo Freire: Contribuições para pensar a prática da EJA no Brasil. Trabalho, Ciência, Cultura e Tecnologia como dimensões da formação humana na EJA e na Educação Profissional. Características da aprendizagem e estratégias de ensino para adultos. Educação de jovens e adultos e Educação Profissional: a confluência de duas modalidades. Implicações das especificidades da EJA na organização do trabalho pedagógico e na gestão educacional da EPT.
                        </AccordionTab>
                        <AccordionTab header="Políticas Públicas em Educação Profissional e Tecnológica">
                            Concepções e bases conceituais sobre Estado e políticas educacionais no Brasil; análise de políticas em educação profissional, educação básica, educação de jovens e adultos e formação docente; produção histórica das políticas e das bases legais da educação básica, da educação profissional, da educação de jovens e adultos e da formação docente.
                        </AccordionTab>
                        <AccordionTab header="Produção de Recursos Educacionais">
                            Relação entre recursos educacionais e metodologias de ensino na EPT. Recursos educacionais impressos na EPT: características; produção; utilização. Recursos educacionais digitais na EPT: características; produção; utilização. Recursos educacionais alternativos: jogos; maquetes; vídeos; softwares; experimentos; outros. Produção, avaliação e utilização de sequências didáticas. Desenvolvimento de atividades baseadas em problemas e investigações.
                        </AccordionTab>
                        <AccordionTab header="Currículo e Formação Integrada">
                            Concepções e histórico de Currículo. Trajetória histórica da educação profissional no Brasil na perspectiva de sua integração com a educação básica – elementos essenciais à compreensão do processo curricular. Concepções e princípios do currículo do Ensino médio integrado à Educação profissional: regular e modalidade EJA. Elementos estruturantes de um currículo integrado.
                        </AccordionTab>
                        <AccordionTab header="Diversidade e Inclusão">
                            Estudos acerca da trajetória histórica da inclusão educacional - da década de 1990 até a contemporaneidade. Educação Inclusiva. Diversidade Cultural. Diversidade e Currículo. Reflexões acerca do papel da escola na inclusão dentro da perspectiva da diversidade.
                        </AccordionTab>
                        <AccordionTab header="Ciência, Tecnologia, Sociedade e Ambiente">
                            Origens dos estudos com o enfoque “Ciência, Tecnologia, Sociedade e Ambiente” (CTS/CTSA) no Brasil e no mundo. Relações entre ciência, tecnologia, sociedade e ambiente. Questões éticas e políticas. Diferentes perspectivas da abordagem CTSA. Configurações curriculares mediante o enfoque CTSA. O enfoque CTSA e a Educação Profissional e Tecnológica. Propostas metodológicas com ênfase CTS para o ensino profissional e tecnológico.
                        </AccordionTab>
                        <AccordionTab header="Avaliação nos Espaços Educativos">
                            Avaliação da/na escola. Ensino e suas relações com a avaliação da aprendizagem. Objetivos educacionais e avaliação. Avaliação formativa. Alternativas propositivas no campo da avaliação. As relações pessoais na escola e a avaliação. Instrumentos de avaliação.
                        </AccordionTab>
                        <AccordionTab header="Tópicos Especiais em Educação Profissional e Tecnológica">
                            Essa disciplina visa apresentar tópicos especiais em Educação Profissional e Tecnológica, em caráter extraordinário. Visa trabalhar a construção de artigos científicos e tecnológicos, como também produtos tecnológicos gerados pelos estudos. Poderá ser ofertada a qualquer momento conforme demanda específica e aprovação da Comissão Acadêmica Local de cada Instituição Associada.
                        </AccordionTab>
                      </Accordion>
                      <br />
                  </Col>
              </Row>
              <Row>
                  <Col sm={12}>
                      Para maiores informações, acesse profept.ifes.edu.br.
                  </Col>
              </Row>
          </Container>
    );
  }
}
