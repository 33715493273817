import React, { useEffect, useState, useContext } from 'react'
import cronJobService from '../../services/cronJobService';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';
import { formatDate } from '../../helpers/Date';

export default function CronJobLattes() {

    const [dataAtualizacao, setDataAtualizacao] = useState(null);
    const idCt = useContext(IdiomaContext);

    useEffect(() => {
        GetData();
    }, [])

    async function GetData() {
        cronJobService.getUltimaAtualizacaoLattes()
            .then(async res => {
                setDataAtualizacao(await res.json());
            })
            .catch(error => {
                setDataAtualizacao(null);
            });

    }

    function GetDescricao() {
        if (dataAtualizacao == null) {
            return (
                <span style={{ fontSize: "0.75em", color: '#666666' }} >
                    {idCt.t("Dados extraídos da Plataforma Lattes em ", "Data extracted from the Lattes Platform in ")} <strong> <i className="fa fa-refresh fa-spin"></i> ...</strong>
                </span>
                )
        }
        if (dataAtualizacao.emExecucao == true) {
            return (
                <span style={{ fontSize: "0.75em", color: '#666666' }} >
                    <i className="fa fa-refresh fa-spin"></i> {idCt.t("Iniciamos o processo de importação dos dados da Plataforma Lattes em ", "We started the process of importing data from the Lattes Platform in ")} <strong>{dataAtualizacao.data}. {dataAtualizacao.etapa}</strong>
                </span>
            )
        }
        else {
            return (
                <span style={{ fontSize: "0.75em", color: '#666666' }} >
                    {idCt.t("Dados extraídos da Plataforma Lattes em ", "Data extracted from the Lattes Platform in ")} <strong> {dataAtualizacao.data}</strong>
                </span>
            )
        }
    }

    return (
        <div>
            {GetDescricao()}
            <br />
            <span style={{ fontSize: "0.75em", color: '#ff6666' }} >
                {idCt.t("Os estratos do Qualis Periódicos são do Quadriênio 2017-2020", "The Qualis Periodicos classifiers used are Quadriênio 2017-2020")}
            </span>
        </div>
    )
}