import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import RelacaoOfertaProcura from '../../components/DisciplinasEletivas/RelacaoOfertaProcura';
import SelectOfertaDisciplina from '../../components/SelectOfertaDisciplina/SelectOfertaDisciplina';

export class ConsultaRelacaoOfertas extends Component {
    displayName = ConsultaRelacaoOfertas.name

    constructor(props) {
        super(props);
        let OFERTA = {
            id: 0,
            anoSemestre: "2019/2"
        }
        this.state = { QualOferta: OFERTA };
        this.updateOferta = this.updateOferta.bind(this);
    }

    //Método para atualizar a instituição:
    updateOferta = (QualOferta) => { this.setState({ QualOferta }); }

  render() {
    return (
        <Container > 
            <Row>
                <Col sm={12}>
                    <h2>Relação de oferta e procura de disciplinas eletivas</h2>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <PainelConteudo legenda="Selecione a Oferta"
                        texto={
                            <Row>
                                <Col sm={12}>
                                    Escolha a Oferta:
                                    <SelectOfertaDisciplina AtualizaQualOferta={this.updateOferta} />
                                </Col>
                            </Row>
                        } />
                </Col>
            </Row>           
            <Row>
                <Col sm={12}>
                    <PainelConteudo legenda="Oferta / Procura"
                        texto={
                            <Row>
                                <Col sm={12}>
                                    <RelacaoOfertaProcura QualOferta={this.state.QualOferta.id} />
                                </Col>
                            </Row>
                        } />                    
                </Col>
            </Row>
        </Container>
    );
  }
}
