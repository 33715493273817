import authService from '../components/api-authorization/AuthorizeService';

const disciplinaService = {
    UltimaOferta,
    UltimaOfertaAluno,
    UltimaOfertaDiscProf,
    RelacaoOfertaProcura,
    RelacaoAlunosProcura,
    PutAtualizaDiscAluno,
    PostAtualizaOferta,
    PutRemoverOfertaProf,
    GetDisciplinasObrigatorias
}

async function UltimaOferta() {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: "GET",
        headers: headers
    };
    return await fetch('api/Disciplinas/UltimaOferta', options);
}

async function UltimaOfertaAluno() {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: "GET",
        headers: headers
    };    
    return await fetch('api/Disciplinas/UltimaOfertaAluno', options);
}

async function UltimaOfertaDiscProf(QualInstituicao, QualDisciplina) {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: "GET",
        headers: headers
    };
    let StringFetch = "api/Disciplinas/UltimaOfertaDiscProf?QualInstituicao=" + QualInstituicao +
                      "&QualDisciplina=" + QualDisciplina;
    return await fetch(StringFetch, options);
}

async function RelacaoOfertaProcura(QualOferta) {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: "GET",
        headers: headers
    };
    let StringFetch = "api/Disciplinas/RelacaoOfertaProcura?OfertaID=" + QualOferta;
    return await fetch(StringFetch, options);
}

async function RelacaoAlunosProcura(QualInstituicao, QualOferta, QualAno) {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: "GET",
        headers: headers
    };
    let StringFetch = "api/Disciplinas/RelacaoAlunosProcura?QualInstituicao=" + QualInstituicao + "&OfertaID=" + QualOferta + "&AnoIngresso=" + QualAno;
    return await fetch(StringFetch, options);
}

async function PutAtualizaDiscAluno(pBody) {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: 'PUT',
        headers: headers,
        body: pBody,
        cache: 'default'
    };
    return await fetch('api/Disciplinas/PutAtualizaDiscAluno', options);
}

async function PostAtualizaOferta(pBody) {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: 'POST',
        headers: headers,
        body: pBody,
        cache: 'default'
    };
    return await fetch('api/Disciplinas/PostAtualizaOferta', options);
}

async function PutRemoverOfertaProf(pBody) {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: 'PUT',
        headers: headers,
        body: pBody,
        cache: 'default'
    };
    return await fetch('api/Disciplinas/PutRemoverOfertaProf', options);
}

async function GetDisciplinasObrigatorias() {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: "GET",
        headers: headers
    };
    return await fetch('api/Disciplinas/GetDisciplinasObrigatorias', options);
}

export default disciplinaService;

