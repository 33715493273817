import React, { Component } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import '../Pesquisadores/Pesquisadores.css';

export default class SelecProfessor extends Component {    

    constructor(props) {
        super(props);
        this.state = {
            Professor: null, 
            ObjProfessores: null,
            Professores: null,
            professorSuggestions: null,
            loading: true,
            error: null,
            IndiceProf: -1
        };
    }

    componentDidMount() {
        let StringFetch = 'api/Pesquisadores/ListaPesquisadores';
        if (this.props.QualInstituicao) {
            StringFetch = StringFetch + '?QualInstituicao=' + this.props.QualInstituicao;
        }            
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                let a = [];
                for (let i = 0; i < data.length; i++) {
                    a.push(data[i].nome);
                }
                this.setState({ ObjProfessores: data, Professores: a, loading: false, IndiceProf: -1 });              
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    suggestProfessores(event) {
        let results = this.state.Professores.filter((brand) => {
            return brand.toLowerCase().startsWith(event.query.toLowerCase());
        });

        this.setState({ professorSuggestions: results });
    }

    AlteraProfessor(pValor) {
        let IndiceProf = -1;        
        for (let i = 0; i < this.state.ObjProfessores.length; i++) {
            if (this.state.ObjProfessores[i].nome === pValor.toUpperCase()) {
                IndiceProf = i;
            }
        }
        if (IndiceProf > -1) {
            this.setState({ Professor: pValor, IndiceProf });
            this.props.AtualizaQualProfessor(this.state.ObjProfessores[IndiceProf]);
        }
        else {
            if (IndiceProf != this.state.IndiceProf) {
                this.setState({ Professor: pValor, IndiceProf });
                let NenhumProf = { id: 0, professorID: 0, nome: "" };
                this.props.AtualizaQualProfessor(NenhumProf);
            }
            else {
                this.setState({ Professor: pValor });
            }
        }
    }

    itemTemplate(prof) {
        let urlFoto = "user.svg";
        for (let i = 0; i < this.state.ObjProfessores.length; i++) {
            if (this.state.ObjProfessores[i].nome === prof) {
                urlFoto = this.state.ObjProfessores[i].urlFoto;
                break;
            }
        }
        return (
            <div className="p-clearfix">
                <img src={urlFoto} style={{ display: 'inline-block', margin: '7px 0 2px 5px' }} className="prof-imagem-redondo" />
                <div style={{ fontSize: '14px', float: 'left', margin: '10px 10px 0 0' }}>{prof}</div>
            </div>
        );
    }

    renderProfessores() {
        var TamanhoInput = 36;
        var windowWidth = window.innerWidth;
        if (windowWidth < 770) { //Celular
            TamanhoInput = 26;
        }
        return (
            <div>
                <span className="p-float-label">
                    <AutoComplete style={{ width: "100%" }} dropdown={true}
                        size={TamanhoInput}
                        onChange={(e) => this.AlteraProfessor(e.value)}
                        value={this.state.Professor}
                        suggestions={this.state.professorSuggestions}
                        itemTemplate={this.itemTemplate.bind(this)}
                        completeMethod={this.suggestProfessores.bind(this)} />
                    <label htmlFor="in">Selecione o professor</label>
                </span>
            </div>   
        );
    }
    render() {
        const { loading, error } = this.state;
        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents =  <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                        </div>;
        }
        else {
            contents = this.renderProfessores();
        }

        return (
            <div>
                {contents}
            </div>
        );

    }
}