import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import '../Pesquisadores/Pesquisadores.css';
import { formatDate } from '../../helpers/Date';

import InfoContext from '../../context/infoContext/infoContext';

export default function PerfilProfessor(props) {

    const infoContext = useContext(InfoContext);

    function returnRender() {
        let contents = <p>Você deve logar como <b>Docente ou Secretário</b> para acessar este conteúdo.</p>;
        if ((infoContext.user != null) && (infoContext.userInfo != null)) {
            contents = <Row>
                            <Col sm={4}>
                                <img src={(infoContext.user.picture) ? infoContext.user.picture: 'user.svg'} alt="Foto" className="prof-imagem-redondo-plus" />
                            </Col>
                            <Col sm={8}>
                                <p><b>Nome: </b>{infoContext.user.name}</p>
                                <p><b>Email: </b>{infoContext.user.preferred_username}</p>
                                <p><b>Instituição: </b>{infoContext.userInfo.instituicao.nome}</p>
                                <p><b>Quantidade de acessos: </b>{infoContext.userInfo.qtdeAcesso}</p>
                                <p><b>Último acesso: </b>{formatDate(infoContext.userInfo.ultimoAcesso)}</p>
                            </Col>
                        </Row>;
        }
        return contents;
    }

    
    return (
        <div>
            {returnRender()}
        </div>
    );
}