import React, { useState, useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './Pesquisadores.css';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import LoadingContext from '../../context/loadingContext/loadingContext';
import MessageContext from '../../context/messageContext/messageContext';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';

export default function Descredenciados(props) {

    //Contexto
    const loadingContext = useContext(LoadingContext);
    const messageContext = useContext(MessageContext);
    const idCt = useContext(IdiomaContext);

    //Construtor
    const [Professores, setProfessores] = useState([]);    
    const [globalFilter, setGlobalFilter] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);
    const [error, setError] = useState(null);    

    useEffect(() => {
        //componentDidMount e componentDidUpdate
        loadingContext.showLoading();        
        CarregarDados();        
        loadingContext.hideLoading();
    }, [props.QualInstituicao]); 
    
    function ColunaEditar(rowData, column) {
        let contentsLattes = <div style={{ display: 'inline-block' }}></div>;

        if (rowData.lattesEndereco) {
            contentsLattes= <div style={{ display: 'inline-block' }}>
                                <IconButton size="small" color="primary" aria-label="Lattes" style={{ verticalAlign: "top", padding: "6px" }}
                                    onClick={() => abrirJanela(rowData.lattesEndereco)}>
                                    <Icon fontSize="small">
                                        <img src="images/lattes2.png" alt="Lattes" style={{ verticalAlign: "top", width: "100%", height: "100%", margin: "0px", padding: "0px" }} />
                                    </Icon>
                                </IconButton>
                            </div>;
        }

        return (
            <div>
                {contentsLattes}
            </div>
        );
    }

    function CarregarDados() {
        setGlobalFilter(null);    
        let StringFetch = 'api/Pesquisadores/ListaDescredenciados?QualInstituicao=' + props.QualInstituicao;
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                setProfessores(data);                
            })
            .catch(error => {
                messageContext.openMessage("error", "Erro.Por favor, tente novamente.");
                setError(error);
            });
    }

    function abrirJanela(endereco) {
        if ((endereco.substring(0, 7) != 'http://') && (endereco.substring(0, 8) != 'https://')) {
            endereco = 'https://' + endereco;
        }
        window.open(endereco);
    }

    function rowExpansionTemplate(data) {
        return (
            <div className="p-grid p-fluid" style={{ padding: '1em' }}>
                <div className="p-col-12 p-md-12">
                    <div className="p-grid">
                        <div className="p-md-2">{idCt.t("IA", "AI")}: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.instituicao}</div>

                        <div className="p-md-2">Email: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.email}</div>

                        <div className="p-md-2">{idCt.t("Descredenciado", "Exit")}: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.saidaInstituicao}</div>
                    </div>
                </div>
            </div>
        );
    }

    function renderProfessoresCelular() {
        var header = <div style={{ 'textAlign': 'left' }}>
            <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar" size="20" />
        </div>;

        return (
            <div>
                <div className="content-section implementation">
                    <DataTable value={Professores} paginator={true} rows={15} rowsPerPageOptions={[5, 15, 30]}
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        globalFilter={globalFilter} header={header}
                        expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                        rowExpansionTemplate={rowExpansionTemplate}
                    >
                        <Column expander={true} style={{ width: '3em' }} />
                        <Column field="nome" header={idCt.t("Nome", "Name")} sortable={true} />
                        <Column field="editar" header="+Info" body={ColunaEditar} sortable={false} style={{ textAlign: 'center', width: '5em' }} />
                    </DataTable>
                </div>
            </div>
        );
    }

    function renderProfessoresTamanhoNormal() {
        var header = <div style={{ 'textAlign': 'left' }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar" size="50" />
                    </div>;
        if (props.QualInstituicao === 0) {
            return (
                <div>
                    <div className="content-section implementation">
                        <DataTable value={Professores} paginator={true} rows={15} rowsPerPageOptions={[5, 15, 30]}
                            resizableColumns={true} columnResizeMode="fit"
                            globalFilter={globalFilter} header={header}                           
                        >
                            <Column field="nome" header={idCt.t("Nome", "Name")} sortable={true} />
                            <Column field="instituicao" header={idCt.t("IA", "AI")} sortable={true} style={{ textAlign: 'center', width: '9em' }} />
                            <Column field="email" header="Email" sortable={false} />
                            <Column field="saidaInstituicao" header={idCt.t("Descredenciado", "Exit")} sortable={true} style={{ textAlign: 'center', width: '12em' }} />
                            <Column field="editar" header="+Info" body={ColunaEditar} sortable={false} style={{ textAlign: 'center', width: '5em' }} />
                        </DataTable>
                    </div>
                </div>
            );      
        }
        return (
            <div>
                <div className="content-section implementation">
                    <DataTable value={Professores} paginator={true} rows={15} rowsPerPageOptions={[5, 15, 30]}
                        resizableColumns={true} columnResizeMode="fit"
                        globalFilter={globalFilter} header={header}
                    >
                        <Column field="nome" header={idCt.t("Nome", "Name")} sortable={true} /> 
                        <Column field="email" header="Email" sortable={false} />
                        <Column field="saidaInstituicao" header={idCt.t("Descredenciado", "Exit")} sortable={true} style={{ textAlign: 'center', width: '12em' }} />
                        <Column field="editar" header="+Info" body={ColunaEditar} sortable={false} style={{ textAlign: 'center', width: '5em' }} />
                    </DataTable>
                </div>
            </div>
        );
    }

    function renderProfessores() {
        var windowWidth = window.innerWidth;
        if (windowWidth < 770) {
            return renderProfessoresCelular();
        }
        else {
            return renderProfessoresTamanhoNormal();
        }
    }

    function renderAll() {

        let contents = <p></p>;
        if (loadingContext.loading) {
            return (
                <div>
                    <p><i className="fa fa-refresh fa-spin"></i> <em>Lendo/salvando dados...</em></p>
                </div>
            );
        } 
        else {
            contents = renderProfessores();
        }

        return (
            <Container >
                <Row>
                    <Col sm={12}>
                        {contents}
                    </Col>
                </Row>
            </Container>
        );

    }

    return (
        renderAll()
    );

}