import React, { Component } from 'react';
import { Form } from 'react-bootstrap'; ///lib/FormControl';

export default class SelectTipoProdEdu extends Component {    

    constructor(props) {
        super(props);
        this.state = {
            TiposProdEdu: [],
            loading: true,
            error: null
        };
    }

    componentDidMount() {
        let strFetch = 'api/Discentes/GetTiposProdutosEducacionais';
        fetch(strFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ TiposProdEdu: data, loading: false });
                if (!this.props.TipoProdEduSelecionado) {
                    this.props.AtualizaQualTipoProdEdu(this.state.TiposProdEdu[0].id);
                }                
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    AlteraTipo(pValor) {
        let a = this.state.TiposProdEdu;
        for (let i = 0; i < a.length; i++) {
            if (a[i].id == pValor) {
                /*
                if (this.props.InstituicaoSelecionada) {
                    this.props.InstituicaoSelecionada = a[i].id;
                }
                */
                this.props.AtualizaQualTipoProdEdu(a[i].id);
            }
        }        
    }

    renderSelectTipoProdEdu() {
        return (
            <div>
                <Form.Control as="select"
                    placeholder="Selecione o Tipo do Produto Educacional"
                    onChange={(e) => this.AlteraTipo(e.target.value)} >
                    {
                        this.state.TiposProdEdu.map(tipo =>
                            (this.props.TipoProdEduSelecionado && this.props.TipoProdEduSelecionado === tipo.id) ?
                                <option selected value={tipo.id} key={tipo.id}> {(this.props.Descricao && tipo.id === 0) ? this.props.Descricao : tipo.text} </option> :
                                <option value={tipo.id} key={tipo.id}> {(this.props.Descricao && tipo.id === 0) ? this.props.Descricao : tipo.text} </option>)
                    }
                </Form.Control>
            </div>       
        );
    }
    render() {
        const { loading, error } = this.state;
        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents =  <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                        </div>;
        }
        else {
            contents = this.renderSelectTipoProdEdu();
        }

        return (
            <div>
                {contents}
            </div>
        );

    }
}