import React, { useState, useContext, useEffect } from 'react';
import { Container, Col, Row, Form } from 'react-bootstrap';
import '../Pesquisadores/Pesquisadores.css';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';
import ModalMensagem from '../ModalMensagem/ModalMensagem';
import { Button } from 'primereact/button';
import ButtonMaterial from '@material-ui/core/Button';
import jsonCidades from '../../dados/estados-cidades.json';

import LoadingContext from '../../context/loadingContext/loadingContext';
import MessageContext from '../../context/messageContext/messageContext';
import InfoContext from '../../context/infoContext/infoContext';
import discenteService from '../../services/discenteService';

export default function PerfilAluno(props) { 

    const loadingContext = useContext(LoadingContext);
    const messageContext = useContext(MessageContext);
    const infoContext = useContext(InfoContext);
    const estados = ['AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO'];

    //Constructor
    const [lattes, setLattes] = useState(null);
    const [facebook, setFacebook] = useState(null);
    const [estado, setEstado] = useState(null);
    const [cidade, setCidade] = useState(null);
    const [cidades, setCidades] = useState(null);
    const [saibaComoLattes, setSaibaComoLattes] = useState(false);
    const [saibaComoFace, setSaibaComoFace] = useState(false);
    const [error, setError] = useState(false);

    function updatesaibaComoLattes(saibaComoLattes) {
        setSaibaComoLattes(saibaComoLattes);
    }

    function updatesaibaComoFace(saibaComoFace) {
        setSaibaComoFace(saibaComoFace);
    }

    useEffect(() => {
        //componentDidMount
        loadingContext.showLoading();
        populateAuthenticationState();
        loadingContext.hideLoading();
    }, []); 

    function populateAuthenticationState() {
        if (infoContext.userInfo.idaluno === 0)
            return;
        discenteService.getAtualizaPerfil() //fetch('api/Discentes/GetAtualizaPerfil?AlunoId=' + user.idaluno)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados do perfil...');
                }
            })
            .then(data => {
                setLattes(data.lattesEndereco);
                setFacebook(data.facebookEndereco);
                setEstado(data.estado);
                setCidade(data.cidade);
                if (data.cidade && data.estado) {
                    ProcuraCidades(data.estado);
                }
            })
            .catch(error => {
                messageContext.openMessage("error", "Erro. Por favor, tente novamente.");
                setError(error);
                });
    }

    function AlteraEstado(pValor) {     
        setEstado(pValor);      
        if (pValor) {
            for (let i = 0; i < estados.length; i++) {
                if (estados[i] === pValor) {                    
                    ProcuraCidades(pValor);
                }
            }           
        }
    }

    function AlteraCidade(pValor) {
        setCidade(pValor);        
    }

    function ProcuraCidades(QualEstado) {
        loadingContext.showLoading();
        setCidades(null);        

        let a = [];
        for (let i = 0; i < jsonCidades.estados.length; i++) {
            if (jsonCidades.estados[i].sigla === QualEstado) {
                for (let j = 0; j < jsonCidades.estados[i].cidades.length; j++) {
                    a.push(jsonCidades.estados[i].cidades[j]);
                }
            }
        }

        setCidades(a);        
        loadingContext.hideLoading();
    }

    function handleSubmit(event){
        event.preventDefault();
        loadingContext.showLoading();
        
        let pBody = JSON.stringify({
            ID: infoContext.userInfo.idaluno, LattesEndereco: lattes, FacebookEndereco: facebook,
            Cidade: cidade, Estado: estado
        });

        if ((infoContext.userInfo.idaluno) && (infoContext.userInfo.idaluno > 0)) {
            discenteService.putAtualizaPerfil(pBody)
                .then(r => {
                    r.json().then(response => {
                        if (response.statusCode) {
                            messageContext.openMessage(response.statusVariant, response.statusDescription);
                        }
                    });
                })                
                .catch(error => {
                    messageContext.openMessage("error", "Erro. Por favor, tente novamente.");
                    setError(error);
                });
        }
        loadingContext.hideLoading();
    }    

    function renderCidades() {
        let contents = <p></p>;
        if (cidades) {
            contents =  <div>
                            <br />
                            <span style={{ fontSize: "12px" }} >
                                Cidade de sua residência:
                                <Form.Control as="select"
                                    placeholder="Estado"
                                    value={cidade}
                                    onChange={(e) => AlteraCidade(e.target.value)} >
                                    <option value="" key=""></option>
                                    {
                                        cidades.map(cid => <option value={cid} key={cid}> {cid} </option>)
                                    }
                                </Form.Control>
                            </span>
                        </div>;
        }
        return contents;
    }

    function renderAll() {  
        let contents = <p>Você deve logar como <b>Discente</b> para acessar este conteúdo.</p>;
        let contentsSave = <p></p>;        
        if ((infoContext.user != null) && (infoContext.userInfo != null)) {        
            let contentsCidades = <p></p>;
            if (estado) {
                contentsCidades = renderCidades();
            }            
            contents = <Container >
                        <Row>
                            <Col sm={2}>
                              <div verticalAlign="middle">
                                <img src={(infoContext.user.picture) ? infoContext.user.picture: 'user.svg'} alt="Foto" className="prof-imagem-redondo-plus" /> 
                              </div>
                          </Col>
                          <Col sm={10}>
                              <Row>
                                  <Col sm={12}>
                                     <p>Olá <b>{infoContext.user.name}</b>, é muito importante mantermos os seus dados atualizados.</p>
                                     <p>A sua conta está vinculada ao email <b>{infoContext.user.preferred_username}</b>. Caso deseje alterar o email, vá em configurações da conta.</p>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col sm={5}>
                                      <br />
                                      <span className="p-float-label">
                                          <InputText style={{width: "100%"}} id="in" value={lattes} onChange={(e) => setLattes(e.target.value)} />
                                          <label htmlFor="in">Endereço Lattes</label> 
                                      </span>
                                      <span style={{ fontSize: "11px" }}><i>Exemplo: http://lattes.cnpq.br/0989262262491615 </i>&nbsp;&nbsp;</span>
                                      <ButtonMaterial variant="contained" size="small" color="primary" onClick={() => setSaibaComoLattes(true)}>
                                        <i class="fa fa-info-circle"></i>&nbsp;&nbsp;Saiba como
                                      </ButtonMaterial>                                            
                                      <ModalMensagem updateShow={updatesaibaComoLattes} show={saibaComoLattes} titulo="Saiba como obter o endereço Lattes" 
                                                     mensagem={<div>
                                                                   <img style={{ height: '400px', width: 'auto', float: 'left', marginRight: '8px', marginLeft: '0px', marginTop: '0px' }} src="Lattes.png" border="0" />
                                                               </div>
                                                         } />
                                  </Col>
                                  <Col sm={5}>
                                      <br />
                                      <span className="p-float-label">
                                          <InputText style={{width: "100%"}} id="in" value={facebook} onChange={(e) => setFacebook(e.target.value)} />
                                          <label htmlFor="in">Endereço Facebook (opcional)</label> 
                                      </span>
                                      <span style={{ fontSize: "11px"}}><i>Exemplo: https://facebook.com/ernani.melo.58 </i> &nbsp;&nbsp;</span>
                                      <ButtonMaterial variant="contained" size="small" color="primary" onClick={() => setSaibaComoFace(true)}>
                                        <i class="fa fa-info-circle"></i>&nbsp;&nbsp;Saiba como
                                      </ButtonMaterial>                                            
                                      <ModalMensagem updateShow={updatesaibaComoFace} show={saibaComoFace} titulo="Saiba como obter o endereço do Facebook" 
                                                     mensagem={<div>
                                                                   <img style={{ height: '400px', width: 'auto', float: 'left', marginRight: '8px', marginLeft: '0px', marginTop: '0px' }} src="Facebook.png" border="0" />
                                                               </div>
                                                         } />                                
                                  </Col>
                                  <Col sm={2}>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col sm={5}>
                                    <br />
                                    <span style={{fontSize: "12px"}} >
                                        Estado de sua residência:
                                        <Form.Control as="select"
                                                      placeholder="Estado"
                                                      value={estado}
                                                      onChange={(e) => AlteraEstado(e.target.value)} >
                                                      <option value="" key=""></option>
                                            {
                                                estados.map(est => <option value={est} key={est}> {est} </option>)
                                            }
                                        </Form.Control>
                                    </span>
                                  </Col>
                                  <Col sm={5}>      
                                      {contentsCidades}
                                  </Col>
                                  <Col sm={2}>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col sm={12}> 
                                    <br />
                                    <div style={{ textAlign: "left" }}>
                                        <Button label="Salvar" onClick={handleSubmit.bind(this)} className="p-button-success" />
                                    </div>
                                  </Col>
                              </Row>
                          </Col>
                       </Row>
                       </Container>;
        }
        if (error) {
            let contentsErro = <p>Erro. Por favor, tente novamente.</p>;
            return (
                <div>
                    {contentsErro}
                </div>
            );
        }
        if (loadingContext.loading) {
            contents =  <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                        </div>;
        }

        return (
            <form name="PerfilAlunoform">
                <div >
                    {contentsSave}
                    {contents}
                </div>
            </form >
        );
    }

    return (
        renderAll()
        );
}