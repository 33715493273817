import React, { Component } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';

// Components
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';

export class Adm extends Component {
    displayName = Adm.name;

    constructor(props) {
        super(props);
        this.state = {
            StatusImportacao: null,
            loadingStatus: true,
            errorStatus: null,
            StatusImportacaoQualis: null,
            loadingQualis: false,
            errorQualis: null,
            StatusImportacaoInstituicoes: 0,
            loadingInstituicoes: false,
            errorInstituicoes: null,
            StatusImportacaoProfessores: 0,
            loadingProfessores: false,
            errorProfessores: null,
            StatusImportacaoCurriculos: 0,
            loadingCurriculos: false,
            errorCurriculos: null,
            StatusImportacaoProducaoBibliografica: 0,
            loadingProducaoBibliografica: false,
            errorProducaoBibliografica: null,
            StatusImportacaoProducaoPeriodicos: 0,
            loadingProducaoPeriodicos: false,
            errorProducaoPeriodicos: null,
            StatusImportacaoOrientacao: 0,
            loadingOrientacao: false,
            errorOrientacao: null,
            StatusImportacaoTopProd: 0,
            loadingTopProd: false,
            errorTopProd: null,
            StatusImportacaoPalavraChave: 0,
            loadingPalavraChave: false,
            errorPalavraChave: null,
            StatusImportacaoFormacao: 0,
            loadingFormacao: false,
            errorFormacao: null,
            StatusImportacaoUsuarios: 0,
            loadingUsuarios: false,
            errorUsuarios: null,
            StatusImportacaoAlunos: 0,
            loadingAlunos: false,
            errorAlunos: null,
            StatusImportacaoProgramaProfEPT: 0,
            loadingProgramaProfEPT: false,
            errorProgramaProfEPT: null
        };
        this.handleEtapaQualis = this.handleEtapaQualis.bind(this);
        this.handleEtapaInstituicoes = this.handleEtapaInstituicoes.bind(this);
        this.handleEtapaProfessores = this.handleEtapaProfessores.bind(this);
        this.handleEtapaCurriculos = this.handleEtapaCurriculos.bind(this);
        this.handleEtapaProducaoBibliografica = this.handleEtapaProducaoBibliografica.bind(this);
        this.handleEtapaProducaoPeriodicos = this.handleEtapaProducaoPeriodicos.bind(this);
        this.handleEtapaOrientacao = this.handleEtapaOrientacao.bind(this);
        this.handleEtapaTopProd = this.handleEtapaTopProd.bind(this);
        this.handleEtapaPalavraChave = this.handleEtapaPalavraChave.bind(this);
        this.handleEtapaFormacao = this.handleEtapaFormacao.bind(this);
        this.handleEtapaUsuarios = this.handleEtapaUsuarios.bind(this);
        this.handleEtapaAlunos = this.handleEtapaAlunos.bind(this);
        this.handleEtapaProgramaProfEPT = this.handleEtapaProgramaProfEPT.bind(this);
    }

    componentDidMount() {
        fetch('api/Adm/StatusImportacao')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ StatusImportacao: data, loadingStatus: false });
            })
            .catch(errorStatus => this.setState({ errorStatus, loadingStatus: false }));
    }
    
    handleEtapaQualis() {
        this.setState({ loadingQualis: true });

        fetch('api/Adm/EtapaQualis')
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Ocorreu um erro ao receber os dados...');
                    }
                })
                .then(data => {
                    this.setState({ StatusImportacaoQualis: data, loadingQualis: false });
                })
            .catch(errorQualis => this.setState({ errorQualis, loadingQualis: false }));

    }

    handleEtapaInstituicoes() {
        this.setState({ loadingInstituicoes: true });

        fetch('api/Adm/EtapaInstituicoes')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ StatusImportacaoInstituicoes: data, loadingInstituicoes: false });
            })
            .catch(errorInstituicoes => this.setState({ errorInstituicoes, loadingInstituicoes: false }));

    }

    handleEtapaProfessores() {
        this.setState({ loadingProfessores: true });

        fetch('api/Adm/EtapaProfessores')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ StatusImportacaoProfessores: data, loadingProfessores: false });
            })
            .catch(errorProfessores => this.setState({ errorProfessores, loadingProfessores: false }));

    }

    handleEtapaCurriculos() {
        this.setState({ loadingCurriculos: true });

        fetch('api/Adm/EtapaCurriculos')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ StatusImportacaoCurriculos: data, loadingCurriculos: false });
            })
            .catch(errorCurriculos => this.setState({ errorCurriculos, loadingCurriculos: false }));

    }

    handleEtapaProducaoBibliografica() {
        this.setState({ loadingProducaoBibliografica: true });

        fetch('api/Adm/EtapaProducaoBibliografica')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ StatusImportacaoProducaoBibliografica: data, loadingProducaoBibliografica: false });
            })
            .catch(errorProducaoBibliografica => this.setState({ errorProducaoBibliografica, loadingProducaoBibliografica: false }));
    }

    handleEtapaProducaoPeriodicos() {
        this.setState({ loadingProducaoPeriodicos: true });

        fetch('api/Adm/EtapaProducaoPeriodicos')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ StatusImportacaoProducaoPeriodicos: data, loadingProducaoPeriodicos: false });
            })
            .catch(errorProducaoPeriodicos => this.setState({ errorProducaoPeriodicos, loadingProducaoPeriodicos: false }));

    }

    handleEtapaOrientacao() {
        this.setState({ loadingOrientacao: true });

        fetch('api/Adm/EtapaOrientacao')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ StatusImportacaoOrientacao: data, loadingOrientacao: false });
            })
            .catch(errorOrientacao => this.setState({ errorOrientacao, loadingOrientacao: false }));

    }

    handleEtapaTopProd() {
        this.setState({ loadingTopProd: true });

        fetch('api/Adm/EtapaMaisProdutivos')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ StatusImportacaoTopProd: data, loadingTopProd: false });
            })
            .catch(errorTopProd => this.setState({ errorTopProd, loadingTopProd: false }));

    }

    handleEtapaPalavraChave() {
        this.setState({ loadingPalavraChave: true });

        fetch('api/Adm/EtapaPalavrasChave')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ StatusImportacaoPalavraChave: data, loadingPalavraChave: false });
            })
            .catch(errorPalavraChave => this.setState({ errorPalavraChave, loadingPalavraChave: false }));

    }

    handleEtapaFormacao() {
        this.setState({ loadingFormacao: true });

        fetch('api/Adm/EtapaFormacao')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ StatusImportacaoFormacao: data, loadingFormacao: false });
            })
            .catch(errorFormacao => this.setState({ errorFormacao, loadingFormacao: false }));

    }

    handleEtapaUsuarios() {
        this.setState({ loadingUsuarios: true });

        fetch('api/Adm/EtapaUsuarios')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ StatusImportacaoUsuarios: data, loadingUsuarios: false });
            })
            .catch(errorUsuarios => this.setState({ errorUsuarios, loadingUsuarios: false }));

    }

    handleEtapaAlunos() {
        this.setState({ loadingAlunos: true });

        fetch('api/Adm/EtapaAlunos')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ StatusImportacaoAlunos: data, loadingAlunos: false });
            })
            .catch(errorAlunos => this.setState({ errorAlunos, loadingAlunos: false }));
    }

    handleEtapaProgramaProfEPT() {
        this.setState({ loadingProgramaProfEPT: true });

        fetch('api/Adm/EtapaProgramaProfEPT')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ StatusImportacaoProgramaProfEPT: data, loadingProgramaProfEPT: false });
            })
            .catch(errorProgramaProfEPT => this.setState({ errorProgramaProfEPT, loadingProgramaProfEPT: false }));
    }

    renderQualis() {
        if (this.state.StatusImportacaoQualis) {
            return (
                <div>
                    Importação Finalizada. Total de Periódicos importados: {this.state.StatusImportacaoQualis.qtdePeriodicosLidos}
                </div>
            );
        }
        else if (this.state.loadingQualis == false) {
            return (
                <div>
                    Importar todos os periódicos da CAPES:&nbsp;&nbsp;
                    <Button
                        bsStyle="success"
                        bsSize='small'
                        disabled={this.state.loadingQualis}
                        onClick={!this.state.loadingQualis ? this.handleEtapaQualis : null}
                    >
                        {this.state.loadingQualis ? 'Lendo dados...' : 'Importar'}
                    </Button>
                </div>
            );
        }
        return (
            <div className="overlay">
                <i className="fa fa-refresh fa-spin"></i> <em>Importando dados... Este processo demora em torno de 30 minutos.</em>
            </div>
        );
    }

    renderInstituicoes() {
        if (this.state.StatusImportacaoInstituicoes > 0) {
            return (
                <div>
                    Cadastro das {this.state.StatusImportacaoInstituicoes} instituições finalizado.
                </div>
            );
        }
        else if (this.state.loadingInstituicoes == false) {
            return (
                <div>
                    Cadastrar Instituições no Observatório:&nbsp;&nbsp;
                    <Button
                        bsStyle="success"
                        bsSize='small'
                        disabled={this.state.loadingInstituicoes}
                        onClick={!this.state.loadingInstituicoes ? this.handleEtapaInstituicoes : null}
                    >
                        {this.state.loadingInstituicoes ? 'Lendo dados...' : 'Cadastrar'}
                    </Button>
                </div>
            );
        }
        return (
            <div className="overlay">
                <i className="fa fa-refresh fa-spin"></i> <em>Cadastrando instituições... Este processo é rápido.</em>
            </div>
        );
    }

    renderProfessores() {
        if (this.state.StatusImportacaoProfessores > 0) {
            return (
                <div>
                    Cadastro dos {this.state.StatusImportacaoProfessores} docentes finalizado.
                </div>
            );
        }
        else if (this.state.loadingProfessores == false) {
            return (
                <div>
                    Cadastrar Professores no Observatório:&nbsp;&nbsp;
                    <Button
                        bsStyle="success"
                        bsSize='small'
                        disabled={this.state.loadingProfessores}
                        onClick={!this.state.loadingProfessores ? this.handleEtapaProfessores : null}
                    >
                        {this.state.loadingProfessores ? 'Lendo dados...' : 'Cadastrar'}
                    </Button>
                </div>
            );
        }
        return (
            <div className="overlay">
                <i className="fa fa-refresh fa-spin"></i> <em>Cadastrando professores... Este processo demora em torno de 2 minutos.</em>
            </div>
        );
    }

    renderCurriculos() {
        if (this.state.StatusImportacaoCurriculos > 0) {
            return (
                <div>
                    Total de {this.state.StatusImportacaoCurriculos} currículos importados.
                </div>
            );
        }
        else if (this.state.loadingCurriculos == false) {
            return (
                <div>
                    Importar os Currículos dos Professores no Observatório:&nbsp;&nbsp;
                    <Button
                        bsStyle="success"
                        bsSize='small'
                        disabled={this.state.loadingCurriculos}
                        onClick={!this.state.loadingCurriculos ? this.handleEtapaCurriculos : null}
                    >
                        {this.state.loadingCurriculos ? 'Lendo dados...' : 'Importar'}
                    </Button>
                </div>
            );
        }
        return (
            <div className="overlay">
                <i className="fa fa-refresh fa-spin"></i> <em>Importando currículos... Este processo demora em torno de 3 horas.</em>
            </div>
        );
    }

    renderProducaoBibliografica() {
        if (this.state.StatusImportacaoProducaoBibliografica > 0) {
            return (
                <div>
                    Total de {this.state.StatusImportacaoProducaoBibliografica} estatísticas de produção bibliográfica geradas.
                </div>
            );
        }
        else if (this.state.loadingProducaoBibliografica == false) {
            return (
                <div>
                    Gerar estatística da Produção Bibliográfica dos professores:&nbsp;&nbsp;
                    <Button
                        bsStyle="success"
                        bsSize='small'
                        disabled={this.state.loadingProducaoBibliografica}
                        onClick={!this.state.loadingProducaoBibliografica ? this.handleEtapaProducaoBibliografica : null}
                    >
                        {this.state.loadingProducaoBibliografica ? 'Lendo dados...' : 'Gerar Estatística'}
                    </Button>
                </div>
            );
        }
        return (
            <div className="overlay">
                <i className="fa fa-refresh fa-spin"></i> <em>Gerando as estatísticas de produção bibliográfica... Este processo demora em torno de 10 minutos.</em>
            </div>
        );
    }

    renderProducaoPeriodicos() {
        if (this.state.StatusImportacaoProducaoPeriodicos > 0) {
            return (
                <div>
                    Total de {this.state.StatusImportacaoProducaoPeriodicos} estatísticas de produção em periódicos geradas.
                </div>
            );
        }
        else if (this.state.loadingProducaoPeriodicos == false) {
            return (
                <div>
                    Gerar estatística da Produção em Periódicos dos professores:&nbsp;&nbsp;
                    <Button
                        bsStyle="success"
                        bsSize='small'
                        disabled={this.state.loadingProducaoPeriodicos}
                        onClick={!this.state.loadingProducaoPeriodicos ? this.handleEtapaProducaoPeriodicos : null}
                    >
                        {this.state.loadingProducaoPeriodicos ? 'Lendo dados...' : 'Gerar Estatística'}
                    </Button>
                </div>
            );
        }
        return (
            <div className="overlay">
                <i className="fa fa-refresh fa-spin"></i> <em>Gerando as estatísticas de produção em periódicos... Este processo demora em torno de 1 hora.</em>
            </div>
        );
    }

    renderOrientacao() {
        if (this.state.StatusImportacaoOrientacao > 0) {
            return (
                <div>
                    Total de {this.state.StatusImportacaoOrientacao} estatísticas de orientações geradas.
                </div>
            );
        }
        else if (this.state.loadingOrientacao == false) {
            return (
                <div>
                    Gerar estatística das orientações dos professores:&nbsp;&nbsp;
                    <Button
                        bsStyle="success"
                        bsSize='small'
                        disabled={this.state.loadingOrientacao}
                        onClick={!this.state.loadingOrientacao ? this.handleEtapaOrientacao : null}
                    >
                        {this.state.loadingOrientacao ? 'Lendo dados...' : 'Gerar Estatística'}
                    </Button>
                </div>
            );
        }
        return (
            <div className="overlay">
                <i className="fa fa-refresh fa-spin"></i> <em>Gerando as estatísticas de orientações... Este processo demora em torno de 1 minuto.</em>
            </div>
        );
    }

    renderTopProd() {
        if (this.state.StatusImportacaoTopProd > 0) {
            return (
                <div>
                    Total de {this.state.StatusImportacaoTopProd} estatísticas de professores com maiores produções científicas.
                </div>
            );
        }
        else if (this.state.loadingTopProd == false) {
            return (
                <div>
                    Gerar estatística dos professores com maiores produções científicas:&nbsp;&nbsp;
                    <Button
                        bsStyle="success"
                        bsSize='small'
                        disabled={this.state.loadingTopProd}
                        onClick={!this.state.loadingTopProd ? this.handleEtapaTopProd : null}
                    >
                        {this.state.loadingTopProd ? 'Lendo dados...' : 'Gerar Estatística'}
                    </Button>
                </div>
            );
        }
        return (
            <div className="overlay">
                <i className="fa fa-refresh fa-spin"></i> <em>Gerando as estatísticas de professores com maiores produções científicas... Este processo demora em torno de 5 minutos.</em>
            </div>
        );
    }

    renderPalavraChave() {
        if (this.state.StatusImportacaoPalavraChave > 0) {
            return (
                <div>
                    Total de {this.state.StatusImportacaoPalavraChave} estatísticas de palavras-chave.
                </div>
            );
        }
        else if (this.state.loadingPalavraChave === false) {
            return (
                <div>
                    Gerar estatística dos assuntos mais pesquisados nas instituições:&nbsp;&nbsp;
                    <Button
                        bsStyle="success"
                        bsSize='small'
                        disabled={this.state.loadingPalavraChave}
                        onClick={!this.state.loadingPalavraChave ? this.handleEtapaPalavraChave : null}
                    >
                        {this.state.loadingPalavraChave ? 'Lendo dados...' : 'Gerar Estatística'}
                    </Button>
                </div>
            );
        }
        return (
            <div className="overlay">
                <i className="fa fa-refresh fa-spin"></i> <em>Gerando as estatísticas dos assuntos mais pesquisados nas instituições... Este processo demora em torno de 50 minutos.</em>
            </div>
        );
    }

    renderFormacao() {
        if (this.state.StatusImportacaoFormacao > 0) {
            return (
                <div>
                    Total de {this.state.StatusImportacaoFormacao} estatísticas de formação acadêmica.
                </div>
            );
        }
        else if (this.state.loadingFormacao === false) {
            return (
                <div>
                    Gerar estatística da formação acadêmica dos docentes:&nbsp;&nbsp;
                    <Button
                        bsStyle="success"
                        bsSize='small'
                        disabled={this.state.loadingFormacao}
                        onClick={!this.state.loadingFormacao ? this.handleEtapaFormacao : null}
                    >
                        {this.state.loadingFormacao ? 'Lendo dados...' : 'Gerar Estatística'}
                    </Button>
                </div>
            );
        }
        return (
            <div className="overlay">
                <i className="fa fa-refresh fa-spin"></i> <em>Gerando as estatísticas da formação dos docentes... Este processo demora em torno de 10 minutos.</em>
            </div>
        );
    }

    renderUsuarios() {
        if (this.state.StatusImportacaoUsuarios > 0) {
            return (
                <div>
                    Total de {this.state.StatusImportacaoUsuarios} usuários.
                </div>
            );
        }
        else if (this.state.loadingUsuarios === false) {
            return (
                <div>
                    Gerar usuários:&nbsp;&nbsp;
                    <Button
                        bsStyle="success"
                        bsSize='small'
                        disabled={this.state.loadingUsuarios}
                        onClick={!this.state.loadingUsuarios ? this.handleEtapaUsuarios : null}
                    >
                        {this.state.loadingUsuarios ? 'Lendo dados...' : 'Gerar Usuários'}
                    </Button>
                </div>
            );
        }
        return (
            <div className="overlay">
                <i className="fa fa-refresh fa-spin"></i> <em>Gerando usuários... Este processo demora em torno de 2 minutos.</em>
            </div>
        );
    }

    renderAlunos() {
        if (this.state.StatusImportacaoAlunos > 0) {
            return (
                <div>
                    Total de {this.state.StatusImportacaoAlunos} alunos.
                </div>
            );
        }
        else if (this.state.loadingAlunos === false) {
            return (
                <div>
                    Importar alunos:&nbsp;&nbsp;
                    <Button
                        bsStyle="success"
                        bsSize='small'
                        disabled={this.state.loadingAlunos}
                        onClick={!this.state.loadingAlunos ? this.handleEtapaAlunos : null}
                    >
                        {this.state.loadingAlunos ? 'Lendo dados...' : 'Importar Alunos'}
                    </Button>
                </div>
            );
        }
        return (
            <div className="overlay">
                <i className="fa fa-refresh fa-spin"></i> <em>Importando alunos... Este processo demora em torno de 2 minutos.</em>
            </div>
        );
    }

    renderProgramaProfEPT() {
        if (this.state.StatusImportacaoProgramaProfEPT > 0) {
            return (
                <div>
                    Total de {this.state.StatusImportacaoProgramaProfEPT} dados importados.
                </div>
            );
        }
        else if (this.state.loadingProgramaProfEPT === false) {
            return (
                <div>
                    Configurar dados do Programa ProfEPT (Coordenação, Oferta disciplina, disciplinas eletivas):&nbsp;&nbsp;
                    <Button
                        bsStyle="success"
                        bsSize='small'
                        disabled={this.state.loadingProgramaProfEPT}
                        onClick={!this.state.loadingProgramaProfEPT ? this.handleEtapaProgramaProfEPT : null}
                    >
                        {this.state.loadingProgramaProfEPT ? 'Lendo dados...' : 'Configurar programa ProfEPT'}
                    </Button>
                </div>
            );
        }
        return (
            <div className="overlay">
                <i className="fa fa-refresh fa-spin"></i> <em>Configurando dados do ProfEPT... Este processo demora em torno de 1 minuto.</em>
            </div>
        );
    }    

    render() {        
        const { loadingStatus, errorStatus } = this.state;
        let contentStatusTotalLinhasExcel = <p>...</p>;
        if (errorStatus) {
            contentStatusTotalLinhasExcel = <p>{errorStatus.message}</p>;
        }
        else if (loadingStatus) {
            contentStatusTotalLinhasExcel = <div className="overlay">
                                                <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                                            </div>;
        }
        else {
            contentStatusTotalLinhasExcel = this.state.StatusImportacao.totalLinhasExcel;
        }
        let contentTotalLinhasExcel = <p>Total de linhas do arquivo Excel para importação: {contentStatusTotalLinhasExcel}</p>;

        const { loadingQualis, errorQualis } = this.state;
        let contentsQualis = <p>Conteudo</p>;
        if (errorQualis) {
            contentsQualis = <p>{errorQualis.message}</p>;
        }
        else {
            contentsQualis = this.renderQualis();
        }

        const { loadingInstituicoes, errorInstituicoes } = this.state;
        let contentsInstituicoes = <p>Conteudo</p>;
        if (errorInstituicoes) {
            contentsInstituicoes = <p>{errorInstituicoes.message}</p>;
        }
        else {
            contentsInstituicoes = this.renderInstituicoes();
        }

        const { loadingProfessores, errorProfessores } = this.state;
        let contentsProfessores = <p>Conteudo</p>;
        if (errorProfessores) {
            contentsProfessores = <p>{errorProfessores.message}</p>;
        }
        else {
            contentsProfessores = this.renderProfessores();
        }

        const { loadingCurriculos, errorCurriculos } = this.state;
        let contentsCurriculos = <p>Conteudo</p>;
        if (errorCurriculos) {
            contentsCurriculos = <p>{errorCurriculos.message}</p>;
        }
        else {
            contentsCurriculos = this.renderCurriculos();
        }

        const { loadingProducaoBibliografica, errorProducaoBibliografica } = this.state;
        let contentsProducaoBibliografica = <p>Conteudo</p>;
        if (errorProducaoBibliografica) {
            contentsProducaoBibliografica = <p>{errorProducaoBibliografica.message}</p>;
        }
        else {
            contentsProducaoBibliografica = this.renderProducaoBibliografica();
        }

        const { loadingProducaoPeriodicos, errorProducaoPeriodicos } = this.state;
        let contentsProducaoPeriodicos = <p>Conteudo</p>;
        if (errorProducaoPeriodicos) {
            contentsProducaoPeriodicos = <p>{errorProducaoPeriodicos.message}</p>;
        }
        else {
            contentsProducaoPeriodicos = this.renderProducaoPeriodicos();
        }

        const { loadingOrientacao, errorOrientacao } = this.state;
        let contentsOrientacao = <p>Conteudo</p>;
        if (errorOrientacao) {
            contentsOrientacao = <p>{errorOrientacao.message}</p>;
        }
        else {
            contentsOrientacao = this.renderOrientacao();
        }

        const { loadingTopProd, errorTopProd } = this.state;
        let contentsTopProd = <p>Conteudo</p>;
        if (errorTopProd) {
            contentsTopProd = <p>{errorTopProd.message}</p>;
        }
        else {
            contentsTopProd = this.renderTopProd();
        }

        const { loadingPalavrachave, errorPalavraChave } = this.state;
        let contentsPalavraChave = <p>Conteudo</p>;
        if (errorPalavraChave) {
            contentsPalavraChave = <p>{errorPalavraChave.message}</p>;
        }
        else {
            contentsPalavraChave = this.renderPalavraChave();
        }

        const { loadingFormacao, errorFormacao } = this.state;
        let contentsFormacao = <p>Conteudo</p>;
        if (errorFormacao) {
            contentsFormacao = <p>{errorFormacao.message}</p>;
        }
        else {
            contentsFormacao = this.renderFormacao();
        }

        const { loadingUsuarios, errorUsuarios } = this.state;
        let contentsUsuarios = <p>Conteudo</p>;
        if (errorUsuarios) {
            contentsUsuarios = <p>{errorUsuarios.message}</p>;
        }
        else {
            contentsUsuarios = this.renderUsuarios();
        }

        const { loadingAlunos, errorAlunos } = this.state;
        let contentsAlunos = <p>Conteudo</p>;
        if (errorAlunos) {
            contentsAlunos = <p>{errorAlunos.message}</p>;
        }
        else {
            contentsAlunos = this.renderAlunos();
        }

        const { loadingProgramaProfEPT, errorProgramaProfEPT } = this.state;
        let contentsProgramaProfEPT = <p>Conteudo</p>;
        if (errorProgramaProfEPT) {
            contentsProgramaProfEPT = <p>{errorProgramaProfEPT.message}</p>;
        }
        else {
            contentsProgramaProfEPT = this.renderProgramaProfEPT();
        }

        return (        
          <Container >
              <Row>
                  <Col sm={12}>
                      <h2>Administração dos dados do Observatório ProfEPT</h2>
                  </Col>
              </Row>
              <Row>
                  <Col sm={12}>
                      <PainelConteudo legenda="Etapa 1: Periódicos Qualis"
                          texto={
                              <Row>
                                  <Col sm={12}>
                                      {contentTotalLinhasExcel}
                                      {contentsQualis}
                                  </Col>
                              </Row>
                          } />
                  </Col>
              </Row>
                <Row>
                    <Col sm={12}>
                        <PainelConteudo legenda="Etapa 2: Currículos Professores"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        {contentsCurriculos}
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <PainelConteudo legenda="Etapa 3.1: Estatística de Produção Bibliográfica"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        {contentsProducaoBibliografica}
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <PainelConteudo legenda="Etapa 3.2: Estatística de Produção em Periódicos"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        {contentsProducaoPeriodicos}
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <PainelConteudo legenda="Etapa 4: Estatística de Orientações e Bancas"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        {contentsOrientacao}
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <PainelConteudo legenda="Etapa 5: Estatística de Professores com Mais Produções Científicas"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        {contentsTopProd}
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <PainelConteudo legenda="Etapa 6: Estatística de Assuntos mais Pesquisados nas Instituições"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        {contentsPalavraChave}
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <PainelConteudo legenda="Etapa 7: Estatística de Formação Acadêmica dos Docentes"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        {contentsFormacao}
                                    </Col>
                                </Row>
                            } />
                    </Col>
                </Row>
          </Container >
        );
    }
}
