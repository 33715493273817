import React, { useState, useContext, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, DropdownItem, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from '../api-authorization/LoginMenu';
import './NavMenu.css';
import Logo from '../Logo/Logo';
import imgLogo from '../../imagens/IF_Branco.png';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';
import InfoContext from '../../context/infoContext/infoContext';

export function NavMenu(props) {

    const idCt = useContext(IdiomaContext);
    const infoContext = useContext(InfoContext);
    const [collapsed, setCollapsed] = useState(true);

    const [mostraPerfilEgresso, setMostraPerfilEgresso] = useState(false);

    useEffect(() => {
        //componentDidMount
        if ((infoContext.user != null) && ((infoContext.user.profile.admin) || (infoContext.user.profile.coordenadorNacional) ||
            (infoContext.user.profile.coordenadorCurso) || (infoContext.user.profile.secretario) || (infoContext.user.profile.aluno) )) {
            setMostraPerfilEgresso(true);
        }
    }, [infoContext.user]); 

    function toggleNavbar() {
        setCollapsed(!collapsed);
        //#01420a
    }
    //<Navbar.Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} >

    function renderAll() {
        let contentsEgresso = null;
        if (mostraPerfilEgresso) {
            contentsEgresso =
                <LinkContainer to={'/perfilEgresso'}>
                    <NavDropdown.Item >
                        <span><i className='fa fa-calendar-check-o'></i> {idCt.t("Defesa de Dissertação", "Dissertation Defense")} </span>
                    </NavDropdown.Item >
                </LinkContainer>
        }
        return (
            <header>
                <Navbar collapseOnSelect className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" variant="light" bg='light' expand="lg">
                    <Container style={{ maxWidth: "980px" }}>
                        <Navbar.Brand>
                            <Nav.Link style={{ padding: "0px", marginRight: "5px", color: "black" }} as={Link} to={'/'} >
                                <Logo />
                            </Nav.Link>
                        </Navbar.Brand>
                        <Navbar.Toggle className="mr-2" aria-controls="navbar-midi" onClick={toggleNavbar} />
                        <Navbar.Collapse className="d-sm-inline-flex flex-sm-row-reverse" >
                            <ul className="navbar-nav flex-grow">
                                <Nav.Item as="li">
                                    <LinkContainer to={'/'}>
                                        <Nav.Link>
                                            <i className="fa fa-th"></i> {idCt.t("Home", "Home")}
                                        </Nav.Link>
                                    </LinkContainer>
                                </Nav.Item>

                                <NavDropdown title={<span><i className='fa fa-pie-chart'></i> {idCt.t("Indicadores", "Indicators")} </span>}
                                    id="indicadores-dropdown">
                                    <NavDropdown title={<span><i className='fa fa-line-chart'></i> {idCt.t("Docentes", "Professors")} </span>} id="proddocente-dropdown"
                                        className='dropdown-submenu' drop='right' key='right' >
                                        <LinkContainer to={'/indInstituicao'}>
                                            <NavDropdown.Item>
                                                {idCt.t("Produção por Instituição", "Production by Institution")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <LinkContainer to={'/indAno'}>
                                            <NavDropdown.Item>
                                                {idCt.t("Produção por Ano", "Production per year")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <LinkContainer to={'/indEstado'}>
                                            <NavDropdown.Item>
                                                {idCt.t("Produção por Estado", "Production by State")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <LinkContainer to={'/indProfessor'}>
                                            <NavDropdown.Item>
                                                {idCt.t("Produção por Professor", "Production by Professor")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                    </NavDropdown>
                                    <NavDropdown title={<span><i className='fa fa-bar-chart'></i> {idCt.t("Programa", "Program")} </span>} id="prodprograma-dropdown"
                                        className='dropdown-submenu' drop='right' key='right'>
                                        <LinkContainer to={'/indAssuntos'}>
                                            <NavDropdown.Item>
                                                {idCt.t("Assuntos Pesquisados", "Research topics")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <LinkContainer to={'/residenciaAlunos'}>
                                            <NavDropdown.Item>
                                                {idCt.t("Residência dos Discentes/Egressos", "Residence of Students / Alumni")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                    </NavDropdown>
                                    <NavDropdown.Divider />
                                    <NavDropdown title={<span><i className='fa fa-area-chart'></i> {idCt.t("Autoavaliação", "Self-evaluation")} </span>} id="proddicente-dropdown"
                                        className='dropdown-submenu' drop="right" key="right">
                                        <LinkContainer to={'/autoAvalRelPrograma'}>
                                            <NavDropdown.Item >
                                                {idCt.t("Programa", "Program")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <LinkContainer to={'/autoAvalRelDisc'}>
                                            <NavDropdown.Item >
                                                {idCt.t("Disciplinas", "Courses")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <NavDropdown.Divider />
                                        <LinkContainer to={'/autoAvalRelObs'}>
                                            <NavDropdown.Item >
                                                {idCt.t("Observatório", "Observatory")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                    </NavDropdown>
                                </NavDropdown>

                                <NavDropdown title={<span><i className='fa fa-users'></i> {idCt.t("Pessoas", "Academics")} </span>}
                                    id="pesquisadores-dropdown">
                                    <NavDropdown title={<span><i className='fa fa-user-secret'></i> {idCt.t("Docentes", "Professors")}</span>} id="docentes-dropdown"
                                        className='dropdown-submenu' drop='right' key='right'>
                                        <LinkContainer to={'/ListaPesquisadores'}>
                                            <NavDropdown.Item >
                                                {idCt.t("Permanentes", "Permanent")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <LinkContainer to={'/FormacaoAcademica'}>
                                            <NavDropdown.Item >
                                                {idCt.t("Formação Acadêmica", "Academic Education")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <LinkContainer to={'/AssuntoPesq'}>
                                            <NavDropdown.Item >
                                                {idCt.t("Temas de Pesquisa", "Research topics")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <LinkContainer to={'/MaisProdutivos'}>
                                            <NavDropdown.Item >
                                                {idCt.t("Produção Científica", "Scientific Production")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <NavDropdown.Divider />
                                        <LinkContainer to={'/ListaDescredenciados'}>
                                            <NavDropdown.Item >
                                                {idCt.t("Descredenciados", "Output Professors")}
                                            </NavDropdown.Item >
                                        </LinkContainer>                                    

                                    </NavDropdown>

                                    <LinkContainer to={'/Coordenadores'}>
                                        <NavDropdown.Item >
                                            <span><i className='fa fa-comments'></i> {idCt.t("Coordenadores", "Coordinators")} </span>
                                        </NavDropdown.Item >
                                    </LinkContainer>

                                    <LinkContainer to={'/Secretarios'}>
                                        <NavDropdown.Item >
                                            <span><i className='fa fa-phone'></i> {idCt.t("Secretários", "Secretaries")} </span>
                                        </NavDropdown.Item >
                                    </LinkContainer>

                                    <NavDropdown.Divider />

                                    <LinkContainer to={'/Discentes'}>
                                        <NavDropdown.Item >
                                            <span><i className='fa fa-vcard-o'></i> {idCt.t("Discentes", "Master Students")} </span>
                                        </NavDropdown.Item >
                                    </LinkContainer>

                                    <LinkContainer to={'/Egressos'}>
                                        <NavDropdown.Item >
                                            <span><i className='fa fa-graduation-cap'></i> {idCt.t("Egressos", "Alumni")} </span>
                                        </NavDropdown.Item >
                                    </LinkContainer>

                                </NavDropdown>

                                <NavDropdown title={<span ><i className='fa fa-university'></i> {idCt.t("Programa", "Program")}</span>} id="programa-dropdown">
                                    <LinkContainer to={'/instituicoes'}>
                                        <NavDropdown.Item >
                                            <span><i className='fa fa-handshake-o'></i> {idCt.t("Instituições Associadas", "Associated Institutions")} </span>
                                        </NavDropdown.Item >
                                    </LinkContainer>
                                    <LinkContainer to={'/Egressos'}>
                                        <NavDropdown.Item >
                                            <span><i className='fa fa-clone'></i> {idCt.t("Dissertações e Produtos", "Dissertations and Products")} </span>
                                        </NavDropdown.Item >
                                    </LinkContainer>
                                    <NavDropdown title={<span><i className='fa fa-book'></i> {idCt.t("Disciplinas Eletivas", "Elective Courses")}</span>} id="disciplinas-dropdown"
                                        className='dropdown-submenu' drop='right' key='right'>
                                        <LinkContainer to={'/comeceAquiDisc'}>
                                            <NavDropdown.Item >
                                                {idCt.t("Comece aqui", "Start here")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <NavDropdown.Divider />
                                        <LinkContainer to={'/disciplinasEletivas'}>
                                            <NavDropdown.Item >
                                                {idCt.t("Consulta", "Search")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <LinkContainer to={'/interessedisciplina'}>
                                            <NavDropdown.Item >
                                                {idCt.t("Oferta / Procura", "Supply and demand")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <LinkContainer to={'/ofertaProcuraDisc'}>
                                            <NavDropdown.Item >
                                                {idCt.t("Relação Oferta / Procura", "Relation of supply and demand")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <LinkContainer to={'/alunoProcuraDisc'}>
                                            <NavDropdown.Item >
                                                {idCt.t("Relação Por Aluno", "Relation per student")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <LinkContainer to={'/turmasDiscEletivas'}>
                                            <NavDropdown.Item >
                                                {idCt.t("Turmas", "Classes")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <LinkContainer to={'/docsDiscEletivas'}>
                                            <NavDropdown.Item >
                                                {idCt.t("Documentos Matrícula", "Registration Documents")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <LinkContainer to={'/exportarTurmas'}>
                                            <NavDropdown.Item >
                                                {idCt.t("Exportar Turmas (Moodle)", "Export Classes (Moodle)")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                    </NavDropdown>
                                    <NavDropdown title={<span><i className='fa fa-question-circle-o'></i> {idCt.t("Autoavaliação", "Self-Assessment")}</span>} id="disciplinas-dropdown"
                                        className='dropdown-submenu' drop='right' key='right'>
                                        <LinkContainer to={'/comeceAquiAutoAval'}>
                                            <NavDropdown.Item >
                                                {idCt.t("Comece aqui", "Start here")}
                                            </NavDropdown.Item >
                                        </LinkContainer>
                                        <NavDropdown.Divider /> 
                                        <LinkContainer to={'/autoAvalResponder'}>
                                            <NavDropdown.Item >
                                                {idCt.t("Responder", "Answer")}
                                            </NavDropdown.Item >
                                        </LinkContainer>   
                                        <LinkContainer to={'/autoAvalAcompanhar'}>
                                            <NavDropdown.Item >
                                                {idCt.t("Acompanhar", "Follow")}
                                            </NavDropdown.Item >
                                        </LinkContainer>                                         
                                    </NavDropdown>
                                    {contentsEgresso}

                                </NavDropdown>

                                <LoginMenu>
                                </LoginMenu>

                            </ul>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    return (renderAll());
}
