import authService from '../components/api-authorization/AuthorizeService';

const documentoService = {
    PutDocumentoAluno   
}

async function PutDocumentoAluno(pBody) {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: 'PUT',
        headers: headers,
        body: pBody,
        cache: 'default'
    };
    return await fetch('api/Documentos/PutDocumentoAluno', options);
}

export default documentoService;

