import React, { Component } from 'react';
//import { FormControl } from 'react-bootstrap'; ///lib/FormControl';
import { MultiSelect } from 'primereact/multiselect';

export default class SelecMultAnos extends Component {    
    AnosSelectItems = [];

    constructor(props) {
        super(props);
        this.state = {
            Anos: [],
            AnosSelecionados: [],
            loading: true,
            error: null
        };
    }

    componentDidMount() {
        fetch('api/Indicadores/QuaisAnos')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ Anos: data, loading: false });
                let a = [];
                a.push(this.state.Anos[0]);
                this.setState({ AnosSelecionados: a });
                this.mapearAnos(this.state.Anos);
                this.props.AtualizaQualAno(a);
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    mapearAnos(pAnos) {
        let a = pAnos;
        for (let i = 0; i < a.length; i++) {
            this.AnosSelectItems.push({
                label: a[i],
                value: a[i]
            });
        } 
    }

    AlteraAnos(pValor) {
        this.setState({ AnosSelecionados: pValor });
        this.props.AtualizaQualAno(pValor);
    }

    renderSelectMultAnos() {
        return (
            <div>
                <MultiSelect value={/*this.props.AtualizaQualAno*/this.state.AnosSelecionados} options={this.AnosSelectItems}
                    placeholder="Escolha"
                    onChange={(e) => this.AlteraAnos(e.value) /*this.props.AtualizaQualAno(e.value) this.setState({ AnosSelecionados: e.value })*/} />
            </div>       
        );
    }

    render() {
        const { loading, error } = this.state;
        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents = <div className="overlay">
                         <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                       </div>;
        }
        else {
            contents = this.renderSelectMultAnos();
        }

        return (
            <div>
                {contents}
            </div>
        );

    }
}