import React, { Component } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import Mensagens from '../Mensagens/Mensagens';

export default class SelecInstituicaoAluno extends Component {    

    constructor(props) {
        super(props);
        this.state = {
            Instituicao: null,
            Instituicoes: null,
            ObjInstituicoes: null,
            instituicaoSuggestions: null,
            Aluno: null,
            Alunos: null,
            ObjAlunos: null,
            alunoSuggestions: null,
            AlunoId: null,
            loadingInstituicoes: true,
            loadingAlunos: true,
            mensagemAviso: null,
            ExibeMensagem: false,
            IndiceAluno: -1
        };
    }

    componentDidMount() {
        this.CarregarInstituicoes();
    }

    CarregarInstituicoes() {
        this.setState({ loadingInstituicoes: true, loadingAlunos: false, Alunos: null });

        fetch('api/Indicadores/QuaisInstituicoes?ComTodas=1')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                let a = [];
                for (let i = 0; i < data.length; i++) {
                    a.push(data[i].sigla);
                }
                this.setState({ ObjInstituicoes: data, Instituicoes: a, loadingInstituicoes: false });
            })
            .catch(error => this.setState({
                ExibeMensagem: true, loadingInstituicoes: false,
                mensagemAviso: { statusCode: 2, statusDescription: "Ocorreu ao ler instituições.", statusVariant: "error" }
            }));
    }

    ProcuraAlunos(QualInstituicao) {
        this.setState({ loadingInstituicoes: false, loadingAlunos: true });

        fetch('api/Discentes/ListaDiscentes?QualInstituicao=' + QualInstituicao)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                let a = [];
                for (let i = 0; i < data.length; i++) {
                    a.push(data[i].nome);
                }
                this.setState({ ObjAlunos: data, Alunos: a, loadingAlunos: false });
            })
            .catch(error => this.setState({
                ExibeMensagem: true, loadingAlunos: false,
                mensagemAviso: { statusCode: 2, statusDescription: "Ocorreu ao ler alunos.", statusVariant: "error" }
            }));
    }

    suggestInstituicoes(event) {
        let results = this.state.Instituicoes.filter((brand) => {
            return brand.toLowerCase().startsWith(event.query.toLowerCase());
        });

        this.setState({ instituicaoSuggestions: results });
    }

    AlteraInstituicao(pValor) {
        let QualId = -1;
        for (let i = 0; i < this.state.ObjInstituicoes.length; i++) {
            if (this.state.ObjInstituicoes[i].sigla === pValor) {
                QualId = this.state.ObjInstituicoes[i].id;
            }
        }
        if (QualId > -1) {
            this.setState({ Instituicao: pValor });
            this.ProcuraAlunos(QualId);
        }
        else {
            this.setState({ Instituicao: pValor, Alunos: null, AlunoId: null });
        }
    }

    suggestAlunos(event) {
        let results = this.state.Alunos.filter((brand) => {
            return brand.toLowerCase().startsWith(event.query.toLowerCase());
        });

        this.setState({ alunoSuggestions: results });
    }

    AlteraAluno(pValor) {
        let IndiceAluno = -1;        
        for (let i = 0; i < this.state.ObjAlunos.length; i++) {
            if (this.state.ObjAlunos[i].nome === pValor.toUpperCase()) {
                IndiceAluno = i;
            }
        }
        if (IndiceAluno > -1) {
            this.setState({ Aluno: pValor, IndiceAluno });
            this.props.AtualizaQualAluno(this.state.ObjAlunos[IndiceAluno]);
        }
        else {
            if (IndiceAluno != this.state.IndiceAluno) {
                this.setState({ Aluno: pValor, IndiceAluno });
                let NenhumAluno = { id: 0, nome: "" };
                this.props.AtualizaQualAluno(NenhumAluno);
            }
            else {
                this.setState({ Aluno: pValor });
            }
        }
    }

    itemTemplate(brand) {
        return (
            <div style={{ maxWidth: '80%', fontSize: '14px', margin: '0 0 0 0', padding: '0px' }}><span>{brand}</span></div>
        );
    }

    renderSelectInstituicoes() {
        var TamanhoInput = 26;
        var windowWidth = window.innerWidth;
        if (windowWidth < 770) { //Celular
            TamanhoInput = 26;
        }
        return (
            <div>
                <br />
                <span className="p-float-label">
                    <AutoComplete dropdown={true}
                        size={TamanhoInput}
                        onChange={(e) => this.AlteraInstituicao(e.value)}
                        value={this.state.Instituicao}
                        suggestions={this.state.instituicaoSuggestions}
                        itemTemplate={this.itemTemplate.bind(this)}
                        completeMethod={this.suggestInstituicoes.bind(this)} />
                    <label htmlFor="in">Selecione a sua instituição</label>
                </span>
            </div>
        );
    }

    renderAlunos() {
        var TamanhoInput = 26;
        var windowWidth = window.innerWidth;
        if (windowWidth < 770) { //Celular
            TamanhoInput = 26;
        }
        return (
            <div>
                <br />
                <span className="p-float-label">
                    <AutoComplete dropdown={true}
                        size={TamanhoInput}
                        onChange={(e) => this.AlteraAluno(e.value)}
                        value={this.state.Aluno}
                        suggestions={this.state.alunoSuggestions}
                        itemTemplate={this.itemTemplate.bind(this)}
                        completeMethod={this.suggestAlunos.bind(this)} />
                    <label htmlFor="in">Nome</label>
                </span>
            </div>
        );
    }

    render() {
        const { loadingInstituicoes, loadingAlunos, mensagemAviso, ExibeMensagem } = this.state;
        let contentsInst = <p></p>;
        let contentsAluno = <p></p>;
        let contentsAviso = <p></p>;

        if (mensagemAviso) {
            contentsAviso =  <Mensagens Exibir={ExibeMensagem} AtualizaExibir={this.updateExibeMensagem}
                                        Opcao={mensagemAviso.statusVariant} Mensagem={mensagemAviso.statusDescription} />;
            return (
                <div>
                    {contentsAviso}
                </div>
            );
        }
        if (loadingInstituicoes) {
            contentsInst = <div className="overlay">
                              <i className="fa fa-refresh fa-spin"></i> <em>Lendo instituições...</em>
                           </div>;
        }
        else {
            contentsInst = this.renderSelectInstituicoes();
        }

        if (this.state.Alunos) {
            if (loadingAlunos) {
                contentsAluno = <div className="overlay">
                                    <i className="fa fa-refresh fa-spin"></i> <em>Lendo alunos...</em>
                                </div>;
            }
            else {
                contentsAluno = this.renderAlunos();
            }
        }

        return (
            <div style={{ width: "100%" }}>
                {contentsInst}<br />
                {contentsAluno}
            </div>
        );       

    }
}