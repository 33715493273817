import React from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Mensagens from '../Mensagens/Mensagens';
import axios from 'axios';

export default class Documento extends React.Component {       

    constructor(props) {
        super(props);
        this.state = {
            fileName: "NomeArquivo",
            error: null,
            ExibeError: false
        };
        this.updateExibeError = this.updateExibeError.bind(this);
    }
    updateExibeError = (ExibeError) => { this.setState({ ExibeError }); }

    GetNomeArquivo() {
        this.setState({ error: null });
        let QualTipoDoc = 0;
        if (this.props.TipoDoc) {
            QualTipoDoc = this.props.TipoDoc;
        } 
        axios('api/Documentos/GetNomeArquivo?idAluno=' + this.props.Id + '&tipoDocumento=' + QualTipoDoc, {
            method: 'GET',
            responseType: 'text' 
        })
            .then(response => {
                this.setState({ fileName: response.data });
            })
            .catch(Msgerror => this.setState({ ExibeError: true, error: Msgerror }));
    }

    downloadFile = () => {
        let DescTipoDoc = 'application/pdf';
        let ie_edge = this.state.fileName + '.pdf';
        if ((this.props.TipoDoc) && (this.props.TipoDoc == 0)) {
            DescTipoDoc = 'application/zip';
            ie_edge = this.state.fileName + '.zip';
        } 
        axios('api/Documentos/GetDocumentoAluno?idAluno=' + this.props.Id + '&tipoDocumento=' + this.props.TipoDoc, {
            method: 'GET',
            responseType: 'blob' //Force to receive data in a Blob Format
        })
            .then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [response.data],
                    { type: DescTipoDoc });
                //IE e Microsoft Edge
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(file, ie_edge);
                }
                else {//Outros navegadores
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    //Open the URL on new Window
                    //window.open(fileURL);
                    let a = document.createElement('a');
                    a.href = fileURL;
                    a.download = this.state.fileName;
                    a.click();
                }
            })
            .catch(Msgerror => this.setState({ ExibeError: true, error: Msgerror }));
    }

    componentDidMount() {
        if (!(this.props.Id)) {
            return;
        }
        this.GetNomeArquivo();
    }

    render() {
        const { error } = this.state;
        let contentsErro = null;
        let DescricaoBotao = "attach_file";
        if (this.props.MostraIcone) {
            if (this.props.TipoDoc == 1) DescricaoBotao = "web";
            if (this.props.TipoDoc == 2) DescricaoBotao = "credit_card";
            if (this.props.TipoDoc == 3) DescricaoBotao = "fingerprint"; 
            if (this.props.TipoDoc == 4) DescricaoBotao = "face";
            if (this.props.TipoDoc == 5) DescricaoBotao = "account_balance";
            /*Possibilidades: perm_identity, contact_mail */
        }
        if (error) {
            contentsErro = <Mensagens Exibir={this.state.ExibeError} AtualizaExibir={this.updateExibeError}
                                      Opcao="error" Mensagem="Erro. Por favor, tente novamente." />;
        }
        return (
            <div style={{ display: 'inline-block' }}>
                <IconButton color="primary" aria-label="Download" style={{ verticalAlign: "top", padding: '5px' }}
                    onClick={this.downloadFile}>
                    <Icon>{DescricaoBotao}</Icon>
                </IconButton>
                {contentsErro}
            </div>
        );
    }
    
}

