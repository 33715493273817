import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner';
import { Modal } from 'react-bootstrap';
import './ModalLoading.css'

export default function ModalLoading(props) {

    return (
        <Modal show={props.show} backdrop={"static"} className="modalLoading">
            <ProgressSpinner />
        </Modal>
    )
    
}