import React, { useState, useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap'; 
import { DataView, DataViewLayoutOptions } from "primereact/dataview";

import autoavaliacaoService from '../../services/autoavaliacaoService';

import MessageContext from '../../context/messageContext/messageContext';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';

import { AvalRelatorioItem } from './AvalRelatorioItem';

export default function AvalRelatorioObs(props) {

    //Contexto
    const messageContext = useContext(MessageContext);
    const idCt = useContext(IdiomaContext);

    //Construtor
    const [Relatorio, setRelatorio] = useState(null);
    const [layout, setLayout] = useState('list');
    const [sortKey, setSortKey] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState('dimensaoSecao');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        //componentDidMount e componentDidUpdate      
        CarregarDados();        
    }, [props]);

    function CarregarDados() {
        setLoading(true);
        setRelatorio(null); 
        if (props.QualAutoAval.id === 0) return;
        autoavaliacaoService.GetRelatorioObs(props.QualAutoAval.id, props.QualInstituicao.id)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                setRelatorio(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                messageContext.openMessage("error", "Erro.Por favor, tente novamente.");
                setError(error);
            });
    }

    function onSortChange(value) {
        //const value = event.value;
        if (value.indexOf('!') === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        }
        else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
    }

    function itemTemplate(indicador, playout) {
        if (!indicador) {
            return renderNullItem();
        }
        if (playout === 'list')
            return renderListItem(indicador);
        //else if (layout === 'grid')
        return renderGridItem(indicador);
    }

    function renderNullItem() {
        return (<div></div>);
    }

    function renderItem(indicador, tipoRender) {
        return (
            <Container >
                <Row>
                    <Col sm={12}>
                        <div style={{ fontSize: '13px' }}><b>Indicador: </b><span style={{ color: 'black' }}>{indicador.pergunta}</span></div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <div style={{ fontSize: '12px' }}>
                            <AvalRelatorioItem Indicador={indicador} Tipo={tipoRender} QualInstituicao={props.QualInstituicao} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <hr size="1" />
                    </Col>
                </Row>
            </Container>
        );
    }

    function renderListItem(indicador) {
        return (renderItem(indicador, "COMPLETO"));
    }

    function renderGridItem(indicador) {
        return (renderItem(indicador, "RESUMO"));
    }

    function renderHeader() {
        const sortOptions = [
            { label: 'Indicador', value: 'dimensaoSecao' },
            { label: 'Melhor desempenho', value: 'melhorDesempenho' },
            { label: 'Pior desempenho', value: 'piorDesempenho' },
        ];

        return (
            <Container >
                <Row>
                    <Col sm={8} style={{ textAlign: 'left' }}>
                        <div style={{ display: 'inline-block', padding: '0.28em 0 0 0' }}>Ordenar por:&nbsp;&nbsp;</div>
                        <div style={{ display: 'inline-block' }}>
                            <Form.Control as="select"
                                placeholder="Ordenar por"
                                onChange={(e) => onSortChange(e.target.value)} >
                                {
                                    sortOptions.map(tipo =>
                                        <option value={tipo.value} key={tipo.value}> {tipo.label} </option>)
                                }
                            </Form.Control>
                        </div>
                    </Col>
                    <Col sm={4} style={{ textAlign: 'right' }}>
                        <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
                    </Col>
                </Row>
            </Container>
        );
    }

    function renderRelatorio() {
        const header = renderHeader();
        if ((loading) || (Relatorio == null)||(Relatorio.indicadores == null)||(Relatorio.indicadores.length == 0)) {
            return (
                <Container>
                    <Row>
                        <Col sm={12}>
                            <p><i className="fa fa-refresh fa-spin"></i> <em>Aguarde. Lendo dados...</em></p>
                        </Col>
                    </Row>
                </Container>
            );
        }
        return (
            <Container>
                <Row>
                    <Col sm={12} style={{ padding: '0' }}>
                        <DataView value={Relatorio.indicadores} layout={layout} header={header}
                                  itemTemplate={itemTemplate} paginatorPosition={'both'} paginator={true} rows={10}
                                  sortOrder={sortOrder} sortField={sortField} />
                    </Col>
                </Row>
            </Container>
            );
    }
         
    function renderAll() {

        let contents = <p></p>;
        if ((loading)) {
            return (
                <div>
                    <p><i className="fa fa-refresh fa-spin"></i> <em>Aguarde. Lendo dados...</em></p>
                </div>
            );
        }
        else if (error) {
            return (
                <div>
                    <p><em>Ocorreu um erro.</em></p>
                </div>
            );
        }
        else {
            contents = renderRelatorio();
        }

        return (
            <div>
                {contents}
            </div>
        );

    }

    return (
        renderAll()
    );

}