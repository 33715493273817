import React, { useState, useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';

import SelectDisciplina from '../SelectDisciplina/SelecDisciplina';
import SelecInstituicoes from '../../components/SelecInstituicoes/SelecInstituicoes';

import LoadingContext from '../../context/loadingContext/loadingContext';
import MessageContext from '../../context/messageContext/messageContext';
import InfoContext from '../../context/infoContext/infoContext';

import disciplinaService from '../../services/disciplinaService';

import { stableSort, getSorting, toolbarStyles, styles, EnhancedTableHead } from '../../helpers/TableMaterialUI';

let EnhancedTableToolbar = props => {
    const { numSelected, classes, onDeleteSelected } = props;

    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        <span style={{ fontSize: "16px" }}>{numSelected} selecionadas</span>
                    </Typography>
                ) : (
                    <Typography variant="h6" id="tableTitle">
                        <span style={{ fontSize: "16px" }}>Oferta de disciplinas eletivas:</span>
                    </Typography>
                    )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {numSelected > 0 ? (
                    <Tooltip title="Excluir">
                        <IconButton aria-label="Excluir">
                            <DeleteIcon onClick={onDeleteSelected} />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <p></p>
                    )}
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

function OfertaDiscEletiva(props) {

    //Contexto
    const loadingContext = useContext(LoadingContext);
    const messageContext = useContext(MessageContext);
    const infoContext = useContext(InfoContext);

    //Atualizacao de pagina
    const { AtualizaPagina } = props;

    //Construtor
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('disciplina');
    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [QualInstituicao, setQualInstituicao] = useState(infoContext.userInfo.instituicao);
    const [QualDisciplina, setQualDisciplina] = useState({ id: 0, nome: "Todas as Disciplinas", sigla: "Todas as Disciplinas" });
    const [error, setError] = useState(null);

    //Método para atualizar a instituição:
    const updateInst = (pQualInstituicao) => { setQualInstituicao(pQualInstituicao); }
    const updateDisciplina = (pQualDisciplina) => { setQualDisciplina(pQualDisciplina); }

    const rows = [
        { id: 'disciplina', numeric: false, disablePadding: true, label: 'Disciplina' },
        { id: 'professor', numeric: false, disablePadding: true, label: 'Professor' },
        { id: 'ia', numeric: false, disablePadding: true, label: 'IA' }
    ];

    useEffect(() => {
        //componentDidMount e componentDidUpdate
        loadingContext.showLoading();
        BuscaDisciplinasOfertadas();
        loadingContext.hideLoading();
    }, [AtualizaPagina, selected, QualInstituicao, QualDisciplina]); 

    function BuscaDisciplinasOfertadas() {
        if (QualInstituicao == null || QualDisciplina == null) return;
        disciplinaService.UltimaOfertaDiscProf(QualInstituicao.id, QualDisciplina.id)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Nenhuma oferta em aberto.');
                }
            })
            .then(DadosResposta => {
                setData(DadosResposta);
            })
            .catch(error => {
                messageContext.openMessage("error", "Nenhuma oferta em aberto.");
                setError(error);
            });
    }

    const handleRequestSort = (event, property) => {
        const porderBy = property;
        let porder = 'desc';

        if (orderBy === property && order === 'desc') {
            porder = 'asc';
        }
        setOrder(porder);
        setOrderBy(porderBy);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            //this.setState(state => ({ selected: state.data.map(n => n.id) }));
            let selecionados = [];
            for (let i = 0; i < data.length; i++) {
                let n = data[i];
                if (n.seleciona) {
                    selecionados.push(n.id);
                }
            }
            setSelected(selecionados);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id, inscProf, nPodeSelecionar) => {
        if (!nPodeSelecionar) {
            return;
        }
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];      

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);            
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected); 
    };

    const handleClickDelete = () => {
        loadingContext.showLoading();
        RemoverDisciplina();
        loadingContext.hideLoading();
    }

    function RemoverDisciplina() {

        let pBody = JSON.stringify({
            VetorIdOfertas: selected
        });

        if ((infoContext.user != null) && ((infoContext.user.profile.professor || infoContext.user.profile.coordenadorNacional))) {
            disciplinaService.PutRemoverOfertaProf(pBody)
                .then(r => {
                    r.json().then(response => {
                        if (response.statusCode) {
                            messageContext.openMessage(response.statusVariant, response.statusDescription);
                            setSelected([]);                            
                        }
                        else {
                            setSelected([]);
                        }
                    });
                })
                .catch(error => {
                    messageContext.openMessage("error", "Erro.Por favor, tente novamente.");
                    setError(error);
                });
        }

    } 

    const handleChangePage = (event, page) => {
        setPage(page);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value);
    };

    const funcIsSelected = id => selected.indexOf(id) !== -1;

    function renderAll() {
        const { classes } = props;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        let contents = <p></p>;
        if (loadingContext.loading) {
            return (
                <div>
                    <p><i className="fa fa-refresh fa-spin"></i> <em>Lendo/salvando dados...</em></p>
                </div>
            );
        }   
        else if (error) {
            return (
                <div>
                    <p><em>Nenhuma oferta em aberto.</em></p>
                </div>
            );
        }         
        else {
            contents =
                <Container >
                    <Row>
                        <Col sm={6}>
                            Escolha a Instituição:
                            <SelecInstituicoes InstituicaoSelecionada={QualInstituicao.id} AtualizaQualInstituicao={updateInst} />
                        </Col>
                        <Col sm={6}>
                            Escolha a Disciplina:
                            <SelectDisciplina AtualizaQualDisciplina={updateDisciplina} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                        <div>
                            <Paper className={classes.root}>
                                <EnhancedTableToolbar onDeleteSelected={handleClickDelete} numSelected={selected.length} />
                                <div className={classes.tableWrapper}>
                                    <Table className={classes.table} aria-labelledby="tableTitle">
                                        <EnhancedTableHead
                                            rows={rows}
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={handleSelectAllClick}
                                            onRequestSort={handleRequestSort}
                                            rowCount={data.length}
                                        />
                                        <TableBody>
                                            {stableSort(data, getSorting(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map(n => {
                                                    const isSelected = funcIsSelected(n.id);
                                                    let checkVisivel = <p></p>;
                                                    if (n.seleciona) {
                                                        checkVisivel = <Checkbox checked={isSelected} />
                                                    }
                                                    return (                                                        
                                                        <TableRow
                                                            hover
                                                            onClick={event => handleClick(event, n.id, n.inscProf, n.seleciona)}
                                                            role="checkbox"
                                                            aria-checked={isSelected}
                                                            tabIndex={-1}
                                                            key={n.id}
                                                            selected={isSelected}
                                                        >
                                                            <TableCell style={{ width: '10px' }} padding="checkbox">
                                                                {checkVisivel}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" padding="none">
                                                                <span style={{ fontSize: "14px" }}>{n.disciplina}</span><br />
                                                                <i><span style={{ fontSize: "10px" }}>{n.conteudo}</span></i>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <span style={{ fontSize: "14px" }}>{n.professor}</span><br />
                                                                <i><span style={{ fontSize: "10px" }}>{n.professorAuxiliar} {n.iaAuxiliar}</span></i>
                                                            </TableCell>
                                                            <TableCell align="center">{n.ia}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: 49 * emptyRows }}>
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        'aria-label': 'Anterior',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Próximo',
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </div>
                        </Col>
                    </Row>
                </Container>
        }
        return (
            <div>
                {contents}
            </div>
        );
    }

    return (
        renderAll()
        );
}

OfertaDiscEletiva.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OfertaDiscEletiva);
