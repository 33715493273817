import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import CronJobLattes from '../../components/AtualizacaoLattes/CronJobLattes';
import SelecMultAnos from '../../components/SelecAnos/SelecMultAnos';
import SelecQualArea from '../../components/SelectArea/SelecQualArea';
import SelecInstituicoes from '../../components/SelecInstituicoes/SelecInstituicoes';
import PesquisadoresTopMais from '../../components/PesquisadoresTopMais/PesquisadoresTopMais';

export class MaisProdutivos extends Component {
    displayName = MaisProdutivos.name

    constructor(props) {
        super(props);
        this.state = {
            QualAno: [],
            MostraAnos: "",
            QualArea: "ensino"
        };
        let IFTM = {
            id: -1,
            nome: "IFTM",
            sigla: "IFTM",
            quantidadeProfessores: 0,
            quantidadeProfessoresLattes: 0
        };
        this.state = { QualInstituicao: IFTM };
        this.updateAno = this.updateAno.bind(this);
        this.updateArea = this.updateArea.bind(this);
        this.updateInst = this.updateInst.bind(this);
    }

  //Método para atualizar o ano:
    updateAno = (QualAno) => {
        let Mostra = "";
        for (let i = 0; i < QualAno.length; i++) {
            if (i === 0) {
                Mostra = " " + QualAno[i];
            }
            else {
                Mostra = Mostra + " ; " + QualAno[i];
            }
        }
        this.setState({ QualAno, MostraAnos: Mostra });        
    }
    //Método para mudar a area
    updateArea = (QualArea) => { this.setState({ QualArea });}
    //Método para mudar a instituição
    updateInst = (QualInstituicao) => { this.setState({ QualInstituicao }); }

    /*
                        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Cálculo da pontuação: </span><br />
                        <span style={{ fontSize: '13px' }}>A1: 100 pontos; A2: 86 pontos; B1: 70 pontos; B2: 55 pontos; B3: 40 pontos; B4: 25 pontos; B5: 10 pontos; C/SemQualis: 5 pontos.</span><br />
                        <span style={{ fontSize: '13px' }}>Livro: 25 pontos; Capítulo de Livro: 12 pontos; Artigo completo em evento: 10 pontos; Resumo em evento: 5 pontos.</span><br />

     */

    /*
                    <Col sm={4}>
                        <PainelConteudo legenda="Selecione a área"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        Escolha a Área da CAPES:
                                        <SelecQualArea AtualizaQualArea={this.updateArea} />
                                    </Col>
                                </Row>
                            } />
                    </Col>
     */

    //Resposta temporaria
    /*
    render() {
        return (
            <Container >
                <Row>
                    <Col sm={12}>
                        <h2>Produção Científica dos Pesquisadores</h2>
                        <PainelConteudo legenda="Aviso"
                            texto={
                                <p>Esta opção estará disponível apenas após a publicação oficial do Qualis Periódicos 2017-2020.</p>
                            } />
                    </Col>
                </Row>                
            </Container>
        );
    }   
}
    */
    render() {
        return (
            <Container >
                <Row>
                    <Col sm={12}>
                        <h2>Produção Científica dos Docentes em {this.state.MostraAnos}</h2>
                        <CronJobLattes />
                        <PainelConteudo legenda="Pesquisar"
                            texto={
                                <Container >
                                    <Row>
                                        <Col sm={6}>
                                            Escolha o(s) ano(s):
                                            <SelecMultAnos AtualizaQualAno={this.updateAno} />
                                        </Col>
                                        <Col sm={6}>
                                            Escolha a Instituição:
                                            <SelecInstituicoes AtualizaQualInstituicao={this.updateInst} />
                                        </Col>
                                    </Row>
                                </Container>
                            } />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Produção Científica - {this.state.QualInstituicao.nome}</span><br />
                        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Número de docentes: {this.state.QualInstituicao.quantidadeProfessores}</span><br />
                        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Número de docentes com Lattes: {this.state.QualInstituicao.quantidadeProfessoresLattes}</span><br />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <PainelConteudo legenda="Pesquisadores"
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        <PesquisadoresTopMais
                                            QualAno={this.state.QualAno}
                                            QualArea="ensino"
                                            QualInstituicao={this.state.QualInstituicao.id} />
                                    </Col>
                                </Row>
                            } /> 
                    </Col>
                </Row>
            </Container>
        );
    }
}

