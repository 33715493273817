import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../Pesquisadores/Pesquisadores.css';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ModalMensagem from '../ModalMensagem/ModalMensagem';

export default class PesquisadoresTemasPesquisa extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            Professores: [],
            stackedData: {},
            stackedOptions: {},
            ExibeDescricaoProducao: false,
            TextoSelecionado: null
        };
        this.ColunaFoto = this.ColunaFoto.bind(this);
        this.ColunaFotoNome = this.ColunaFotoNome.bind(this);
        this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
        this.ColunaAssuntos = this.ColunaAssuntos.bind(this);
        this.updateExibeDescricaoProducao = this.updateExibeDescricaoProducao.bind(this);
    }

    updateExibeDescricaoProducao = (ExibeDescricaoProducao) => { this.setState({ ExibeDescricaoProducao }); }

    componentDidMount() {
        this.CarregarDados();
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.QualInstituicao !== this.props.QualInstituicao) ||
            (prevProps.QualAssunto !== this.props.QualAssunto) ){
            this.CarregarDados();
        }
    }

    ColunaFoto(rowData, column) {
        return <div>
                    <img src={rowData.urlFoto} className="prof-imagem-redondo" />
               </div>;
    }

    ColunaFotoNome(rowData, column) {
        return <div>
                    <img src={rowData.urlFoto} className="prof-imagem-redondo" />&nbsp;{rowData.nome}
               </div>;
    }

    CarregarDados() {
        this.setState({ loading: true });
        if ((this.props.QualInstituicao === -1) || (this.props.QualAssunto == null) || (this.props.QualAssunto.length === 0))
            return;
        let StringFetch = 'api/Pesquisadores/PesquisadoresTemasPesquisa?QualInstituicao=' + this.props.QualInstituicao + '&QuaisAssuntos=' + this.props.QualAssunto;
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ Professores: data, loading: false });
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    rowExpansionTemplate(data) {
        return (
            <div className="p-grid p-fluid" style={{ padding: '1em' }}>
                <div className="p-col-12 p-md-12">
                    <div className="p-grid">
                        <div className="p-md-2">IA: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.instituicao}</div>

                        <div className="p-md-2">Assuntos Pesquisados: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.descricaoProducao}</div>

                        <div className="p-md-2">Data Lattes: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.dataAtualizacaoLattes}</div>
                    </div>
                </div>
            </div>
        );
    }

    renderProfessoresCelular() {
        var header = <div style={{ 'textAlign': 'left' }}>
            <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
            <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Pesquisar" size="20" />
        </div>;

        return (
            <div>
                <div className="content-section implementation">
                    <DataTable value={this.state.Professores} paginator={true} rows={5} rowsPerPageOptions={[5, 10, 20]}
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        globalFilter={this.state.globalFilter} header={header}
                        expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                        rowExpansionTemplate={this.rowExpansionTemplate}
                    >
                        <Column expander={true} style={{ width: '3em' }} />
                        <Column field="nome" header="Nome" body={this.ColunaFotoNome} sortable={true} />
                        <Column field="pontos" header="Pontos" sortable={true} style={{ textAlign: 'center', width: '6em', padding: '0 0 0 0' }} />
                    </DataTable>
                </div>
            </div>
        );
    }

    ColunaAssuntos(rowData, column) {
        return (
            <div style={{ display: 'inline-block', width: '100%', textAlign: 'center', margin: '0px' }}>
                <IconButton color="primary" aria-label="Visualizar" style={{ verticalAlign: "top", padding: "6px", margin: "0px" }}
                    onClick={() => this.setState({ ExibeDescricaoProducao: true, TextoSelecionado: rowData })}>
                    <Icon>search</Icon>
                </IconButton>
            </div>
            );
    }

    renderProfessoresTamanhoNormal() {
        var header = <div style={{ 'textAlign': 'left' }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Pesquisar" size="50" />
                    </div>;
        //<Column field="descricaoProducao" header="Assuntos Pesquisados" sortable={false} />
        if (this.props.QualInstituicao === 0) {
            return (
                <div>
                    <div className="content-section implementation">
                        <DataTable value={this.state.Professores} paginator={true} rows={5} rowsPerPageOptions={[5, 10, 20]}
                            resizableColumns={true} columnResizeMode="fit"
                            globalFilter={this.state.globalFilter} header={header} >
                            <Column header="Foto" body={this.ColunaFoto} style={{ textAlign: 'center', width: '4em' }} />
                            <Column field="nome" header="Nome" sortable={true} />
                            <Column field="instituicao" header="IA" sortable={true} style={{ textAlign: 'center', width: '9em' }} />
                            <Column field="pontos" header="Pontos" sortable={true} style={{ textAlign: 'center', width: '7em' }} />
                            <Column header="Assuntos Pesquisados" body={this.ColunaAssuntos} sortable={false} />
                            <Column field="dataAtualizacaoLattes" header="Data Lattes" sortable={true} style={{ textAlign: 'center', width: '10em' }} />
                        </DataTable>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <div className="content-section implementation">
                    <DataTable value={this.state.Professores} paginator={true} rows={5} rowsPerPageOptions={[5, 10, 20]}
                        resizableColumns={true} columnResizeMode="fit"
                        globalFilter={this.state.globalFilter} header={header} >
                        <Column header="Foto" body={this.ColunaFoto} style={{ textAlign: 'center', width: '4em' }} />
                        <Column field="nome" header="Nome" sortable={true} />
                        <Column field="pontos" header="Pontos" sortable={true} style={{ textAlign: 'center', width: '7em' }} />
                        <Column header="Assuntos Pesquisados" body={this.ColunaAssuntos} sortable={false} />
                        <Column field="dataAtualizacaoLattes" header="Data Lattes" sortable={true} style={{ textAlign: 'center', width: '10em' }} />
                    </DataTable>
                </div>
            </div>
        );
    }
       
    renderProfessores() {
        //let paginatorLeft = <Button icon="pi pi-refresh" />;
        //let paginatorRight = <Button icon="pi pi-cloud-upload" />;
        /*paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}*/
        /* <Column field="nome" header="Nome" sortable={true} filter={true} / > */
        var windowWidth = window.innerWidth;
        if (windowWidth < 770) {
            return this.renderProfessoresCelular();
        }
        else {
            return this.renderProfessoresTamanhoNormal();
        }
    }

    renderDescricaoAssuntos() {
        const { TextoSelecionado } = this.state;
        if (!TextoSelecionado) {
            return;
        }
        return (<div>
                    <ModalMensagem updateShow={this.updateExibeDescricaoProducao}
                        show={this.state.ExibeDescricaoProducao} titulo="Assuntos Pesquisados"
                        mensagem=
                        {<div>
                            <p><b>Docente</b>: {TextoSelecionado.nome}</p>
                            <p><b>Assuntos</b>: {TextoSelecionado.descricaoProducao}</p>
                            <br />
                        </div>
                        }
                    />
                </div>
        );
    }

    render() {
        const { loading, error } = this.state;

        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            if ((this.props.QualAssunto == null) || (this.props.QualAssunto.length === 0)) {
                contents = <div className="overlay">
                               <em>Escolha o(s) assunto(s)</em>
                           </div>;
            }
            else {
                contents = <div className="overlay">
                               <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                           </div>;
            }
        }
        else {
            contents = this.renderProfessores();
        }

        return (
            <div>
                {contents}
                {this.renderDescricaoAssuntos()}
            </div>
        );

    }

}