import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { DataView } from "primereact/dataview";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ModalMensagem from '../ModalMensagem/ModalMensagem';
import Button from '@material-ui/core/Button';
//import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0),
        padding: '0px',
    },
}));

export default function AvalRelatorioItemAberta(props) {

    const classes = useStyles();

    //Construtor
    const [ExibeTodasIA, setExibeTodasIA] = useState(false);
    const [ExibeTodasNacional, setExibeTodasNacional] = useState(false);

    useEffect(() => {
        //componentDidMount e componentDidUpdate
    }, [props]);

    function renderHeader(Instituicao) {
        let pcolor = '#3F51B5';
        let pcolorDesc = "primary";
        if (Instituicao === "Nacional") {
            pcolor = '#F50057';
            pcolorDesc = "secondary";
        }
        return (
            <div style={{ fontSize: '12px', color: pcolor, textAlign: 'center', margin: '0px', padding: '0px' }}>
                <Button size="small" className={classes.margin} color={pcolorDesc} onClick={() => { (Instituicao === "Nacional")? setExibeTodasNacional(true) : setExibeTodasIA(true)}}>
                    Respostas ({Instituicao})
                </Button>
            </div>
        );
    }

    function renderExibirTodasRespostas() {
        if (!ExibeTodasIA && !ExibeTodasNacional) {
            return;
        }
        let sigla = "Respostas (Nacional)";
        let conteudo = <p></p>;
        let setExibe = setExibeTodasNacional;
        if (ExibeTodasIA) {
            sigla = "Respostas (" + props.QualInstituicao.sigla + ")";
            conteudo = props.Indicador.respostaInstituicao.perguntaItens.map(item => itemTemplateIA(item, true));
            setExibe = setExibeTodasIA;
        }
        else if (ExibeTodasNacional) {
            conteudo = props.Indicador.respostaGeral.perguntaItens.map(item => itemTemplateGeral(item, true));
        }
        return (<div>
            <ModalMensagem updateShow={setExibe}
                show={true} titulo={sigla}
                mensagem=
                {<div style={{ textAlign: "justify" }}>
                    <p><b>Indicador</b>: {props.Indicador.pergunta}</p>
                    {conteudo}
                    <br />
                </div>
                }
            />
        </div>
        );
    }

    function itemTemplateIA(item, playout) {
        let Avaliador = "";
        let resposta = <VisibilityOffIcon color="primary" fontSize="small" />;
        if (item.percRespostaCoord === 1) {
            Avaliador = "Coord.";
            if (props.Indicador.respostaInstituicao.exibirCoord) resposta = item.perguntaItem;
        }
        else if (item.percRespostaDiscente === 1) {
            Avaliador = "Discente";
            if (props.Indicador.respostaInstituicao.exibirDiscente) resposta = item.perguntaItem;
        }
        else if (item.percRespostaDocente === 1) {
            Avaliador = "Docente";
            if (props.Indicador.respostaInstituicao.exibirDocente) resposta = item.perguntaItem;
        }
        else if (item.percRespostaEgresso === 1) {
            Avaliador = "Egresso";
            if (props.Indicador.respostaInstituicao.exibirEgresso) resposta = item.perguntaItem;
        }
        return (
            <div style={{ fontSize: '12px', textAlign: 'justify', color: 'black' }}>
                <b>{Avaliador}</b>: {resposta}
            </div>
        );
    }

    function itemTemplateGeral(item, playout) {
        let Avaliador = "";
        if (item.percRespostaCoord === 1) Avaliador = "Coord.";
        else if (item.percRespostaDiscente === 1) Avaliador = "Discente";
        else if (item.percRespostaDocente === 1) Avaliador = "Docente";
        else if (item.percRespostaEgresso === 1) Avaliador = "Egresso";
        return (
            <div style={{ fontSize: '12px', textAlign: 'justify', color: 'black' }}>
                <b>{Avaliador}</b>: {item.perguntaItem}
            </div>
            );
    }

    function renderResposta() {
        let contents = <p></p>;

        let TamanhoPageLink = 5;
        var windowWidth = window.innerWidth;
        if (windowWidth < 770) {
            TamanhoPageLink = 1;
        }

        if (props.QualInstituicao.id > 0) {
            if (!props.Indicador.respostaInstituicao) {
                return (<p><i className="fa fa-refresh fa-spin"></i> <em>Aguarde...</em></p>);
            }
            contents =
                <Container style={{ margin: '0px' }} >
                    <Row>
                        <Col sm={6} style={{ textAlign: 'center', padding: '0px', paddingRight: '3px' }}>
                            <DataView value={props.Indicador.respostaInstituicao.perguntaItens}
                                header={renderHeader(props.QualInstituicao.sigla)}
                                pageLinkSize={TamanhoPageLink}
                                emptyMessage={<div style={{ fontSize: '12px', textAlign: 'left', color: '#ff0000' }}>Nenhuma resposta</div>}
                                itemTemplate={itemTemplateIA} paginatorPosition={'bottom'} paginator={true} rows={1}
                            />
                        </Col>
                        <Col sm={6} style={{ textAlign: 'center', padding: '0px', paddingLeft: '3px' }}>
                            <DataView value={props.Indicador.respostaGeral.perguntaItens}
                                header={renderHeader("Nacional")}
                                pageLinkSize={TamanhoPageLink}
                                emptyMessage={<div style={{ fontSize: '12px', textAlign: 'left', color: '#ff0000' }}>Nenhuma resposta</div>}
                                itemTemplate={itemTemplateGeral} paginatorPosition={'bottom'} paginator={true} rows={1}
                            />
                            {renderExibirTodasRespostas()}
                        </Col>
                    </Row>
                </Container>;
        }
        else {
            contents =
                <Container style={{ margin: '0px' }} >
                    <Row>
                        <Col sm={12} style={{ textAlign: 'center', padding: '0px' }}>
                            <DataView value={props.Indicador.respostaGeral.perguntaItens}
                                header={renderHeader("Nacional")}
                                pageLinkSize={TamanhoPageLink}
                                emptyMessage={<div style={{ fontSize: '12px', textAlign: 'left', color: '#ff0000' }}>Nenhuma resposta</div>}
                                itemTemplate={itemTemplateGeral} paginatorPosition={'bottom'} paginator={true} rows={1}
                            />
                            {renderExibirTodasRespostas()}
                        </Col>
                    </Row>
                </Container>;
        }
        return (contents);
    }

    return (
        renderResposta()
    );

}