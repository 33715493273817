import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../Pesquisadores/Pesquisadores';
import ModalMensagem from '../ModalMensagem/ModalMensagem';
import { Button } from 'primereact/button';

export default class Coordenadores extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            Professores: [],
            stackedData: {},
            stackedOptions: {},
            MensagemBotaoEmailCoord: false
        };
        this.ColunaFoto = this.ColunaFoto.bind(this);
        this.ColunaFotoNome = this.ColunaFotoNome.bind(this);
        this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
        this.updateMensagemBotaoEmailCoord = this.updateMensagemBotaoEmailCoord.bind(this);
    }
    updateMensagemBotaoEmailCoord = (MensagemBotaoEmailCoord) => { this.setState({ MensagemBotaoEmailCoord }); }

    componentDidMount() {
        this.CarregarDados();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.QualInstituicao !== this.props.QualInstituicao) {
            this.CarregarDados();
        }
    }

    ColunaFoto(rowData, column) {
        return <div>
                    <img src={rowData.urlFoto} className="prof-imagem-redondo" />
               </div>;
    }

    ColunaFotoNome(rowData, column) {
        return <div>
                    <img src={rowData.urlFoto} className="prof-imagem-redondo" />&nbsp;{rowData.nome}
               </div>;
    }

    CarregarDados() {
        this.setState({ loading: true });
        let StringFetch = 'api/Pesquisadores/ListaCoordenadores';
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ Professores: data, loading: false });
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    rowExpansionTemplate(data) {
        return (
            <div className="p-grid p-fluid" style={{ padding: '1em' }}>
                <div className="p-col-12 p-md-12">
                    <div className="p-grid">
                        <div className="p-md-2">IA: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.instituicao}</div>

                        <div className="p-md-2">Email: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.email}</div>

                    </div>
                </div>
            </div>
        );
    }

    renderProfessoresCelular() {
        var header = <div style={{ 'textAlign': 'left' }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Pesquisar" size="20" />
                    </div>;

        return (
            <div>
                <div className="content-section implementation">
                    <DataTable value={this.state.Professores} paginator={true} rows={15} rowsPerPageOptions={[5, 15, 30]}
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        globalFilter={this.state.globalFilter} header={header}
                        expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                        rowExpansionTemplate={this.rowExpansionTemplate}
                    >
                        <Column expander={true} style={{ width: '3em' }} />
                        <Column field="nome" header="Nome" body={this.ColunaFotoNome} sortable={true} />
                    </DataTable>
                </div>
            </div>
        );
    }

    renderProfessoresTamanhoNormal() {
        var header = <div style={{ 'textAlign': 'left' }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Pesquisar" size="50" />
                     </div>;
        return (
                <div>
                    <div className="content-section implementation">
                        <DataTable value={this.state.Professores} paginator={true} rows={15} rowsPerPageOptions={[5, 15, 30]}
                            resizableColumns={true} columnResizeMode="fit"
                            globalFilter={this.state.globalFilter} header={header} 
                            expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                        >
                            <Column header="Foto" body={this.ColunaFoto} style={{ textAlign: 'center', width: '4em' }} />
                            <Column field="nome" header="Nome" sortable={true} />
                            <Column field="instituicao" header="IA" sortable={true} style={{ textAlign: 'center', width: '9em' }} />
                            <Column field="email" header="Email" sortable={false} />                            
                        </DataTable>
                    </div>
                </div>
            );             
    }
       
    renderProfessores() {
        var windowWidth = window.innerWidth;
        if (windowWidth < 770) {
            return this.renderProfessoresCelular();
        }
        else {
            return this.renderProfessoresTamanhoNormal();
        }
    }

    renderEmailsCoordenadores() {
        return (
            <div>
                <ModalMensagem updateShow={this.updateMensagemBotaoEmailCoord}
                    show={this.state.MensagemBotaoEmailCoord} titulo="Lista de emails dos coordenadores"
                    mensagem=
                    {<div>
                        {this.state.Professores.map((item, i) => {
                            return (<span>{(i == 0) ? <i></i> : <i>;</i>} {item.email}</span>)
                        })}
                    </div>
                    }
                />
            </div>
        );
    }

    render() {
        const { loading, error } = this.state;

        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents = <div className="overlay">
                           <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                       </div>;
        }
        else {
            contents = <div>
                            <b>Facilite sua vida:</b> <br />
                            <Button label="Emails Coordenadores" icon="pi pi-envelope" onClick={() => this.setState({ MensagemBotaoEmailCoord: true })} ></Button>
                            <br /> <br />
                            {this.renderEmailsCoordenadores()}
                            {this.renderProfessores()}
                       </div>;
        }

        return (
            <div>
                {contents}                
            </div>
        );

    }

}