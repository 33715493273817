import authService from '../components/api-authorization/AuthorizeService';

const usuarioService = {
    getQtdeAcesso,
    getUserInfo
}

async function getQtdeAcesso() {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: "GET",
        headers: headers
    };    
    return await fetch('api/Home/QtdeAcesso', options);
}

async function getUserInfo() {
    const token = await authService.getAccessToken();
    let headers = !token ? { 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` };
    let options = {
        method: "GET",
        headers: headers
    };
    return await fetch('api/Usuarios/GetUserInfo', options);
}

export default usuarioService;

