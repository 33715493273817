import React, { useContext, useState, useEffect } from 'react';
import AdmExecutaEtapa from '../../components/AdmExecutaEtapa/AdmExecutaEtapa';

export function downloadLattes(props) { 
    return (
        <div>
            <AdmExecutaEtapa QualEtapa="Download Curriculos Lattes"></AdmExecutaEtapa>  
            <br />
            <AdmExecutaEtapa QualEtapa="Download Curriculos Lattes e Gerar Estatísticas (Completo)"></AdmExecutaEtapa>
        </div>
        );
}
