import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';

export function Sobre(props) {

    const idCt = useContext(IdiomaContext);

    return (
          <Container >
              <Row>
                  <Col sm={12}>
                    <h2>{idCt.t("Sobre o Observatório ProfEPT", "About the Observatory of the ProfEPT")}</h2>
                  </Col>
              </Row>
              <Row>
                  <Col sm={12}>
                    <PainelConteudo legenda={idCt.t("Histórico", "Historic")}
                        texto={
                            <div>
                              <p>
                                  {idCt.t("O Observatório ProfEPT foi idealizado em setembro de 2018, durante a reunião de coordenadores em Florianópolis-SC, pelo docente do programa Ernani Viriato de Melo (IFTM). Ernani percebeu a necessidade de termos um único ambiente para entender e analisar o perfil dos docentes do programa.",
                                          "The Observatory ProfEPT was conceived in September 2018, during the meeting of coordinators in Florianópolis - SC, by Ernani Viriato Melo (IFTM). Melo realized the need to have a single environment to understand and analyze the profile of the program's professors.")}
                              </p>
                              <p>
                                  {idCt.t("Em março de 2019, na reunião de coordenadores realizada no Rio de Janeiro-RJ, o Ernani apresentou um protótipo do Observatório que foi muito bem acolhido pela Coordenação Nacional e pelos demais coordenadores presentes na reunião.",
                                          "In March 2019, at the coordinators' meeting held in Rio de Janeiro-RJ, Ernani presented a prototype of the Observatory that was very well received by the National Coordination and the other coordinators present at the meeting.")}
                              </p>
                              <p>
                                  {idCt.t("Em maio de 2019 foi disponibilizada a primeira versão do Observatório. Desde então, esta ferramenta vem adquirindo novas funcionalidades para auxiliar na gestão, diagnóstico, autoavaliação, exibição de resultados e acompanhamento de egressos do programa.",
                                          "In May 2019, the first version of the Observatory was made available. Since then, this tool has acquired new functionalities to assist in the management, diagnosis, self-assessment, display of results and monitoring of alumni.")}
                              </p>
                              <p>
                                  {idCt.t("Para conhecer um pouco mais da história, equipe de desenvolvimento e funcionalidades desta ferramenta, ",
                                      "To learn more about the history, development team and features of this tool, ")}   
                                    <a href={"https://youtu.be/e0IJvqpA-MA"} target="blank">{idCt.t("assista este vídeo", "watch this video")}</a>{idCt.t(" gravado em 10/08/2020."," recorded on 10/08/2020.")}
                              </p>
                            </div>
                        } />
                  </Col>                  
              </Row>
              <Row>
                  <Col sm={12}>
                      <PainelConteudo legenda={idCt.t("Perguntas Frequentes", "Common Questions")} 
                        texto={
                            <div>
                              <p><b>Como acesso o sistema?</b><br />
                                  Todos os docentes já possuem uma conta de usuário no sistema. A conta é vínculada ao email.<br />
                                  Quanto aos dicentes / egressos a conta é criada no primeiro acesso. É muito importante que os dicentes / egressos confiram os dados no primeiro acesso. <br />
                                  Para acessar (logar) no Observatório, o usuário tem duas opções: usar um provedor externo (Facebook, Microsoft e Google) ou uma senha cadastrada no Observatório.
                              </p>
                              <p><b>Sou discente/egresso e não encontrei meu orientador no cadastro. O que devo fazer?</b><br />
                                  Você deve comunicar imediatamente a Coordenação Local que por sua vez vai entrar em contato com a Coordenação Nacional para inserir o novo cadastro do docente. Mesmo que este docente já esteja descredenciado, ele deve ser cadastrado no sistema.
                              </p>
                              <p><b>Sou coordenador local e os dados sobre docentes estão desatualizados. O que devo fazer?</b><br />
                                  Você coordenador pode inserir a data do descredenciamento de um docente ou alterar o email de acesso do docente. Mas novos docentes devem ser encaminhados para a Coordenação Nacional.                                  
                              </p>
                              <p><b>Sou aluno e selecionei as disciplinas eletivas. Como confirmar se selecionei corretamente?</b><br />
                                  A coordenação local tem acesso ao relatório com a relação das solicitações dos alunos. Quanto aos alunos, basta visualizar quais disciplinas estão selecionadas na tela de cadastro de Oferta / Procura. 
                              </p>
                              <p><b>Como eu posso alterar o meu email de acesso?</b><br />
                                  Você discente, egresso ou docente deve solicitar ao seu coordenador local a alteração de email. O coordenador local tem permissão para alterar o email dos usuários de sua instituição associada.
                              </p>
                              <p><b>A autoavaliação é anônima?</b><br />
                                  Sim. Temos apenas a quantidade de discentes, egressos, docentes que responderam a autoavaliação.
                              </p>
                              <p><b>Posso responder a autoavaliação e posteriormente alterar uma resposta?</b><br />
                                  Sim. Entretanto, isto é possível apenas no período definido para responder a autoavaliação. 
                              </p>
                              <p><b>Informei meus dados incorretamente (nome completo, ano de ingresso, etc.). O que devo fazer?</b><br />
                                  Você deve preenhcer <a href={"https://docs.google.com/forms/d/e/1FAIpQLSd6pzddQ7dGwGL1A2R9YKux98zXBy0Ish2IyMHcWll5QYORGA/viewform?usp=pp_url"} target="blank">este formulário</a> relatando o problema.                                 
                              </p>
                              <p><b>Fiquei com o meu cadastro duplicado. O que devo fazer?</b><br />
                                  Você deve preenhcer <a href={"https://docs.google.com/forms/d/e/1FAIpQLSd6pzddQ7dGwGL1A2R9YKux98zXBy0Ish2IyMHcWll5QYORGA/viewform?usp=pp_url"} target="blank">este formulário</a> relatando o problema.
                              </p>
                              <p><b>Toda vez que tento fazer algum procedimento aparece uma mensagem de erro. O que devo fazer?</b><br />
                                  Você deve preenhcer <a href={"https://docs.google.com/forms/d/e/1FAIpQLSd6pzddQ7dGwGL1A2R9YKux98zXBy0Ish2IyMHcWll5QYORGA/viewform?usp=pp_url"} target="blank">este formulário</a> relatando o problema.
                              </p>
                              <p><b>Gostaria de fazer um projeto de pesquisa em parceria com o Observatório ProfEPT. O que devo fazer?</b><br />
                                  Excelente. Você pode entrar em contato preenchendo <a href={"https://docs.google.com/forms/d/e/1FAIpQLSdPBGPnZAP-VvR9cWuIXvFK78CXVOpmGUfwPmQbheZlt78pfA/viewform?usp=pp_url"} target="blank">este formulário</a> relatando a sua ideia e como será a parceria.
                              </p>
                            </div>
                          } />
                  </Col>
              </Row>
              <Row>
                  <Col sm={12}>
                      <PainelConteudo legenda={idCt.t("Agradecimentos", "Acknowledgments")} 
                          texto={
                              <Row>
                                  <Col sm={12}>
                                      <span>Agradecemos aos vários alunos do IFTM que contribuíram para o desenvolvimento do Observatório.</span><br />
                                      <span>Agradecemos a equipe de TI do campus e da reitoria, em especial o Lorenzzo Egydio Mollinar da Cruz e Tiago Souza Silva.</span><br />
                                      <span>Agradecemos o apoio da Coordenação Nacional do ProfEPT.</span><br />
                                  </Col>
                              </Row>
                          } />
                  </Col>
              </Row>
          </Container>
    );
}
