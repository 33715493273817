import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import StarIcon from '@material-ui/icons/Star';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';

const StyledRatingSimNao = withStyles({
    iconFilled: {
        color: '#115293',
    },
    iconHover: {
        color: '#1976D2',
    },
})(Rating);

const StyledRatingCoracao = withStyles({
    iconFilled: {
        color: '#ff6d75',
    },
    iconHover: {
        color: '#ff3d47',
    },
})(Rating);

const EstrelasRatingInt = {
    1: {
        icon: <StarIcon fontSize="large" />,
        label: 'Péssimo',
    },
    2: {
        icon: <StarIcon fontSize="large" />,
        label: 'Ruim',
    },
    3: {
        icon: <StarIcon fontSize="large" />,
        label: 'Ok',
    },
    4: {
        icon: <StarIcon fontSize="large" />,
        label: 'Bom',
    },
    5: {
        icon: <StarIcon fontSize="large" />,
        label: 'Ótimo',
    }
};

const ImportanciaRatingInt = {
    1: {
        icon: <FavoriteIcon fontSize="large" />,
        label: 'Nem um pouco importante',
    },
    2: {
        icon: <FavoriteIcon fontSize="large" />,
        label: 'Ligeiramente importante',
    },
    3: {
        icon: <FavoriteIcon fontSize="large" />,
        label: 'Moderadamente importante',
    },
    4: {
        icon: <FavoriteIcon fontSize="large" />,
        label: 'Muito importante',
    },
    5: {
        icon: <FavoriteIcon fontSize="large" />,
        label: 'Extremamente importante',
    }
};

const ConcordanciaRatingInt = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon fontSize="large" />,
        label: 'Discordo totalmente',
    },
    2: {
        icon: <SentimentDissatisfiedIcon fontSize="large" />,
        label: 'Discordo parcialmente',
    },
    3: {
        icon: <SentimentSatisfiedIcon fontSize="large" />,
        label: 'Não concordo nem discordo',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon fontSize="large" />,
        label: 'Concordo parcialmente',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon fontSize="large" />,
        label: 'Concordo totalmente',
    }
};

const SimNaoRatingInt = {
    1: {
        icon: <NotInterestedIcon fontSize="large" />,
        label: 'Não se aplica / Não sei',
    },
    2: {
        icon: <ThumbUpIcon fontSize="large" />,
        label: 'Sim',
    },
    3: {
        icon: <ThumbDownIcon fontSize="large" />,
        label: 'Não',
    }
};

const SimNaoRatingInt_NaoAplica = {
    1: {
        icon: <NotInterestedIcon fontSize="large" />,
        label: 'Não se aplica / Não sei',
    },
};

const SimNaoRatingInt_Sim = {
    1: {
        icon: <ThumbUpIcon fontSize="large" />,
        label: 'Sim',
    },
};

const SimNaoRatingInt_Nao = {
    1: {
        icon: <ThumbDownIcon fontSize="large" />,
        label: 'Não',
    }
};


function IconContainerEstrelas(props) {
    const { value, ...other } = props;
    return <span {...other}>{EstrelasRatingInt[value].icon}</span>;
}

function IconContainerImportancia(props) {
    const { value, ...other } = props;
    return <span {...other}>{ImportanciaRatingInt[value].icon}</span>;
}

function IconContainerConcordancia(props) {
    const { value, ...other } = props;
    return <span {...other}>{ConcordanciaRatingInt[value].icon}</span>;
}

function IconContainerSimNao(props) {
    const { value, ...other } = props;
    return <span {...other}>{SimNaoRatingInt[value].icon}</span>;
}

function IconContainerSimNao_NaoAplica(props) {
    const { value, ...other } = props;
    return <span {...other}>{SimNaoRatingInt_NaoAplica[value].icon}</span>;
}

function IconContainerSimNao_Sim(props) {
    const { value, ...other } = props;
    return <span {...other}>{SimNaoRatingInt_Sim[value].icon}</span>;
}

function IconContainerSimNao_Nao(props) {
    const { value, ...other } = props;
    return <span {...other}>{SimNaoRatingInt_Nao[value].icon}</span>;
}

IconContainerEstrelas.propTypes = {
    value: PropTypes.number.isRequired,
};

IconContainerImportancia.propTypes = {
    value: PropTypes.number.isRequired,
};

IconContainerConcordancia.propTypes = {
    value: PropTypes.number.isRequired,
};

IconContainerSimNao.propTypes = {
    value: PropTypes.number.isRequired,
};

IconContainerSimNao_NaoAplica.propTypes = {
    value: PropTypes.number.isRequired,
};

IconContainerSimNao_Sim.propTypes = {
    value: PropTypes.number.isRequired,
};

IconContainerSimNao_Nao.propTypes = {
    value: PropTypes.number.isRequired,
};

export function AvalPergunta(props) {

    //Constructor

    //Verificar Checkbox ou RadioButton
    let quaisCheckbox = [];
    let qtdCheckboxInicial = 0;
    if ((parseInt(props.pergunta.tipoExibicao) === 5) || (parseInt(props.pergunta.tipoExibicao) === 7)) { //CheckBox ou RadioButton
        for (let i = 0; i < props.pergunta.perguntaItens.length; i++) {
            quaisCheckbox.push({
                id: props.pergunta.perguntaItens[i].id,
                nome: props.pergunta.perguntaItens[i].nome,
                valor: props.pergunta.perguntaItens[i].valor,
                checked: false
            })
        }
    }

    //Verificar respostas
    let respRate = null;
    let respAberta = null;
    let respRadio = null;
    if ((props.respostas != null) && (props.respostas.length > 0)){
        if ((parseInt(props.pergunta.tipoExibicao) === 1) || (parseInt(props.pergunta.tipoExibicao) === 2) ||
            (parseInt(props.pergunta.tipoExibicao) === 3) || (parseInt(props.pergunta.tipoExibicao) === 6)) {
            respRate = parseInt(props.respostas[0].valor) + 1;
        }
        if (parseInt(props.pergunta.tipoExibicao) === 4) { //Aberta
            respAberta = props.respostas[0].valorAberta;
        }
        if (parseInt(props.pergunta.tipoExibicao) === 5) { //CheckBox
            for (let i = 0; i < props.respostas.length; i++) {
                for (let j = 0; j < quaisCheckbox.length; j++)
                    if (props.respostas[i].valor === quaisCheckbox[j].valor) {
                        quaisCheckbox[j].checked = true;
                        qtdCheckboxInicial++;
                    }
            }
        }
        if (parseInt(props.pergunta.tipoExibicao) === 7) { //RadioButton
            respRadio = parseInt(props.respostas[0].valor)
        }
    }

    const [respostaAberta, setRespostaAberta] = useState(respAberta);
    const [respostaCheckbox, setRespostaCheckbox] = useState(quaisCheckbox);
    const [qtdCheckbox, setQtdCheckbox] = useState(qtdCheckboxInicial);
    const [respostaRadio, setRespostaRadio] = useState(respRadio);
    const [alterouCheckBox, setAlterouCheckbox] = useState(false);
    const [rate, setRate] = useState(respRate);
    const [hoverRateInt, setHoverRateInt] = React.useState(-1);

    useEffect(() => {
        //componentDidMount
        //InicializarComponente();
    }, []); 

    function InicializarComponente() {
        if ((parseInt(props.pergunta.tipoExibicao) === 4) && (respAberta == null)) {
            handleChangeAberta("");
        }
        if ((parseInt(props.pergunta.tipoExibicao) === 5) && (qtdCheckbox == 0)) {
            props.responder(props.pergunta.id, props.pergunta.tipoExibicao, props.etapa, [], (props.disciplina) ? props.disciplina:0);
        }
    }

    function handleChangeRate(newValue) {
        setRate(newValue);
        let Resp = [];
        if (newValue != null) {
            Resp.push({
                valor: newValue - 1,
                valorAberta: ""
            });
        }
        props.responder(props.pergunta.id, props.pergunta.tipoExibicao, props.etapa, Resp, (props.disciplina) ? props.disciplina : 0);
    }

    function handleChangeRadio(newValue) {
        setRespostaRadio(newValue);
        //setAlterouCheckbox(!alterouCheckBox);
        let Resp = [];
        Resp.push({
            valor: newValue,
            valorAberta: ""
        });
        props.responder(props.pergunta.id, props.pergunta.tipoExibicao, props.etapa, Resp, (props.disciplina) ? props.disciplina : 0);
    }

    function handleChangeAberta(newValue) {
        setRespostaAberta(newValue)
        let Resp = [];
        Resp.push({
            valor: 0,
            valorAberta: newValue
        });
        props.responder(props.pergunta.id, props.pergunta.tipoExibicao, props.etapa, Resp, (props.disciplina) ? props.disciplina : 0);
    }

    function handleChangeCheckBox(index, newValue) {
        let TempRespostas = respostaCheckbox;
        TempRespostas[index].checked = newValue;
        setRespostaCheckbox(TempRespostas);
        setAlterouCheckbox(!alterouCheckBox);
        let Resp = [];
        for (let i = 0; i < TempRespostas.length; i++) {
            if (TempRespostas[i].checked) {
                Resp.push({
                    valor: TempRespostas[i].valor,
                    valorAberta: ""
                });
            }
        }
        props.responder(props.pergunta.id, props.pergunta.tipoExibicao, props.etapa, Resp, (props.disciplina) ? props.disciplina : 0);
    };

    function getOpcao() {
        let contents = <span></span>;
        let Nome = "Resp" + props.pergunta.id.toString();
        if (props.discObrigatoria) Nome = Nome + "_disOb" + props.discObrigatoria;
        if (props.discEletiva) Nome = Nome + "_disEl" + props.discEletiva;

        if (parseInt(props.pergunta.tipoExibicao) === 1) { //IconeEstrela
            let label = null;
            if (hoverRateInt !== -1) {
                label = (hoverRateInt === 1) ? SimNaoRatingInt_NaoAplica[1].label : EstrelasRatingInt[hoverRateInt - 1].label;
            }
            else if (rate) {
                label = (rate == 1) ? SimNaoRatingInt_NaoAplica[1].label : EstrelasRatingInt[rate - 1].label;
            }
            contents =
                <div style={{ fontSize: '0.9rem', display: 'inline-block' }}>
                    <div id={"DIV" + Nome} style={{ display: 'inline-block' }}>
                        <StyledRatingCoracao name={Nome + "_NaoAplica"} value={(rate == null || rate !== 1) ? null : 1}
                            onChange={(event, newValue) => { handleChangeRate((newValue == null) ? null : 1); }}
                            onChangeActive={(event, newHover) => { setHoverRateInt((newHover === -1) ? -1 : 1); }}
                            IconContainerComponent={IconContainerSimNao_NaoAplica}
                            max={1}
                        />&nbsp;
                        <Rating name={Nome} value={(rate == null || rate == 1) ? null : rate - 1}
                            onChange={(event, newValue) => { handleChangeRate((newValue == null) ? null : newValue + 1); }}
                            onChangeActive={(event, newHover) => { setHoverRateInt((newHover === -1) ? -1 : newHover + 1); }}
                            IconContainerComponent={IconContainerEstrelas}
                            max={5}
                        />
                    </div>
                    <div style={{ display: 'inline-block', position: 'absolute', paddingLeft: '10px', paddingTop: '8px' }}>
                        {label}
                    </div>
                </div>
        }
        if (parseInt(props.pergunta.tipoExibicao) === 2) { //IconeSentimentos
            let label = null;
            if (hoverRateInt !== -1) {
                label = (hoverRateInt === 1) ? SimNaoRatingInt_NaoAplica[1].label : ConcordanciaRatingInt[hoverRateInt - 1].label;
            }
            else if (rate){
                label = (rate == 1) ? SimNaoRatingInt_NaoAplica[1].label : ConcordanciaRatingInt[rate - 1].label;
            }
            contents =
                <div style={{ fontSize: '0.9rem', display: 'inline-block' }}>
                    <div id={"DIV"+Nome} style={{ display: 'inline-block' }}>
                        <StyledRatingCoracao name={Nome + "_NaoAplica"} value={(rate == null || rate !== 1) ? null : 1}
                            onChange={(event, newValue) => { handleChangeRate((newValue == null) ? null : 1); }}
                            onChangeActive={(event, newHover) => { setHoverRateInt((newHover === -1) ? -1 : 1); }}
                            IconContainerComponent={IconContainerSimNao_NaoAplica}
                            max={1}
                        />&nbsp;
                        <Rating name={Nome} value={(rate == null || rate == 1) ? null : rate-1}
                            onChange={(event, newValue) => { handleChangeRate((newValue == null) ? null : newValue+1); }}
                            onChangeActive={(event, newHover) => { setHoverRateInt((newHover === -1) ? -1 : newHover+1); }} 
                            IconContainerComponent={IconContainerConcordancia}
                            max={5}
                        />
                    </div>
                    <div style={{ display: 'inline-block', position: 'absolute', paddingLeft: '10px', paddingTop: '8px' }}>
                    {label}
                    </div>
                </div>
        }
        if (parseInt(props.pergunta.tipoExibicao) === 3) { //SimNao
            /*
            contents =
                <div style={{ fontSize: '0.9rem', display: 'inline-block' }}>
                    <div style={{ display: 'inline-block' }}>
                        <StyledRatingSimNao name={Nome} value={rate}
                            onChange={(event, newValue) => { handleChangeRate(newValue); }}
                            onChangeActive={(event, newHover) => { setHoverRateInt(newHover); }}
                            IconContainerComponent={IconContainerSimNao}
                            max={3}
                        />
                    </div>
                    <div style={{ display: 'inline-block', position: 'absolute', paddingLeft: '10px', paddingTop: '8px' }}>
                        {(hoverRateInt !== -1 || rate) && SimNaoRatingInt[hoverRateInt !== -1 ? hoverRateInt : rate].label}
                    </div>
                </div>
            */
            contents =
                <div style={{ fontSize: '0.9rem', display: 'inline-block' }}>
                    <div id={"DIV"+Nome} style={{ display: 'inline-block' }}>
                        <StyledRatingCoracao name={Nome + "_NaoAplica"} value={(rate == null || rate !== 1)?null:1}
                            onChange={(event, newValue) => { handleChangeRate((newValue == null)?null:1); }}
                            onChangeActive={(event, newHover) => { setHoverRateInt((newHover === -1)?-1:1); }}
                            IconContainerComponent={IconContainerSimNao_NaoAplica}
                            max={1}
                        />&nbsp;
                        <StyledRatingSimNao name={Nome + "_Sim"} value={(rate == null || rate !== 2)?null:1}
                            onChange={(event, newValue) => { handleChangeRate((newValue == null)?null:2); }}
                            onChangeActive={(event, newHover) => { setHoverRateInt((newHover === -1)?-1:2); }}
                            IconContainerComponent={IconContainerSimNao_Sim}
                            max={1}
                        />&nbsp;
                        <StyledRatingSimNao name={Nome + "_Nao"} value={(rate == null || rate !== 3)?null:1}
                            onChange={(event, newValue) => { handleChangeRate((newValue == null)?null:3); }}
                            onChangeActive={(event, newHover) => { setHoverRateInt((newHover === -1)?-1:3); }}
                            IconContainerComponent={IconContainerSimNao_Nao}
                            max={1}
                        />
                    </div>
                    <div style={{ display: 'inline-block', position: 'absolute', paddingLeft: '10px', paddingTop: '8px' }}>
                        {(hoverRateInt !== -1 || rate) && SimNaoRatingInt[hoverRateInt !== -1 ? hoverRateInt : rate].label}
                    </div>
                </div>
        }
        if (parseInt(props.pergunta.tipoExibicao) === 4) { //Aberta
            contents = 
                <Form.Control id={"DIV" + Nome} name={Nome} as="textarea" rows="4" cols="50" style={{ width: "100%" }}
                    value={respostaAberta} onChange={(e) => handleChangeAberta(e.target.value)} autoResize={true} />
        }
        if (parseInt(props.pergunta.tipoExibicao) === 5) { //CheckBox
            contents = 
                <div>
                    {respostaCheckbox.map((item, index) => (
                        <div>
                            <Checkbox
                            checked={item.checked}
                            onChange={(event, newValue) => { handleChangeCheckBox(index, newValue); }}
                            name={"check_" + item.id + "_" + index}
                            color="primary"
                            />
                            <span>{item.nome}</span>
                        </div>
                    ))}
                </div>
        }
        if (parseInt(props.pergunta.tipoExibicao) === 6) { //GrauImportancia
            let label = null;
            if (hoverRateInt !== -1) {
                label = (hoverRateInt === 1) ? SimNaoRatingInt_NaoAplica[1].label : ImportanciaRatingInt[hoverRateInt - 1].label;
            }
            else if (rate) {
                label = (rate == 1) ? SimNaoRatingInt_NaoAplica[1].label : ImportanciaRatingInt[rate - 1].label;
            }
            contents =
                <div style={{ fontSize: '0.9rem', display: 'inline-block' }}>
                    <div id={"DIV" + Nome} style={{ display: 'inline-block' }}>
                        <StyledRatingCoracao name={Nome + "_NaoAplica"} value={(rate == null || rate !== 1) ? null : 1}
                            onChange={(event, newValue) => { handleChangeRate((newValue == null) ? null : 1); }}
                            onChangeActive={(event, newHover) => { setHoverRateInt((newHover === -1) ? -1 : 1); }}
                            IconContainerComponent={IconContainerSimNao_NaoAplica}
                            max={1}
                        />&nbsp;
                        <StyledRatingCoracao name={Nome} value={(rate == null || rate == 1) ? null : rate - 1}
                            onChange={(event, newValue) => { handleChangeRate((newValue == null) ? null : newValue + 1); }}
                            onChangeActive={(event, newHover) => { setHoverRateInt((newHover === -1) ? -1 : newHover + 1); }}
                            IconContainerComponent={IconContainerImportancia}
                            max={5}
                        />
                    </div>
                    <div style={{ display: 'inline-block', position: 'absolute', paddingLeft: '10px', paddingTop: '8px' }}>
                        {label}
                    </div>
                </div>
        }
        if (parseInt(props.pergunta.tipoExibicao) === 7) { //RadioButton
            contents =
                <div>
                    <div>
                        <Radio
                            checked={respostaRadio === 0}
                            value={0}
                            onClick={(event, newValue) => { handleChangeRadio(0); }}
                            name={Nome + "radio_zero"}
                            color="primary"
                        />
                        <span>Não se aplica / Não sei / Não quero responder</span>
                    </div>
                    {respostaCheckbox.map((item, index) => (
                        <div>
                            <Radio
                                checked={respostaRadio === item.valor}
                                value={item.valor}
                                onClick={(event, newValue) => { handleChangeRadio(item.valor); }}
                                name={"radio_" + item.id + "_" + index}
                                color="primary"
                            />
                            <span>{item.nome}</span>
                        </div>
                    ))}
                </div>
        }

        return (
            <div>
                {contents}
            </div>
        );
    }

    function renderAll() {
        let contents = 
            <div>
                <p>{props.etapa}.{props.nro}. {props.pergunta.nome}</p>
                <p>{getOpcao()}</p>
                <hr size="1" />
            </div>       

        return (
            <div>
                {contents}
            </div>
        );
    }

    return (
        renderAll()
        );
}
