import React, { useState, useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { AvalRelatorioItemRating } from './AvalRelatorioItemRating';
import AvalRelatorioItemGrafico from './AvalRelatorioItemGrafico';
import AvalRelatorioItemAberta from './AvalRelatorioItemAberta';

export function AvalRelatorioItem(props) {

    useEffect(() => {
        //componentDidMount e componentDidUpdate
    }, [props]);

    function renderAll() {
        if (props.QualInstituicao.id > 0) {
            if (!props.Indicador.respostaInstituicao) {
                return (<p><i className="fa fa-refresh fa-spin"></i> <em>Aguarde...</em></p>);
            }
        }
        if ((parseInt(props.Indicador.tipoExibicao) === 1) || //IconeEstrela
            (parseInt(props.Indicador.tipoExibicao) === 2) || //IconeSentimentos
            (parseInt(props.Indicador.tipoExibicao) === 6)) { //GrauImportancia
            if (props.Tipo === "RESUMO") {
                return (<AvalRelatorioItemRating QualInstituicao={props.QualInstituicao} Indicador={props.Indicador} />);
            }
            else {
                return (
                    <div>
                        <div>
                            <AvalRelatorioItemRating QualInstituicao={props.QualInstituicao} Indicador={props.Indicador} />
                        </div>
                        <div>
                            <AvalRelatorioItemGrafico QualInstituicao={props.QualInstituicao} Indicador={props.Indicador} />
                        </div>
                    </div>
                );
            }
        }
        if ((parseInt(props.Indicador.tipoExibicao) === 3) || //SimNao
            (parseInt(props.Indicador.tipoExibicao) === 5) || //CheckBox
            (parseInt(props.Indicador.tipoExibicao) === 7)) { //RadioButton
            return (<AvalRelatorioItemGrafico QualInstituicao={props.QualInstituicao} Indicador={props.Indicador} />);
        }
        if (props.Indicador.tipoExibicao === 4) { //Aberta
            return (<AvalRelatorioItemAberta QualInstituicao={props.QualInstituicao} Indicador={props.Indicador} />);
        }                   
        return (<p>teste</p>);
    }  

    return (
        renderAll()
    );

}