import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import Coordenadores from '../../components/Coordenadores/Coordenadores';

export class ListaCoordenadores extends Component {
    displayName = ListaCoordenadores.name

  constructor(props) {
      super(props);
    }

  render() {
    return (
        <Container >       
            <Row>
                <Col sm={12}>
                    <h2>Consulta de Coordenadores das Instituições Associadas</h2>
                    <PainelConteudo legenda="Coordenadores"
                        texto={
                            <Row>
                                <Col sm={12}>
                                    <Coordenadores />
                                </Col>
                            </Row>
                        } />                    
                </Col>
            </Row>
        </Container>
    );
  }
}
