import React, { Component } from 'react';
import { Form } from 'react-bootstrap'; ///lib/FormControl';
//import authService from '../api-authorization/AuthorizeService';

export default class SelecInstituicoes extends Component {    

    constructor(props) {
        super(props);
        this.state = {
            Instituicoes: [],
            loading: true,
            error: null
        };
    }

    componentDidMount() {
        let strFetch = 'api/Indicadores/QuaisInstituicoes';
        if (this.props.ComTodas) {
            strFetch += '?ComTodas=' + this.props.ComTodas;
        }
        fetch(strFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ Instituicoes: data, loading: false });
                if (!this.props.InstituicaoSelecionada) {
                    this.props.AtualizaQualInstituicao(this.state.Instituicoes[0]);
                }                
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    AlteraInstituicao(pValor) {
        let a = this.state.Instituicoes;
        for (let i = 0; i < a.length; i++) {
            if (a[i].id == pValor) {
                /*
                if (this.props.InstituicaoSelecionada) {
                    this.props.InstituicaoSelecionada = a[i].id;
                }
                */
                this.props.AtualizaQualInstituicao(a[i]);
            }
        }        
    }

    renderSelectInstituicoes() {
        return (
            <div>
                <Form.Control as="select"
                    placeholder="Selecionar instituição"
                    onChange={(e) => this.AlteraInstituicao(e.target.value)} >
                    {
                        this.state.Instituicoes.map(instituicao =>
                            (this.props.InstituicaoSelecionada && this.props.InstituicaoSelecionada === instituicao.id) ?
                                <option selected value={instituicao.id} key={instituicao.id}> {instituicao.nome} </option> :
                                <option value={instituicao.id} key={instituicao.id}> {instituicao.nome} </option>)
                    }
                </Form.Control>
            </div>       
        );
    }
    render() {
        const { loading, error } = this.state;
        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents =  <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                        </div>;
        }
        else {
            contents = this.renderSelectInstituicoes();
        }

        return (
            <div>
                {contents}
            </div>
        );

    }
}