import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import HighMaps from 'highcharts/highmaps';
import BrazilAll from "./BrazilAll";

export default class BrazilMap extends Component {
     
  componentDidMount() {

      const options = {
      chart: {
        map: 'countries/br/br-all'
      },
      title: {
          enabled: false,
          text: ''
      },

      subtitle: {
          text: ''
      },

      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: 'bottom'
        }
      },
      colorAxis: {
        min: 0
        //minColor: '#f4f7f2', //'#E6E7E8',
        //maxColor: '#2b6305' //'#D92B10'
      },
      series: [{
        mapData: BrazilAll,
        data: this.props.selectedData,
        name: this.props.rotulo, // 'Quantidade',
        states: {
          hover: {
            color: '#998035' // '#BADA55'
          }
        },
        dataLabels: {
          enabled: true,
          format: '{point.name}'
        }
      }],
    };

    this.chart = new HighMaps['Map'](
      findDOMNode(this),
      options
    );
  }

  componentWillReceiveProps(){

  }
  componentWillUnmount() {
    this.chart.destroy();
  }

  render() {
 
    return (
      <div className="in-highchart"></div>
    )
  }
}

