import React, { Component } from 'react';
import { Checkbox } from 'primereact/checkbox';
import BrazilMap from '../BrazilMap/BrazilMap';

export default class BrazilMapProducaoBibliografica extends Component {

    TituloGraficoParte1 = "Quantidade";

    mapDataEstados() {
        let qSP = 0, qMA = 0, qPA = 0, qSC = 0, qBA = 0, qAP = 0, qMS = 0, qMG = 0, qGO = 0,
            qRS = 0, qTO = 0, qPI = 0, qAL = 0, qPB = 0, qCE = 0, qSE = 0, qRR = 0, qPE = 0,
            qPR = 0, qES = 0, qRJ = 0, qRN = 0, qAM = 0, qMT = 0, qDF = 0, qAC = 0, qRO = 0;

        let dSP = 0, dMA = 0, dPA = 0, dSC = 0, dBA = 0, dAP = 0, dMS = 0, dMG = 0, dGO = 0,
            dRS = 0, dTO = 0, dPI = 0, dAL = 0, dPB = 0, dCE = 0, dSE = 0, dRR = 0, dPE = 0,
            dPR = 0, dES = 0, dRJ = 0, dRN = 0, dAM = 0, dMT = 0, dDF = 0, dAC = 0, dRO = 0;

        let a = this.state.EstatisticaProducao;
        for (let i = 0; i < a.length; i++) {
            let QualEstado = a[i].instituicao.estado;
            let QeventoArtigoCompleto = a[i].eventoArtigoCompleto;
            let QeventoArtigoResumo = a[i].eventoArtigoResumo;
            let QrevistaArtigo = a[i].revistaArtigo;
            let QcapituloLivro = a[i].capituloLivro;
            let Qlivro = a[i].livro; 
            let TotalElementos = 0;

            let DocentesLattes = a[i].instituicao.quantidadeProfessoresLattes;

            if (this.state.eventoArtigoCompletoChecked) TotalElementos += QeventoArtigoCompleto;
            if (this.state.eventoArtigoResumoChecked) TotalElementos += QeventoArtigoResumo;
            if (this.state.revistaArtigoChecked) TotalElementos += QrevistaArtigo;
            if (this.state.capituloLivroChecked) TotalElementos += QcapituloLivro;
            if (this.state.livroChecked) TotalElementos += Qlivro;       

            if (QualEstado === "SP") { qSP += TotalElementos; dSP += DocentesLattes; }
            if (QualEstado === "MA") { qMA += TotalElementos; dMA += DocentesLattes; }
            if (QualEstado === "PA") { qPA += TotalElementos; dPA += DocentesLattes; }
            if (QualEstado === "SC") { qSC += TotalElementos; dSC += DocentesLattes; }
            if (QualEstado === "BA") { qBA += TotalElementos; dBA += DocentesLattes; }
            if (QualEstado === "AP") { qAP += TotalElementos; dAP += DocentesLattes; }
            if (QualEstado === "MS") { qMS += TotalElementos; dMS += DocentesLattes; }
            if (QualEstado === "MG") { qMG += TotalElementos; dMG += DocentesLattes; }
            if (QualEstado === "GO") { qGO += TotalElementos; dGO += DocentesLattes; }
            if (QualEstado === "RS") { qRS += TotalElementos; dRS += DocentesLattes; }
            if (QualEstado === "TO") { qTO += TotalElementos; dTO += DocentesLattes; }
            if (QualEstado === "PI") { qPI += TotalElementos; dPI += DocentesLattes; }
            if (QualEstado === "PB") { qPB += TotalElementos; dPB += DocentesLattes; }
            if (QualEstado === "CE") { qCE += TotalElementos; dCE += DocentesLattes; }
            if (QualEstado === "SE") { qSE += TotalElementos; dSE += DocentesLattes; }
            if (QualEstado === "RR") { qRR += TotalElementos; dRR += DocentesLattes; }
            if (QualEstado === "PE") { qPE += TotalElementos; dPE += DocentesLattes; }
            if (QualEstado === "PR") { qPR += TotalElementos; dPR += DocentesLattes; }
            if (QualEstado === "ES") { qES += TotalElementos; dES += DocentesLattes; }
            if (QualEstado === "RJ") { qRJ += TotalElementos; dRJ += DocentesLattes; }
            if (QualEstado === "RN") { qRN += TotalElementos; dRN += DocentesLattes; }
            if (QualEstado === "AM") { qAM += TotalElementos; dAM += DocentesLattes; }
            if (QualEstado === "MT") { qMT += TotalElementos; dMT += DocentesLattes; }
            if (QualEstado === "DF") { qDF += TotalElementos; dDF += DocentesLattes; }
            if (QualEstado === "AC") { qAC += TotalElementos; dAC += DocentesLattes; }
            if (QualEstado === "RO") { qRO += TotalElementos; dRO += DocentesLattes; }
        }

        if (this.props.TipoGrafico === "PorProfessor") {
            this.setState({
                selectedData: [
                    ['br-sp', (dSP > 0) ? Math.round((qSP * 10.00) / dSP) : 0],
                    ['br-ma', (dMA > 0) ? Math.round((qMA * 10.00) / dMA) : 0],
                    ['br-pa', (dPA > 0) ? Math.round((qPA * 10.00) / dPA) : 0],
                    ['br-sc', (dSC > 0) ? Math.round((qSC * 10.00) / dSC) : 0],
                    ['br-ba', (dBA > 0) ? Math.round((qBA * 10.00) / dBA) : 0],
                    ['br-ap', (dAP > 0) ? Math.round((qAP * 10.00) / dAP) : 0],
                    ['br-ms', (dMS > 0) ? Math.round((qMS * 10.00) / dMS) : 0],
                    ['br-mg', (dMG > 0) ? Math.round((qMG * 10.00) / dMG) : 0],
                    ['br-go', (dGO > 0) ? Math.round((qGO * 10.00) / dGO) : 0],
                    ['br-rs', (dRS > 0) ? Math.round((qRS * 10.00) / dRS) : 0],
                    ['br-to', (dTO > 0) ? Math.round((qTO * 10.00) / dTO) : 0],
                    ['br-pi', (dPI > 0) ? Math.round((qPI * 10.00) / dPI) : 0],
                    ['br-al', (dAL > 0) ? Math.round((qAL * 10.00) / dAL) : 0],
                    ['br-pb', (dPB > 0) ? Math.round((qPB * 10.00) / dPB) : 0],
                    ['br-ce', (dCE > 0) ? Math.round((qCE * 10.00) / dCE) : 0],
                    ['br-se', (dSE > 0) ? Math.round((qSE * 10.00) / dSE) : 0],
                    ['br-rr', (dRR > 0) ? Math.round((qRR * 10.00) / dRR) : 0],
                    ['br-pe', (dPE > 0) ? Math.round((qPE * 10.00) / dPE) : 0],
                    ['br-pr', (dPR > 0) ? Math.round((qPR * 10.00) / dPR) : 0],
                    ['br-es', (dES > 0) ? Math.round((qES * 10.00) / dES) : 0],
                    ['br-rj', (dRJ > 0) ? Math.round((qRJ * 10.00) / dRJ) : 0],
                    ['br-rn', (dRN > 0) ? Math.round((qRN * 10.00) / dRN) : 0],
                    ['br-am', (dAM > 0) ? Math.round((qAM * 10.00) / dAM) : 0],
                    ['br-mt', (dMT > 0) ? Math.round((qMT * 10.00) / dMT) : 0],
                    ['br-df', (dDF > 0) ? Math.round((qDF * 10.00) / dDF) : 0],
                    ['br-ac', (dAC > 0) ? Math.round((qAC * 10.00) / dAC) : 0],
                    ['br-ro', (dRO > 0) ? Math.round((qRO * 10.00) / dRO) : 0]
                ]
            });
        }
        else {
            this.setState({
                selectedData: [
                    ['br-sp', qSP],
                    ['br-ma', qMA],
                    ['br-pa', qPA],
                    ['br-sc', qSC],
                    ['br-ba', qBA],
                    ['br-ap', qAP],
                    ['br-ms', qMS],
                    ['br-mg', qMG],
                    ['br-go', qGO],
                    ['br-rs', qRS],
                    ['br-to', qTO],
                    ['br-pi', qPI],
                    ['br-al', qAL],
                    ['br-pb', qPB],
                    ['br-ce', qCE],
                    ['br-se', qSE],
                    ['br-rr', qRR],
                    ['br-pe', qPE],
                    ['br-pr', qPR],
                    ['br-es', qES],
                    ['br-rj', qRJ],
                    ['br-rn', qRN],
                    ['br-am', qAM],
                    ['br-mt', qMT],
                    ['br-df', qDF],
                    ['br-ac', qAC],
                    ['br-ro', qRO]
                ]
            });
        }

    }    
        
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            EstatisticaProducao: [],
            selectedData: [],
            eventoArtigoCompletoChecked: true,
            eventoArtigoResumoChecked: true,
            revistaArtigoChecked: true,
            capituloLivroChecked: true,
            livroChecked: true
        };
    }

    componentDidMount() {
        this.CarregarDados();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.QualAno !== this.props.QualAno) {
            this.CarregarDados();
        }
    }

    CarregarDados() {
        this.setState({ loading: true });
        let StringFetch = "";
        if (this.props.QualConsulta === "PorAno") {
            if (this.props.QualAno == null)
                return;
            if (this.props.QualAno.length === 0)
                return;
            StringFetch = 'api/Indicadores/ProducaoBibliografica?QuaisAnos=' + this.props.QualAno;//QualAno=' + this.props.QualAno;
        }
        else {
            if (this.props.QualInstituicao == null)
                return;
            if (this.props.QualInstituicao === -1)
                return;
            StringFetch = 'api/Indicadores/ProducaoBibliografica?QualInstituicao=' + this.props.QualInstituicao;
        }
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ EstatisticaProducao: data, loading: false });
                this.ConfiguraDados();
            })
            .catch(error => this.setState({ error, loading: false }));
    }
       
    ConfiguraDados() {

        if (this.props.TipoGrafico === "PorProfessor") {
            this.TituloGraficoParte1 = "Média por professor * 10";
        }

        this.mapDataEstados = this.mapDataEstados.bind(this);

        this.mapDataEstados();
    }

    MudoueventoArtigoCompleto(valor) {
        this.setState({ eventoArtigoCompletoChecked: valor }, () => {
            this.mapDataEstados();
        });
    }
    MudoueventoArtigoResumo(valor) {
        this.setState({ eventoArtigoResumoChecked: valor }, () => {
            this.mapDataEstados();
        });
    }
    MudourevistaArtigo(valor) {
        this.setState({ revistaArtigoChecked: valor }, () => {
            this.mapDataEstados();
        });
    }
    MudoucapituloLivro(valor) {
        this.setState({ capituloLivroChecked: valor }, () => {
            this.mapDataEstados();
        });
    }
    Mudoulivro(valor) {
        this.setState({ livroChecked: valor }, () => {
            this.mapDataEstados();
        });
    }

    renderProducaoBibliografica() {
        return (
            <div align="center"> 
                <p align="center"><b><i>{this.TituloGraficoParte1}</i></b></p>
                <Checkbox onChange={e => this.MudoueventoArtigoCompleto(e.checked)} checked={this.state.eventoArtigoCompletoChecked}></Checkbox>
                <label style={{ fontSize: '11px' }} htmlFor="cb1" className="p-checkbox-label">Artigo Completo em Evento</label>&nbsp;&nbsp;&nbsp;
                <Checkbox onChange={e => this.MudoueventoArtigoResumo(e.checked)} checked={this.state.eventoArtigoResumoChecked}></Checkbox>
                <label style={{ fontSize: '11px' }} htmlFor="cb2" className="p-checkbox-label">Resumo em Evento</label>&nbsp;&nbsp;&nbsp;
                <Checkbox onChange={e => this.MudourevistaArtigo(e.checked)} checked={this.state.revistaArtigoChecked}></Checkbox>
                <label style={{ fontSize: '11px' }} htmlFor="cb3" className="p-checkbox-label">Artigo em Periódico </label><br />
                <Checkbox onChange={e => this.MudoucapituloLivro(e.checked)} checked={this.state.capituloLivroChecked}></Checkbox>
                <label style={{ fontSize: '11px' }} htmlFor="cb4" className="p-checkbox-label">Capítulo de Livro </label>&nbsp;&nbsp;&nbsp;
                <Checkbox onChange={e => this.Mudoulivro(e.checked)} checked={this.state.livroChecked}></Checkbox>
                <label style={{ fontSize: '11px' }} htmlFor="cb5" className="p-checkbox-label">Livro</label><br />

                <BrazilMap key={this.state.selectedData} rotulo={this.TituloGraficoParte1} selectedData={this.state.selectedData}></BrazilMap>
            </div>
        );
    }

    render() {
        const { loading, error } = this.state;

        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents = <div className="overlay">
                           <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                       </div>;
        }
        else {
            contents = this.renderProducaoBibliografica();
        }

        return (
            <div>
                {contents}
            </div>
        );

    }

}