import React, { useState, useContext, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import InfoContext from '../../context/infoContext/infoContext';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import config from '../../config.json';
import './ModalUpdateMode.css';

export default function ModalLoading(props) {

    //Contexto
    const infoContext = useContext(InfoContext);
    let loginPath = `${ApplicationPaths.Login}`;
    //Construtor
    const [show, setShow] = useState(false);
    const [showLink, setShowLink] = useState(<div></div>);

    useEffect(() => {
        let pshow = config.MANUTENCAO;
        let pshowLink = <div></div>;
        if (!infoContext.user) {
            pshowLink = <span>Se você é administrador do site, <Link to={loginPath}>Clique aqui</Link></span>;
        }
        if ((infoContext.user) && (infoContext.user.profile.admin)) {
            pshow = false;
        }
        setShow(pshow);
        setShowLink(pshowLink);
    }, [infoContext.user]);

    return (
        <Modal show={show} backdrop={"static"} className="modalLoading">
            <Alert style={{ margin: "40px 40px 40px 40px", padding: "20px" }}
                variant="danger">
                <span style={{ fontSize: "18px" }}>
                    O Observatório está em manutenção e/ou atualização de dados no período de <b>{config.MANUTENCAO_INICIO}</b> a <b>{config.MANUTENCAO_FIM}</b>.
		        </span><br /><br />
                {showLink}
            </Alert>
        </Modal>
    )
    
}