import React, { useContext, useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatDate } from '../../helpers/Date';

import LoadingContext from '../../context/loadingContext/loadingContext';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';
import MessageContext from '../../context/messageContext/messageContext';

import autoavaliacaoService from '../../services/autoavaliacaoService';

export default function RelacaoAutoAval(props) {

    //Contexto
    const loadingContext = useContext(LoadingContext);
    const idCt = useContext(IdiomaContext);
    const messageContext = useContext(MessageContext);

    const [instituicoes, setInstituicoes] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        loadingContext.showLoading();
        lerDados();
        loadingContext.hideLoading();
    }, [props.QualAutoAval]); 

    function lerDados() {
        if (props.QualAutoAval.id == 0)
            return;
        autoavaliacaoService.GetRelacaoAvalInstituicoes(props.QualAutoAval.id)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(DadosResposta => {
                setInstituicoes(DadosResposta);
            })
            .catch(error => {
                messageContext.openMessage("error", "Erro. Por favor, tente novamente.");
                setError(error);
            });
    }

    function renderCelular() {
        if (instituicoes == null) {
            return (<p><i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em></p>);
        }
        var header = <div style={{ 'textAlign': 'left' }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar" size="20" />
                     </div>;

        return (
            <div>
                <div className="content-section implementation">
                    <DataTable value={instituicoes} paginator={true} rows={15} rowsPerPageOptions={[5, 15, 30]}
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        globalFilter={globalFilter} header={header}                        
                    >                        
                        <Column field="sigla" header="Instituição" sortable={true} />
                        <Column field="total" header="Total" sortable={true} style={{ textAlign: 'center', width: '8em' }} />
                    </DataTable>
                </div>
            </div>
        );
    }

    function renderTamanhoNormal() {
        if (instituicoes == null) {
            return (<p><i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em></p>);
        }
        var header = <div style={{ 'textAlign': 'left' }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Pesquisar" size="50" />
                     </div>;
        return (
            <div>
                <div className="content-section implementation">
                    <DataTable value={instituicoes} paginator={true} rows={15} rowsPerPageOptions={[5, 15, 30]}
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        globalFilter={globalFilter} header={header}
                    >
                        <Column field="nome" header="Instituição" sortable={true} />
                        <Column field="coord" header="Coord." sortable={false} style={{ textAlign: 'center', width: '8em' }} />
                        <Column field="docente" header="Docentes" sortable={false} style={{ textAlign: 'center', width: '8em' }} />
                        <Column field="discente" header="Discentes" sortable={false} style={{ textAlign: 'center', width: '8em' }} />
                        <Column field="egresso" header="Egressos" sortable={false} style={{ textAlign: 'center', width: '8em' }} />
                        <Column field="total" header="Total" sortable={true} style={{ textAlign: 'center', width: '8em' }} />
                    </DataTable>
                </div>
            </div>
        );
    }

    function renderAll() {
        if (error) {
            return (<p><i className="fa fa-refresh fa-spin"></i> <em>Erro. Por favor, tente novamente.</em></p>);
        }
        else if (instituicoes == null) {
            return (<p><i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em></p>);
        }

        var windowWidth = window.innerWidth;

        let item = props.QualAutoAval;

        let TempoCoord = (item.duracaoAvaliacaoCoordLocal > 0) ? ("Coord: " + Math.trunc(item.duracaoAvaliacaoCoordLocal) + "m" + Math.trunc(Number((item.duracaoAvaliacaoCoordLocal - Math.trunc(item.duracaoAvaliacaoCoordLocal)).toFixed(2)) * 60) + "s ; ") : "";
        let TempoDosce = (item.duracaoAvaliacaoDocente > 0) ? ("Docentes: " + Math.trunc(item.duracaoAvaliacaoDocente) + "m" + Math.trunc(Number((item.duracaoAvaliacaoDocente - Math.trunc(item.duracaoAvaliacaoDocente)).toFixed(2)) * 60) + "s ; ") : "";
        let TempoDisce = (item.duracaoAvaliacaoDiscente > 0) ? ("Discentes: " + Math.trunc(item.duracaoAvaliacaoDiscente) + "m" + Math.trunc(Number((item.duracaoAvaliacaoDiscente - Math.trunc(item.duracaoAvaliacaoDiscente)).toFixed(2)) * 60) + "s ; ") : "";
        let TempoEgres = (item.duracaoAvaliacaoEgresso > 0) ? ("Egressos: " + Math.trunc(item.duracaoAvaliacaoEgresso) + "m" + Math.trunc(Number((item.duracaoAvaliacaoEgresso - Math.trunc(item.duracaoAvaliacaoEgresso)).toFixed(2)) * 60) + "s") : "";
        let contents =
            <Container >
                <Row>
                    <Col sm={12}>
                        <p><b>Prazos para responder o questionário</b>:<br />
                            Coord.: {formatDate(item.inicioAvaliacaoDocente)} a {formatDate(item.fimAvaliacaoDocente)};
                            Docentes: {formatDate(item.inicioAvaliacaoDocente)} a {formatDate(item.fimAvaliacaoDocente)}<br/>
                            Discentes: {formatDate(item.inicioAvaliacaoDiscente)} a {formatDate(item.fimAvaliacaoDiscente)};
                            Egressos: {formatDate(item.inicioAvaliacaoEgresso)} a {formatDate(item.fimAvaliacaoEgresso)}</p>
                        <p><b>Duração média (minutos) para responder o questionário</b>:<br />
                            {TempoCoord}
                            {TempoDosce}
                            {TempoDisce}
                            {TempoEgres}</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <p><b>Quantidade de pessoas que iniciaram ou já finalizaram o questionário da autoavaliação:</b></p>
                        {(windowWidth < 770) ? renderCelular() : renderTamanhoNormal()}
                    </Col>
                </Row>
            </Container>
        return (contents);
    }

    return (
            <div>
                {renderAll()}
            </div>
        );    
}
