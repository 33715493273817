import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

function CircularProgressWithLabel(props) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="static" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
        >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <span style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.54)'}}>{Math.round(props.value)}%</span>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

export default function AvalRelatorioItemGrafico(props) {

    useEffect(() => {
        //componentDidMount e componentDidUpdate
    }, [props]);


    function renderAmostraCelular() {
        if (props.QualInstituicao.id > 0) {
            if (!props.Indicador.respostaInstituicao) {
                return (<p><i className="fa fa-refresh fa-spin"></i> <em>Aguarde...</em></p>);
            }
            return (
                <div>
                    <div>
                        <b>Amostra <span style={{ color: '#3F51B5' }}>{props.QualInstituicao.sigla}</span> : </b><span style={{ color: '#3F51B5' }}>{props.Indicador.respostaInstituicao.nroRespostaTotal}</span>
                    </div>
                    <div>
                        <b>Amostra <span style={{ color: '#F50057' }}>Nacional</span> : </b><span style={{ color: '#F50057' }}>{props.Indicador.respostaGeral.nroRespostaTotal}</span>
                    </div>
                </div>);
        }
        else {
            return (
                <div>
                    <b>Amostra <span style={{ color: '#F50057' }}>Nacional</span> :</b><span style={{ color: '#F50057' }}>{props.Indicador.respostaGeral.nroRespostaTotal}</span>
                </div>
            );
        }
    }

    function renderAmostra() {

        var windowWidth = window.innerWidth;
        if (windowWidth < 770) {
            return renderAmostraCelular();
        }

        let contentsAmostra = <p></p>;
        let contentsCoord = <p></p>;
        let contentsDocente = <p></p>;
        let contentsDiscente = <p></p>;
        let contentsEgresso = <p></p>;
        if (props.QualInstituicao.id > 0) {
            if (!props.Indicador.respostaInstituicao) {
                return (<p><i className="fa fa-refresh fa-spin"></i> <em>Aguarde...</em></p>);
            }
            contentsAmostra =
                <div>
                    <div>
                        <b>Amostra <span style={{ color: '#3F51B5' }}>{props.QualInstituicao.sigla}</span>&nbsp;&nbsp;/&nbsp;&nbsp;<span style={{ color: '#F50057' }}>Nacional</span> :</b>
                    </div>
                </div>;

            if (props.Indicador.respostaGeral.nroRespostaCoord > 0) {
                contentsCoord =
                    <div>
                        <span style={{ color: '#3F51B5' }}>{props.Indicador.respostaInstituicao.nroRespostaCoord}</span> /&nbsp;
                        <span style={{ color: '#F50057' }}>{props.Indicador.respostaGeral.nroRespostaCoord}</span>
                    </div>;
            }
            if (props.Indicador.respostaGeral.nroRespostaDocente > 0) {
                contentsDocente =
                    <div>
                        <span style={{ color: '#3F51B5' }}>{props.Indicador.respostaInstituicao.nroRespostaDocente}</span> /&nbsp;
                        <span style={{ color: '#F50057' }}>{props.Indicador.respostaGeral.nroRespostaDocente}</span>
                    </div>;
            }
            if (props.Indicador.respostaGeral.nroRespostaDiscente > 0) {
                contentsDiscente =
                    <div>
                        <span style={{ color: '#3F51B5' }}>{props.Indicador.respostaInstituicao.nroRespostaDiscente}</span> /&nbsp;
                        <span style={{ color: '#F50057' }}>{props.Indicador.respostaGeral.nroRespostaDiscente}</span>
                    </div>;
            }
            if (props.Indicador.respostaGeral.nroRespostaEgresso > 0) {
                contentsEgresso =
                    <div>
                        <span style={{ color: '#3F51B5' }}>{props.Indicador.respostaInstituicao.nroRespostaEgresso}</span> /&nbsp;
                        <span style={{ color: '#F50057' }}>{props.Indicador.respostaGeral.nroRespostaEgresso}</span>
                    </div>;
            }
        }
        else {
            contentsAmostra =
                <div>
                    <div>
                        <b>Amostra <span style={{ color: '#F50057' }}>Nacional</span> :</b>
                    </div>
                </div>;
            if (props.Indicador.respostaGeral.nroRespostaCoord > 0) {
                contentsCoord =
                    <div>
                        <span style={{ color: '#F50057' }}>{props.Indicador.respostaGeral.nroRespostaCoord}</span>
                    </div>;
            }
            if (props.Indicador.respostaGeral.nroRespostaDocente > 0) {
                contentsDocente =
                    <div>
                        <span style={{ color: '#F50057' }}>{props.Indicador.respostaGeral.nroRespostaDocente}</span>
                    </div>;
            }
            if (props.Indicador.respostaGeral.nroRespostaDiscente > 0) {
                contentsDiscente =
                    <div>
                        <span style={{ color: '#F50057' }}>{props.Indicador.respostaGeral.nroRespostaDiscente}</span>
                    </div>;
            }
            if (props.Indicador.respostaGeral.nroRespostaEgresso > 0) {
                contentsEgresso =
                    <div>
                        <span style={{ color: '#F50057' }}>{props.Indicador.respostaGeral.nroRespostaEgresso}</span>
                    </div>;
            }
        }
        if ((parseInt(props.Indicador.tipoExibicao) === 1) || //IconeEstrela
            (parseInt(props.Indicador.tipoExibicao) === 2) || //IconeSentimentos
            (parseInt(props.Indicador.tipoExibicao) === 6)) { //GrauImportancia
            return (
                <Container style={{ margin: '0px' }} >
                    <Row>
                        <Col sm={4} style={{ textAlign: 'left', padding: '0px' }}>
                            {contentsAmostra}
                        </Col>
                        <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                            {contentsCoord}
                        </Col>
                        <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                            {contentsDocente}
                        </Col>
                        <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                            {contentsDiscente}
                        </Col>
                        <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                            {contentsEgresso}
                        </Col>
                    </Row>
                </Container>
            );
        }
        return (
            <Container style={{ margin: '0px' }} >
                <Row>
                    <Col sm={4} style={{ textAlign: 'left', padding: '0px' }}>
                        <br />
                        {contentsAmostra}
                    </Col>
                    <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                        <b>Coord. Local</b><br />
                        {contentsCoord}
                    </Col>
                    <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                        <b>Docentes</b><br />
                        {contentsDocente}
                    </Col>
                    <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                        <b>Discentes</b><br />
                        {contentsDiscente}
                    </Col>
                    <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                        <b>Egressos</b><br />
                        {contentsEgresso}
                    </Col>
                </Row>
            </Container>
        );
    }

    function tipoIcone(i) {
        if (props.Indicador.tipoExibicao !== 2) {
            return <span></span>;
        }
        switch (i) {
            case 0: return <span><SentimentVeryDissatisfiedIcon fontSize="small" />&nbsp;</span>; 
            case 1: return <span><SentimentDissatisfiedIcon fontSize="small" />&nbsp;</span>; 
            case 2: return <span><SentimentSatisfiedIcon fontSize="small" />&nbsp;</span>; 
            case 3: return <span><SentimentSatisfiedAltIcon fontSize="small" />&nbsp;</span>; 
            case 4: return <span><SentimentVerySatisfiedIcon fontSize="small" />&nbsp;</span>; 
            default: return "";
        }
    }

    function ExibePercentual(Nro, Cor, Valor) {
        return ExibePercentualEye(Nro, Cor, Valor, true);
    }

    function ExibePercentualEye(Nro, Cor, Valor, Exibir) {
        let retorno = <span></span>;
        if (parseInt(Nro) > 0) {
            if (Exibir)
                retorno = <CircularProgressWithLabel color={Cor} value={Valor} />
            else
                retorno = <div style={{ height: '100%', display: 'inline-block', verticalAlign: 'middle'}}><VisibilityOffIcon color="primary" fontSize="small" /></div>
        }
        return retorno;
    }

    function renderGraficoCelular() {
        let contents = <p></p>;
        if (props.QualInstituicao.id > 0) {
            if (!props.Indicador.respostaInstituicao) {
                return (<p><i className="fa fa-refresh fa-spin"></i> <em>Aguarde...</em></p>);
            }
            contents =
                <Container style={{ margin: '0px' }} >
                    {props.Indicador.respostaGeral.perguntaItens.map((item, i) =>
                        <Row>
                            <Col sm={12} style={{ textAlign: 'left', padding: '0px' }}>
                                {tipoIcone(i)}{item.perguntaItem} <br />
                                <div style={{ width: '50%', display: 'inline-block', paddingRight: '2px' }}>
                                    {(props.Indicador.respostaInstituicao.exibirTotal) ?
                                        <LinearProgressWithLabel color="primary" value={props.Indicador.respostaInstituicao.perguntaItens[i].percRespostaTotal} />
                                        : <span></span>
                                    }
                                </div>
                                <div style={{ width: '50%', display: 'inline-block', paddingLeft: '2px' }}>
                                    <LinearProgressWithLabel color="secondary" value={props.Indicador.respostaGeral.perguntaItens[i].percRespostaTotal} />
                                </div>
                            </Col>
                        </Row>
                    )}
                </Container>;
        }
        else {
            contents =
                <Container style={{ margin: '0px' }} >
                    {props.Indicador.respostaGeral.perguntaItens.map((item, i) =>
                        <Row>
                            <Col sm={12} style={{ textAlign: 'left', padding: '0px' }}>
                                <div style={{ height: '100%', verticalAlign: 'middle' }}>
                                    {tipoIcone(i)}{item.perguntaItem}<br />
                                    <LinearProgressWithLabel color="secondary" value={props.Indicador.respostaGeral.perguntaItens[i].percRespostaTotal} />
                                </div>
                            </Col>
                        </Row>
                    )}
                </Container>;
        }
        return (contents);
    }

    function renderGrafico() {

        var windowWidth = window.innerWidth;
        if (windowWidth < 770) {
            return renderGraficoCelular();
        }

        let contents = <p></p>;
        if (props.QualInstituicao.id > 0) {
            if (!props.Indicador.respostaInstituicao) {
                return (<p><i className="fa fa-refresh fa-spin"></i> <em>Aguarde...</em></p>);
            }
            contents =
                <Container style={{ margin: '0px' }} >
                    {props.Indicador.respostaGeral.perguntaItens.map((item, i) =>
                        <Row>
                            <Col sm={4} style={{ textAlign: 'left', padding: '0px' }}>
                                {tipoIcone(i)}{item.perguntaItem} <br />
                                <div style={{ width: '50%', display: 'inline-block', paddingRight: '2px' }}>
                                    {(props.Indicador.respostaInstituicao.exibirTotal)?
                                        <LinearProgressWithLabel color="primary" value={props.Indicador.respostaInstituicao.perguntaItens[i].percRespostaTotal} />
                                        :<span></span>
                                    }
                                </div>
                                <div style={{ width: '50%', display: 'inline-block', paddingLeft: '2px' }}>
                                    <LinearProgressWithLabel color="secondary" value={props.Indicador.respostaGeral.perguntaItens[i].percRespostaTotal} />
                                </div>
                            </Col>
                            <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                                {ExibePercentualEye(props.Indicador.respostaInstituicao.nroRespostaCoord, "primary", props.Indicador.respostaInstituicao.perguntaItens[i].percRespostaCoord, props.Indicador.respostaInstituicao.exibirCoord)}
                                &nbsp;&nbsp;
                                {ExibePercentual(props.Indicador.respostaGeral.nroRespostaCoord, "secondary", item.percRespostaCoord)}
                            </Col>
                            <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                                {ExibePercentualEye(props.Indicador.respostaInstituicao.nroRespostaDocente, "primary", props.Indicador.respostaInstituicao.perguntaItens[i].percRespostaDocente, props.Indicador.respostaInstituicao.exibirDocente)}
                                &nbsp;&nbsp;
                                {ExibePercentual(props.Indicador.respostaGeral.nroRespostaDocente, "secondary", item.percRespostaDocente)}
                            </Col>
                            <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                                {ExibePercentualEye(props.Indicador.respostaInstituicao.nroRespostaDiscente, "primary", props.Indicador.respostaInstituicao.perguntaItens[i].percRespostaDiscente, props.Indicador.respostaInstituicao.exibirDiscente)}                                 
                                &nbsp;&nbsp;
                                {ExibePercentual(props.Indicador.respostaGeral.nroRespostaDiscente, "secondary", item.percRespostaDiscente)}
                            </Col>
                            <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                                {ExibePercentualEye(props.Indicador.respostaInstituicao.nroRespostaEgresso, "primary", props.Indicador.respostaInstituicao.perguntaItens[i].percRespostaEgresso, props.Indicador.respostaInstituicao.exibirEgresso)}
                                &nbsp;&nbsp;
                                {ExibePercentual(props.Indicador.respostaGeral.nroRespostaEgresso, "secondary", item.percRespostaEgresso)}
                            </Col>
                        </Row>
                    )}
                </Container>;
        }
        else {
            contents =
                <Container style={{ margin: '0px' }} >
                    {props.Indicador.respostaGeral.perguntaItens.map((item, i) =>
                        <Row>
                            <Col sm={4} style={{ textAlign: 'left', padding: '0px' }}>
                                <div style={{ height: '100%', verticalAlign: 'middle' }}>
                                    {tipoIcone(i)}{item.perguntaItem}<br />
                                    <LinearProgressWithLabel color="secondary" value={props.Indicador.respostaGeral.perguntaItens[i].percRespostaTotal} />
                                </div>
                            </Col>
                            <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                                {ExibePercentual(props.Indicador.respostaGeral.nroRespostaCoord, "secondary", item.percRespostaCoord)}
                            </Col>
                            <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                                {ExibePercentual(props.Indicador.respostaGeral.nroRespostaDocente, "secondary", item.percRespostaDocente)}
                            </Col>
                            <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                                {ExibePercentual(props.Indicador.respostaGeral.nroRespostaDiscente, "secondary", item.percRespostaDiscente)}
                            </Col>
                            <Col sm={2} style={{ textAlign: 'center', padding: '0px' }}>
                                {ExibePercentual(props.Indicador.respostaGeral.nroRespostaEgresso, "secondary", item.percRespostaEgresso)}
                            </Col>
                        </Row>
                    )}
                </Container>;
        }
        return (contents);
    }

    function renderAll() {
        if (props.Indicador.respostaGeral.nroRespostaTotal === 0) {
            return (<p><span style={{ fontSize: '12px', textAlign: 'left', color: '#ff0000' }}>Nenhuma resposta</span></p>);
        }        
        return (
            <div>
                <div style={{marginTop: '10px', marginBottom: '10px'}}>
                    {renderAmostra()}
                </div>
                <div>
                    {renderGrafico()}
                </div>
            </div>
        );
    }

    return (
        renderAll()
    );

}