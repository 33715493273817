import React, { useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { NavMenu } from '../NavMenu/NavMenu';
import CabecalhoMenu from '../CabecalhoMenu/CabecalhoMenu';
import Rodape from '../Rodape/Rodape';

import ModalLoading from '../ModalLoading/ModalLoading';
import LoadingContext from '../../context/loadingContext/loadingContext';
import Mensagens from '../Mensagens/Mensagens';
import MessageContext from '../../context/messageContext/messageContext';

import ModalUpdateModel from '../ModalUpdateMode/ModalUpdateMode';

export function Layout(props) {

    const loadingContext = useContext(LoadingContext);
    const messageContext = useContext(MessageContext);

    return (
        <React.Fragment>
            <header>
                <CabecalhoMenu />
                <NavMenu />
            </header>
            <Container style={{ maxWidth: "990px", minHeight: "50px" }}>
                <Row >
                    <Col xs={12}>
                        {props.children}
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                    </Col>
                </Row>
            </Container>
            <footer>
                <Rodape />
            </footer>
            <ModalLoading show={loadingContext.loading} />
            <Mensagens open={messageContext.open} closeMessage={messageContext.closeMessage} variant={messageContext.variant} message={messageContext.message} />
            <ModalUpdateModel />
        </React.Fragment>
    );
}

