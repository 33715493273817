import React, { Component } from 'react';
import { Chart } from 'primereact/chart';

//import SelectArea from './selectArea';

export default class PeriodicoPorArea extends Component {
    areas = [];
    qtdAreas = 0;
    anos = [];
    qtdAnos = 0;
    Campus = [];
    qtdCampus = 0;
    TituloGraficoParte1 = "Quantidade x ";
    TituloGraficoParte2 = "Produção em Periódicos";
    qtdLattes = [];

    insertValidData(label, dataType, index) {
        if (dataType[index] == null || dataType[index][label] == null) {
            return;
        }
        return dataType[index][label];
    }

    reflectsLabelArea(valueLabel) {
        this.setState({ labelChart: valueLabel }, () => {
            this.mapDataToChart();
        });
    }

    mapAreas() {
        //Popula as areas
        this.areas = [];
        this.qtdAreas = 0;
        let a = this.state.EstatisticaQualisGrupo.areas;
        for (let i = 0; i < a.length; i++) {
            this.areas.push({
                label: a[i].descricao,
                value: a[i].valor
            });
            this.qtdAreas++;
        }
    }

    mapDataYears() {
        //Popula os anos
        this.anos = [];
        this.qtdLattes = [];
        this.qtdAnos = 0;
        let a = this.state.EstatisticaQualisGrupo.listaQualisTodos;
        if (a !== null) {
            for (let i = 0; i < a.length; i++) {
                if (a[i] !== null) {
                    this.anos.push(a[i].ano);
                    this.qtdLattes.push(a[i].instituicao.quantidadeProfessoresLattes);
                    this.qtdAnos++;
                }
            }
        }
        this.TituloGraficoParte2 = "Anos";
    }

    mapDataCampus() {
        //Popula os campus
        this.Campus = [];
        this.qtdLattes = [];
        this.qtdCampus = 0;
        let a = this.state.EstatisticaQualisGrupo.listaQualisTodos;
        if (a !== null) {
            for (let i = 0; i < a.length; i++) {
                if (a[i] !== null) {
                    this.Campus.push(a[i].instituicao.sigla);
                    this.qtdLattes.push(a[i].instituicao.quantidadeProfessoresLattes);
                    this.qtdCampus++;
                }
            }
        }
        this.TituloGraficoParte2 = "Instituições";
    }

    mapDataMediaPorProfessor() {
        if (this.props.TipoGrafico !== "PorProfessor") {
            return;
        }
        let actualData;
        let actualLabel = this.state.labelChart;
        let a = this.state.EstatisticaQualisGrupo;
        let propertiesName = Object.keys(a);
        let indexDate = 0;
        let lengthEixoX = this.qtdAnos;
        if (this.props.QualConsulta === "PorAno") {
            lengthEixoX = this.qtdCampus;
        }  
        if (this.props.QualConsulta === "PorIndividuo") {
            lengthEixoX = this.qtdCampus;
        }
        for (indexDate; indexDate < lengthEixoX; indexDate++) {
            propertiesName.forEach(actualProperty => {
                if (actualProperty !== "areas") {
                    for (let i = 0; i < this.areas.length; i++) {
                        actualData = this.insertValidData(actualLabel, a[actualProperty], indexDate);
                        if (actualData != null) {
                            a[actualProperty][indexDate][this.areas[i].value] = ((a[actualProperty][indexDate][this.areas[i].value] * 1.00) / this.qtdLattes[indexDate]).toFixed(3);
                        } 
                        //a[actualProperty][indexDate][this.areas[i].value] = ((a[actualProperty][indexDate][this.areas[i].value] * 1.00) / this.qtdLattes[indexDate]).toFixed(3);
                    }  
                }
            });
        }  
        this.setState({ EstatisticaQualisGrupo: a });
        this.TituloGraficoParte1 = "Média por professor x ";
    }

    mapDataToChart() {
        //Popula as datas
        let actualData;
        let actualLabel = this.state.labelChart;
        let propertiesName = Object.keys(this.dataType);
        let indexDate = 0;
        let TipoBarraGrafico = 'bar';
        let lengthEixoX = this.qtdAnos;
        let QuaisLabels = this.anos;
        if (this.props.QualConsulta === "PorAno") {
            QuaisLabels = this.Campus;
            lengthEixoX = this.qtdCampus;
            TipoBarraGrafico = 'horizontalBar';
        }
        if (this.props.QualConsulta === "PorIndividuo") {
            QuaisLabels = this.Campus;
            lengthEixoX = this.qtdCampus;
        }

        let finalData = {
            qualisA1: [],
            qualisA2: [],
            qualisA3: [],
            qualisA4: [],
            qualisB1: [],
            qualisB2: [],
            qualisB3: [],
            qualisB4: [],
            qualisC: [],
            qualisSem: []
        };
        for (indexDate; indexDate < lengthEixoX; indexDate++) {
            propertiesName.forEach(actualProperty => {
                actualData = this.insertValidData(actualLabel, this.dataType[actualProperty], indexDate);
                if (actualData != null) {
                    finalData[actualProperty].push(actualData);
                }
            });
        }

        let stackedDataUpdated = {
            labels: QuaisLabels,
            datasets: [{
                type: TipoBarraGrafico,
                label: 'A1',
                backgroundColor: '#ff0000',
                data: finalData.qualisA1
            }, {
                type: TipoBarraGrafico,
                label: 'A2',
                backgroundColor: '#ff00bf',
                data: finalData.qualisA2
            }, {
                type: TipoBarraGrafico,
                label: 'A3',
                backgroundColor: '#8000ff',
                data: finalData.qualisA3
            },
            {
                type: TipoBarraGrafico,
                label: 'A4',
                backgroundColor: '#0040ff',
                data: finalData.qualisA4
            },
            {
                type: TipoBarraGrafico,
                label: 'B1',
                backgroundColor: '#006400',
                data: finalData.qualisB1
            }, 
            {
                type: TipoBarraGrafico,
                label: 'B2',
                backgroundColor: '#00ff00',
                data: finalData.qualisB2
            }, 
            {
                type: TipoBarraGrafico,
                label: 'B3',
                backgroundColor: '#00ffff',
                data: finalData.qualisB3
            }, 
            {
                type: TipoBarraGrafico,
                label: 'B4',
                backgroundColor: '#ffff00',
                data: finalData.qualisB4
            },
            {
                type: TipoBarraGrafico,
                label: 'C',
                backgroundColor: '#ff8000',
                data: finalData.qualisC
            },
            {
                type: TipoBarraGrafico,
                label: 'Sem qualis ',
                backgroundColor: '#993300',
                data: finalData.qualisSem                
            }]
        };

        let stackedOptionsUpdated = {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    stacked: true,
                }],
                yAxes: [{
                    stacked: true
                }]
            }
        };

        this.setState({
            stackedData: stackedDataUpdated,
            stackedOptions: stackedOptionsUpdated
        });
    }

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            EstatisticaQualisGrupo: {},
            labelChart: "",
            stackedData: {},
            stackedOptions: {}
        };
      
    }

    componentDidMount() {
        this.CarregarDados();
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.QualInstituicao !== this.props.QualInstituicao) ||
            (prevProps.QualAno !== this.props.QualAno)) {
            this.CarregarDados();
        }
    }

    CarregarDados() {
        this.setState({ loading: true });
        let StringFetch = "";
        if (this.props.QualConsulta === "PorAno") {
            if (this.props.QualAno == null)
                return;
            if (this.props.QualAno.length === 0)
                return;
            StringFetch = 'api/Indicadores/PeriodicoPorArea?QuaisAnos=' + this.props.QualAno;//QualAno=' + this.props.QualAno;
        }
        else if (this.props.QualConsulta === "PorIndividuo") {
            if (this.props.QualAno == null)
                return;
            if (this.props.QualAno.length === 0)
                return;
            /*if (!localStorage.getItem('user'))
                return;
            let user = JSON.parse(localStorage.getItem('user'));
            if (user.idprof === 0)
                return;*/
            StringFetch = 'api/Indicadores/PeriodicoPorAreaDocente?QuaisAnos=' + this.props.QualAno;
        }
        else {
            if (this.props.QualInstituicao == null)
                return;
            if (this.props.QualInstituicao === -1)
                return;
            StringFetch = 'api/Indicadores/PeriodicoPorArea?QualInstituicao=' + this.props.QualInstituicao;
        }
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ EstatisticaQualisGrupo: data, loading: false });
                this.setState({ labelChart: 'ensino' });
                this.ConfiguraDados();
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    ConfiguraDados() {
        
        this.dataType = {
            qualisA1: this.state.EstatisticaQualisGrupo.listaQualisA1,
            qualisA2: this.state.EstatisticaQualisGrupo.listaQualisA2,
            qualisA3: this.state.EstatisticaQualisGrupo.listaQualisA3,
            qualisA4: this.state.EstatisticaQualisGrupo.listaQualisA4,
            qualisB1: this.state.EstatisticaQualisGrupo.listaQualisB1,
            qualisB2: this.state.EstatisticaQualisGrupo.listaQualisB2,
            qualisB3: this.state.EstatisticaQualisGrupo.listaQualisB3,
            qualisB4: this.state.EstatisticaQualisGrupo.listaQualisB4,            
            qualisC: this.state.EstatisticaQualisGrupo.listaQualisC,
            qualisSem: this.state.EstatisticaQualisGrupo.listaQualisSem
        };

        if (this.props.QualConsulta === "PorAno") {
            this.mapDataCampus = this.mapDataCampus.bind(this);
        }
        else if (this.props.QualConsulta === "PorIndividuo") {
            this.mapDataCampus = this.mapDataCampus.bind(this);
        }
        else {
            this.mapDataYears = this.mapDataYears.bind(this);
        }
        this.reflectsLabelArea = this.reflectsLabelArea.bind(this);
        if (this.props.TipoGrafico === "PorProfessor") {
            this.mapDataMediaPorProfessor = this.mapDataMediaPorProfessor.bind(this);
        }
        this.mapDataToChart = this.mapDataToChart.bind(this);

        this.optionsInstituicoes = [
            {
                label: "random",
                value: "random"
            }
        ];

        if (this.props.QualConsulta === "PorAno") {
            this.mapDataCampus();
        }
        else if (this.props.QualConsulta === "PorIndividuo") {
            this.mapDataCampus();
        }
        else {
            this.mapDataYears();
        } 
        this.mapAreas();
        if (this.props.TipoGrafico === "PorProfessor") {
            this.mapDataMediaPorProfessor();
        } 
        this.mapDataToChart();
    }

    renderPeriodicoPorArea() {
        /*
                        <p>Escolha a <strong>Área</strong>:</p>
                        <SelectArea reflectsLabelArea={this.reflectsLabelArea}
                            optionLabel="Selecione uma área"
                            optionsList={this.areas}></SelectArea>
                            <br />
         */ 
        if (this.props.QualConsulta === "PorAno") {
            return (
                <div style={{ /*minHeight: '1700px', position: "fixed"*/ }}>
                    <div style={{ /*minHeight: '500px'*/ }}>
                        <p align="center"><b><i>{this.TituloGraficoParte1}{this.TituloGraficoParte2}</i></b></p>                        
                    </div>
                    <div style={{ /*minHeight: '1200px'*/ }}>
                        <div className="content-section implementation">
                            <p>Clique no <strong>Estrato</strong> para inserir ou remover a seleção:</p>
                            <Chart height="900" type="horizontalBar" data={this.state.stackedData} options={this.state.stackedOptions} />
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <p align="center"><b><i>{this.TituloGraficoParte1}{this.TituloGraficoParte2}</i></b></p>
                <div className="content-section implementation">                    
                    <p>Clique no <strong>Estrato</strong> para inserir ou remover a seleção:</p>
                    <Chart height="300px" type="bar" data={this.state.stackedData} options={this.state.stackedOptions} />
                </div>
            </div>
        );
    }

    render() {
        const { loading, error } = this.state;

        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents = <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                       </div>;
        }
        else {
            contents = this.renderPeriodicoPorArea();
        }

        return (
            <div>
                {contents}
            </div>
        );
    }

}