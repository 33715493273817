import React, { useState, useContext, useEffect } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import MessageContext from '../../context/messageContext/messageContext';
import DocumentoDownload from './DocumentoDownload';
//import axios from 'axios';
import documentoService from '../../services/documentoService';

export default function DocumentoUpload(props) {       

    //Contexto
    const messageContext = useContext(MessageContext);

    const [file, setFile] = useState(null);  
    const [error, setError] = useState(null);  
    const [loadingPerfil, setLoadingPerfil] = useState(true);  
    const [loadingSave, setLoadingSave] = useState(false);  
    const [conteudoFile, setConteudoFile] = useState(null);

    function qtdeFileDownload() {
        setError(null);
        setLoadingPerfil(true);        
        fetch('api/Documentos/GetQtdeDocumentoAluno?idAluno=' + props.Id + '&tipoDocumento=' + props.TipoDoc)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados do perfil...');
                }
            })
            .then(data => {  
                setConteudoFile(data);
                setLoadingPerfil(false);                
            })
            .catch(error => {
                setError(error);
                //setExibeError(error);                
                messageContext.openMessage("error", "Erro ao ler dados. Por favor, tente novamente.");
                setLoadingPerfil(false);
            });
    } 

    useEffect(() => {
        //componentDidMount e componentDidUpdate
        if (!(props.Id) || !(props.TipoDoc)) {
            messageContext.openMessage("error", "Aluno ou tipo de documento inválido.");
        }
        else {
            qtdeFileDownload();
        }
    }, []); 

    const onFileChange = (event) => {
        setFile(event.target.files[0]);
        setError(null);
        ValidateFile(event.target.files[0]);
    }

    function ValidateFile(file) {  
        if (!(props.Id) || !(props.TipoDoc)) {
            messageContext.openMessage("error", "Aluno ou tipo de documento inválido.");
            return;
        }

        if (!file) {
            messageContext.openMessage("error", "Por favor, escolha um arquivo PDF (máximo 3MB).");
            return;
        }

        if (file.size > 3000000) {
            messageContext.openMessage("error", "Tamanho do arquivo excedeu o limite de 3MB.");
            return;
        }

        var fileName = file.name;
        var dotPosition = fileName.lastIndexOf(".");
        var fileExt = fileName.substring(dotPosition).toLowerCase();
        var allowedFormats = [".pdf"];

        if (!(allowedFormats.indexOf(fileExt) > -1)) {
            messageContext.openMessage("error", "Somente arquivo PDF é permitido.");
            return;
        }
        uploadFile(file);
    }
    
    function uploadFile(file) {
        setLoadingSave(true);
                
        var reader = new FileReader();        
        reader.onloadend = (event) => {
            fetchUploadFile(event.target.result);
        };
        reader.readAsDataURL(file);
    }

    function fetchUploadFile(fildBase64) {

        const dataBlob = JSON.stringify({
            alunoID: parseInt(props.Id),
            tipoDocumento: parseInt(props.TipoDoc),
            documento: fildBase64
        });
        /*
        const options = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: dataBlob,
            cache: 'default'
        };
        fetch('api/Documentos/PutDocumentoAluno', options)
        //axios.put('api/Documentos/PutDocumentoAluno', { dataBlob })
        */
        documentoService.PutDocumentoAluno(dataBlob)
            .then(r => {
                r.json().then(response => {
                    if (response.statusCode) {
                        setLoadingSave(false);
                        setConteudoFile(1);
                    }
                    else {
                        setLoadingSave(false);                        
                    }
                });
            })
            .catch(Msgerror => {
                setError(Msgerror);
                messageContext.openMessage("error", "Erro: " + Msgerror);
                setLoadingSave(false);
            });
    }

    function renderTemplate() {
        if (props.Template) {
            return (
                <div>
                    <Button variant="contained" href={props.Template} color="primary"  
                        style={{ textTransform: 'none', color: '#ffffff' }} >
                        <Icon>cloud_download</Icon>&nbsp;
                        Modelo
                    </Button>                    
                </div>
            );
        }
        return;
    }

    function renderBotao() {
        if ((props.Visivel) && (props.Visivel == true)) {
            let NomeInput = 'BotaoInput_Upload_' + props.Id + '_' + props.TipoDoc;
            return (
                <div>
                    <input
                        accept="application/pdf"
                        style={{ display: 'none' }}
                        id={NomeInput}
                        onChange={onFileChange}
                        type="file"
                    />
                    <label htmlFor={NomeInput}>
                        <Button variant="contained" component="span"
                            style={{ backgroundColor: '#34A835', color: '#ffffff', textTransform: 'none' }}>
                            <Icon>cloud_upload</Icon>&nbsp;Upload
                        </Button>
                    </label>
                </div>
            );
        }
        else {
            return (
                <div >
                    <label htmlFor="contained-disabled-button-file">
                        <Button variant="contained" component="span" color="primary" disabled
                            style={{ backgroundColor: '#34A835', textTransform: 'none' }}>
                            <Icon>cloud_upload</Icon>&nbsp;Upload
                        </Button>
                    </label>
                </div>
            );
        }
    }

    function renderAnexo() {
        if (loadingPerfil) {
            return (
                <div >
                    <span style={{ verticalAlign: "middle", marginTop: '6px', display: 'inline-block' }}><i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em></span>
                </div>
            );
        }
        else if (loadingSave) {
            return (
                <div >
                    <span style={{ verticalAlign: "middle", marginTop: '6px', display: 'inline-block' }}>
                        <i className="pi pi-bars p-toolbar-separator" style={{ marginRight: '.25em' }} />
                    </span>
                    <span style={{ verticalAlign: "middle", marginTop: '6px', display: 'inline-block' }}><i className="fa fa-refresh fa-spin"></i> <em>Aguarde...</em></span>
                </div>
            ); 
        }
        else if ((!conteudoFile) || (conteudoFile == 0)) {
            return (
                <div >
                    <span style={{ verticalAlign: "middle", marginTop: '6px', display: 'inline-block' }}>
                        <i className="pi pi-bars p-toolbar-separator" style={{ marginRight: '.25em' }} />
                    </span>
                    <span style={{ verticalAlign: "middle", marginTop: '6px', display: 'inline-block' }}>Nenhum anexo.</span>
                </div>
            ); 
        }        
        return (
            <div >
                <span style={{ verticalAlign: "middle", marginTop: '6px', display: 'inline-block' }}>
                    <i className="pi pi-bars p-toolbar-separator" style={{ marginRight: '.25em' }} />
                </span>
                <DocumentoDownload Id={props.Id} TipoDoc={props.TipoDoc} />
                <span style={{ verticalAlign: "middle", marginTop: '6px', display: 'inline-block' }}>Anexado.</span>
            </div>
        );
    }
       
    function renderAll() {
        return (
            <form method="put" enctype="multipart/form-data">
                <div>  
                    <div>
                        <ButtonGroup>
                            &nbsp;&nbsp;
                            {renderTemplate()}
                            &nbsp;&nbsp;
                            {renderBotao()}
                            &nbsp;&nbsp;
                            {renderAnexo()}
                        </ButtonGroup>  
                    </div>
                </div>
            </form>
        );
    }

    return (renderAll());
}

