import React, { Component } from 'react';
import { Chart } from 'primereact/chart';

export default class Orientacao extends Component {
    anos = [];
    qtdAnos = 0;
    Campus = [];
    qtdCampus = 0;
    TituloGraficoParte1 = "Quantidade x ";
    TituloGraficoParte2 = "Orientações Concluídas";

    mapDataYears() {
        //Popula os anos
        this.anos = [];
        this.qtdAnos = 0;
        let a = this.state.EstatisticaOrientacao;
        for (let i = 0; i < a.length; i++) {
            this.anos.push(a[i].ano);
            this.qtdAnos++;
        }
        this.TituloGraficoParte2 = "Anos";
    }

    mapDataCampus() {
        //Popula os campus
        this.Campus = [];
        this.qtdCampus = 0;
        let a = this.state.EstatisticaOrientacao;
        for (let i = 0; i < a.length; i++) {
            this.Campus.push(a[i].instituicao.sigla);
            this.qtdCampus++;
        }
        this.TituloGraficoParte2 = "Instituições";
    }

    mapDataMediaPorProfessor() {
        let a = this.state.EstatisticaOrientacao;
        for (let i = 0; i < a.length; i++) {
            a[i].iniciacaoCientifica = ((a[i].iniciacaoCientifica * 1.00) / a[i].instituicao.quantidadeProfessoresLattes).toFixed(3);
            a[i].tccGraduacao = ((a[i].tccGraduacao * 1.00) / a[i].instituicao.quantidadeProfessoresLattes).toFixed(3);
            a[i].tccEspecializacao = ((a[i].tccEspecializacao * 1.00) / a[i].instituicao.quantidadeProfessoresLattes).toFixed(3);
            a[i].mestrado = ((a[i].mestrado * 1.00) / a[i].instituicao.quantidadeProfessoresLattes).toFixed(3);
            a[i].doutorado = ((a[i].doutorado * 1.00) / a[i].instituicao.quantidadeProfessoresLattes).toFixed(3);
        }
        this.setState({ EstatisticaOrientacao: a });
        this.TituloGraficoParte1 = "Média por professor x ";
    }

    mapDataToChart() {
        //Popula as datas
        let finalData = {
            iniciacaoCientifica: [],
            tccGraduacao: [],
            tccEspecializacao: [],
            mestrado: [],
            doutorado: []
        };
        let TipoBarraGrafico = 'bar';

        let a = this.state.EstatisticaOrientacao;
        for (let i = 0; i < a.length; i++) {
            finalData.iniciacaoCientifica.push(a[i].iniciacaoCientifica);
            finalData.tccGraduacao.push(a[i].tccGraduacao);
            finalData.tccEspecializacao.push(a[i].tccEspecializacao);
            finalData.mestrado.push(a[i].mestrado);
            finalData.doutorado.push(a[i].doutorado);  
        }
        let QuaisLabels = this.anos;
        if (this.props.QualConsulta === "PorAno") {
            QuaisLabels = this.Campus;
            TipoBarraGrafico = 'horizontalBar';
        }
        let stackedDataUpdated = {
            labels: QuaisLabels,
            datasets: [{
                type: TipoBarraGrafico,
                label: 'Iniciação Científica',
                backgroundColor: '#ff0000',
                data: finalData.iniciacaoCientifica
            }, {
                type: TipoBarraGrafico,
                label: 'TCC - Graduação',
                backgroundColor: '#ff00bf',
                data: finalData.tccGraduacao
            }, {
                type: TipoBarraGrafico,
                label: 'TCC - Especialização',
                backgroundColor: '#8000ff',
                data: finalData.tccEspecializacao
            },
            {
                type: TipoBarraGrafico,
                label: 'Mestrado',
                backgroundColor: '#0040ff',
                data: finalData.mestrado
            },
            {
                type: TipoBarraGrafico,
                label: 'Doutorado',
                backgroundColor: '#00ffff',
                data: finalData.doutorado
            }]
        };

        let stackedOptionsUpdated = {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    stacked: true
                }],
                yAxes: [{
                    stacked: true
                }]
            }
        };

        this.setState({
            stackedData: stackedDataUpdated,
            stackedOptions: stackedOptionsUpdated
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            EstatisticaOrientacao: [],
            stackedData: {},
            stackedOptions: {}
        };
    }

    componentDidMount() {
        this.CarregarDados();
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.QualInstituicao !== this.props.QualInstituicao) ||
            (prevProps.QualAno         !== this.props.QualAno)) {
            this.CarregarDados();
        }
    }

    CarregarDados() {
        this.setState({ loading: true });
        let StringFetch = "";
        if (this.props.QualConsulta === "PorAno") {
            if (this.props.QualAno == null)
                return;
            if (this.props.QualAno.length === 0)
                return;
            StringFetch = 'api/Indicadores/Orientacao?QuaisAnos=' + this.props.QualAno;//QualAno=' + this.props.QualAno;
        }
        else {
            if (this.props.QualInstituicao == null)
                return;
            if (this.props.QualInstituicao === -1)
                return;
            StringFetch = 'api/Indicadores/Orientacao?QualInstituicao=' + this.props.QualInstituicao;
        }
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ EstatisticaOrientacao: data, loading: false });
                this.ConfiguraDados();
            })
            .catch(error => this.setState({ error, loading: false }));
    }
       
    ConfiguraDados() {
        
        this.dataType = {
            iniciacaoCientifica: this.state.EstatisticaOrientacao.iniciacaoCientifica,
            tccGraduacao: this.state.EstatisticaOrientacao.tccGraduacao,
            tccEspecializacao: this.state.EstatisticaOrientacao.tccEspecializacao,
            mestrado: this.state.EstatisticaOrientacao.mestrado,
            doutorado: this.state.EstatisticaOrientacao.doutorado
        };

        if (this.props.QualConsulta === "PorAno") {
            this.mapDataCampus = this.mapDataCampus.bind(this);
        }
        else {
            this.mapDataYears = this.mapDataYears.bind(this);
        }
        if (this.props.TipoGrafico === "PorProfessor") {
            this.mapDataMediaPorProfessor = this.mapDataMediaPorProfessor.bind(this);
        }
        this.mapDataToChart = this.mapDataToChart.bind(this);

        this.optionsInstituicoes = [
            {
                label: "random",
                value: "random"
            }
        ];

        if (this.props.QualConsulta === "PorAno") {
            this.mapDataCampus();
        }
        else {
            this.mapDataYears();
        }   
        if (this.props.TipoGrafico === "PorProfessor") {
            this.mapDataMediaPorProfessor();
        }   
        this.mapDataToChart();
    }

    renderOrientacao() {
        if (this.props.QualConsulta === "PorAno") {
            return (
                <div height="1200">
                    <div className="content-section implementation">
                        <p align="center"><b><i>{this.TituloGraficoParte1}{this.TituloGraficoParte2}</i></b></p>
                        <p>Clique no <strong>tipo de orientação</strong> para inserir ou remover a seleção:</p>
                        <Chart height="900" type="horizontalBar" data={this.state.stackedData} options={this.state.stackedOptions} />
                    </div>
                </div>
            );
        }
        return (
            <div>
                <div className="content-section implementation">
                    <p align="center"><b><i>{this.TituloGraficoParte1}{this.TituloGraficoParte2}</i></b></p>
                    <p>Clique no <strong>tipo de orientação</strong> para inserir ou remover a seleção:</p>
                    <Chart height="300px" type="bar" data={this.state.stackedData} options={this.state.stackedOptions} />
                </div>
            </div>
        );
    }

    render() {
        const { loading, error } = this.state;

        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents = <div className="overlay">
                           <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                       </div>;
        }
        else {
            contents = this.renderOrientacao();
        }

        return (
            <div>
                {contents}
            </div>
        );

    }

}