import React, { Component } from 'react';
import { Form } from 'react-bootstrap'; ///lib/FormControl';

export default class SelecQualArea extends Component {    

    constructor(props) {
        super(props);
        this.state = {
            Areas: [],
            area: '',
            loading: true,
            error: null
        };
    }

    componentDidMount() {
        fetch('api/Indicadores/QuaisAreas')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ Areas: data, loading: false });
                this.props.AtualizaQualArea(this.state.Areas[0].valor);
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    renderSelectAreas() {
        return (
            <div>
                <Form.Control as="select"
                    placeholder="Selecionar instituição"
                    onChange={(e) => this.props.AtualizaQualArea(e.target.value)} >
                    {
                        this.state.Areas.map(area => <option value={area.valor} key={area.valor}> {area.descricao} </option>)
                    }
                </Form.Control>
            </div>       
        );
    }
    render() {
        const { loading, error } = this.state;
        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents = <div className="overlay">
                         <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                       </div>;
        }
        else {
            contents = this.renderSelectAreas();
        }

        return (
            <div>
                {contents}
            </div>
        );

    }
}