import React from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout/Layout';
//Autenticacao
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
//Providers
import IdiomaContextProvider from './context/idiomaContext/idiomaContextProvider';
import InfoContextProvider from './context/infoContext/infoContextProvider';
import LoadingContextProvider from './context/loadingContext/loadingContextProvider';
import MessageContextProvider from './context/messageContext/messageContextProvider';
//Pages
import { Home } from './pages/Home/Home';
import { IndicadoresPorAno } from './pages/IndicadoresPorAno/IndicadoresPorAno';
import { IndicadoresPorEstado } from './pages/IndicadoresPorEstado/IndicadoresPorEstado';
import { IndicadoresPorInstituicao } from './pages/IndicadoresPorInstituicao/IndicadoresPorInstituicao';
import { IndicadoresPorProfessor } from './pages/IndicacoresPorProfessor/IndicadoresPorProfessor';
import { IndicadoresAssuntos } from './pages/IndicadoresAssuntos/IndicadoresAssuntos';
import { ResidenciaAlunos } from './pages/ResidenciaAlunos/ResidenciaAlunos';
import { ListaPesquisadores } from './pages/ListaPesquisadores/ListaPesquisadores';
import { ListaDescredenciados } from './pages/ListaPesquisadores/ListaDescredenciados';
import { FormacaoAcademica } from './pages/FormacaoAcademica/FormacaoAcademica';
import { MaisProdutivos } from './pages/MaisProdutivos/MaisProdutivos';
import { AssuntoPesquisadores } from './pages/AssuntoPesquisadores/AssuntoPesquisadores';
import { Discentes } from './pages/Discentes/Discentes';
import { ListaCoordenadores } from './pages/ListaCoordenadores/ListaCoordenadores';
import { ListaSecretarios } from './pages/ListaSecretarios/ListaSecretarios';
import { ListaEgressos } from './pages/ListaEgressos/ListaEgressos';
import { Sobre } from './pages/Sobre/Sobre';
import PrimeiroAcesso from "./pages/Autenticacao/PrimeiroAcesso";
import ExcluirConta from "./pages/Autenticacao/ExcluirConta";
import { Perfil } from "./pages/Autenticacao/Perfil";
import { PerfilEgresso } from "./pages/Autenticacao/PerfilEgresso";
//ProfEPT
import { Instituicoes } from "./pages/Instituicoes/Instituicoes";
import { DisciplinasEletivasComeceAqui } from "./pages/DisciplinasEletivas/DisciplinasEletivasComeceAqui";
import { DisciplinasEletivas } from "./pages/DisciplinasEletivas/DisciplinasEletivas";
import { InteresseDiscEletiva } from './pages/InteresseDiscEletiva/InteresseDiscEletiva';
import { ConsultaRelacaoOfertas } from './pages/DisciplinasEletivas/ConsultaRelacaoOfertas';
import { ConsultaRelacaoAlunos } from './pages/DisciplinasEletivas/ConsultaRelacaoAlunos';
import { ConsultaTurmas } from './pages/DisciplinasEletivas/ConsultaTurmas';
import { DocumentacaoMatricula } from './pages/DisciplinasEletivas/DocumentacaoMatricula';
import { ExportarTurmasExcel } from './pages/DisciplinasEletivas/ExportarTurmasExcel';
//AutoAvaliacao
import { AutoAvaliacaoComeceAqui } from "./pages/AutoAvaliacao/AutoAvaliacaoComeceAqui";
import { AutoAvaliacaoResponder } from "./pages/AutoAvaliacao/AutoAvaliacaoResponder";
import { AutoAvaliacaoAcompanhamento } from "./pages/AutoAvaliacao/AutoAvaliacaoAcompanhamento";
import { AutoAvaliacaoPrograma } from "./pages/AutoAvaliacao/AutoAvaliacaoPrograma";
import { AutoAvaliacaoObservatorio } from "./pages/AutoAvaliacao/AutoAvaliacaoObservatorio";
import { AutoAvaliacaoDisciplinas } from "./pages/AutoAvaliacao/AutoAvaliacaoDisciplinas";
//Adm
import { Adm } from './pages/Adm/Adm';
import { downloadLattes } from './pages/Adm/downloadLattes';
import { excluiEstatisticas } from './pages/Adm/excluiEstatisticas';

export default function App() {
                      
    return (
        <IdiomaContextProvider>
            <InfoContextProvider>
                <LoadingContextProvider>
                    <MessageContextProvider>
                    <Layout>
                        <Route exact path='/' component={Home} />
                        <Route path='/sobre' component={Sobre} />
                        <Route path='/indInstituicao' component={IndicadoresPorInstituicao} />
                        <Route path='/indAno' component={IndicadoresPorAno} />
                        <Route path='/indEstado' component={IndicadoresPorEstado} />
                        <AuthorizeRoute exact path='/indProfessor' component={IndicadoresPorProfessor} />
                        <Route path='/indAssuntos' component={IndicadoresAssuntos} />
                        <Route path='/residenciaAlunos' component={ResidenciaAlunos} />
                        <Route path='/ListaPesquisadores' component={ListaPesquisadores} />
                        <Route path='/ListaDescredenciados' component={ListaDescredenciados} />
                        <Route path='/FormacaoAcademica' component={FormacaoAcademica} />
                        <Route path='/MaisProdutivos' component={MaisProdutivos} />
                        <Route path='/AssuntoPesq' component={AssuntoPesquisadores} />
                        <Route path='/Discentes' component={Discentes} />
                        <Route path='/Coordenadores' component={ListaCoordenadores} />
                        <Route path='/Secretarios' component={ListaSecretarios} />
                        <Route path='/Egressos' component={ListaEgressos} />
                        <AuthorizeRoute path='/downloadLattes' component={downloadLattes} />
                        <AuthorizeRoute path='/excluiEstatisticas' component={excluiEstatisticas} />
                        <AuthorizeRoute path='/adm' component={Adm} />
                        <Route path='/primeiroAcesso' component={PrimeiroAcesso} />
                        <Route path='/excluirConta' component={ExcluirConta} />
                        <AuthorizeRoute path='/perfil' component={Perfil} />
                        <AuthorizeRoute path='/perfilEgresso' component={PerfilEgresso} />
                        <Route path='/instituicoes' component={Instituicoes} />
                        <Route path='/comeceAquiDisc' component={DisciplinasEletivasComeceAqui} />
                        <Route path='/disciplinasEletivas' component={DisciplinasEletivas} />
                        <AuthorizeRoute exact path='/interessedisciplina' component={InteresseDiscEletiva} />
                        <Route path='/ofertaProcuraDisc' component={ConsultaRelacaoOfertas} />
                        <AuthorizeRoute exact path='/alunoProcuraDisc' component={ConsultaRelacaoAlunos} />
                        <AuthorizeRoute exact path='/turmasDiscEletivas' component={ConsultaTurmas} />
                        <AuthorizeRoute exact path='/docsDiscEletivas' component={DocumentacaoMatricula} />
                        <AuthorizeRoute exact path='/exportarTurmas' component={ExportarTurmasExcel} />
                        <Route path='/comeceAquiAutoAval' component={AutoAvaliacaoComeceAqui} />
                        <AuthorizeRoute exact path='/autoAvalResponder' component={AutoAvaliacaoResponder} />
                        <Route path='/autoAvalAcompanhar' component={AutoAvaliacaoAcompanhamento} />
                        <Route path='/autoAvalRelPrograma' component={AutoAvaliacaoPrograma} />
                        <Route path='/autoAvalRelDisc' component={AutoAvaliacaoDisciplinas} />
                        <Route path='/autoAvalRelObs' component={AutoAvaliacaoObservatorio} />
                        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                    </Layout>
                 </MessageContextProvider>
                </LoadingContextProvider>
            </InfoContextProvider>
        </IdiomaContextProvider>
    );
}

