import React, { useState, useContext, useEffect } from 'react';
import { Container, Col, Row, Form } from 'react-bootstrap';
import '../Pesquisadores/Pesquisadores.css';
import { InputText } from 'primereact/inputtext';
import ModalMensagem from '../ModalMensagem/ModalMensagem';
import { Button } from 'primereact/button';
import ButtonMaterial from '@material-ui/core/Button';
import SelectBasicoProfessor from '../SelectProfessor/SelectBasicoProfessor';
import SelectTipoProdEdu from '../SelectTipoProdEdu/SelectTipoProdEdu';
import { Calendar } from 'primereact/calendar';
import { returnDate } from '../../helpers/Date';

import LoadingContext from '../../context/loadingContext/loadingContext';
import MessageContext from '../../context/messageContext/messageContext';
import InfoContext from '../../context/infoContext/infoContext';
import discenteService from '../../services/discenteService';

export default function EgressoDefesa(props) { 

    const loadingContext = useContext(LoadingContext);
    const messageContext = useContext(MessageContext);
    const infoContext = useContext(InfoContext);

    //Constructor
    const [aluno, setAluno] = useState(null);
    const [dataDefesa, setDataDefesa] = useState(null);
    const [tituloDissertacao, setTituloDissertacao] = useState(null);
    const [tituloDissertacaoIng, setTituloDissertacaoIng] = useState(null);
    const [linkDissertacao, setLinkDissertacao] = useState(null);
    const [tituloProdEdu, setTituloProdEdu] = useState(null);
    const [tituloProdEduIng, setTituloProdEduIng] = useState(null);
    const [linkProdEdu, setLinkProdEdu] = useState(null);
    const [registroProdEdu, setRegistroProdEdu] = useState(null);
    const [tipoProdEdu, setTipoProdEdu] = useState(null);
    const [tipoProdEdu2, setTipoProdEdu2] = useState(null);
    const [professorID, setProfessorID] = useState(null);
    const [membroProfEPT1, setMembroProfEPT1] = useState(null);
    const [membroProfEPT2, setMembroProfEPT2] = useState(null);
    const [membroProfEPT3, setMembroProfEPT3] = useState(null);
    const [membroExtInst1, setMembroExtInst1] = useState(null);
    const [membroExtNome1, setMembroExtNome1] = useState(null);
    const [membroExtInst2, setMembroExtInst2] = useState(null);
    const [membroExtNome2, setMembroExtNome2] = useState(null);
    const [membroExtInst3, setMembroExtInst3] = useState(null);
    const [membroExtNome3, setMembroExtNome3] = useState(null);

    const [saibaComoDissertacao, setSaibaComoDissertacao] = useState(false);
    const [saibaComoProdEdu, setSaibaComoProdEdu] = useState(false);
    const [error, setError] = useState(false);

    const pt_br = {
        firstDayOfWeek: 1,
        dayNames: ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado"],
        dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
        dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
        monthNames: ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"],
        monthNamesShort: ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"]
    };

    useEffect(() => {
        //componentDidMount
        loadingContext.showLoading();
        populateAuthenticationState();
        loadingContext.hideLoading();
    }, []); 

    function populateAuthenticationState() {
        if (props.QualAluno == null)
            return;
        discenteService.getAtualizaEgresso(props.QualAluno) 
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados do perfil...');
                }
            })
            .then(data => {
                setProfessorID(data.professorID);
                setDataDefesa(returnDate(data.dataDefesa));
                setTituloDissertacao(data.tituloDissertacao);
                setTituloDissertacaoIng(data.tituloDissertacaoIng);
                setLinkDissertacao(data.linkDissertacao);
                setTituloProdEdu(data.tituloProdEdu);
                setTituloProdEduIng(data.tituloProdEduIng);
                setLinkProdEdu(data.linkProdEdu);
                setRegistroProdEdu(data.registroProdEdu);
                setTipoProdEdu(data.tipoProdEdu);
                setTipoProdEdu2(data.tipoProdEdu2);
                if (data.membroBancaProfEPT1ID !== null) {
                    setMembroProfEPT1({ id: data.membroBancaProfEPT1ID, nome: "" });
                }
                else {
                    setMembroProfEPT1({ id: 0, nome: "" });
                }
                if (data.membroBancaProfEPT2ID !== null) {
                    setMembroProfEPT2({ id: data.membroBancaProfEPT2ID, nome: "" });
                }
                else {
                    setMembroProfEPT2({ id: 0, nome: "" });
                }
                if (data.membroBancaProfEPT3ID !== null) {
                    setMembroProfEPT3({ id: data.membroBancaProfEPT3ID, nome: "" });
                }
                else {
                    setMembroProfEPT3({ id: 0, nome: "" });
                }
                setMembroExtInst1(data.membroBancaExt1Inst);
                setMembroExtNome1(data.membroBancaExt1Nome);
                setMembroExtInst2(data.membroBancaExt2Inst);
                setMembroExtNome2(data.membroBancaExt2Nome);
                setMembroExtInst3(data.membroBancaExt3Inst);
                setMembroExtNome3(data.membroBancaExt3Nome);
                setAluno(data);
            })
            .catch(error => {
                messageContext.openMessage("error", "Erro. Por favor, tente novamente.");
                setError(error);
                });
    }


    function handleSubmit(event){
        event.preventDefault();
        loadingContext.showLoading();

        let pBody = JSON.stringify({
            ID: props.QualAluno,     
            DataDefesa: dataDefesa,
            TituloDissertacao: (tituloDissertacao != null) ? tituloDissertacao : "",
            TituloDissertacaoIng: (tituloDissertacaoIng != null) ? tituloDissertacaoIng : "",
            LinkDissertacao: (linkDissertacao != null) ? linkDissertacao : "",
            TituloProdEdu: (tituloProdEdu != null) ? tituloProdEdu : "",
            TituloProdEduIng: (tituloProdEduIng != null) ? tituloProdEduIng : "",
            LinkProdEdu: (linkProdEdu != null) ? linkProdEdu : "",
            RegistroProdEdu: (registroProdEdu != null) ? registroProdEdu : "",
            TipoProdEdu: parseInt(tipoProdEdu),
            TipoProdEdu2: parseInt(tipoProdEdu2),
            ProfessorID: professorID,
            MembroBancaProfEPT1ID: (membroProfEPT1 != null) ? parseInt(membroProfEPT1.id) : 0,
            MembroBancaProfEPT2ID: (membroProfEPT2 != null) ? parseInt(membroProfEPT2.id) : 0,
            MembroBancaProfEPT3ID: (membroProfEPT3 != null) ? parseInt(membroProfEPT3.id) : 0,
            MembroBancaExt1Inst: (membroExtInst1 != null) ? membroExtInst1 : "",
            MembroBancaExt1Nome: (membroExtNome1 != null) ? membroExtNome1 : "",
            MembroBancaExt2Inst: (membroExtInst2 != null) ? membroExtInst2 : "",
            MembroBancaExt2Nome: (membroExtNome2 != null) ? membroExtNome2 : "",
            MembroBancaExt3Inst: (membroExtInst3 != null) ? membroExtInst3 : "",
            MembroBancaExt3Nome: (membroExtNome3 != null) ? membroExtNome3 : ""
        });

        if ((infoContext.user.profile.aluno) || (infoContext.user.profile.secretario) || (infoContext.user.profile.coordenadorCurso) ||
            (infoContext.user.profile.coordenadorNacional) || (infoContext.user.profile.admin)) {
            discenteService.putAtualizaEgresso(pBody)
                .then(r => {
                    r.json().then(response => {
                        if (response.statusCode) {
                            messageContext.openMessage(response.statusVariant, response.statusDescription);
                        }
                    });
                })
                .catch(error => {
                    messageContext.openMessage("error", "Erro. Por favor, tente novamente.");
                    setError(error);
                });
        }
        loadingContext.hideLoading();
    } 

    function AlteraOrientador(NovoOrientador) {
        if (NovoOrientador == null) {
            setProfessorID(0);
        }
        else {
            setProfessorID(NovoOrientador.id);
        }
    }

    function renderHelpLinkDissertacao() {
        let contents =
            <div>
                <p>1. Acesse a Plataforma Sucupira: <a href="https://sucupira.capes.gov.br/" target="_blank">https://sucupira.capes.gov.br/</a></p>
                <p>2. Clique em Coleta Capes</p>
                <p>3. Clique em Trabalhos de Conclusão</p>
                <p>4. Selecione o Ano da Defesa de Dissertação, sua Instituição, e o Programa EDUCAÇÃO PROFISSIONAL E TECNOLÓGICA (Programa em Rede)</p>
                <p>5. Digite o título do trabalho (<i>para títulos extensos, digite apenas o trecho inicial do título</i>) e em seguida clique em Consultar.<br />
                &nbsp;&nbsp;&nbsp;<b>Observação: </b>Caso não tenha sucesso na busca, tente outro trecho do título e se ainda não tiver sucesso procure o seu Coordenador Local.</p>
                <p>6. Aparecendo o trabalho, clique na lupa.</p>                
                <p>7. A janela que abrirá será do seu trabalho. Copie o endereço (link) como mostra a figura abaixo</p>
                <div>
                    <img style={{ height: 'auto', width: '550px', float: 'left', marginRight: '8px', marginLeft: '0px', marginTop: '0px' }} src="Dissertacao.png" border="0" />
                </div>
            </div>;
        return contents;
    }

    function renderHelpLinkProdEdu() {
        let contents =
            <div>
                <p>Este passo a passo é para produtos depositados no repositório eduCapes</p>
                <p>1. Acesse a Plataforma eduCapes: <a href="https://educapes.capes.gov.br/" target="_blank">https://educapes.capes.gov.br/</a></p>
                <p>2. Digite em "Buscar no repositório" o título do produto educacional (<i>para títulos extensos, digite apenas o trecho inicial do título</i>)</p>
                <p>3. Na listagem, procure o seu produto educacional</p>
                <p>4. Clique no produto educacional</p>
                <p>5. Copie o endereço (link) como mostra a figura abaixo</p>
                <div>
                    <img style={{ height: 'auto', width: '550px', float: 'left', marginRight: '8px', marginLeft: '0px', marginTop: '0px' }} src="ProdEdu.png" border="0" />
                </div>
            </div>;
        return contents;
    }

    function renderAll() {  
        let contents = <p>Você deve logar para acessar este conteúdo.</p>;             
        if (error) {
            let contentsErro = <p>Erro. Por favor, tente novamente.</p>;
            return (
                <div>
                    {contentsErro}
                </div>
            );
        }
        if (loadingContext.loading) {
            contents =  <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                        </div>;
            return (
                <div>
                    {contents}
                </div>
            );
        }
        if ((infoContext.user != null) && (infoContext.userInfo != null)) {
            if (aluno == null) {
                return (<p>Aguarde...</p>);
            }
            if (aluno.editarEgresso == false) {
                if (aluno.dataDefesa == null) {
                    return (<p>Olá <b>{aluno.nome}</b>, somente <b>egressos</b> podem acessar esta página.</p>);
                }
                return (<p>Olá <b>{aluno.nome}</b>, tem mais de 30 meses que concluiu o Mestrado ProfEPT.<br />Você não tem permissão para editar sua defesa de dissertação.<br />Caso tenha alguma inconsistência nos dados, procure o Coordenador Local / Secretaria de sua instituição.</p>);
            }
            contents = <Container >
                <Row>
                    <Col sm={12}>
                        <p>Olá <b>{aluno.nome}</b>, o trabalho que você desenvolveu é muito importante para o programa ProfEPT.</p>
                        <p>Precisamos manter os dados de sua defesa de dissertação atualizados para que outras pessoas possam acessar o seu trabalho.</p>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <span><b>Título da Dissertação (Português):</b></span>
                        <Form.Control as="textarea" rows="2" cols="50" style={{ width: "100%" }}
                            value={tituloDissertacao} onChange={(e) => setTituloDissertacao(e.target.value)} autoResize={true} />
                        <br/>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <span><b>Título da Dissertação (Inglês):</b></span>
                        <Form.Control as="textarea" rows="2" cols="50" style={{ width: "100%" }}
                            value={tituloDissertacaoIng} onChange={(e) => setTituloDissertacaoIng(e.target.value)} autoResize={true} />
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col sm={10}>
                        <span><b>URL (Link) da Dissertação:</b></span><br/>
                        <InputText style={{ width: "100%" }} value={linkDissertacao} onChange={(e) => setLinkDissertacao(e.target.value)} />
                        <span style={{ fontSize: "10px" }}><i>Exemplo: https://sucupira.capes.gov.br/sucupira/public/consultas/coleta/trabalhoConclusao/viewTrabalhoConclusao.jsf?popup=true&id_trabalho=7696455 </i></span>
                        <br /><br />
                    </Col>
                    <Col sm={2}>
                        <br />
                        <ButtonMaterial variant="contained" size="small" color="primary" onClick={() => setSaibaComoDissertacao(true)}>
                             <i class="fa fa-info-circle"></i>&nbsp;&nbsp;Saiba como
                        </ButtonMaterial>
                        <ModalMensagem updateShow={setSaibaComoDissertacao} show={saibaComoDissertacao} titulo="Saiba como obter o link da dissertação"
                             mensagem={renderHelpLinkDissertacao()} />
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <span><b>Tipo do Produto Educacional (Principal):</b></span><br />
                        <SelectTipoProdEdu TipoProdEduSelecionado={tipoProdEdu} AtualizaQualTipoProdEdu={setTipoProdEdu} />
                    </Col>
                    <Col sm={6}>
                        <span><b>Tipo do Produto Educacional (Secundário) (se houver):</b></span><br />
                        <SelectTipoProdEdu TipoProdEduSelecionado={tipoProdEdu2} AtualizaQualTipoProdEdu={setTipoProdEdu2} />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <span style={{ fontSize: "11px" }}><i>Você vai informar o Tipo <b>Secundário</b> apenas para os casos onde o produto educacional contém mais de um tipo. Exemplo: O seu Produto Educacional é uma página de internet (<b>principal</b>) que contém várias animações produzidas no trabalho (<b>secundário</b>).</i></span>
                        <br /><br /> 
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <span><b>Título do Produto Educacional (Português):</b></span><br />
                        <InputText style={{ width: "100%" }} value={tituloProdEdu} onChange={(e) => setTituloProdEdu(e.target.value)} />                        
                        <br /><br />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <span><b>Título do Produto Educacional (Inglês):</b></span><br />
                        <InputText style={{ width: "100%" }} value={tituloProdEduIng} onChange={(e) => setTituloProdEduIng(e.target.value)} />
                        <br /><br />
                    </Col>
                </Row>
                <Row>
                    <Col sm={10}>
                        <span><b>URL (Link) do Produto Educacional no Repositório:</b></span><br />
                        <InputText style={{ width: "100%" }} value={linkProdEdu} onChange={(e) => setLinkProdEdu(e.target.value)} />
                        <span style={{ fontSize: "10px" }}><i>Exemplo: http://educapes.capes.gov.br/handle/capes/432908 </i>&nbsp;&nbsp;</span>
                        <br /><br />
                    </Col>
                    <Col sm={2}>
                        <br />
                        <ButtonMaterial variant="contained" size="small" color="primary" onClick={() => setSaibaComoProdEdu(true)}>
                            <i class="fa fa-info-circle"></i>&nbsp;&nbsp;Saiba como
                                </ButtonMaterial>
                        <ModalMensagem updateShow={setSaibaComoProdEdu} show={saibaComoProdEdu} titulo="Saiba como obter o link do produto educacional"
                            mensagem={renderHelpLinkProdEdu()} />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <span><b>Registro do Produto Educacional (ISBN, URL [Link], etc.) (se houver):</b></span><br />
                        <InputText style={{ width: "100%" }} value={registroProdEdu} onChange={(e) => setRegistroProdEdu(e.target.value)} />
                        <br /><br />
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <span><b>Data da Defesa:</b></span><br />
                        <Calendar value={dataDefesa} onChange={(e) => setDataDefesa(e.value)} locale={pt_br} dateFormat="dd/mm/yy" />
                    </Col>
                    <Col sm={6}>
                        <span><b>Orientador:</b></span><br />
                        <SelectBasicoProfessor ProfSelecionado={professorID} AtualizaQualProf={AlteraOrientador} Selecao="TODOS" />
                        <span style={{ fontSize: "10px" }}><i>Caso não encontre, entre em contato com o coordenador de sua instituição</i></span><br />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <span><b>Membros da Banca Examinadora:</b></span><br />    
                        <span style={{ fontSize: "11px" }}><i><b>Observação:</b> para os membros externos, informe apenas a sigla da instituição (<b>NÃO</b> informe o campus)</i></span>
                        <br /><br />
                    </Col>
                </Row>
                <Row>
                    <Col sm={5}>
                        <span style={{ fontSize: "12px" }}><b>Presidente da Banca (orientador):</b></span><br /><br />
                        <SelectBasicoProfessor ProfSelecionado={membroProfEPT1.id} AtualizaQualProf={setMembroProfEPT1} Selecao="TODOS" />
                        <br />
                    </Col>
                    <Col sm={5}>
                        <span style={{ fontSize: "11px" }}><b>Primeiro Membro Externo (se houver):</b></span><br /><br />
                        <span className="p-float-label">
                            <InputText style={{ width: "100%" }} id="in" value={membroExtNome1} onChange={(e) => setMembroExtNome1(e.target.value)} />
                            <label htmlFor="in">Nome</label>
                        </span>
                    </Col>
                    <Col sm={2}>
                        <span style={{ fontSize: "11px" }}> </span><br /><br />
                        <span className="p-float-label">
                            <InputText style={{ width: "100%" }} id="in" value={membroExtInst1} onChange={(e) => setMembroExtInst1(e.target.value)} />
                            <label htmlFor="in">Instituição (Sigla)</label>
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col sm={5}>
                        <span style={{ fontSize: "12px" }}><b>Segundo Membro ProfEPT (se houver):</b></span><br /><br />
                        <SelectBasicoProfessor ProfSelecionado={membroProfEPT2.id} AtualizaQualProf={setMembroProfEPT2} Selecao="TODOS"/>
                        <br />
                    </Col>
                    <Col sm={5}>
                        <span style={{ fontSize: "11px" }}><b>Segundo Membro Externo (se houver):</b></span><br /><br />
                        <span className="p-float-label">
                            <InputText style={{ width: "100%" }} id="in" value={membroExtNome2} onChange={(e) => setMembroExtNome2(e.target.value)} />
                            <label htmlFor="in">Nome</label>
                        </span>
                    </Col>
                    <Col sm={2}>
                        <span style={{ fontSize: "11px" }}> </span><br /><br />
                        <span className="p-float-label">
                            <InputText style={{ width: "100%" }} id="in" value={membroExtInst2} onChange={(e) => setMembroExtInst2(e.target.value)} />
                            <label htmlFor="in">Instituição (Sigla)</label>
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col sm={5}>
                        <span style={{ fontSize: "12px" }}><b>Terceiro Membro ProfEPT (se houver):</b></span><br /><br />
                        <SelectBasicoProfessor ProfSelecionado={membroProfEPT3.id} AtualizaQualProf={setMembroProfEPT3} Selecao="TODOS"/>
                        <br />
                    </Col>
                    <Col sm={5}>
                        <span style={{ fontSize: "11px" }}><b>Terceiro Membro Externo (se houver):</b></span><br /><br />
                        <span className="p-float-label">
                            <InputText style={{ width: "100%" }} id="in" value={membroExtNome3} onChange={(e) => setMembroExtNome3(e.target.value)} />
                            <label htmlFor="in">Nome</label>
                        </span>
                    </Col>
                    <Col sm={2}>
                        <span style={{ fontSize: "11px" }}> </span><br /><br />
                        <span className="p-float-label">
                            <InputText style={{ width: "100%" }} id="in" value={membroExtInst3} onChange={(e) => setMembroExtInst3(e.target.value)} />
                            <label htmlFor="in">Instituição (Sigla)</label>
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <span style={{ fontSize: "11px" }}><i>Confira os dados antes de Salvar.</i></span>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <br />
                        <div style={{ textAlign: "left" }}>
                            <Button label="Salvar" onClick={handleSubmit.bind(this)} className="p-button-success" />
                        </div>
                    </Col>
                </Row>
            </Container>;
        }

        return (
            <form name="PerfilAlunoform">
                <div >
                    {contents}
                </div>
            </form >
        );
    }

    return (
        renderAll()
        );
}