import React, { useContext, useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import PerfilAluno from '../../components/Autenticacao/PerfilAluno';
import AlunoDiscEletiva from '../../components/DisciplinasEletivas/AlunoDiscEletiva';
import ProfessorDiscEletiva from '../../components/DisciplinasEletivas/ProfessorDiscEletiva';
import OfertaDiscEletiva from '../../components/DisciplinasEletivas/OfertaDiscEletiva';

import IdiomaContext from '../../context/idiomaContext/idiomaContext';
import InfoContext from '../../context/infoContext/infoContext';
import MessageContext from '../../context/messageContext/messageContext';

export function InteresseDiscEletiva(props) {

    //Contexto
    const idCt = useContext(IdiomaContext);
    const infoContext = useContext(InfoContext);
    const messageContext = useContext(MessageContext);

    //Constructor
    const [AtualizaPagina, setAtualizaPagina] = useState(false);
    const [AcessoNegado, setAcessoNegado] = useState(true);

    const updatePage = () => {
        setAtualizaPagina(!AtualizaPagina);        
    }

    useEffect(() => {
        //componentDidMount
        if ((infoContext.user != null) && ((infoContext.user.profile.professor) || (infoContext.user.profile.aluno) || (infoContext.user.profile.coordenadorNacional))) {
            setAcessoNegado(false);
        }
        else {
            messageContext.openMessage("warning", "Somente docentes e discentes tem acesso a este conteúdo.");
        }
    }, []); 

    function renderAll() {
        if (AcessoNegado) {
            return (
                <Container >
                    <Row>
                        <Col sm={12}>
                            <h2>{idCt.t("Interesse na disciplina eletiva", "Interest in elective courses")}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <PainelConteudo legenda={idCt.t("Acesso Negado", "Access Denied")}
                                texto={
                                    <Row>
                                        <Col sm={12}>
                                            {idCt.t("Somente docentes e discentes tem acesso a este conteúdo.", "Only professors and students have access to this content.")}                                           
                                        </Col>
                                    </Row>
                                } />
                        </Col>
                    </Row>
                </Container >
            );
        }
        if ((infoContext.user.profile.professor) || (infoContext.user.profile.coordenadorNacional) || (infoContext.user.profile.admin)) {
            return (
                <Container >
                    <Row>
                        <Col sm={12}>
                            <h2>{idCt.t("Interesse do professor em ofertar disciplina eletiva.", "Professor's interest in offering elective course.")}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <PainelConteudo legenda={idCt.t("Adicionar Oferta", "Add Offer")}
                                texto={
                                    <Row>
                                        <Col sm={12}>
                                            <ProfessorDiscEletiva AtualizaPagina={updatePage} />
                                        </Col>
                                    </Row>
                                } />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <PainelConteudo legenda={idCt.t("Disciplinas Eletivas Ofertadas", "Elective Courses Offered")}
                                texto={
                                    <Row>
                                        <Col sm={12}>
                                            <OfertaDiscEletiva AtualizaPagina={AtualizaPagina} />
                                        </Col>
                                    </Row>
                                } />
                        </Col>
                    </Row>
                </Container >
            );
        }

        return (
            <Container >
                <Row>
                    <Col sm={12}>
                        <h2>{idCt.t("Interesse do aluno por disciplina eletiva", "Student interest in elective course")}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>                    
                        <PainelConteudo legenda={idCt.t("Perfil do Aluno", "Student Profile")}
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        <PerfilAluno />
                                    </Col>
                                </Row>                            
                            } />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <PainelConteudo legenda={idCt.t("Interesse Disciplinas Eletivas", "Interest in Elective Courses")}
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        <AlunoDiscEletiva />
                                    </Col>
                                </Row>
                            } />                    
                    </Col>           
                </Row>
            </Container >
        );
    }

    return (
        renderAll()
        );
}
