import React, { useState } from 'react';
import MessageContext from './messageContext';

export default function MessageContextProvider(props) {


    const [open, setOpen] = useState(false);
    const [variant, setVariant] = useState('info');
    const [message, setMessage] = useState('');


    function openMessage(variant, message) {
        setOpen(true);
        setVariant(variant);
        setMessage(message);
    }
    function closeMessage() {
        setOpen(false);
    }


    return (
        <MessageContext.Provider
            value={{
                open: open,
                openMessage: openMessage,
                closeMessage: closeMessage,
                variant: variant,
                message: message
            }}
        >
            {props.children}
        </MessageContext.Provider>
    )    
}