import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { Button } from 'primereact/button';
import Mensagens from '../Mensagens/Mensagens';
import ModalMensagem from '../ModalMensagem/ModalMensagem';

export default class DiscentesCompleto extends Component {

    PodeEditarNaInstituicao = -1;
    user = null;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            loadingSave: false,
            Alunos: [],
            ExibeMensagem: false,
            mensagemAviso: null,
            email: "",
            ExibeEditarAluno: false,
            AlunoSelecionado: null
        };
        this.updateExibeMensagem = this.updateExibeMensagem.bind(this);
        this.updateExibeEditarAluno = this.updateExibeEditarAluno.bind(this);
        this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
        this.ColunaEditar = this.ColunaEditar.bind(this);
    }

    updateExibeMensagem = (ExibeMensagem) => { this.setState({ ExibeMensagem }); }
    updateExibeEditarAluno = (ExibeEditarAluno) => { this.setState({ ExibeEditarAluno }); }

    componentDidMount() {
        this.CarregarDados();
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.QualInstituicao !== this.props.QualInstituicao) ||
            (prevProps.QualAnoIngresso !== this.props.QualAnoIngresso)){
            this.CarregarDados();
        }
    }

    CarregarDados() {
        this.setState({ loading: true, globalFilter: null, AlunoSelecionado: null });
        this.PodeEditarNaInstituicao = -1;        
        if (this.props.QualInstituicao <= 0) {
            if (!((this.props.QualAnoIngresso) && (parseInt(this.props.QualAnoIngresso) > 2016))) {
                this.setState({ Alunos: [], loading: false });
                return;
            }
        }
        if ((this.props.TipoEdicao) && (this.props.TipoEdicao == "Tudo") && (this.props.QualInstituicaoUsr)) {
            this.PodeEditarNaInstituicao = this.props.QualInstituicaoUsr.id;
        }
        if ((this.props.TipoEdicao) && (this.props.TipoEdicao == "Instituicao") &&
            (this.props.QualInstituicaoUsr) && (this.props.QualInstituicao == this.props.QualInstituicaoUsr.id)) {
            this.PodeEditarNaInstituicao = this.props.QualInstituicaoUsr.id;
        }
        let StringFetch = 'api/Discentes/ListaAtuaisDiscentes?QualInstituicao=' + this.props.QualInstituicao;
        if (this.props.QualAnoIngresso) {
            StringFetch += '&QualAnoIngresso=' + this.props.QualAnoIngresso;
        }
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                this.setState({ Alunos: data, loading: false });
            })
            .catch(error => this.setState({
                ExibeMensagem: true, loading: false,
                mensagemAviso: { statusCode: 2, statusDescription: "Ocorreu um erro ao ler os secretários.", statusVariant: "error" }
            }));
    }

    editarAluno() {
        this.setState({ loadingSave: true, ExibeEditarAluno: false, ExibeMensagem: false, mensagemAviso: null });
        const { AlunoSelecionado } = this.state;

        const options = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ID: AlunoSelecionado.id, Nome: AlunoSelecionado.nome, Email: this.state.email,
                UsuarioID: AlunoSelecionado.usuarioID
            }),
            cache: 'default'
        };

        fetch('api/Discentes/PutMudancaEmail', options)
            .then(r => {
                r.json().then(response => {
                    if (response.statusCode) {
                        this.setState({ ExibeMensagem: true, mensagemAviso: response, loadingSave: false });
                    }
                    else {
                        this.setState({ loadingSave: false });
                    }
                    this.CarregarDados();
                });
            })
            .catch(error => this.setState({
                ExibeMensagem: true, loadingSave: false,
                mensagemAviso: { statusCode: 2, statusDescription: "Ocorreu erro ao atualizar discente.", statusVariant: "error" }
            }));
    }

    abrirJanela(endereco) {
        if ((endereco.substring(0, 7) != 'http://') && (endereco.substring(0, 8) != 'https://')) {
            endereco = 'https://' + endereco;
        }
        window.open(endereco);
    }

    ColunaEditar(rowData, column) {
        let contentsLattes = <div style={{ display: 'inline-block' }}></div>;
        let contentsFace = <div style={{ display: 'inline-block' }}></div>;
        let contentsEditar = <div style={{ display: 'inline-block' }}></div>;

        if (rowData.lattesEndereco) {
            contentsLattes= <div style={{ display: 'inline-block' }}>
                                <IconButton size="small" color="primary" aria-label="Lattes" style={{ verticalAlign: "top", padding: "6px" }}
                                    onClick={() => this.abrirJanela(rowData.lattesEndereco)}>
                                    <Icon fontSize="small">
                                        <img src="images/lattes2.png" alt="Lattes" style={{ verticalAlign: "top", width: "100%", height: "100%", margin: "0px", padding: "0px" }} />
                                    </Icon>
                                </IconButton>
                            </div>;
        }
        if (rowData.facebookEndereco) {
            contentsFace= <div style={{ display: 'inline-block' }}>
                                <IconButton size="small" color="primary" aria-label="Lattes" style={{ verticalAlign: "top", padding: "6px" }}
                                    onClick={() => this.abrirJanela(rowData.facebookEndereco)}>
                                    <Icon fontSize="small">
                                        <img src="images/facebook-logo.svg" alt="Lattes" style={{ verticalAlign: "top", width: "100%", height: "100%", margin: "0px", padding: "0px"}} />
                                    </Icon>
                                </IconButton>
                            </div>;
        }
        if ((this.PodeEditarNaInstituicao > 0) && (rowData.email) ) {
            contentsEditar= <div style={{ display: 'inline-block' }}>
                                <IconButton size="small" color="secondary" aria-label="Download" style={{ verticalAlign: "top", padding: "6px" }}
                                    onClick={() => this.setState({ ExibeEditarAluno: true, AlunoSelecionado: rowData, email: rowData.email })}>
                                    <Icon fontSize="small">edit</Icon>
                                </IconButton>
                            </div>;
        }
        return (
            <div>
                {contentsLattes}
                {contentsFace}
                {contentsEditar}
            </div>
        );
    }

    rowExpansionTemplate(data) {
        return (
            <div className="p-grid p-fluid" style={{ padding: '1em' }}>
                <div className="p-col-12 p-md-12">
                    <div className="p-grid">
                        <div className="p-md-2">Email: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.email}</div>

                        <div className="p-md-2">Início: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.inicioAnoMes}</div>

                        <div className="p-md-2">Orientador: </div>
                        <div className="p-md-10" style={{ fontWeight: 'bold' }}>{data.orientador}</div>
                    </div>
                </div>
            </div>
        );
    }

    renderAlunosCelular() {
        var header = <div style={{ 'textAlign': 'left' }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Pesquisar" size="20" />
                    </div>;

        return (
            <div>
                <div className="content-section implementation">
                    <DataTable value={this.state.Alunos} paginator={true} rows={12} rowsPerPageOptions={[6, 12, 24]}
                        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        globalFilter={this.state.globalFilter} header={header}
                        expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                        rowExpansionTemplate={this.rowExpansionTemplate}
                    >
                        <Column expander={true} style={{ width: '3em' }} />
                        <Column field="nome" header="Nome" sortable={true} />
                        <Column field="editar" header="+Info" body={this.ColunaEditar} sortable={false} style={{ textAlign: 'center', width: '9em' }} />
                    </DataTable>
                </div>
            </div>
        );
    }

    renderAlunosTamanhoNormal() {
        var header = <div style={{ 'textAlign': 'left' }}>
                        <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
                        <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Pesquisar" size="50" />
                    </div>;
        if (this.props.QualInstituicao === 0) {
            return (
                <div>
                    <div className="content-section implementation">
                        <DataTable value={this.state.Alunos} paginator={true} rows={12} rowsPerPageOptions={[6, 12, 24]}
                            resizableColumns={true} columnResizeMode="fit"
                            globalFilter={this.state.globalFilter} header={header} 
                        >
                            <Column field="nome" header="Nome" sortable={true} />
                            <Column field="instituicaoSigla" header="IA" sortable={true} style={{ textAlign: 'center', width: '9em' }} />
                            <Column field="email" header="Email" sortable={false} />
                            <Column field="inicioAnoMes" header="Início" sortable={true} style={{ textAlign: 'center', width: '8em' }} />
                            <Column field="orientador" header="Orientador" sortable={true} />
                            <Column field="editar" header="+Info" body={this.ColunaEditar} sortable={false} style={{ textAlign: 'center', width: '9em' }} />
                        </DataTable>
                    </div>
                </div>
            );      
        }
        return (
            <div>
                <div className="content-section implementation">
                    <DataTable value={this.state.Alunos} paginator={true} rows={12} rowsPerPageOptions={[6, 12, 24]}
                        resizableColumns={true} columnResizeMode="fit"
                        globalFilter={this.state.globalFilter} header={header}
                    >
                        <Column field="nome" header="Nome" sortable={true} />
                        <Column field="email" header="Email" sortable={false} />
                        <Column field="inicioAnoMes" header="Início" sortable={true} style={{ textAlign: 'center', width: '8em' }} />
                        <Column field="orientador" header="Orientador" sortable={true} />
                        <Column field="editar" header="+Info" body={this.ColunaEditar} sortable={false} style={{ textAlign: 'center', width: '9em' }} />
                    </DataTable>
                </div>
            </div>
        );
    }

    renderEditarAluno() {
        const { AlunoSelecionado } = this.state;
        if (!AlunoSelecionado) {
            return;
        }
        return (<div>
                    <ModalMensagem updateShow={this.updateExibeEditarAluno}
                        show={this.state.ExibeEditarAluno} titulo="Editar Aluno"
                        mensagem=
                        {<div>  
                            <br />
                            <span className="p-float-label">
                                <InputText style={{ width: "100%" }} disabled id="in" value={AlunoSelecionado.nome} />
                                <label htmlFor="in">Nome</label>
                            </span>                            
                            <br /><br />
                            <span className="p-float-label">
                                <InputText style={{ width: "100%" }} id="in" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                <label htmlFor="in">Email</label>
                            </span>
                            <span style={{ color: "red" }}><i>Cuidado: </i></span><i>somente emails do <b>Google</b> ou <b>Facebook</b> ou <b>Microsoft</b>.</i>
                            <br /><br />
                            <div style={{ textAlign: "left" }}>
                                <Button label="Salvar" onClick={() => this.editarAluno()} className="p-button-raised p-button-success" />
                            </div>
                            <br />
                        </div>
                        }
                    />
                </div>
        );
    }

    renderAlunos() {
        //let paginatorLeft = <Button icon="pi pi-refresh" />;
        //let paginatorRight = <Button icon="pi pi-cloud-upload" />;
        /*paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}*/
        /* <Column field="nome" header="Nome" sortable={true} filter={true} / > */
        var windowWidth = window.innerWidth;
        if (windowWidth < 770) {
            return this.renderAlunosCelular();
        }
        else {
            return this.renderAlunosTamanhoNormal();
        }
    }

    render() {
        const { loading, loadingSave, ExibeMensagem, mensagemAviso } = this.state;

        let contents = <p></p>;
        let contentsAviso = <p></p>;
        if ((this.props.QualInstituicao <= 0) && (!this.props.QualAnoIngresso)) {
            contents =  <div className="overlay">
                            <em>Escolha uma instituição ou ano de ingresso.</em>
                        </div>;
        }
        else if ((this.props.QualInstituicao <= 0) && (parseInt(this.props.QualAnoIngresso) <= 0)) {
            contents =  <div className="overlay">
                            <em>Escolha uma instituição ou ano de ingresso.</em>
                        </div>;
        }
        else if (loading) {
                contents =  <div className="overlay">
                                <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                            </div>;
        }
        else if (loadingSave) {
            contents =  <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i> <em>Atualizando dados...</em>
                        </div>;
        }
        else {
            contents = this.renderAlunos();
        }
        if (mensagemAviso) {
            contentsAviso = <Mensagens Exibir={ExibeMensagem} AtualizaExibir={this.updateExibeMensagem}
                                       Opcao={mensagemAviso.statusVariant} Mensagem={mensagemAviso.statusDescription} />;
        }

        return (
            <div>
                {contents}
                {contentsAviso}
                {this.renderEditarAluno()}
            </div>
        );
        /*
                <div>
                    <ModalLoading show={loadingSave} />
                </div>
         */ 

    }

}