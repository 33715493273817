import React, { Component } from 'react';
import { AutoComplete } from 'primereact/autocomplete';

export default class SelecAluno extends Component {    

    constructor(props) {
        super(props);
        this.state = {
            Aluno: null, 
            ObjAlunos: null,
            Alunos: null,
            alunoSuggestions: null,
            loading: true,
            error: null,
            IndiceAluno: -1
        };
    }

    CarregaAlunos() {
        let StringFetch = 'api/Discentes/ListaDiscentes';
        if (this.props.QualInstituicao) {
            StringFetch = 'api/Discentes/ListaDiscentes?QualInstituicao=' + this.props.QualInstituicao;
        }
        else if (this.props.QualOferta) {
            StringFetch = 'api/Discentes/ListaDiscentesOferta?QualOferta=' + this.props.QualOferta;
        }
        fetch(StringFetch)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Ocorreu um erro ao receber os dados...');
                }
            })
            .then(data => {
                let a = [];
                for (let i = 0; i < data.length; i++) {
                    a.push(data[i].nome);
                }
                this.setState({ ObjAlunos: data, Alunos: a, loading: false, IndiceAluno: -1 });              
            })
            .catch(error => this.setState({ error, loading: false }));
    }

    componentDidMount() {
        this.CarregaAlunos();
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.QualOferta != this.props.QualOferta) ||
            (prevProps.QualInstituicao != this.props.QualInstituicao)) {
            this.CarregaAlunos();
        }
    }

    suggestAlunos(event) {
        let results = this.state.Alunos.filter((brand) => {
            return brand.toLowerCase().startsWith(event.query.toLowerCase());
        });

        this.setState({ alunoSuggestions: results });
    }

    AlteraAluno(pValor) {
        let IndiceAluno = -1;
        if ((!pValor || 0 === pValor.length) && (this.state.Aluno !== null && pValor != this.state.Aluno)) {
            this.setState({ Aluno: pValor, IndiceAluno });
            let NenhumAluno = { id: 0, nome: "" };
            this.props.AtualizaQualAluno(NenhumAluno);
        }        
        for (let i = 0; i < this.state.ObjAlunos.length; i++) {
            if (this.state.ObjAlunos[i].nome === pValor.toUpperCase()) {
                IndiceAluno = i;
            }
        }
        if (IndiceAluno > -1) {
            this.setState({ Aluno: pValor, IndiceAluno });
            this.props.AtualizaQualAluno(this.state.ObjAlunos[IndiceAluno]);
        }
        else {
            if (IndiceAluno != this.state.IndiceAluno) {
                this.setState({ Aluno: pValor, IndiceAluno });
                let NenhumAluno = { id: 0, nome: "" };
                this.props.AtualizaQualAluno(NenhumAluno);
            }
            else {
                this.setState({ Aluno: pValor });
            }
        }
    }

    itemTemplate(brand) {
        //<span style={{width: '200px'}}>{brand}<br/></span>
        return (
            <div style={{ minWidth: '200px', fontSize: '14px', margin: '0 0 0 0', padding: '0px' }}><span>{brand}</span></div>
        );
    }

    renderAlunos() {
        var TamanhoInput = 36;
        var windowWidth = window.innerWidth;
        if (windowWidth < 770) { //Celular
            TamanhoInput = 26;
        }
        return (
            <div>
                <span className="p-float-label">
                    <AutoComplete style={{ width: "100%" }} dropdown={true}
                        size={TamanhoInput}
                        onChange={(e) => this.AlteraAluno(e.value)}
                        value={this.state.Aluno} 
                        suggestions={this.state.alunoSuggestions}
                        itemTemplate={this.itemTemplate.bind(this)}
                        minLength={3}
                        completeMethod={this.suggestAlunos.bind(this)} />
                    <label htmlFor="in">Nome</label>
                </span>
            </div> 
        );
    }
    render() {
        const { loading, error } = this.state;
        let contents = <p>Conteudo</p>;
        if (error) {
            contents = <p>{error.message}</p>;
        }
        else if (loading) {
            contents =  <div className="overlay">
                            <i className="fa fa-refresh fa-spin"></i> <em>Lendo dados...</em>
                        </div>;
        }
        else {
            contents = this.renderAlunos();
        }

        return (
            <div>
                {contents}
            </div>
        );

    }
}