import React, { useState, useContext, useEffect } from 'react';
import { Col, Container, Row} from 'react-bootstrap';
import { Button } from 'primereact/button'; 
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import { Checkbox } from 'primereact/checkbox';
import { Fieldset } from 'primereact/fieldset';
import DocumentoUpload from '../../components/Documento/DocumentoUpload';
import './DocumentacaoMatricula.css'; 

import InfoContext from '../../context/infoContext/infoContext';
import MessageContext from '../../context/messageContext/messageContext';

export function DocumentacaoMatricula(props) {

    //Contexto    
    const infoContext = useContext(InfoContext);
    const messageContext = useContext(MessageContext);

    //Constructor
    const [AcessoNegado, setAcessoNegado] = useState(true);
    const [ciente, setCiente] = useState(false);

    useEffect(() => {
        //componentDidMount
        if ((infoContext.user != null) && (infoContext.user.profile.aluno)) {
            setAcessoNegado(false);
        }
        else {
            messageContext.openMessage("warning", "Acesso permitido apenas para discentes!");
        }
    }, []); 

    function renderAll() {
        if (AcessoNegado) {
            return (
                <Container >
                    <Row>
                        <Col sm={12}>
                            <h2>Documentação para matrícula em disciplinas eletivas</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <PainelConteudo legenda="Acesso Negado"
                                texto={
                                    <Row>
                                        <Col sm={12}>
                                            Acesso permitido apenas para discentes!
                                        </Col>
                                    </Row>
                                } />
                        </Col>
                    </Row>
                </Container >
            );
        }
        let contentsTop = 
            <div>
                <p>Caro <b>discente</b>, o seu vínculo com as instituições (exceto {infoContext.userInfo.instituicao.sigla}) onde cursará as disciplinas eletivas é de <b>aluno especial</b>. </p>
                <p>Para que este vínculo seja concretizado, as instituições precisam de seus documentos para efetuar a matrícula.</p>
                <p>Se você escolheu uma ou mais disciplinas eletivas que não serão ofertadas pela sua instituição de origem, você precisa fornecer a documentação necessária para o seu vínculo de aluno especial.</p>
                <p>Vale ressaltar que o aluno não é obrigado a anexar os documentos no Observatório ProfEPT. O papel do Observatório é agilizar a entrega da documentação entre os alunos e as secretarias das instituições associadas.</p>
                <p>Os documentos digitalizados devem estar em arquivos <span style={{ color: 'red' }}><b>PDF</b></span> de no máximo <span style={{ color: 'red' }}><b>3 MB</b></span>. Caso o seu arquivo possua mais de 3 MB, alguns sites realizam a redução de tamanho de arquivos PDF, por exemplo <a href={"https://www.ilovepdf.com/pt/comprimir_pdf"} target="blank">ILovePDF</a>.</p>
                <p>Para cada documento, você pode anexar apenas um arquivo. Se o seu diploma, por exemplo, estiver em dois arquivos (frente e verso), você deve juntar (frente e verso) em um único arquivo. Vários sites fazem a junção de arquivos PDF em um único arquivo, por exemplo <a href={"https://smallpdf.com/pt/juntar-pdf"} target="blank">Smallpdf</a>.</p>
                <Checkbox onChange={e => setCiente(e.checked)} checked={ciente}></Checkbox> Ciente que os documentos serão armazenados na base de dados do Observatório e que estes documentos estarão disponíveis apenas para as Secretarias das Instituições onde o aluno será vinculado como aluno especial. Por motivo de segurança, estes documentos estarão disponíveis apenas durante a oferta das disciplinas.
                <br /><br />
            </div>
        let contentsL =
                <div>
                    <Fieldset legend="Requerimento de Matrícula (Eletiva)">
                        <DocumentoUpload Template="RequerimentoMatricula_ProfEPT.docx" Visivel={ciente} Id={infoContext.userInfo.idaluno} TipoDoc="1" />
                    </Fieldset> 
                    <Fieldset legend="Declaração de Matrícula (IA Origem)">
                        <DocumentoUpload Visivel={ciente} Id={infoContext.userInfo.idaluno} TipoDoc="2" />
                    </Fieldset>
                    <Fieldset legend="RG e CPF">
                        <DocumentoUpload Visivel={ciente} Id={infoContext.userInfo.idaluno} TipoDoc="3" />
                    </Fieldset>   
                </div>
        let contentsR =
                <div>
                    <Fieldset legend="Certidão de Nascimento ou Casamento">
                        <DocumentoUpload Visivel={ciente} Id={infoContext.userInfo.idaluno} TipoDoc="4" />
                    </Fieldset>  
                    <Fieldset legend="Diploma de Graduação">
                        <DocumentoUpload Visivel={ciente} Id={infoContext.userInfo.idaluno} TipoDoc="5" />
                    </Fieldset>             
                </div>;        
        return (
            <Container >
                <Row>
                    <Col sm={12}>
                        <h2>Documentação para matrícula em disciplinas eletivas</h2>
                        <PainelConteudo legenda="Documentos"
                            texto={
                                <Container >
                                    <Row>
                                        <Col sm={12}>
                                            {contentsTop}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            {contentsL}
                                        </Col>
                                        <Col sm={6}>
                                            {contentsR}
                                        </Col>
                                        </Row>
                                </Container>
                            } />
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        renderAll()
        )
}
