import React, { useContext, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PainelConteudo from '../../components/PainelConteudo/PainelConteudo';
import SelectAnosIngresso from '../../components/SelectAnosIngresso/SelectAnosIngresso';
import SelecInstituicoes from '../../components/SelecInstituicoes/SelecInstituicoes';
import DiscentesCidades from '../../components/DiscentesCidades/DiscentesCidades';

import IdiomaContext from '../../context/idiomaContext/idiomaContext';

export function ResidenciaAlunos(props) {

    //Contexto
    const idCt = useContext(IdiomaContext);

    let QualInstituicaoInicial = {
        id: 0,
        nome: "Todas as Instituições",
        sigla: "IFTM",
        quantidadeProfessores: 0,
        quantidadeProfessoresLattes: 0
    }
    const [QualInstituicao, setQualInstituicao] = useState(QualInstituicaoInicial);
    const [QualAno, setQualAno] = useState(0);

    function renderAll() {        
        return (
            <Container >  
                <Row>
                    <Col sm={12}>
                        <h2>{idCt.t("Local de Residência dos Discentes / Egressos", "Place of Residence of Students / Alumni")}</h2>
                        <PainelConteudo legenda={idCt.t("Pesquisar", "Search")}
                            texto={
                                <Container >
                                    <Row>
                                        <Col sm={6}>
                                            {idCt.t("Escolha a Instituição:", "Choose the Institution:")}
                                            <SelecInstituicoes AtualizaQualInstituicao={setQualInstituicao} />
                                        </Col>
                                        <Col sm={6}>
                                            {idCt.t("Escolha o Ano de Ingresso:", "Choose the Year:")}
                                            <SelectAnosIngresso AtualizaQualAno={setQualAno} />
                                        </Col>
                                    </Row>
                                </Container>
                            } />
                    </Col>
                </Row>          
                <Row>
                    <Col sm={12}>
                        <PainelConteudo mostraAjuda={true} qualIndicador="LOCALIDADE" legenda={idCt.t("Cidades", "Cities")}
                            texto={
                                <Row>
                                    <Col sm={12}>
                                        <DiscentesCidades QualInstituicao={QualInstituicao} QualAno={QualAno} />
                                    </Col>
                                </Row>
                            } />                    
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        renderAll()
        );
}
