import React, { useState, useContext, useEffect, Fragment } from 'react';
import { NavDropdown, DropdownItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import '../../App.css';
import LoadingContext from '../../context/loadingContext/loadingContext';
import IdiomaContext from '../../context/idiomaContext/idiomaContext';
import InfoContext from '../../context/infoContext/infoContext';
import usuarioService from '../../services/usuarioService';

export function LoginMenu(props) {

    //Constructor
    const loadingContext = useContext(LoadingContext);
    const idCt = useContext(IdiomaContext);
    const infoContext = useContext(InfoContext);
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [userName, setUserName] = useState(null);
    const [userPicture, setUserPicture] = useState('user.svg');

    useEffect(() => {
        //componentDidMount
        const _subscription = authService.subscribe(() => populateState());
        populateState();
        //componentWillUnmount
        return () => { authService.unsubscribe(_subscription); }
    }, []);   
         
    async function populateState() {
        //loadingContext.showLoading();
        const [GetisAuthenticated, Getuser] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        setAuthenticated(GetisAuthenticated);
        if (Getuser) {            
            setUserName(Getuser.nickname);
            if (Getuser.picture) {
                setUserPicture(Getuser.picture);
            }      
            Getuser.profile = JSON.parse(Getuser.profile);
            infoContext.setUser(Getuser);
            userInfoStateUpdate();
        }
        else {
            infoContext.setUser(null);
            infoContext.setUserInfo(null);
        };
        //loadingContext.hideLoading();
    }

    function userInfoStateUpdate() {
        usuarioService.getUserInfo()
            .then(async res => {
                infoContext.setUserInfo(await res.json());
            })
            .catch(err => {
                infoContext.setUserInfo(null);
            })
    } 

    function authenticatedView(userName, profilePath, logoutPath) {
        //<Fragment style={{ padding: "0px", marginLeft: "10px" }}>
        let MenuAdmin = null;
        if ((infoContext.user != null) && (infoContext.user.profile.admin)) {
            MenuAdmin =
                <NavDropdown className="ml-lg-auto" title="Adm" id="adm-dropdown">
                    <LinkContainer to={'/downloadLattes'}>
                        <DropdownItem >
                            <i className="fa fa-user" aria-hidden="true"></i> Importar Lattes
                        </DropdownItem>
                    </LinkContainer>
                    <LinkContainer to={'/excluiEstatisticas'}>
                        <DropdownItem >
                            <i className="fa fa-user" aria-hidden="true"></i> Excluir Estatísticas
                        </DropdownItem>
                    </LinkContainer>
                    <LinkContainer to={'/adm'}>
                        <DropdownItem >
                            <i className="fa fa-user" aria-hidden="true"></i> Gerar Estatísticas
                        </DropdownItem>
                    </LinkContainer>
                </NavDropdown>;
        }
        return (
            <Fragment>
                {MenuAdmin}
            <NavDropdown className="ml-lg-auto"
                title={<span style={{ color: 'black' }}><img src={userPicture} alt="Usuário" className="prof-imagem-redondo" />{userName}</span>}
                id="usuario-dropdown">
                <LinkContainer to={'/perfil'}>
                    <DropdownItem >
                        <i className="fa fa-user" aria-hidden="true"></i> {idCt.t("Perfil", "Profile")}
                    </DropdownItem>
                </LinkContainer>
                <LinkContainer to={profilePath}>
                    <DropdownItem >
                        <i className="fa fa-user" aria-hidden="true"></i> {idCt.t("Configurações da Conta", "Settings")}
                    </DropdownItem>
                </LinkContainer>
                <LinkContainer to={logoutPath}>
                    <DropdownItem >
                        <i className="fa fa-sign-out" aria-hidden="true"></i> {idCt.t("Sair", "Logout")}
                    </DropdownItem>
                </LinkContainer>
            </NavDropdown>
            </Fragment>
        );            

    }
    function anonymousView(registerPath, loginPath) {
        //<Fragment style={{ padding: "0px", marginLeft: "10px" }}>
        return (
            <Fragment>
                <NavDropdown className="ml-lg-auto" title="Login"
                    id="usuario-dropdown">
                    <LinkContainer to={loginPath}>
                        <DropdownItem >
                            <i className="fa fa-user" aria-hidden="true"></i> Login
                        </DropdownItem>
                    </LinkContainer>
                    <LinkContainer to={'/primeiroAcesso'} >
                        <DropdownItem >
                            <i className="fa fa-user" aria-hidden="true"></i> {idCt.t("Primeiro Acesso", "First account")} 
                        </DropdownItem>
                    </LinkContainer>
                </NavDropdown>
            </Fragment>
        );
    }

    function returnRender() {
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return authenticatedView(userName, profilePath, logoutPath);
        }
    }

    return (
        returnRender()
    );

}
